import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { HangfireDetailsModel } from "app/features/system-settings/hangfire-details/hangfire-response.model";
import { FieldLogSetup } from "app/shared/classes/fieldLogSetup";
import * as AspNetData from "devextreme-aspnet-data-nojquery";

import { ClientIndividualBasicInfoModel } from "app/features/client/clientIndividual/clientIndividual.model";
import { referenceTablesEnum } from "app/shared/enumerations/referenceTablesEnum";
import CustomStore from "devextreme/data/custom_store";
import { Observable, throwError } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";
import { AuthTokenService } from "./auth-token.service";
import { HelperFunctionsService } from "./helperFunctions.service";
import { environment } from 'environments/environment';


@Injectable()
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    public authToken: AuthTokenService
  ) {
  }

  public selectedRowId: any;
  public cachedData: any[] = [];

  private getCachedData(endpoint: string) {
    if (!this.cachedData.find((x) => x.endpoint == endpoint)) {
      this.cachedData.push({
        endpoint: endpoint,
        data: this.fetch(endpoint).pipe(shareReplay(1)),
      });
    }
    return this.cachedData.find((x) => x.endpoint == endpoint).data;
  }

  /**
   * @param endpoint the endpoint we would like to remove from the cache (if it exists).
   */
  public removeEndpointFromCachedData(endpoint: string) {
    this.cachedData = this.cachedData.filter(
      (data) => data.endpoint !== endpoint
    );
  }

  /**
   * The below properties cache data temporarily.
   */
  public temporarilyCachedLayoutsData: any[] = [];
  public temporarilyCachedCustomFieldData: any[] = [];

  /**
   * This method is used to store the layout result of `getLayoutWithCustomFields`.
   * In forms such as the transaction-screening-case component, this endpoint is called consecutively for each
   * person analysis entity, with the same request body. This is not efficient as all of them return the same response.
   * Thus, we cache the first response for 1.5 seconds, so that the subsequent calls will not call the API again,
   * and after 1.5 seconds we remove it from the cache. The same goes for the business analysis.
   *
   * @param endpoint should always be '/user/layout/customFields'
   * @param body contains a component name, data grid id, and a client id
   * @returns the observable of `getLayoutWithCustomFields`
   */
  private getCachedLayoutsData(endpoint: string, body: any) {
    if (
      !this.temporarilyCachedLayoutsData.find(
        (x) =>
          x.endpoint == endpoint &&
          x.body.ComponentName == body.ComponentName &&
          x.body.DataGridId == body.DataGridId &&
          x.body.ClientId == body.ClientId
      )
    ) {
      this.temporarilyCachedLayoutsData.push({
        endpoint: endpoint,
        data: this.postJson(endpoint, JSON.stringify(body)).pipe(
          shareReplay(1)
        ),
        body: body,
      });
      setTimeout(() => {
        this.temporarilyCachedLayoutsData = [];
      }, 2000);
    }
    return this.temporarilyCachedLayoutsData.find(
      (x) =>
        x.endpoint == endpoint &&
        x.body.ComponentName == body.ComponentName &&
        x.body.DataGridId == body.DataGridId &&
        x.body.ClientId == body.ClientId
    ).data;
  }

  /**
   * This method is used to store the custom fields setup result of `getCustomFieldsByReferenceTable`.
   * It is a common method, that might be called by many components consecutively with the same referenceTable and client id.
   * This is not efficient as all of them return the same response.
   * Thus, we cache the first response for 1.5 seconds, so that the subsequent calls will not call the API again,
   * and after 1.5 seconds we remove it from the cache.
   *
   * @param endpoint should always be in the following format '/settings/customFields/form/{referenceTable}/{clientId}'
   * @param body contains a component name, data grid id, and a client id
   * @returns the observable of `getLayoutWithCustomFields`
   */
  private getCachedCustomFieldData(endpoint: string) {
    if (
      !this.temporarilyCachedCustomFieldData.find((x) => x.endpoint == endpoint)
    ) {
      this.temporarilyCachedCustomFieldData.push({
        endpoint: endpoint,
        data: this.fetch(endpoint).pipe(shareReplay(1)),
      });
      setTimeout(() => {
        this.temporarilyCachedCustomFieldData = [];
      }, 1500);
    }
    return this.temporarilyCachedCustomFieldData.find(
      (x) => x.endpoint == endpoint
    ).data;
  }

  private fetch(url, params?: HttpParams): Observable<any> {
    let httpOptions: { headers: HttpHeaders, params?: HttpParams } = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": this.getLanguageCode(),
      }),
    };
    if (params) {
      httpOptions = {
        ...httpOptions, params: params
      }
    }

    return this.httpClient.get(environment.URL + url, httpOptions).pipe(
      map((data: any) => data || data.data),
      catchError(this.handleError)
    );
  }

  private fetchBase(url): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": this.getLanguageCode(),
      }),
    };
    return this.httpClient.get(environment.BASE_URL + url, httpOptions).pipe(
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private post(url, body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": this.getLanguageCode(),
      }),
    };

    return this.httpClient
      .post(environment.URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  private delete(url, body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": this.getLanguageCode(),
      }),
      body: body,
    };

    return this.httpClient.delete(environment.URL + url, httpOptions).pipe(
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private postBase(url, body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": this.getLanguageCode(),
      })
    };

    return this.httpClient
      .post(environment.BASE_URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  private putBase(url, body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": this.getLanguageCode(),
      }),
    };
    return this.httpClient
      .put(environment.BASE_URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  private postForm(url, body): Observable<any> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'multipart/form-data'
    //   })
    // };
    return this.httpClient.post(environment.URL + url, body).pipe(
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private postJson(url, body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept-Language": this.getLanguageCode(),
      }),
    };
    return this.httpClient
      .post(environment.URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  private postJsonBase(url, body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept-Language": this.getLanguageCode(),
      }),

    };
    return this.httpClient
      .post(environment.BASE_URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  public putJson(url, body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept-Language": this.getLanguageCode(),
      }),
    };
    return this.httpClient
      .put(environment.URL + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  private put(url): Observable<any> {
    return this.httpClient.put(environment.URL + url, null).pipe(
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private deleteJson(url, body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept-Language": this.getLanguageCode(),
      }),
      body: body,
    };
    return this.httpClient.delete(environment.URL + url, httpOptions).pipe(
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public putForm(url, body): Observable<any> {
    return this.httpClient.put(environment.URL + url, body).pipe(
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public setSelectedRowId(id: any) {
    this.selectedRowId = id;
  }

  public getSelectedRowId() {
    return this.selectedRowId;
  }

  //TODO: Dummy url below has been added to avoid errors on console.  It will be good idea if we find a way to pass IDs to update or delete grid rows.
  /**
   * @params key: id
   * @params method: GET/POST/PUT/DELETE
   * @params endpoint: controllerName/endpoint
   * @params body: pass an object with parameters or null
   * @returns Retrieve data in customStore using Paging.  The amount of data that this method is going to take and skip from the database defines in template file.
   */
  public getAspNetDataStore(
    key: any,
    method: string,
    endpoint: string,
    body: any
  ): CustomStore {
    return AspNetData.createStore({
      key: key,
      loadMethod: method,
      loadUrl: body != null ? `${environment.URL}/${endpoint}?customFilters=` + JSON.stringify(body) : `${environment.URL}/${endpoint}`,
      deleteUrl: `${environment.URL}/paging/devExtremeDummyUrl`,
      updateUrl: `${environment.URL}/paging/devExtremeDummyUrl`,
      onBeforeSend: (_, httpOptions) => {
        if (environment.WindowsAuthenticationMode == true) {
          httpOptions.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept-Language": this.getLanguageCode(),
          };
          httpOptions.xhrFields = {
            withCredentials: true,
          };
        } else {
          httpOptions.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${this.authToken.token}`,
            "Accept-Language": this.getLanguageCode()
          };
        }
        // If condition prevents disabling the search button while filtering with clients dropdown.
        if (
          endpoint != "reportFilters/filter" &&
          endpoint != "paging/filter" &&
          endpoint != "paging/criteriaFilter"
        ) {
          // Disable button while loading the data
          $('button:contains("Search")')
            .addClass("disabled")
            .prop("disabled", true);
          $('button:contains("Reload")')
            .addClass("disabled")
            .prop("disabled", true);
        }
      },
      onLoaded: function (_) {
        // Enable button when data have loaded
        $('button:contains("Search")')
          .removeClass("disabled")
          .prop("disabled", false);
        $('button:contains("Reload")')
          .removeClass("disabled")
          .prop("disabled", false);
      },
      errorHandler: function (_) {
        $('button:contains("Search")')
          .removeClass("disabled")
          .prop("disabled", false);
        $('button:contains("Reload")')
          .removeClass("disabled")
          .prop("disabled", false);
      },
    });
  }

  /**
   * @params method: GET/POST/PUT/DELETE
   * @params endpoint: controllerName/endpoint
   * @params body: pass an object with parameters or null
   * @returns Retrieve data in customStore using Paging.  The amount of data that this method is going to take and skip from the database defines in template file.
   */
  public getAspNetDataStoreWithSort(
    key: string,
    sortKey: string,
    desc: boolean,
    method: string,
    endpoint: string,
    body: any
  ): CustomStore {
    //Passing the key parameter enforces devexpress to force an orderBy statement by the id column. Removing this as it is causes delays for transaction screening cases
    return AspNetData.createStore({
      key: key,
      loadMethod: method,
      loadParams: {
        sort: JSON.stringify([{
          selector: sortKey,
          desc: desc
        }])
      },
      loadUrl: body != null ? `${environment.URL}/${endpoint}?customFilters=` + JSON.stringify(body) : `${environment.URL}/${endpoint}`,
      deleteUrl: `${environment.URL}/paging/devExtremeDummyUrl`,
      updateUrl: `${environment.URL}/paging/devExtremeDummyUrl`,
      onBeforeSend: (_, httpOptions) => {
        if (environment.WindowsAuthenticationMode == true) {
          httpOptions.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept-Language": this.getLanguageCode(),
          };
          httpOptions.xhrFields = {
            withCredentials: true,
          };
        } else {
          httpOptions.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${this.authToken.token}`,
            "Accept-Language": this.getLanguageCode()
          };
        }
        // If condition prevents disabling the search button while filtering with clients dropdown.
        if (
          endpoint != "reportFilters/filter" &&
          endpoint != "paging/filter" &&
          endpoint != "paging/criteriaFilter"
        ) {
          // Disable button while loading the data
          $('button:contains("Search")')
            .addClass("disabled")
            .prop("disabled", true);
          $('button:contains("Reload")')
            .addClass("disabled")
            .prop("disabled", true);
        }
      },
      onLoaded: function (_) {
        // Enable button when data have loaded
        $('button:contains("Search")')
          .removeClass("disabled")
          .prop("disabled", false);
        $('button:contains("Reload")')
          .removeClass("disabled")
          .prop("disabled", false);
      },
      errorHandler: function (_) {
        $('button:contains("Search")')
          .removeClass("disabled")
          .prop("disabled", false);
        $('button:contains("Reload")')
          .removeClass("disabled")
          .prop("disabled", false);
      },
    });
  }


  //Implement all methods for aspnetdatastore
  /**
   * @params key: id
   * @params method: GET/POST/PUT/DELETE
   * @params endpoint: controllerName/endpoint
   * @params body: pass an object with parameters or null
   * @returns Retrieve data in customStore using Paging.  The amount of data that this method is going to take and skip from the database defines in template file.
   */
  public getAspNetDataStoreComplete(
    key: any,
    method: string,
    endpoint: string,
    body: any,
    helperFunctionsService: HelperFunctionsService
  ): CustomStore {
    return AspNetData.createStore({
      key: key,
      loadMethod: method,
      loadUrl: body != null ? `${environment.URL}/${endpoint}?customFilters=` + JSON.stringify(body) : `${environment.URL}/${endpoint}`,
      insertUrl: `${environment.URL}/${endpoint}/add`,
      deleteUrl: `${environment.URL}/${endpoint}/delete`,
      updateUrl: `${environment.URL}/${endpoint}/update`,
      onAjaxError(e) {
        //convert e.error to object
        if (typeof e.error == "string") {
          e.error = JSON.parse(e.error);
        }
        helperFunctionsService.displayNotificationErrorAlert(e);
        // helperFunctionsService.displayNotificationWarningAlertCustomText(e.error[0].title, e.error[0].description, "");

      },
      onBeforeSend: (_, httpOptions) => {
        if (environment.WindowsAuthenticationMode == true) {
          httpOptions.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept-Language": this.getLanguageCode(),
          };
          httpOptions.xhrFields = {
            withCredentials: true,
          };
        } else {
          httpOptions.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${this.authToken.token}`,
            "Accept-Language": this.getLanguageCode(),
          };
        }
        // If condition prevents disabling the search button while filtering with clients dropdown.
        if (
          endpoint != "reportFilters/filter" &&
          endpoint != "paging/filter" &&
          endpoint != "paging/criteriaFilter"
        ) {
          // Disable button while loading the data
          $('button:contains("Search")')
            .addClass("disabled")
            .prop("disabled", true);
          $('button:contains("Reload")')
            .addClass("disabled")
            .prop("disabled", true);
        }
      },
      onLoaded: function (_) {
        // Enable button when data have loaded
        $('button:contains("Search")')
          .removeClass("disabled")
          .prop("disabled", false);
        $('button:contains("Reload")')
          .removeClass("disabled")
          .prop("disabled", false);
      },
      errorHandler: function (_) {
        $('button:contains("Search")')
          .removeClass("disabled")
          .prop("disabled", false);
        $('button:contains("Reload")')
          .removeClass("disabled")
          .prop("disabled", false);
      },
    });
  }

  public getAspNetDataStoreWithOnLoadedPromise(
    key: string,
    method: string,
    endpoint: string,
    body: any
  ) {
    return new Promise((resolve, reject) => {
      var dataStore = AspNetData.createStore({
        key: key,
        loadMethod: method,
        loadUrl:
          body != null
            ? `${environment.URL}/${endpoint}?customFilters=` +
            JSON.stringify(body)
            : `${environment.URL}/${endpoint}`,
        deleteUrl: `${environment.URL}/paging/devExtremeDummyUrl`,
        updateUrl: `${environment.URL}/paging/devExtremeDummyUrl`,
        onBeforeSend: (_, httpOptions) => {
          if (environment.WindowsAuthenticationMode == true) {
            httpOptions.headers = {
              "Content-Type": "application/x-www-form-urlencoded",
              "Accept-Language": this.getLanguageCode(),
            };
            httpOptions.xhrFields = {
              withCredentials: true,
            };
          } else {
            httpOptions.headers = {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${this.authToken.token}`,
              "Accept-Language": this.getLanguageCode(),
            };
          }
        },
        onLoaded: (_) => {
          resolve(dataStore);
        },
      }).load();
    });
  }

  public getAuthHeaders() {
    let headers;
    if (environment.WindowsAuthenticationMode == true) {
      headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": this.getLanguageCode(),
      };
    } else {
      headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.authToken.token}`,
        "Accept-Language": this.getLanguageCode(),
      };
    }
    return headers;
  }

  public getDashboardEndpoint() {
    let endpoint = `${environment.URL}/advanced-dashboard`;
    return endpoint;
  }

  public getDashboardEndpointInit() {
    let endpoint = `${environment.URL}/advanced-dashboard-init`;
    return endpoint;
  }

  public updateNotificationIsRead(id: any): Observable<any> {
    let body = {
      id: id,
    };

    return this.putJson(
      "/administration/updateNotificationIsRead",
      JSON.stringify(body)
    );
  }

  public updateAllNotificationsIsRead(isRead: boolean): Observable<any> {
    let body = {
      isRead: isRead,
    };
    return this.putJson(
      "/administration/updateAllNotificationsIsRead",
      JSON.stringify(body)
    );
  }

  public getPowerBiAvailableReports(): Observable<any> {
    return this.fetch("/powerBi/reports");
  }

  public getPowerBiReportEmbedConfig(id: String): Observable<any> {
    return this.fetch(`/powerBi/reports/${id}`);
  }

  public getUser(): Observable<any> {
    return this.fetch("/user/currentuser");
  }

  public getUserHasFirstLoginFlag(): Observable<any> {
    return this.fetch("/user/currentuser/hasFirstLogin");
  }

  public getNotifications(): Observable<any> {
    return this.fetch("/user/notifications");
  }

  public getHealthCheckDashboard(): Observable<any> {
    return this.fetch("/healthcheck/healthcheckdashboard");
  }

  public getAccountServicesStatus(): Observable<any> {
    return this.fetch("/dashboard/accountServicesStatus");
  }

  public getHealthCheckIndicator(): Observable<any> {
    return this.fetch("/healthcheck/healthcheckindicator");
  }

  public getRulesStatistics(): Observable<any> {
    return this.fetch("/healthcheck/rulesStatistics");
  }

  public performHealthCheck(): Observable<any> {
    const body = {};

    return this.postJson(
      "/healthcheck/performHealthCheck",
      JSON.stringify(body)
    );
  }

  public updateBlacklistFileStatusToPending(
    clientType: string,
    fileName: string
  ): Observable<any> {
    return this.putJson(
      `/healthcheck/internalBlacklistFile/${clientType}/${fileName}`,
      "{}"
    );
  }

  public getUserByUsername(username: string): Observable<any> {
    return this.fetch("/administration/users/" + username);
  }

  public getUsers(): Observable<any> {
    return this.fetch("/administration/users");
  }

  public getUsersDropdown(): Observable<any> {
    return this.fetch("/dropdown/users");
  }

  public getUserRegulationGroups(): Observable<any> {
    return this.fetch("/user/userRegulationGroups");
  }

  public getAdminUsers(): Observable<any> {
    return this.fetch("/administration/adminUsers");
  }

  public getDropDownUsers(): Observable<any> {
    return this.fetch("/dropdown/users");
  }

  public getDropDownUsersByClientId(clientId: number): Observable<any> {
    return this.fetch(`/dropdown/users/${clientId}`);
  }

  public getDropDownDefaultCaseWorkflowStatuses(
    username: string
  ): Observable<any> {
    return this.fetch(`/dropdown/caseWorkflowStatuses/'${username}'`);
  }

  public getDropDownCaseWorkflowStatuses(): Observable<any> {
    return this.fetch("/dropdown/caseWorkflowStatuses");
  }

  public getDropDownUserCaseWorkflowStatuses(): Observable<any> {
    return this.fetch("/dropdown/userCaseWorkflowStatuses");
  }

  public getDropDownDataAccessGroups(): Observable<any> {
    return this.fetch("/dropdown/dataAccessGroups");
  }

  public getDropDownCaseStatuses(): Observable<any> {
    return this.fetch("/dropdown/caseStatuses");
  }

  public getParameterExecutionPossibleValues(referenceTable: any, regulationGroupId: any, parameterQueryId: any): Observable<any> {
    return this.fetch(`/dropdown/tuning/parameterExecutions/${referenceTable}/${regulationGroupId}/${parameterQueryId}/possibleValues`);
  }

  public getFieldLogRefColumnValuesTables(): Observable<any> {
    return this.fetch("/dropdown/fieldLogRefColumnValuesTables");
  }

  public getIsInDevelopmentEnv(): Observable<any> {
    return this.fetch("/administration/isInDevelopmentEnv");
  }

  public getUsernames(): Observable<any> {
    return this.fetch("/clientCommon/users");
  }

  public getSelectedPortalQuestionnaireTypesByClientId(
    clientId
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${clientId}/selectedPortalQuestionnaireTypes`
    );
  }

  public getAccountsDetails(): Observable<any> {
    return this.fetch("/administration/accountDetails");
  }

  public getDataAccessGroups(): Observable<any> {
    return this.fetch("/administration/dataAccessGroups");
  }

  public getDataAccessFields(
    dataAccessGroupRegulationGroupId: number
  ): Observable<any> {
    return this.fetch(
      `/administration/dataAccessFields/${dataAccessGroupRegulationGroupId}`
    );
  }

  public getDataAccessField(
    dataAccessGroupRegulationGroupId: number,
    dataAccessFieldId: number
  ): Observable<any> {
    return this.fetch(
      `/administration/dataAccessFields/${dataAccessGroupRegulationGroupId}/${dataAccessFieldId}`
    );
  }

  public getDataAccessGroupById(id: number): Observable<any> {
    return this.fetch("/administration/dataAccessGroups/" + id);
  }

  public getRelatedWebsiteType(id: number): Observable<any> {
    return this.fetch("/settings/relatedWebsiteTypes/" + id);
  }

  public getDataAccessGroupRegulationGroupById(
    groupId: number,
    dataAccessGroupRegulationGroupId: number
  ): Observable<any> {
    return this.fetch(
      `/administration/dataAccessGroups/${groupId}/dataAccessGroupsRegulationGroups/${dataAccessGroupRegulationGroupId}`
    );
  }

  public addUserRegulationGroup(
    id: any,
    username: string,
    regulationGroupId: any,
    mappingReference: any,
    isVisible: any,
    subGroupId: any
  ): Observable<any> {
    let body = {
      Id: id,
      UserName: username,
      RegulationGroupId: regulationGroupId,
      subGroupId: subGroupId,
      IsVisible: isVisible,
      MappingReference: mappingReference,
    };
    return this.postJson(
      "/administration/users/regulationGroup",
      JSON.stringify(body)
    );
  }

  public addDefault(addDefaultForm: FormGroup): Observable<any> {
    let body = {
      defaultEnum: addDefaultForm.controls.defaultEnum.value,
      json: addDefaultForm.controls.json.value,
      description: addDefaultForm.controls.description.value,
    };
    return this.postJson("/systemsettings/default", JSON.stringify(body));
  }

  public checkDowJonesEndpoint(): Observable<any> {
    return this.getCachedData("/systemsettings/check-dow-jones-endpoint");
  }

  public editDefault(editDefaultForm: FormGroup, id: any): Observable<any> {
    let body = {
      id: id,
      defaultEnum: editDefaultForm.controls.defaultEnum.value,
      json: editDefaultForm.controls.json.value,
      description: editDefaultForm.controls.description.value,
    };
    return this.putJson("/systemsettings/default", JSON.stringify(body));
  }

  public editJobSchedule(form: FormGroup, id: any): Observable<any> {
    let body = {
      id: id,
      status: form.controls.status.value,
      interval: form.controls.interval.value,
      value: form.controls.scheduleJobValue.value,
      time: form.controls.time.value,
      startDate: form.controls.startDate.value,
      jobId: form.controls.jobId.value,
      fromHour: form.controls.fromHour.value,
      toHour: form.controls.toHour.value,
    };
    return this.putJson("/scheduler/jobSchedules", JSON.stringify(body));
  }

  public getJobScheduleById(id: number): Observable<any> {
    return this.fetch("/scheduler/jobSchedules/" + id);
  }

  public restoreUserPermissions(username: string): Observable<any> {
    let body = {
      username: username,
    };
    return this.postJson(
      "/administration/restoreUserPermissions",
      JSON.stringify(body)
    );
  }

  public restoreMultipleUsersPermissions(selectedUsers: any): Observable<any> {
    let body = {
      "usernames": selectedUsers
    }

    return this.putJson("/administration/restoreMultipleUsersPermissions", JSON.stringify(body));
  }

  public restoreDataAccessGroupPermissions(groupId: number): Observable<any> {
    let body = {
      DataAccessGroupId: groupId,
    };
    return this.postJson(
      "/administration/restoreDataAccessGroupPermissions",
      JSON.stringify(body)
    );
  }

  public addDataAccessGroup(addGroupForm: FormGroup): Observable<any> {
    let body = {
      name: addGroupForm.controls.name.value,
      description: addGroupForm.controls.description.value,
    };
    return this.postJson(
      "/administration/dataAccessGroups",
      JSON.stringify(body)
    );
  }

  public addDataAccessField(
    addFieldForm: FormGroup,
    dataAccessGroupRegulationGroupId: number,
    selectedCustomField: any
  ): Observable<any> {
    let body = {
      dataAccessGroupRegulationGroupId: dataAccessGroupRegulationGroupId,
      fieldType: addFieldForm.controls.dataAccessFieldType.value,
      referenceColumn:
        selectedCustomField != null ? selectedCustomField.fieldName : null,
      referenceTable: addFieldForm.controls.dataAccessReferenceTable.value,
      values: null,
    };
    return this.postJson(
      "/administration/dataAccessFields",
      JSON.stringify(body)
    );
  }

  public getReleaseNotes(): Observable<any> {
    return this.fetch("/administration/releaseNotes");
  }

  public getTransactionOriginEnum(): Observable<any> {
    return this.getCachedData("/enumerations/TransactionOriginEnum");
  }

  public getMLRiskClassEnum(): Observable<any> {
    return this.getCachedData("/enumerations/MLRiskClassEnum");
  }

  public getHealthCheckSyncStagingTablesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/healthCheckSyncStagingTablesEnum");
  }

  public getPortalSectionFieldTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/portalSectionFieldTypeEnum");
  }

  public getTimeZoneInfoTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/timezoneinfotypes");
  }

  public getLogLevelsEnum(): Observable<any> {
    return this.getCachedData("/enumerations/logLevels");
  }

  public getKompanyComSearchByOptionsEnum(): Observable<any> {
    return this.getCachedData("/enumerations/kompanyComSearchByOptionsEnum");
  }

  public getKompanyComDatasetTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/kompanyComDatasetTypeEnum");
  }

  public getUnifiedTransactionStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/unifiedTransactionStatusEnum");
  }

  public getInternalScreeningStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/internalScreeningStatusEnum");
  }

  public getScreeningStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/screeningStatusEnum");
  }

  public getVisibleIdentificationEnum(): Observable<any> {
    return this.getCachedData("/enumerations/visibleIdentificationEnum");
  }

  public getElectronicIdentificationStatusDenfyEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/electronicIdentificationStatusesEnum"
    );
  }

  public getAccountIDenfyServicesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/accountIdenfyServicesEnum");
  }

  public getEGOVRequestScopesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/eGOVRequestScopesEnum");
  }

  public getEGOVRequestStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/eGOVRequestStatusEnum");
  }

  public getComplianceFeedbackEnum(): Observable<any> {
    return this.getCachedData("/enumerations/complianceFeedbackEnum");
  }

  public getAccountStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/accountStatusEnum");
  }

  public getPortalAutomationProcessEnum(): Observable<any> {
    return this.fetch("/enumerations/portalAutomationProcessEnum");
  }

  public getDefaultsEnum(): Observable<any> {
    return this.getCachedData("/enumerations/DefaultsEnum");
  }

  public getQuestionnaireProgressStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/questionnaireProgressStatusEnum");
  }

  public getChainalysisAlertReviewStatusEnum(): Observable<any> {
    return this.fetch("/enumerations/chainalysisAlertReviewStatusEnum");
  }

  public getHealthCheckTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/healthCheckTypeEnum");
  }

  public getEmailTemplateTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/emailTemplateTypeEnum");
  }

  public getRegulationGroupSyncTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/regulationGroupSyncTypeEnum");
  }

  public getLegalClientStructureTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/legalClientStructureTypesEnum");
  }

  public getVideoIdentificationStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/videoIdentificationStatusEnum");
  }

  public getHttpResponseStatusCodesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/httpResponseStatusCodesEnum");
  }

  public getDataAccessFieldTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/dataAccessFieldTypeEnum");
  }

  public getDataAccessFieldReferenceTableEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/dataAccessFieldReferenceTableEnum"
    );
  }

  public getTransactionScreeningAutoStatusEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/transactionScreeningAutoStatusEnum"
    );
  }

  public getOngoingMonitoringAutoStatuses(): Observable<any> {
    return this.fetch("/processes/ongoingMonitoringClientStatuses");
  }

  public getTransactionScreeningManualStatusEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/transactionScreeningManualStatusEnum"
    );
  }

  public getPaymentComponentsMessaageTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/paymentComponentsMessageTypeEnum");
  }

  public getPersonSearchStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/personSearchStatusEnum");
  }

  public getTransactionScreeningCaseInternalBlacklistStatusEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/transactionScreeningCaseInternalBlacklistStatusEnum"
    );
  }

  public getBusinessSearchStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/businessSearchStatusEnum");
  }

  public getNegativeListVerificationStatusEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/negativeListVerificationStatusEnum"
    );
  }

  public getAccountLogo(): Observable<any> {
    return this.fetch("/user/accountLogo");
  }

  public getAuthenticationDetails(): Observable<any> {
    return this.fetch("/guest/getAuthenticationDetails");
  }

  public getUserOtpDetailsByUsername(username): Observable<any> {
    return this.fetch(`/guest/users/${username}/otpDetails`);
  }

  public sendLoginOtp(email, type, userName) {
    let body = {
      email: email,
      type: type,
      userName: userName,
    };

    return this.postJson("/guest/sendLoginOtp", JSON.stringify(body));
  }

  public cancelLoginOtp(email, userName) {
    let body = {
      email: email,
      userName: userName,
    };

    return this.postJson("/guest/cancelLoginOtp", JSON.stringify(body));
  }

  public verifyLoginOtp(email, otp, userName) {
    let body = {
      email: email,
      otp: otp,
      userName: userName,
    };

    return this.postJson("/guest/verifyLoginOtp", JSON.stringify(body));
  }

  public getDocumentUploadType(): Observable<any> {
    return this.fetch("/user/documentUploadType");
  }

  public getGuestAccount(): Observable<any> {
    //* This endpoint is called from almost everywhere in RegTek, so we cache it, and remove it from the cache,
    //* when the user changes it in Administration > Account.
    return this.getCachedData("/guest/accountDetails");
  }

  public getAdvancedDashboard(): Observable<any> {
    return this.fetch("/advanced-dashboard");
  }

  public getRegTekCodeDetails(): Observable<any> {
    return this.fetch("/guest/regtekcodedetails");
  }

  public getShowRegTekCode(): Observable<any> {
    return this.fetch("/guest/showRegTekCode");
  }

  public getCaseStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/caseStatusEnum");
  }

  public getFraudRiskAnalysisRecordEnum(): Observable<any> {
    return this.getCachedData("/enumerations/fraudRiskAnalysisRecordEnum");
  }

  public getSumSubModulesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/sumSubModulesEnum");
  }

  public getPriorityEnum(): Observable<any> {
    return this.getCachedData("/enumerations/priorityEnum");
  }

  public getWorkflowTaskStatusEnum(): Observable<any> {
    return this.fetch("/enumerations/workflowTasksStatusEnum");
  }

  public getWorkflowNextAvailableStatuses(taskId: number): Observable<any> {
    return this.fetch("/workflowMyTasks/nextAvailableStatuses/" + taskId);
  }

  public getWorkflowTaskProgressStatusEnum(): Observable<any> {
    return this.fetch("/enumerations/workflowTasksProgressStatusEnum");
  }

  public getWorkflowTaskActionAboutEnum(): Observable<any> {
    return this.fetch("/enumerations/workflowTaskActionEnum");
  }

  public getUserTaskTypeEnum(): Observable<any> {
    return this.fetch("/enumerations/UserTaskTypeEnum");
  }

  public getArrangements(): Observable<any> {
    return this.fetch("/arrangements");
  }

  public getArrangementById(id): Observable<any> {
    return this.fetch(`/arrangements/${id}`);
  }

  public editPortalDynamicRegistrationFieldAnswer(
    json: any,
    email: string
  ): Observable<any> {
    let body = {
      userProfileAnswers: json,
      userEmail: email,
    };
    return this.putJson(
      "/portalOnboarding/dynamicRegistrationFieldAnswer",
      JSON.stringify(body)
    );
  }

  public updateTransactionsLive(
    liveClientTransactionId: number,
    complianceFeedback: string
  ): Observable<any> {
    let body = {
      complianceFeedback: complianceFeedback,
    };
    return this.putJson(
      `/clientCommon/cases/${liveClientTransactionId}/updateTransactionsLive`,
      JSON.stringify(body)
    );
  }

  public getClientTransactionByOrigin(
    type: string,
    id: number
  ): Observable<any> {
    return this.fetch(`/liveTransactions/${type}/${id}`);
  }

  public getCryptoAlertsByClientTransactionId(id: number): Observable<any> {
    return this.fetch(`/liveTransactions/crypto/alerts/${id}`);
  }

  public getCaseByLiveTransactionId(id: number): Observable<any> {
    return this.fetch(`/liveTransactions/${id}/case`);
  }

  public getTransactionLiveCasesCount(
    type: string,
    id: number
  ): Observable<any> {
    return this.fetch(
      `/liveTransactions/transaction/${type}/${id}/live-cases/count`
    );
  }

  public getOfflineClientCasesCountByLiveTransactionReference(
    externalReference: string
  ): Observable<any> {
    const body = {
      externalReference: externalReference,
    };
    return this.postJson(
      `/liveTransactions/offlineClientCases/count`,
      JSON.stringify(body)
    );
  }

  public getLiveTransactionById(liveTransactionId: number): Observable<any> {
    return this.fetch(
      `/liveTransactions/transaction/${liveTransactionId}/live`
    );
  }

  public getLinkedLiveTransactionByPostTransactionId(
    clientTransactionId: number
  ): Observable<any> {
    return this.fetch(
      `/liveTransactions/transaction/${clientTransactionId}/post/linked-live-transaction`
    );
  }

  public getLinkedPostTransactionByLiveTransactionId(
    liveTransactionId: number
  ): Observable<any> {
    return this.fetch(
      `/liveTransactions/transaction/${liveTransactionId}/live/linked-post-transaction`
    );
  }

  public getDynamicRegistrationFieldsByUserEmail(email): Observable<any> {
    return this.fetch(
      `/portalOnboarding/dynamicRegistrationFieldsByUserEmail/${email}`
    );
  }

  public getExpiredClientRegistrations(): Observable<any> {
    return this.fetch(`/portalOnboardingRegTek/clientRegistrations/expired`);
  }

  public getGuestPortalUserRegistrationFields(): Observable<any> {
    return this.fetch("/guest/portalUserRegistrationFields");
  }

  public getTradeFrequenciesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/tradeFrequencies");
  }

  public getClientRegistrationStatusEnum(): Observable<any> {
    return this.fetch("/enumerations/clientRegistrationStatusEnum");
  }

  public getActionTypesFilterEnum(): Observable<any> {
    return this.getCachedData("/enumerations/actionTypesFilterEnum");
  }

  public getCaseSuspicionLevelEnum(): Observable<any> {
    return this.getCachedData("/enumerations/caseSuspicionLevelEnum");
  }

  public getMachineLearningCategoryEnum(): Observable<any> {
    return this.getCachedData("/enumerations/machineLearningCategoryEnum");
  }

  public getCaseLiveTransactionStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/caseLiveTransactionStatusEnum");
  }

  public getClientSegmentEnum(): Observable<any> {
    return this.getCachedData("/enumerations/clientSegmentEnum");
  }

  public getTaskSchedulerFeatureTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/taskSchedulerFeatureTypes");
  }

  public getTaskSchedulerAllFeatureTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/taskSchedulerAllFeatureTypes");
  }

  public updateJobSchedulesStatus(ids: any): Observable<any> {
    let body = {
      "ids": ids
    };

    return this.putJson("/scheduler/statuses", JSON.stringify(body));
  }

  public GetActiveRulesPerRegulationGroup(clientId: any): Observable<any> {
    return this.fetch("/clientCommon/" + clientId + "/rules");
  }

  public getRoles(): Observable<any> {
    return this.fetch("/administration/roles");
  }

  public getAccount(): Observable<any> {
    return this.fetch("/user/accounts");
  }

  public getUserAdministrationAccount(): Observable<any> {
    return this.fetch("/user/administrationAccount");
  }

  public getUserAdministrationAccountLogo(): Observable<any> {
    return this.getCachedData("/user/administrationAccountLogo");
  }

  public getTransactionScreeningSettings(): Observable<any> {
    return this.fetch("/user/administration/transactionscreeningsettings");
  }

  public getRegulationGroups(): Observable<any> {
    return this.fetch("/dropdown/regulationGroups");
  }

  public getCaseStatuses(): Observable<any> {
    return this.fetch("/settings/caseStatuses");
  }

  public getHealthCheckDashboards(): Observable<any> {
    return this.fetch("/healthcheck/healthCheckDashboards");
  }

  public getLiveTransactionStatusThresholds(): Observable<any> {
    return this.fetch("/settings/liveTransactionStatusThresholds");
  }

  public editLiveTransactionStatusThreshold(
    id: number,
    riskPointFrom: number,
    riskPointTo: number
  ): Observable<any> {
    const body = {
      riskPointFrom: riskPointFrom,
      riskPointTo: riskPointTo,
    };
    return this.putJson(
      `/settings/liveTransactionStatusThresholds/${id}`,
      JSON.stringify(body)
    );
  }

  public getPasswordExpirationStatus(username: string): Observable<any> {
    return this.fetchBase(
      `/authorization/passwordExpirationStatus/${username}`
    );
  }

  public isLoggedIn(username: string): Observable<any> {
    return this.fetchBase(`/authorization/isLoggedIn/${username}`);
  }

  public getRegulationGroupsSubGroups(paramGroupId: number): Observable<any> {
    return this.fetch("/settings/regulationGroupsSubGroups/" + paramGroupId);
  }

  public getUserRegulationGroupsByUserName(username: string): Observable<any> {
    return this.fetch(`/administration/users/userRegulationGroups/${username}`);
  }

  public getPortalSetupType(): Observable<any> {
    return this.getCachedData("/user/portalSetupType");
  }

  public getPortalQuestionnaireTypes(): Observable<any> {
    return this.fetch("/portalSetup/portalQuestionnaireTypes");
  }

  public editPortalAccount(
    editPortalAccountForm: FormGroup,
    logoFiles: any,
    splashScreenLogoFiles: any,
    loginPageImgFiles: any,
    registerPageImgFiles: any
  ): Observable<any> {
    let body = new FormData();
    var logoImageFile = logoFiles[0];
    var splashScreenLogoImageFile = splashScreenLogoFiles[0];
    var loginPageImageFile = loginPageImgFiles[0];
    var registerPageImageFile = registerPageImgFiles[0];

    body.append("Id", editPortalAccountForm.controls.id.value);
    body.append("Name", editPortalAccountForm.controls.name.value);
    body.append("Logo", logoImageFile);
    body.append("SplashScreenLogo", splashScreenLogoImageFile);
    body.append(
      "DevelopedBy",
      editPortalAccountForm.controls.developedBy.value
    );
    body.append(
      "DisplayCopyrights",
      editPortalAccountForm.controls.displayCopyrights.value
    );
    body.append(
      "HeaderBackgroundColor",
      editPortalAccountForm.controls.headerBackgroundColor.value
    );
    body.append(
      "LoginButtonBackgroundColor",
      editPortalAccountForm.controls.loginButtonBackgroundColor.value
    );
    body.append(
      "adminFullRights",
      editPortalAccountForm.controls.adminFullRights.value
    );
    body.append(
      "maxNumOfDocumentVerTries",
      editPortalAccountForm.controls.maxNumOfDocumentVerTries.value
    );
    body.append(
      "sendEmailUponSubmission",
      editPortalAccountForm.controls.sendEmailUponSubmission.value
    );
    body.append(
      "twoWayAuthentication",
      editPortalAccountForm.controls.twoWayAuthentication.value
    );
    body.append(
      "recaptchaKey",
      editPortalAccountForm.controls.recaptchaKey.value
    );
    body.append(
      "onboardingUserEmail",
      editPortalAccountForm.controls.onboardingUserEmail.value
    );
    body.append(
      "portalSetupType",
      editPortalAccountForm.controls.portalSetupType.value
    );
    body.append("loginPageImg", loginPageImageFile);
    body.append("registerPageImg", registerPageImageFile);
    body.append(
      "referencePrefix",
      editPortalAccountForm.controls.referencePrefix.value
    );
    body.append("hubId", editPortalAccountForm.controls.hubId.value);
    body.append(
      "defaultFullStructureEvaluation",
      editPortalAccountForm.controls.defaultFullStructureEvaluation.value
    );
    body.append(
      "uppercaseResponses",
      editPortalAccountForm.controls.uppercaseResponses.value || false
    );

    return this.putForm("/portalSetup/portalAccount", body);
  }

  public getPortalRegulationGroups(): Observable<any> {
    return this.fetch("/portalSetup/regulationGroups");
  }

  public getPortalSectionFieldOrder(sectionId): Observable<any> {
    return this.fetch(`/portalSetup/sections/${sectionId}/fields/order`);
  }

  public getPortalAmendmentRequestTypes(): Observable<any> {
    return this.fetch("/portalOnboardingRegTek/amendmentRequestTypes");
  }

  public getPortalAmendmentRequestTypeById(id: any): Observable<any> {
    return this.fetch("/portalOnboardingRegTek/portalAmendmentRequestType/" + id);
  }

  public getPortalPaymentMethodsCustomFields(): Observable<any> {
    return this.fetch("/portalSetup/paymentMethodsCustomFields");
  }

  public getPortalPaymentMethodsCustomFieldsByCustomFieldId(
    customFieldId: any
  ): Observable<any> {
    return this.fetch(
      `/portalSetup/paymentMethodsCustomFields/${customFieldId}`
    );
  }

  public getPortalCapacities(): Observable<any> {
    return this.fetch("/portalSetup/capacities");
  }

  public getPortalCaptions(): Observable<any> {
    return this.fetch("/portalSetup/captions");
  }

  public getPortalPaymentMethodsPrivacyPolicies(): Observable<any> {
    return this.fetch("/portalSetup/paymentMethodsPrivacyPolicies");
  }

  public getPortalPrivacyPolicies(): Observable<any> {
    return this.fetch("/portalSetup/privacyPolicies");
  }

  public getPortalAccount(): Observable<any> {
    return this.fetch("/portalSetup/portalAccount");
  }

  public getPortalTypeSetup(): Observable<any> {
    return this.fetch("/user/portalTypeSetup");
  }

  public getDefaults(): Observable<any> {
    return this.fetch("/systemsettings/defaults");
  }

  public getDefaultsByDefaultEnum(defaultEnum: any): Observable<any> {
    return this.getCachedData("/clientCommon/defaults/" + defaultEnum);
  }

  public getReportDefaultsByDefaultEnum(defaultEnum: any): Observable<any> {
    return this.getCachedData("/reportFilters/defaults/" + defaultEnum);
  }

  public getDefaultById(id: any): Observable<any> {
    return this.fetch("/systemsettings/default/" + id);
  }

  public getPortalPrivacyPolicyById(id: any): Observable<any> {
    return this.fetch("/portalSetup/privacyPolicies/" + id);
  }

  public getTransactionScreeningCaseActionsDropdown(): Observable<any> {
    return this.fetch("/clientCommon/transactionScreeningCaseActionsDropdown");
  }

  public getCountryCategories(): Observable<any> {
    return this.fetch("/settings/countryCategories");
  }

  public getCountryCategoryById(id: any): Observable<any> {
    return this.fetch("/settings/countryCategories/" + id);
  }

  public getCriteriaCategories(): Observable<any> {
    return this.fetch("/settings/criteriaCategories");
  }

  public getSanctionCriteriaCategories(): Observable<any> {
    return this.fetch("/settings/criteriaCategories");
  }

  public getDocumentCategories(): Observable<any> {
    return this.fetch("/settings/documentCategories");
  }

  public getCriteria(): Observable<any> {
    return this.fetch("/settings/criteria");
  }

  public getCriteriaSimple(): Observable<any> {
    return this.fetch("/settings/criteriaSimple");
  }

  public getOldCriteria(): Observable<any> {
    return this.fetch("/settings/oldcriteria");
  }

  public getQuestionsCategories(): Observable<any> {
    return this.fetch("/settings/questionsCategories");
  }

  public getQuestionnaireTypes(): Observable<any> {
    return this.fetch("/settings/questionnaireTypes");
  }

  public getQuestions(): Observable<any> {
    return this.fetch("/settings/questions");
  }

  public getQuestionsSimple(): Observable<any> {
    return this.fetch("/settings/questionsSimple");
  }

  public getEmailTemplates(): Observable<any> {
    return this.fetch("/settings/emailTemplates");
  }

  public getActionCategories(): Observable<any> {
    return this.fetch("/settings/actionCategories");
  }

  public getPurposeOfTransactions(): Observable<any> {
    return this.fetch("/settings/purposeOfTransactions");
  }

  public getRuleCategories(): Observable<any> {
    return this.fetch("/settings/ruleCategories");
  }

  public getRuleParameterExecutionName(
    ruleId: number,
    executionName: string
  ): Observable<any> {
    return this.fetch("/settings/ruleCategories");
  }

  public getCriteriaById(id: any): Observable<any> {
    return this.fetch("/settings/criteria/" + id);
  }

  public getPaymentMethodsById(id: any): Observable<any> {
    return this.fetch("/settings/paymentMethods/" + id);
  }

  public getQuestionsById(id: any): Observable<any> {
    return this.fetch("/settings/questions/" + id);
  }

  public getMidTypeById(id: any): Observable<any> {
    return this.fetch("/settings/midTypes/" + id);
  }

  public getClientCategorizationById(id: any): Observable<any> {
    return this.fetch("/settings/clientCategorizations/" + id);
  }

  public getMidClassById(id: any): Observable<any> {
    return this.fetch("/settings/midClasses/" + id);
  }

  public getRules(): Observable<any> {
    return this.fetch("/settings/rules");
  }

  public getRuleExecutionsForLinkedRule(ruleId: any): Observable<any> {
    return this.fetch(`/administration/ruleExecutionsForLinkedRule/${ruleId}`);
  }

  public getRulesById(rulesId): Observable<any> {
    let body = {
      rulesId: rulesId,
    };

    return this.postJson("/settings/rulesById", JSON.stringify(body));
  }

  public getUpdatedRules(): Observable<any> {
    return this.fetch("/dashboard/updatedRules");
  }

  // public getSystemRules(): Observable<any> {
  //   return this.fetch("/administration/rules");
  // }

  public getJsonPredefinedRules(): Observable<any> {
    return this.fetch("/dropdown/jsonPredefinedRules");
  }

  public getJsonPredefinedDynamicReports(): Observable<any> {
    return this.fetch("/dropdown/jsonPredefinedDynamicReports");
  }

  public getSystemDynamicReports(): Observable<any> {
    return this.fetch("/administration/systemDynamicReports");
  }

  public getReportSystemDynamicReports(): Observable<any> {
    return this.fetch("/reportFilters/systemDynamicReports");
  }

  public getSystemNotificationTemplates(): Observable<any> {
    return this.fetch("/settings/notificationTemplates");
  }

  public getSystemNotificationTemplateById(id: any): Observable<any> {
    return this.fetch(`/settings/notificationTemplate/${id}`);
  }

  public getTriggers(): Observable<any> {
    return this.fetch("/eventEngines/triggers");
  }

  public getTriggersByEventEngineId(eventEngineId: any): Observable<any> {
    return this.fetch(`/eventEngines/triggers/eventEngines/${eventEngineId}`);
  }

  public getTriggerById(id: any): Observable<any> {
    return this.fetch(`/eventEngines/triggers/${id}`);
  }

  public getScopes(): Observable<any> {
    return this.fetch("/eventEngines/scopes");
  }

  public getRecordModifications(): Observable<any> {
    return this.fetch("/systemSettings/recordModifications");
  }

  public getQueryBasedSetups(): Observable<any> {
    return this.fetch("/administration/queryBasedSetups");
  }

  public getQueryBasedSetupById(id: any): Observable<any> {
    return this.fetch("/administration/queryBasedSetups/" + id);
  }

  public deleteQueryBasedSetup(id: any): Observable<any> {
    return this.delete("/administration/queryBasedSetups/" + id, "");
  }

  public deleteQueryBasedSetupParameter(id: any): Observable<any> {
    return this.delete("/administration/queryBasedSetups/parameters/" + id, "");
  }

  public getScopesAllowedByEventEngine(eventEngineId: any): Observable<any> {
    return this.fetch(`/eventEngines/scopes/eventengine/${eventEngineId}`);
  }

  public getScopesAllowedByReportEngineSetup(
    reportEngineSetupId: any
  ): Observable<any> {
    return this.fetch(
      `/settings/scopes/reportEngineSetup/${reportEngineSetupId}`
    );
  }

  public getScopesAllowedByDocument(documentId: any): Observable<any> {
    return this.fetch(`/settings/documents/scopes/${documentId}`);
  }

  public getScopesAllowedByTag(tagId: any): Observable<any> {
    return this.fetch(`/settings/tags/scopes/${tagId}`);
  }

  public getScopesAllowedByActionRecordModification(
    actionRecordModificationId: any
  ): Observable<any> {
    return this.fetch(
      `/eventEngines/scopes/actionRecordModifications/${actionRecordModificationId}`
    );
  }

  public getScopeById(id: any): Observable<any> {
    return this.fetch(`/eventEngines/scopes/${id}`);
  }

  public getRecordModificationById(id: any): Observable<any> {
    return this.fetch(`/settings/recordModifications/${id}`);
  }

  public getPortalSections(
    regulationGroupId: any,
    clientType: any,
    capacityId = -1,
    portalQuestionnaireTypeId
  ): Observable<any> {
    capacityId = capacityId == null ? -1 : capacityId;
    return this.fetch(
      `/portalSetup/sections/${regulationGroupId}/${clientType}/${capacityId}/${portalQuestionnaireTypeId || -1
      }`
    );
  }

  public getPortalClientRegistrationPhases(
    regulationGroupId: any,
    clientType: any,
    capacityId = -1,
    portalQuestionnaireTypeId
  ): Observable<any> {
    capacityId = capacityId == null ? -1 : capacityId;
    return this.fetch(
      `/dropdown/phases/${regulationGroupId}/${clientType}/${capacityId}/${portalQuestionnaireTypeId || -1
      }`
    );
  }

  public getRelatedEntitiesFromPortal(parentRegistrationPhaseId: any): Observable<any> {
    return this.fetch(`/dropdown/relatedClientRegistrations/${parentRegistrationPhaseId}`);
  }

  public getPortalLinkedEntitiesRegistrationPhases(regulationGroupId: any, portalQuestionnaireTypeId: any): Observable<any> {
    return this.fetch(`/dropdown/linkedEntitiesPhases/${regulationGroupId}/${portalQuestionnaireTypeId || -1}`);
  }

  public getAllPortalClientRegistrationPhases(): Observable<any> {
    return this.fetch(`/dropdown/phases`);
  }

  public getPortalClientRegistrationPhaseById(id: any): Observable<any> {
    return this.fetch(`/portalSetup/phases/${id}`);
  }

  public getAllPortalSections(): Observable<any> {
    return this.fetch(`/portalSetup/sections/`);
  }

  public getPortalSectionById(
    regulationGroupId: any,
    clientType: any,
    id: any
  ): Observable<any> {
    return this.fetch(
      `/portalSetup/sections/${regulationGroupId}/${clientType}/${id}`
    );
  }

  public getTimeZones(): Observable<any> {
    return this.fetch("/guest/timeZones");
  }

  public getDbName(): Observable<any> {
    return this.fetch("/user/dbName");
  }

  public updateUserTimezone(timezone: string): Observable<any> {
    let body = {
      timezone: timezone,
    };
    return this.putJson(`/user/timezone`, JSON.stringify(body));
  }

  public getPortalSectionsGroups(): Observable<any> {
    return this.fetch("/portalSetup/sectionsGroups/");
  }

  public getPortalSectionGroupById(id: any): Observable<any> {
    return this.fetch(`/portalSetup/sectionsGroups/${id}`);
  }

  public getPortalSectionFieldById(id: any): Observable<any> {
    return this.fetch(`/portalSetup/sections/fields/${id}`);
  }

  public getPortalSectionFieldRequirements(id: any): Observable<any> {
    return this.fetch(`/portalSetup/sections/fields/${id}/requirements`);
  }

  public getPortalSectionFieldValidations(id: any): Observable<any> {
    return this.fetch(`/portalSetup/sections/fields/${id}/validations`);
  }

  public getPortalSectionGroupValidations(id: any): Observable<any> {
    return this.fetch(`/portalSetup/sections/groups/${id}/validations`);
  }

  public getPortalSectionAvailableFields(id: any): Observable<any> {
    return this.fetch(`/portalSetup/sections/fields/${id}/available`);
  }

  public getPortalSectionFieldPreviousFields(id: any): Observable<any> {
    return this.fetch(`/portalSetup/sections/fields/${id}/previousFields`);
  }

  public getPortalSectionFieldValues(id: any): Observable<any> {
    return this.fetch(`/portalSetup/sections/fields/${id}/values`);
  }

  public getPortalSectionFields(
    regulationGroupId: any,
    clientType: any,
    id: any
  ): Observable<any> {
    return this.fetch(
      `/portalSetup/sections/${regulationGroupId}/${clientType}/${id}/fields`
    );
  }

  public getPortalUserRegistrationFields(): Observable<any> {
    return this.fetch("/portalSetup/userRegistrationFields");
  }

  public getPortalUserRegistrationFieldById(id: any): Observable<any> {
    return this.fetch(`/portalSetup/userRegistrationFieldById/${id}`);
  }

  public addPortalUserRegistrationField(
    addPortalUserRegistrationForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addPortalUserRegistrationForm.controls.name.value,
      description: addPortalUserRegistrationForm.controls.description.value,
      type: addPortalUserRegistrationForm.controls.type.value,
      isMandatory: addPortalUserRegistrationForm.controls.isMandatory.value,
      status: addPortalUserRegistrationForm.controls.status.value,
      order: addPortalUserRegistrationForm.controls.order.value,
    };

    return this.postJson(
      "/portalSetup/userRegistrationFields",
      JSON.stringify(body)
    );
  }

  public editPortalUserRegistrationField(
    editPortalUserRegistrationForm: FormGroup,
    Id: any
  ): Observable<any> {
    let body = {
      id: Id,
      name: editPortalUserRegistrationForm.controls.name.value,
      description: editPortalUserRegistrationForm.controls.description.value,
      type: editPortalUserRegistrationForm.controls.type.value,
      isMandatory: editPortalUserRegistrationForm.controls.isMandatory.value,
      status: editPortalUserRegistrationForm.controls.status.value,
      order: editPortalUserRegistrationForm.controls.order.value,
    };

    return this.putJson(
      `/portalSetup/userRegistrationFields/${Id}`,
      JSON.stringify(body)
    );
  }

  public deletePortalUserRegistrationField(id: any): Observable<any> {
    return this.delete(`/portalSetup/userRegistrationFields/${id}`, "");
  }

  public deletePortalQuestionnaireType(id: any): Observable<any> {
    return this.delete(`/portalSetup/portalQuestionnaireTypes/${id}`, "");
  }

  public getPortalValidationQueries(): Observable<any> {
    return this.fetch("/portalSetup/validationQueries");
  }

  public getPortalValidationQueryById(id: any): Observable<any> {
    return this.fetch(`/portalSetup/validationQuery/${id}`);
  }

  public addPortalValidationQuery(
    addPortalValidationQueryForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addPortalValidationQueryForm.controls.name.value,
      description: addPortalValidationQueryForm.controls.description.value,
      query: addPortalValidationQueryForm.controls.query.value,
      status: addPortalValidationQueryForm.controls.status.value,
    };

    return this.postJson(
      "/portalSetup/validationQueries",
      JSON.stringify(body)
    );
  }

  public editPortalValidationQuery(
    editPortalValidationQueryForm: FormGroup,
    Id: any
  ): Observable<any> {
    let body = {
      id: Id,
      name: editPortalValidationQueryForm.controls.name.value,
      description: editPortalValidationQueryForm.controls.description.value,
      query: editPortalValidationQueryForm.controls.query.value,
      status: editPortalValidationQueryForm.controls.status.value,
    };

    return this.putJson("/portalSetup/validationQuery", JSON.stringify(body));
  }

  public deleteValidationQuery(id: any): Observable<any> {
    return this.delete(`/portalSetup/validationQuery/${id}`, "");
  }

  public deletePortalSectionFieldValidation(
    fieldId,
    validationType,
    group
  ): Observable<any> {
    return this.delete(
      `/portalSetup/sections/fields/${fieldId}/validations/type/${validationType}/group/${group}`,
      ""
    );
  }

  public deletePortalSectionGroupValidation(
    groupId,
    validationType,
    group
  ): Observable<any> {
    return this.delete(
      `/portalSetup/sections/groups/${groupId}/validations/type/${validationType}/group/${group}`,
      ""
    );
  }

  public getPortalDynamicAutoFillReportsEnum(): Observable<any> {
    return this.getCachedData("/portalSetup/dynamicAutoFillReportsEnum");
  }

  public addPortalDynamicAutoFillReport(
    addPortalDynamicAutoFillReportForm: FormGroup
  ): Observable<any> {
    let body = {
      templateId: addPortalDynamicAutoFillReportForm.controls.templateId.value,
      templateName:
        addPortalDynamicAutoFillReportForm.controls.templateName.value,
      regulationGroupId:
        addPortalDynamicAutoFillReportForm.controls.regulationGroupId.value,
      questionnaireTypeId:
        addPortalDynamicAutoFillReportForm.controls.questionnaireTypeId.value,
      capacityId: addPortalDynamicAutoFillReportForm.controls.capacity.value,
      clientType: addPortalDynamicAutoFillReportForm.controls.clientType.value,
      sectionType:
        addPortalDynamicAutoFillReportForm.controls.portalSectionType.value,
      status: addPortalDynamicAutoFillReportForm.controls.status.value,
    };

    return this.postJson(
      "/portalSetup/dynamicAutoFillReports",
      JSON.stringify(body)
    );
  }

  // Portal Reports
  public getPortalDynamicAutoFillReports(
    regulationGroupId: any,
    clientType: any,
    portalQuestionnaireTypeId: any,
    capacityId: any
  ): Observable<any> {
    if (!capacityId) capacityId = -1;
    return this.fetch(
      `/portalSetup/dynamicAutoFillReports/${regulationGroupId}/${clientType}/${portalQuestionnaireTypeId}/${capacityId}`
    );
  }

  public editPortalDynamicAutoFillReport(
    newData: any,
    oldData: any
  ): Observable<any> {
    // Update only the Status of the record.
    let body = {
      id: oldData.id,
      templateId: oldData.templateId,
      templateName: oldData.templateName,
      regulationGroupId: oldData.regulationGroupId,
      questionnaireTypeId: oldData.questionnaireTypeId,
      capacityId: oldData.capacityId,
      clientType: oldData.clientType,
      sectionType: oldData.sectionType,
      status: newData.status != undefined ? newData.status : oldData.status,
    };

    return this.putJson(
      "/portalSetup/dynamicAutoFillReports",
      JSON.stringify(body)
    );
  }

  public deletePortalDynamicAutoFillReport(id: any): Observable<any> {
    return this.delete(`/portalSetup/dynamicAutoFillReport/${id}`, "");
  }

  public editDynamicAutoFillReportSection(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      portalDynamicAutoFillReportId:
        newData.portalDynamicAutoFillReportId != undefined
          ? newData.portalDynamicAutoFillReportId
          : oldData.portalDynamicAutoFillReportId,
      portalSectionId:
        newData.portalSectionId != undefined
          ? newData.portalSectionId
          : oldData.portalSectionId,
      sectionName:
        newData.sectionName != undefined
          ? newData.sectionName
          : oldData.sectionName,
      order: newData.order != undefined ? newData.order : oldData.order,
      displayOnReport:
        newData.displayOnReport != undefined
          ? newData.displayOnReport
          : oldData.displayOnReport,
    };

    return this.putJson(
      "/portalSetup/dynamicAutoFillReportSections",
      JSON.stringify(body)
    );
  }

  public getTriggersScopes(): Observable<any> {
    return this.fetch("/eventEngines/triggersScopes");
  }

  public getTriggersScopeById(id: any): Observable<any> {
    return this.fetch(`/eventEngines/triggersScopes/${id}`);
  }

  public getEventEngines(): Observable<any> {
    return this.fetch("/eventEngines");
  }

  public countStagingAreaInsertRecords(): Observable<any> {
    return this.fetch("/healthCheck/countStagingAreaInsertRecords");
  }

  public countStagingAreaUpdateRecords(): Observable<any> {
    return this.fetch("/healthCheck/countStagingAreaUpdateRecords");
  }

  public getStagingAreaJobStatus(): Observable<any> {
    return this.fetch("/healthCheck/stagingAreaJobStatus");
  }

  public getActionRecordModifications(actionId: any): Observable<any> {
    return this.fetch(`/settings/actions/${actionId}/recordModifications`);
  }

  public getActionRecordModificationScopes(
    actionId: any,
    recordModificationId: any
  ): Observable<any> {
    return this.fetch(
      `/settings/actions/${actionId}/recordModifications/${recordModificationId}/scopes`
    );
  }

  public getActionRecordModificationScopeById(
    actionId: any,
    recordModificationId: any,
    scopeId: any
  ): Observable<any> {
    return this.fetch(
      `/settings/actions/${actionId}/recordModifications/${recordModificationId}/scopes/${scopeId}`
    );
  }

  public getColumnsByScopeLevelAndRefTable(
    scopeLevel: any,
    refTable: any,
    clientType: any
  ): Observable<any> {
    return this.fetch(
      `/eventEngines/helper/columns/${scopeLevel}/${refTable}/${clientType}`
    );
  }

  public getColumnsByScopeLevelAndRefTableRecordModifications(
    scopeLevel: any,
    refTable: any
  ): Observable<any> {
    return this.fetch(
      `/eventEngines/helper/columns/${scopeLevel}/${refTable}/recordModifications`
    );
  }

  public getColumnsByFieldLogLevelAndRefTable(
    FieldLogLevel: any,
    refTable: any
  ): Observable<any> {
    return this.fetch(
      `/administration/helper/columns/${FieldLogLevel}/${refTable}`
    );
  }

  public getColumnsByFieldLevelAndRefTable(
    fieldLevel: any,
    refTable: any,
    clientType: any,
    regulationGroupId: any,
    capacityId = -1
  ): Observable<any> {
    capacityId = capacityId == null ? -1 : capacityId;
    return this.fetch(
      `/portalSetup/helper/columns/${fieldLevel}/${refTable}/${clientType}/${regulationGroupId}/${capacityId}`
    );
  }

  public getValueListsByScopeAndEventEngine(
    scopeId: any,
    eventEngineId: any
  ): Observable<any> {
    return this.fetch(
      `/eventEngines/helper/values/${scopeId}/${eventEngineId}`
    );
  }

  public getValueListsByScopeId(scopeId: any): Observable<any> {
    return this.fetch(
      `/eventEngines/helper/values/actionRecordModification/${scopeId}`
    );
  }

  public getValueListsByScopeAndReportEngineSetup(
    scopeId: any,
    reportEngineSetupId: any
  ): Observable<any> {
    return this.fetch(
      `/settings/helper/values/${scopeId}/${reportEngineSetupId}`
    );
  }

  public getValueListsByScopeAndDocument(
    scopeId: any,
    documentId: any
  ): Observable<any> {
    return this.fetch(
      `/settings/helper/values/documents/${scopeId}/${documentId}`
    );
  }

  public getValueListsByScopeAndTag(scopeId: any, tagId: any): Observable<any> {
    return this.fetch(`/settings/helper/values/tags/${scopeId}/${tagId}`);
  }

  public getValueListsByRecordModification(
    recordModificationId: any
  ): Observable<any> {
    return this.fetch(
      `/eventEngines/helper/values/recordModifications/${recordModificationId}`
    );
  }

  public getEventEngineById(id: any): Observable<any> {
    return this.fetch(`/eventEngines/${id}`);
  }

  public getActionRecordModificationById(
    actionId: any,
    recordModificationId: any
  ): Observable<any> {
    return this.fetch(
      `/settings/actions/${actionId}/recordModifications/${recordModificationId}`
    );
  }

  public getEventEngineTriggers(id: any): Observable<any> {
    return this.fetch(`/eventEngines/${id}/triggers`);
  }

  public getEventEngineTriggerById(id: any, triggerId: any): Observable<any> {
    return this.fetch(`/eventEngines/${id}/triggers/${triggerId}`);
  }

  public getEventEngineScopes(id: any): Observable<any> {
    return this.fetch(`/eventEngines/${id}/scopes`);
  }

  public getEventEngineScopeById(id: any, scopeId: any): Observable<any> {
    return this.fetch(`/eventEngines/${id}/scopes/${scopeId}`);
  }

  public getReportEngineSetupScopes(id: any): Observable<any> {
    return this.fetch(`/settings/${id}/scopes`);
  }

  public getDocumentScopes(id: any): Observable<any> {
    return this.fetch(`/settings/documents/${id}/scopes`);
  }

  public getTagScopes(id: any): Observable<any> {
    return this.fetch(`/settings/tags/${id}/scopes`);
  }

  public getTags(): Observable<any> {
    return this.fetch(`/settings/tags`);
  }

  public getReportEngineSetupScopeById(id: any, scopeId: any): Observable<any> {
    return this.fetch(`/settings/${id}/scopes/${scopeId}`);
  }

  public getDocumentScopeById(id: any, scopeId: any): Observable<any> {
    return this.fetch(`/settings/documents/${id}/scopes/${scopeId}`);
  }

  public getClientEventEngineActions(id: any): Observable<any> {
    return this.fetch(`/eventEngines/${id}/actions`);
  }

  public getScriptRunStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/scriptrunstatusenum");
  }

  public getCompletionStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/completionStatusEnum");
  }

  public getLoginStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/loginStatusEnum");
  }

  public getLoginTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/loginTypeEnum");
  }

  public getPortalUserRegistrationFieldTypesEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/portalUserRegistrionFieldTypesEnum"
    );
  }

  public getProfileTypes(): Observable<any> {
    return this.fetch("/enumerations/profileTypes");
  }

  public deleteTrigger(id: string): Observable<any> {
    return this.delete(`/systemSettings/triggers/${id}`, "");
  }

  public deletePortalRegulationGroup(id: any): Observable<any> {
    return this.delete(`/portalSetup/regulationGroups/${id}`, "");
  }

  public deletePortalAmendmentRequestType(id: any): Observable<any> {
    return this.delete(`/portalSetup/amendmentRequestTypes/${id}`, "");
  }

  public deletePortalCapacity(id: any): Observable<any> {
    return this.delete(`/portalSetup/capacities/${id}`, "");
  }

  public deletePortalCaption(id: any): Observable<any> {
    return this.delete(`/portalSetup/captions/${id}`, "");
  }

  public deletePortalPrivacyPolicy(id: any): Observable<any> {
    return this.delete(`/portalSetup/privacyPolicies/${id}`, "");
  }

  public deleteDefault(id: any): Observable<any> {
    return this.delete(`/systemSettings/default/${id}`, "");
  }

  public deleteScope(id: string): Observable<any> {
    return this.delete(`/systemSettings/scopes/${id}`, "");
  }

  public deleteRecordModification(id: string): Observable<any> {
    return this.delete(`/systemSettings/recordModifications/${id}`, "");
  }

  public getHangfireDetails(): Observable<HangfireDetailsModel> {
    return this.fetch("/systemSettings/hangfireReport/");
  }

  public deleteActionRecordModification(id: string): Observable<any> {
    return this.delete(`/settings/actionRecordModifications/${id}`, "");
  }

  public deleteActionRecordModificationScope(
    actionRecordModificationId: any,
    scopeId: any
  ): Observable<any> {
    return this.delete(
      `/settings/actions/recordModifications/${actionRecordModificationId}/scopes/${scopeId}`,
      ""
    );
  }

  public deletePortalSectionsField(id: string): Observable<any> {
    return this.delete(`/portalsetup/sections/fields/${id}`, "");
  }

  public deletePortalClientRegistrationPhase(id: any): Observable<any> {
    return this.delete(`/portalsetup/phases/${id}`, "");
  }

  public deletePortalSectionFieldRequirement(id: string): Observable<any> {
    return this.delete(`/portalsetup/sections/fields/requirements/${id}`, "");
  }

  public deletePortalSection(id: string): Observable<any> {
    return this.delete(`/portalsetup/sections/${id}`, "");
  }

  public deletePortalSectionGroup(id: string): Observable<any> {
    return this.delete(`/portalsetup/sectionsGroups/${id}`, "");
  }

  public deleteTriggerScope(id: string): Observable<any> {
    return this.delete(`/systemSettings/triggersscopes/${id}`, "");
  }

  public deleteEventEngine(id: string): Observable<any> {
    return this.delete(`/eventengines/${id}`, "");
  }

  public deleteEventEngineTrigger(
    id: string,
    eventEngineTriggerId: any
  ): Observable<any> {
    return this.delete(
      `/eventengines/${id}/triggers/${eventEngineTriggerId}`,
      ""
    );
  }

  public deleteEventEngineScope(
    id: string,
    eventEngineScopeId: any
  ): Observable<any> {
    return this.delete(`/eventengines/${id}/scopes/${eventEngineScopeId}`, "");
  }

  public deleteReportEngineSetupScope(
    id: string,
    eventEngineScopeId: any
  ): Observable<any> {
    return this.delete(`/settings/${id}/scopes/${eventEngineScopeId}`, "");
  }

  public deleteDocumentScope(
    id: string,
    eventEngineScopeId: any
  ): Observable<any> {
    return this.delete(
      `/settings/documents/${id}/scopes/${eventEngineScopeId}`,
      ""
    );
  }

  public deleteTagScope(id: string, eventEngineScopeId: any): Observable<any> {
    return this.delete(`/settings/tags/${id}/scopes/${eventEngineScopeId}`, "");
  }

  public deleteEventEngineAction(
    id: string,
    eventEngineActionId: any
  ): Observable<any> {
    return this.delete(
      `/eventengines/${id}/actions/${eventEngineActionId}`,
      ""
    );
  }

  public deleteCountryCategory(id: any): Observable<any> {
    return this.delete(`/settings/countryCategories/${id}`, "");
  }

  public addTrigger(addTriggerForm: FormGroup): Observable<any> {
    let body = {
      state: addTriggerForm.controls.state.value,
      refTable: addTriggerForm.controls.refTable.value,
      description: addTriggerForm.controls.description.value,
    };
    return this.postJson(`/systemsettings/triggers`, JSON.stringify(body));
  }

  public editTrigger(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      state: newData.stateValue != undefined ? newData.stateValue : oldData.stateValue,
      refTable:
        newData.refTable != undefined ? newData.refTable : oldData.refTable,
      description:
        newData.description != undefined
          ? newData.description
          : oldData.description,
    };
    return this.putJson(`/systemsettings/triggers`, JSON.stringify(body));
  }

  public reorderPortalSectionField(fromId, toId, isSwap): Observable<any> {
    let body = {
      fromId: fromId,
      toId: toId,
      isSwap: isSwap,
    };
    return this.putJson(
      `/portalSetup/sections/fields/reorder`,
      JSON.stringify(body)
    );
  }

  public addScope(addScopeForm: FormGroup): Observable<any> {
    let body = {
      scopeLevel: addScopeForm.controls.scopeLevel.value,
      refTable: addScopeForm.controls.refTable.value,
      refColumn: addScopeForm.controls.refColumn.value,
      clientType:
        addScopeForm.controls.clientType.value == ""
          ? 0
          : addScopeForm.controls.clientType.value,
      refColumnValuesTable:
        addScopeForm.controls.refColumnValuesTable.value == ""
          ? 0
          : addScopeForm.controls.refColumnValuesTable.value,
      refInternalReference: addScopeForm.controls.refInternalReference.value,
      description: addScopeForm.controls.description.value,
      connectWithTriggerId: addScopeForm.controls.connectWithTriggerId.value,
      isNotNullOrEmpty: addScopeForm.controls.isNotNullOrEmpty.value,
      isChanged: addScopeForm.controls.isChanged.value,
    };
    return this.postJson(`/systemsettings/scopes`, JSON.stringify(body));
  }

  public addRecordModification(
    addRecordModificationForm: FormGroup
  ): Observable<any> {
    let body = {
      type: addRecordModificationForm.controls.type.value,
      recordModificationLevel:
        addRecordModificationForm.controls.recordModificationLevel.value,
      refTable: addRecordModificationForm.controls.refTable.value,
      refColumn: addRecordModificationForm.controls.refColumn.value,
      refColumnValuesTable:
        addRecordModificationForm.controls.refColumnValuesTable.value == ""
          ? 0
          : addRecordModificationForm.controls.refColumnValuesTable.value,
      refInternalReference:
        addRecordModificationForm.controls.refInternalReference.value,
      description: addRecordModificationForm.controls.description.value,
    };
    return this.postJson(
      `/systemsettings/recordModifications`,
      JSON.stringify(body)
    );
  }

  public editScope(newData: any, oldData: any): Observable<any> {
    let body = {
      "id": newData.id != undefined ? newData.id : oldData.id,
      "scopeLevel": newData.scopeLevel != undefined ? newData.scopeLevel : oldData.scopeLevel,
      "refTable": newData.refTable != undefined ? newData.refTable : oldData.refTable,
      "description": newData.description != undefined ? newData.description : oldData.description,
      "refColumn": newData.refColumn != undefined ? newData.refColumn : oldData.refColumn,
      "clientType": newData.clientType != undefined ? newData.clientType : oldData.clientType,
      "refColumnValuesTable": newData.refColumnValuesTable != undefined ? newData.refColumnValuesTable : oldData.refColumnValuesTable,
      "refInternalReference": newData.refInternalReference != undefined ? newData.refInternalReference : oldData.refInternalReference,
      "connectWithTriggerId": newData.connectWithTriggerId != undefined ? newData.connectWithTriggerId : oldData.connectWithTriggerId,
      "isNotNullOrEmpty": newData.isNotNullOrEmpty != undefined ? newData.isNotNullOrEmpty : oldData.isNotNullOrEmpty,
      "isChanged": newData.isChanged != undefined ? newData.isChanged : oldData.isChanged,
    }

    return this.putJson(`/systemSettings/scopes`, JSON.stringify(body));
  }

  public editRecordModification(row: any): Observable<any> {
    let body = {
      id: row.id,
      type: row.type,
      recordModificationLevel: row.recordModificationLevel,
      refTable: row.refTable,
      refColumn: row.refColumn,
      refColumnValuesTable:
        row.refColumnValuesTable == "" ? 0 : row.refColumnValuesTable,
      refInternalReference: row.refInternalReference,
      description: row.description,
    };
    return this.putJson(
      `/systemsettings/recordModifications`,
      JSON.stringify(body)
    );
  }

  public editPortalSection(
    form: any,
    paymentMethods: any,
    documentsId,
    portalCapacitiesId,
    portalSectionAddressTypeValidations,
    portalSectionCapacityValidations
  ): Observable<any> {
    let body = {
      id: form.controls.id.value,
      name: form.controls.name.value,
      visibleOrder: form.controls.visibleOrder.value,
      portalSectionType: form.controls.portalSectionType.value,
      minNumOfRecords: form.controls.minNumOfRecords.value,
      maxNumOfRecords: form.controls.maxNumOfRecords.value,
      description: form.controls.description.value,
      isVisible: form.controls.isVisible.value,
      paymentMethodIds: paymentMethods,
      documentsId: documentsId,
      portalCapacitiesId: portalCapacitiesId,
      reportTitle: form.controls.reportTitle.value,
      buttonName: form.controls.buttonName.value,
      portalSectionAddressTypeValidations: portalSectionAddressTypeValidations,
      portalSectionCapacityValidations: portalSectionCapacityValidations,
    };
    return this.putJson(`/portalSetup/sections`, JSON.stringify(body));
  }

  public editPortalClientRegistrationPhase(
    form: any,
    id: any,
    viewOnlySections: any,
    editableSections,
    automatedProcesses,
    description: string
  ): Observable<any> {
    let body = {
      id: id,
      name: form.controls.name.value,
      query: form.controls.query.value,
      clientType: form.controls.clientType.value,
      regulationGroupId: form.controls.regulationGroupId.value,
      isInitial: form.controls.isInitial.value,
      viewOnlySectionIds: viewOnlySections,
      editableSectionIds: editableSections,
      automatedProcesses: automatedProcesses,
      description: description,
      clientStatusId: form.controls.clientStatusId.value,
      capacityId: form.controls.capacityId.value,
      portalQuestionnaireTypeId: form.controls.portalQuestionnaireTypeId.value,
      informationOnSubmit: form.controls.informationOnSubmit.value,
      validationQueryId: form.controls.validationQueryId.value,
      sendEmailUponSubmission: form.controls.sendEmailUponSubmission.value

    };
    return this.putJson(`/portalSetup/phases`, JSON.stringify(body));
  }

  public editPortalSectionGroup(
    form: any,
    id: any,
    description: string
  ): Observable<any> {
    let body = {
      id: id,
      name: form.controls.name.value,
      showGroupSum: form.controls.showGroupSum.value || false,
      groupSumMessage: form.controls.groupSumMessage.value,
      description: description == "<br>" ? "" : description,
      sectionId: form.controls.sectionId.value,
    };
    return this.putJson(`/portalSetup/sectionsGroups`, JSON.stringify(body));
  }

  public editPortalCaption(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      reference:
        newData.reference != undefined ? newData.reference : oldData.reference,
      caption: newData.caption != undefined ? newData.caption : oldData.caption,
    };
    return this.putJson(`/portalSetup/captions`, JSON.stringify(body));
  }

  public addPortalSectionField(
    addSectionFieldForm: FormGroup,
    portalSectionId: any
  ): Observable<any> {
    let body = {
      fieldLevel: addSectionFieldForm.controls.fieldLevel.value,
      refTable: addSectionFieldForm.controls.refTable.value,
      refColumn: addSectionFieldForm.controls.refColumn.value,
      refInternalReference:
        addSectionFieldForm.controls.refInternalReference.value,
      description: addSectionFieldForm.controls.description.value,
      caption: addSectionFieldForm.controls.caption.value,
      visibleOrder: addSectionFieldForm.controls.visibleOrder.value,
      isRequired: addSectionFieldForm.controls.isRequired.value,
      showInModal: addSectionFieldForm.controls.showInModal.value,
      portalSectionGroupId: addSectionFieldForm.controls.groupId.value,
      refColumnValuesTable:
        addSectionFieldForm.controls.refColumnValuesTable.value,
      isVisible: addSectionFieldForm.controls.isVisible.value,
      isEditable: addSectionFieldForm.controls.isEditable.value,
      retrieveExistingAnswer:
        addSectionFieldForm.controls.retrieveExistingAnswer.value || false,
      portalSectionId: portalSectionId,
      displayInAdditionalInformation:
        addSectionFieldForm.controls.displayInAdditionalInformation.value,
      portalSectionFieldType:
        addSectionFieldForm.controls.portalSectionFieldType.value,
    };
    return this.postJson(`/portalSetup/sections/fields`, JSON.stringify(body));
  }

  public addPortalAmendmentRequestType(
    form: FormGroup,
    questionnaireTypes: any
  ): Observable<any> {
    let body = {
      name: form.controls.name.value,
      requiresExplanation: form.controls.requiresExplanation.value,
      portalQuestionnaireTypeIds: questionnaireTypes,
    };
    return this.postJson(
      `/portalSetup/amendmentRequestTypes`,
      JSON.stringify(body)
    );
  }

  public editPortalAmendmentRequestType(
    amendmentRequestTypeId: any,
    editPortalAmendmentRequestTypeForm: any,
    questionnaireTypes: any
  ): Observable<any> {
    let body = {
      id: amendmentRequestTypeId,
      name: editPortalAmendmentRequestTypeForm.controls.name.value,
      requiresExplanation:
        editPortalAmendmentRequestTypeForm.controls.requiresExplanation.value,
      portalQuestionnaireTypeIds: questionnaireTypes,
    };

    return this.putJson(
      `/portalSetup/amendmentRequestTypes`,
      JSON.stringify(body)
    );
  }

  public addPortalQuestionnaireType(form: FormGroup): Observable<any> {
    let body = {
      name: form.controls.name.value,
      deadlineDate: form.controls.deadlineDate.value,
      isDefault: form.controls.isDefault.value,
      isVisibleToClients: form.controls.isVisibleToClients.value,
    };
    return this.postJson(
      `/portalSetup/portalQuestionnaireTypes`,
      JSON.stringify(body)
    );
  }

  public clonePortalQuestionnaireTypeSetup(form: FormGroup): Observable<any> {
    let body = {
      portalQuestionnaireTypeId: form.controls.portalQuestionnaireTypeId.value,
      name: form.controls.name.value,
      deadlineDate: form.controls.deadlineDate.value,
      isDefault: form.controls.isDefault.value,
      isVisibleToClients: form.controls.isVisibleToClients.value,
    };
    return this.postJson(
      `/portalSetup/clonePortalQuestionnaireType`,
      JSON.stringify(body)
    );
  }

  public addPortalSectionFieldRequirement(
    addSectionFieldRequirementForm: FormGroup,
    selectedFieldValues: any
  ): Observable<any> {
    let body = {
      portalSectionFieldId:
        addSectionFieldRequirementForm.controls.portalSectionFieldId.value,
      requirementFieldId:
        addSectionFieldRequirementForm.controls.requirementFieldId.value,
      values: selectedFieldValues,
    };
    return this.postJson(
      `/portalSetup/sections/fields/requirements`,
      JSON.stringify(body)
    );
  }

  public addPortalSectionFieldValidation(
    portalSectionFieldId,
    validationFieldsId,
    validationType,
    errorMessage
  ): Observable<any> {
    let body = {
      portalSectionFieldId: portalSectionFieldId,
      validationFieldsId: validationFieldsId,
      validationType: validationType,
      errorMessage: errorMessage,
    };
    return this.postJson(
      `/portalSetup/sections/fields/validations`,
      JSON.stringify(body)
    );
  }

  public addPortalSectionGroupValidation(
    portalSectionGroupId,
    validationGroupsId,
    validationType,
    errorMessage
  ): Observable<any> {
    let body = {
      portalSectionGroupId: portalSectionGroupId,
      validationGroupsId: validationGroupsId,
      validationType: validationType,
      errorMessage: errorMessage,
    };
    return this.postJson(
      `/portalSetup/sections/groups/validations`,
      JSON.stringify(body)
    );
  }

  public editPortalSectionFieldValidation(
    portalSectionFieldId,
    validationFieldsId,
    validationType,
    group,
    errorMessage
  ): Observable<any> {
    let body = {
      fieldId: portalSectionFieldId,
      validationFieldsId: validationFieldsId || [],
      validationType: validationType,
      group: group,
      errorMessage: errorMessage,
    };
    return this.putJson(
      `/portalSetup/sections/fields/validations`,
      JSON.stringify(body)
    );
  }

  public editPortalSectionGroupValidation(
    portalSectionGroupId,
    validationGroupsId,
    validationType,
    group,
    errorMessage
  ): Observable<any> {
    let body = {
      groupId: portalSectionGroupId,
      validationGroupsId: validationGroupsId || [],
      validationType: validationType,
      group: group,
      errorMessage: errorMessage,
    };
    return this.putJson(
      `/portalSetup/sections/groups/validations`,
      JSON.stringify(body)
    );
  }

  public editPortalSectionFieldRequirement(row): Observable<any> {
    let body = {
      id: row.id,
      selectedValues: row.selectedValues,
    };
    return this.putJson(
      `/portalSetup/sections/fields/requirements`,
      JSON.stringify(body)
    );
  }

  public editPortalPaymentMethodsCustomFields(
    customFieldId: any,
    paymentMethodIds: any
  ): Observable<any> {
    let body = {
      customFieldId: customFieldId,
      paymentMethodIds: paymentMethodIds,
    };
    return this.putJson(
      `/portalSetup/paymentMethodsCustomFields`,
      JSON.stringify(body)
    );
  }

  public editPortalSectionField(
    editSectionFieldForm: FormGroup
  ): Observable<any> {
    let body = {
      id: editSectionFieldForm.controls.id.value,
      portalSectionId: editSectionFieldForm.controls.portalSectionId.value,
      fieldLevel: editSectionFieldForm.controls.fieldLevel.value,
      refTable: editSectionFieldForm.controls.refTable.value,
      refColumn: editSectionFieldForm.controls.refColumn.value,
      refInternalReference:
        editSectionFieldForm.controls.refInternalReference.value,
      refColumnValuesTable:
        editSectionFieldForm.controls.refColumnValuesTable.value,
      caption: editSectionFieldForm.controls.caption.value,
      description: editSectionFieldForm.controls.description.value,
      isRequired: editSectionFieldForm.controls.isRequired.value,
      showInModal: editSectionFieldForm.controls.showInModal.value,
      visibleOrder: editSectionFieldForm.controls.visibleOrder.value,
      isVisible: editSectionFieldForm.controls.isVisible.value,
      isEditable: editSectionFieldForm.controls.isEditable.value,
      retrieveExistingAnswer:
        editSectionFieldForm.controls.retrieveExistingAnswer.value || false,
      portalSectionGroupId:
        editSectionFieldForm.controls.portalSectionGroupId.value,
      displayInAdditionalInformation:
        editSectionFieldForm.controls.displayInAdditionalInformation.value,
      portalSectionFieldType:
        editSectionFieldForm.controls.portalSectionFieldType.value,
    };
    return this.putJson(`/portalSetup/sections/fields`, JSON.stringify(body));
  }

  public addPortalSection(
    addPortalSection: FormGroup,
    paymentMethods: any,
    documentsId,
    portalCapacitiesId,
    portalSectionAddressTypeValidations,
    portalSectionCapacityValidations,
    description:string|null = null,
  ): Observable<any> {
    let body = {
      name: addPortalSection.controls.name.value,
      portalSectionType: addPortalSection.controls.portalSectionType.value,
      clientType: addPortalSection.controls.clientType.value,
      regulationGroupId: addPortalSection.controls.regulationGroupId.value,
      minNumOfRecords: addPortalSection.controls.minNumOfRecords.value,
      maxNumOfRecords: addPortalSection.controls.maxNumOfRecords.value,
      capacityId: addPortalSection.controls.capacityId.value,
      portalQuestionnaireTypeId:
        addPortalSection.controls.portalQuestionnaireTypeId.value,
      description: description ?? addPortalSection.controls.description.value,
      isVisible: addPortalSection.controls.isVisible.value,
      paymentMethodIds: paymentMethods,
      documentsId: documentsId,
      portalCapacitiesId: portalCapacitiesId,
      portalSectionAddressTypeValidations: portalSectionAddressTypeValidations,
      portalSectionCapacityValidations: portalSectionCapacityValidations,
    };
    return this.postJson(`/portalSetup/sections`, JSON.stringify(body));
  }

  public addPortalClientRegistrationPhase(
    form: FormGroup,
    viewOnlySections: any,
    editableSections,
    automatedProcesses,
    description: string
  ): Observable<any> {
    let body = {
      name: form.controls.name.value,
      query: form.controls.query.value,
      clientType: form.controls.clientType.value,
      regulationGroupId: form.controls.regulationGroupId.value,
      isInitial: form.controls.isInitial.value,
      viewOnlySectionIds: viewOnlySections,
      editableSectionIds: editableSections,
      automatedProcesses: automatedProcesses,
      description: description,
      clientStatusId: form.controls.clientStatusId.value,
      informationOnSubmit: form.controls.informationOnSubmit.value,
      capacityId: form.controls.capacityId.value,
      portalQuestionnaireTypeId: form.controls.portalQuestionnaireTypeId.value,
      validationQueryId: form.controls.validationQueryId.value,
      sendEmailUponSubmission: form.controls.sendEmailUponSubmission.value
    };
    return this.postJson(`/portalSetup/phases`, JSON.stringify(body));
  }

  public addTriggerScope(addTriggerScopeForm: FormGroup): Observable<any> {
    let body = {
      triggerId: addTriggerScopeForm.controls.triggerId.value,
      scopeId: addTriggerScopeForm.controls.scopeId.value,
    };
    return this.postJson(
      `/systemsettings/triggersscopes`,
      JSON.stringify(body)
    );
  }

  public addQueryBasedSetup(form: FormGroup): Observable<any> {
    let body = {
      name: form.controls.name.value,
      query: form.controls.query.value,
      description: form.controls.description.value,
      queryTemplateType: form.controls.queryTemplateType.value,
      questionnaireTypeId: form.controls.questionnaireTypeId.value,
      automaticUpdateClientCategorization:
        form.controls.automaticUpdateClientCategorization.value,
    };

    return this.postJson(
      `/administration/queryBasedSetups`,
      JSON.stringify(body)
    );
  }

  public addQueryBasedSetupParameter(
    form: FormGroup,
    queryBasedSetupId: any
  ): Observable<any> {
    let body = {
      queryBasedSetupId: queryBasedSetupId,
      paramName: form.controls.paramName.value,
      name: form.controls.name.value,
      type: form.controls.type.value,
      referenceTable: form.controls.referenceTable.value,
    };

    return this.postJson(
      `/administration/queryBasedSetups/${queryBasedSetupId}/parameters`,
      JSON.stringify(body)
    );
  }

  public editQueryBasedSetup(form: FormGroup): Observable<any> {
    let body = {
      id: form.controls.id.value,
      name: form.controls.name.value,
      query: form.controls.query.value,
      description: form.controls.description.value,
    };

    return this.putJson(
      `/administration/queryBasedSetups`,
      JSON.stringify(body)
    );
  }

  public editQueryBasedSetupParameter(form: FormGroup): Observable<any> {
    let body = {
      id: form.controls.id.value,
      name: form.controls.name.value,
      paramName: form.controls.paramName.value,
      type: form.controls.type.value,
      referenceTable: form.controls.referenceTable.value,
    };

    return this.putJson(
      `/administration/queryBasedSetups/parameters`,
      JSON.stringify(body)
    );
  }

  public editTriggerScope(
    editTriggerScopeForm: FormGroup,
    triggerScopeId: any
  ): Observable<any> {
    let body = {
      id: triggerScopeId,
      triggerId: editTriggerScopeForm.controls.triggerId.value,
      scopeId: editTriggerScopeForm.controls.scopeId.value,
    };
    return this.putJson(`/systemsettings/triggersscopes`, JSON.stringify(body));
  }

  public addEventEngine(addEventEngineForm: FormGroup): Observable<any> {
    let body = {
      name: addEventEngineForm.controls.name.value,
      enabled: addEventEngineForm.controls.enabled.value,
      regulationGroupId: addEventEngineForm.controls.regulationGroupId.value,
    };
    return this.postJson(`/eventEngines`, JSON.stringify(body));
  }

  public editEventEngine(
    editEventEngineForm: FormGroup,
    eventEngineId: any
  ): Observable<any> {
    let body = {
      id: eventEngineId,
      name: editEventEngineForm.controls.name.value,
      enabled: editEventEngineForm.controls.enabled.value,
      regulationGroupId: editEventEngineForm.controls.regulationGroupId.value,
    };
    return this.putJson(`/eventEngines`, JSON.stringify(body));
  }

  public addEventEngineTrigger(
    addEventEngineTriggerForm: FormGroup,
    eventEngineId: any
  ): Observable<any> {
    let body = {
      eventEngineId: eventEngineId,
      triggerId: addEventEngineTriggerForm.controls.triggerId.value,
    };
    return this.postJson(
      `/eventEngines/${eventEngineId}/triggers`,
      JSON.stringify(body)
    );
  }

  public editEventEngineTrigger(
    editEventEngineTriggerForm: FormGroup,
    eventEngineId: any,
    eventEngineTriggerId: any
  ): Observable<any> {
    let body = {
      id: eventEngineTriggerId,
      eventEngineId: eventEngineId,
      triggerId: editEventEngineTriggerForm.controls.triggerId.value,
    };
    return this.putJson(
      `/eventEngines/${eventEngineId}/triggers/${eventEngineTriggerId}`,
      JSON.stringify(body)
    );
  }

  public addEventEngineScope(
    addEventEngineScopeForm: FormGroup,
    eventEngineId: any,
    previousValues: any,
    filteringValues: any
  ): Observable<any> {
    let body = {
      eventEngineId: eventEngineId,
      scopeId: addEventEngineScopeForm.controls.scopeId.value,
      previousValues: previousValues,
      filteringValues: filteringValues,
    };
    return this.postJson(
      `/eventEngines/${eventEngineId}/scopes`,
      JSON.stringify(body)
    );
  }

  public addActionRecordModification(
    addActionRecordModificationForm: FormGroup,
    actionId: any,
    updateValue: any,
    insertRecord: any
  ): Observable<any> {
    let body = {
      actionId: actionId,
      recordModificationId:
        addActionRecordModificationForm.controls.recordModificationId.value,
      updateValue: updateValue,
      insertRecord: insertRecord,
    };
    return this.postJson(
      `/settings/actions/${actionId}/recordModifications`,
      JSON.stringify(body)
    );
  }

  public addActionRecordModificationScope(
    addActionRecordModificationScopeForm: FormGroup,
    actionId: any,
    recordModificationId: any,
    actionRecordModificationId: any,
    scopeId: any,
    filteringValues: any
  ): Observable<any> {
    let body = {
      actionRecordModificationId: actionRecordModificationId,
      scopeId: scopeId,
      filteringValues: filteringValues,
    };
    return this.postJson(
      `/settings/actions/${actionId}/recordModifications/${recordModificationId}/scopes`,
      JSON.stringify(body)
    );
  }

  public addReportEngineSetupScope(
    addReportEngineSetupScopeForm: FormGroup,
    reportEngineSetupId: any,
    previousValues: any,
    filteringValues: any
  ): Observable<any> {
    let body = {
      reportEngineSetupId: reportEngineSetupId,
      scopeId: addReportEngineSetupScopeForm.controls.scopeId.value,
      previousValues: previousValues,
      filteringValues: filteringValues,
    };
    return this.postJson(
      `/settings/${reportEngineSetupId}/scopes`,
      JSON.stringify(body)
    );
  }

  public addDocumentScope(
    addDocumentScopeForm: FormGroup,
    documentId: any,
    previousValues: any,
    filteringValues: any
  ): Observable<any> {
    let body = {
      documentId: documentId,
      scopeId: addDocumentScopeForm.controls.scopeId.value,
      previousValues: previousValues,
      filteringValues: filteringValues,
    };
    return this.postJson(
      `/settings/documents/${documentId}/scopes`,
      JSON.stringify(body)
    );
  }

  public addTagScope(
    addTagScopeForm: FormGroup,
    tagId: any,
    previousValues: any,
    filteringValues: any
  ): Observable<any> {
    let body = {
      tagId: tagId,
      scopeId: addTagScopeForm.controls.scopeId.value,
      previousValues: previousValues,
      filteringValues: filteringValues,
    };
    return this.postJson(
      `/settings/tags/${tagId}/scopes`,
      JSON.stringify(body)
    );
  }

  public editEventEngineScope(
    editEventEngineScopeForm: FormGroup,
    eventEngineId: any,
    eventEngineScopeId: any
  ): Observable<any> {
    let body = {
      id: eventEngineScopeId,
      eventEngineId: eventEngineId,
      scopeId: editEventEngineScopeForm.controls.scopeId.value,
      previousValues: editEventEngineScopeForm.controls.previousValues.value,
      filteringValues: editEventEngineScopeForm.controls.filteringValues.value,
    };
    return this.putJson(
      `/eventEngines/${eventEngineId}/scopes/${eventEngineScopeId}`,
      JSON.stringify(body)
    );
  }

  public editActionRecordModification(
    editActionRecordModificationForm: FormGroup,
    actionId: any,
    recordModificationId: any,
    actionRecordModificationId: any
  ): Observable<any> {
    let body = {
      id: actionRecordModificationId,
      actionId: actionId,
      recordModificationId: recordModificationId,
      updateValue: editActionRecordModificationForm.controls.updateValue.value,
      insertRecord: {},
    };
    return this.putJson(
      `/settings/actions/${actionId}/recordModifications/${recordModificationId}`,
      JSON.stringify(body)
    );
  }

  public editActionRecordModificationScope(
    editActionRecordModificationScopeForm: FormGroup,
    actionRecordModificationId: any,
    scopeId: any,
    actionRecordModificationScopeId: any,
    actionId: any,
    recordModificationId: any
  ): Observable<any> {
    let body = {
      id: actionRecordModificationScopeId,
      actionRecordModificationId: actionRecordModificationId,
      scopeId: scopeId,
      filteringValues:
        editActionRecordModificationScopeForm.controls.filteringValues.value,
    };
    return this.putJson(
      `/settings/actions/${actionId}/recordModifications/${recordModificationId}/scopes/${scopeId}`,
      JSON.stringify(body)
    );
  }

  public editReportEngineSetupScope(
    editReportEngineSetupScopeForm: FormGroup,
    reportEngineSetupId: any,
    reportEngineSetupScopeId: any
  ): Observable<any> {
    let body = {
      id: reportEngineSetupScopeId,
      reportEngineSetupId: reportEngineSetupId,
      scopeId: editReportEngineSetupScopeForm.controls.scopeId.value,
      previousValues:
        editReportEngineSetupScopeForm.controls.previousValues.value,
      filteringValues:
        editReportEngineSetupScopeForm.controls.filteringValues.value,
    };
    return this.putJson(
      `/settings/${reportEngineSetupId}/scopes/${reportEngineSetupScopeId}`,
      JSON.stringify(body)
    );
  }

  public editDocumentScope(
    editDocumentScopeForm: FormGroup,
    documentId: any,
    documentScopeId: any
  ): Observable<any> {
    let body = {
      id: documentScopeId,
      documentId: documentId,
      scopeId: editDocumentScopeForm.controls.scopeId.value,
      previousValues: editDocumentScopeForm.controls.previousValues.value,
      filteringValues: editDocumentScopeForm.controls.filteringValues.value,
    };
    return this.putJson(
      `/settings/documents/${documentId}/scopes/${documentScopeId}`,
      JSON.stringify(body)
    );
  }

  public addClientEventEngineAction(
    actionBody: any,
    eventEngineId: number
  ): Observable<any> {
    actionBody.eventEngineId = eventEngineId;
    return this.postJson(
      `/eventengines/${eventEngineId}/actions`,
      JSON.stringify(actionBody)
    );
  }

  public getNotificationTemplates(): Observable<any> {
    return this.fetch("/settings/notificationTemplate");
  }

  public getAllNotificationTemplates(): Observable<any> {
    return this.fetch("/settings/notificationTemplate/all");
  }

  public getIdVerificationDataSupports(): Observable<any> {
    return this.fetch("/settings/idVerificationDataSupports");
  }

  public getExternalCredentialsSetups(): Observable<any> {
    return this.fetch("/settings/externalCredentialsSetup");
  }

  public getExternalCredentialsSetupsFromAdministration(): Observable<any> {
    return this.fetch("/administration/externalCredentialsSetup");
  }

  public removeCredentials(id: any): Observable<any> {
    return this.putJson(`/administration/removeCredentials/${id}`, JSON.stringify({ "id": id }));
  }

  public getExternalEndpointBodyTemplateList(): Observable<any> {
    return this.fetch("/settings/externalEndpointBodyTemplates");
  }

  public getDropdownNotificationTemplates(): Observable<any> {
    return this.fetch("/dropdown/notification-templates");
  }

  public getSystemAccount(): Observable<any> {
    return this.fetch("/systemSettings/systemAccounts");
  }

  public getSystemAccounts(): Observable<any> {
    return this.fetch("/clientCommon/systemAccounts");
  }

  public getDocuments(): Observable<any> {
    return this.fetch("/settings/documents");
  }

  public getDocumentsSimplified(): Observable<any> {
    return this.fetch("/settings/documents/simplified");
  }

  public getMandatoryDocumentsDropdown(
    regulationGroupId,
    clientType
  ): Observable<any> {
    return this.fetch(
      `/settings/mandatoryDocumentsDropdown/regulationGroups/${regulationGroupId}/clientTypes/${clientType}`
    );
  }

  public getProposedValues(): Observable<any> {
    return this.fetch("/processes/proposedUpdates");
  }

  public getWorkflowProcesses(): Observable<any> {
    return this.fetch("/processes/processInstances");
  }

  public acceptProposedValues(selectedCountryUpdates: any[]): Observable<any> {
    const body = {
      "acceptedProposedCountryUpdates": selectedCountryUpdates
    }
    return this.putJson("/processes/proposedUpdates", JSON.stringify(body));
  }

  public getMandatoryDocumentsCategories(): Observable<any> {
    return this.fetch(`/settings/mandatoryDocumentsCategories`);
  }

  public getHandleDocuments(): Observable<any> {
    return this.fetch("/processes/getHandleDocuments");
  }

  public getIntroEndings(): Observable<any> {
    return this.fetch("/settings/introEndings");
  }

  public getEvaluationGrades(): Observable<any> {
    return this.fetch("/settings/evaluationGrades");
  }

  public getQuestionnaireGrades(): Observable<any> {
    return this.fetch("/settings/questionnaireGrades");
  }

  public GetModelProperties(model: any): Observable<any> {
    return this.fetch("/settings/modelProperties/" + model);
  }

  public getCustomFields(): Observable<any> {
    return this.fetch(`/settings/customFields`);
  }

  public getCustomFieldsWithInternalReference(): Observable<any> {
    return this.fetch(`/settings/customFieldsWithInternalReference`);
  }

  public getCustomFieldsByReferenceTable(
    referenceTable: any,
    clientId: any
  ): Observable<any> {
    return this.getCachedCustomFieldData(
      `/settings/customFields/form/${referenceTable}/${clientId == null || clientId == undefined ? 0 : clientId
      }`
    );
  }

  public getCustomFieldsByReferenceTables(
    referenceTables: any,
    regulationGroupIds: any
  ): Observable<any> {
    let body = {
      regulationGroupIds: regulationGroupIds,
      referenceTables: referenceTables,
    };
    return this.postJson(
      `/settings/allCustomFields/form/`,
      JSON.stringify(body)
    );
  }

  public getCheckRuleSynchronized(id: any): Observable<any> {
    return this.fetch(
      "/administration/rules/" + id.toString() + "/checkSychronized"
    );
  }

  public getHandleEvaluationClients(): Observable<any> {
    return this.fetch("/processes/handleEvaluations");
  }

  public getCreateOnboardingNewRegistrations(): Observable<any> {
    return this.fetch("/processes/create-onboarding-new-registrations");
  }

  public handleQuestionnaires(): Observable<any> {
    return this.fetch("/processes/handleQuestionnaires");
  }

  public showClientTransactionsTotal(id: any): Observable<any> {
    return this.fetch(
      "/clientCommon/" + id.toString() + "/showClientTransactionsTotal"
    );
  }

  public getClientHighRiskPointFrom(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/highRiskPointFrom");
  }

  public getClientEvaluationStatus(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/EvaluationStatus");
  }

  public getClientNextEvaluationDate(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/NextEvaluationDate");
  }

  public getClientAddresses(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/addresses");
  }

  public getClientAdditionalExternalReferences(clientId): Observable<any> {
    return this.fetch(`/clientCommon/${clientId}/additionalExternalReferences`);
  }

  public getClientIPAddressHistory(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/ipAddressHistory");
  }

  public getClientFraudRiskAnalyzes(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/fraudRiskAnalyzes");
  }

  public getClientFraudRiskAnalysisRecords(id: any): Observable<any> {
    return this.fetch(
      "/clientCommon/" + id.toString() + "/fraudRiskAnalysisRecords"
    );
  }

  public getClientFraudRiskAnalysis(
    id: any,
    fraudRiskAnalysis: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" +
      id.toString() +
      "/fraudRiskAnalysisDatabyId/" +
      fraudRiskAnalysis.toString()
    );
  }

  public getClientFatca(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/fatca");
  }

  public getClientAlternativeNames(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/alternative-names");
  }

  public getClientRelatedWebsites(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/relatedWebsites");
  }

  public getClientCertificates(clientId): Observable<any> {
    return this.fetch(`/clientCommon/${clientId}/certificates`);
  }

  public getClientVideoIdentifications(id: any): Observable<any> {
    return this.fetch(
      "/clientCorporates/" + id.toString() + "/videoIdentifications"
    );
  }

  public getClientPortalQuestionnaire(id): Observable<any> {
    return this.fetch(`/clientCorporates/portalQuestionnaires/${id}`);
  }

  public getClientPortalQuestionnaires(id: any): Observable<any> {
    return this.fetch(
      "/clientCorporates/" + id.toString() + "/portalQuestionnaires"
    );
  }

  public getClientAuthorizedPortalUsers(id): Observable<any> {
    return this.fetch(`/clientCorporates/${id}/authorizedPortalUsers`);
  }

  public getIndividualClientElectronicIdentifications(
    id: any
  ): Observable<any> {
    return this.fetch(
      "/clientIndividuals/" + id.toString() + "/electronicIdentifications"
    );
  }

  public getCorporateClientElectronicIdentifications(id: any): Observable<any> {
    return this.fetch(
      "/clientCorporates/" + id.toString() + "/electronicIdentifications"
    );
  }

  public getElectronicIdentificationsDocument(id: any): Observable<any> {
    return this.fetch(
      "/clientIndividuals/" +
      id.toString() +
      "/electronicIdentificationDocument"
    );
  }

  public getClientMidAccounts(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/midAccounts");
  }

  public getClientChecklists(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/checklists");
  }

  public getClientTaxResidencies(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/tax-residencies");
  }

  public getClientIdentityCheckResults(id: any): Observable<any> {
    return this.fetch(
      "/clientCommon/" + id.toString() + "/identityCheckResults"
    );
  }

  public getAccountServiceCosts(actionName: any): Observable<any> {
    return this.fetch("/clientCommon/accountServiceCosts/" + actionName);
  }

  public getClientDocuments(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id.toString() + "/documents");
  }

  public getClientAvailableDocuments(id: any): Observable<any> {
    return this.fetch(
      "/clientCommon/" + id.toString() + "/documents/availableDocuments"
    );
  }

  public getClientDocumentCheckResults(id: any): Observable<any> {
    return this.fetch(
      "/clientCommon/" + id.toString() + "/documents/documentCheckResults"
    );
  }

  public getClientDocumentCheckResultById(
    id: any,
    clientDocumentCheckResultId: any
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${id}/documents/documentCheckResults/${clientDocumentCheckResultId}`
    );
  }

  public getClientRelatedWebsiteById(
    id: any,
    relatedWebsiteId: any
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${id}/relatedWebsites/${relatedWebsiteId}`
    );
  }

  public getClientCertificatesById(clientId, id): Observable<any> {
    return this.fetch(`/clientCommon/${clientId}/certificates/${id}`);
  }

  public getClientVideoIdentificationById(
    id: any,
    videoIdentificationId: any
  ): Observable<any> {
    return this.fetch(
      `/clientCorporates/${id}/videoIdentifications/${videoIdentificationId}`
    );
  }

  public getClientCompanySearchResults(
    clientId,
    clientCompanySearchId
  ): Observable<any> {
    return this.fetch(
      `/clientCorporates/${clientId}/companySearchResults/${clientCompanySearchId}`
    );
  }

  public checkProductAvailability(
    clientId,
    clientCompanySearchId,
    clientCompanySearchDocumentId
  ): Observable<any> {
    return this.fetch(
      `/clientCorporates/${clientId}/companySearches/${clientCompanySearchId}/documents/${clientCompanySearchDocumentId}`
    );
  }

  public getListOfProductsByCompanyId(
    clientId,
    clientCompanySearchId
  ): Observable<any> {
    return this.fetch(
      `/clientCorporates/${clientId}/companySearches/${clientCompanySearchId}/products`
    );
  }

  public getKompanyComGovernmentFees(): Observable<any> {
    return this.getCachedData(`/clientCorporates/kompanyComGovernmentFees`);
  }

  public getKompanyCharges(): Observable<any> {
    return this.getCachedData(`/clientCorporates/kompanyComCharges`);
  }

  public getKompanyAvailableDatasetsPerCountry(country): Observable<any> {
    return this.getCachedData(
      `/clientCorporates/kompanyAvailableDatasetsPerCountry/${country}`
    );
  }

  public getKompanyDefaultSelectedDataset(country): Observable<any> {
    return this.getCachedData(
      `/clientCorporates/kompanyDefaultSelectedDataset/${country}`
    );
  }

  public getCompanySearchSelectedDataset(
    clientId,
    clientCompanySearchId
  ): Observable<any> {
    return this.fetch(
      `/clientCorporates/${clientId}/companySearches/${clientCompanySearchId}/selectedDataset`
    );
  }

  public getClientCompanySearches(id: any): Observable<any> {
    return this.fetch(`/clientCorporates/${id}/companySearches`);
  }

  public getClientMidAccountById(id: any, midAccountId: any): Observable<any> {
    return this.fetch(`/clientCommon/${id}/midAccounts/${midAccountId}`);
  }

  public getClientInvestmentAccountById(
    userId: number,
    investmentAccountId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + userId + "/investmentAccounts/" + investmentAccountId
    );
  }

  public getClientContractPolicyNumbers(userId: number): Observable<any> {
    return this.fetch("/clientCommon/" + userId + "/contractPolicyNumbers");
  }

  public requestHandleEvaluation(selectedClients: any): Observable<any> {
    let body = {
      clientIds: selectedClients,
    };
    return this.postJson(
      "/processes/requestHandleEvaluation",
      JSON.stringify(body)
    );
  }

  public bulkAddClientsPortalUsers(selectedRows: any): Observable<any> {
    let body = {
      selectedRows: selectedRows,
    };
    return this.postJson(
      "/processes/bulkAddClientsPortalUsers",
      JSON.stringify(body)
    );
  }

  public cancelRequestedHandleEvaluation(
    selectedClients: any
  ): Observable<any> {
    let body = {
      clientIds:
        selectedClients /** in this case, the selected clients are the clients that we will try to unmark as requested for evaluation.
             Do not let the attribute name 'clientsForEvaluation' confuse you. It's like that because we are using the same VM */,
    };
    return this.postJson(
      "/processes/cancelRequestedHandleEvaluation",
      JSON.stringify(body)
    );
  }

  public revertEvaluationStatus(
    selectedClients: any,
    form: FormGroup
  ): Observable<any> {
    let body = {
      clientIds: selectedClients,
      /** in this case, the selected clients are the clients that we will try to unmark as requested for evaluation.
       Do not let the attribute name 'clientsForEvaluation' confuse you. It's like that because we are using the same VM */
      explanation: form.controls.explanation.value,
    };

    return this.postJson(
      "/processes/revertEvaluationStatus",
      JSON.stringify(body)
    );
  }

  public calculateClientsUnifiedScoring(selectedClients: any): Observable<any> {
    let body = {
      clientIds: selectedClients,
    };
    return this.postJson(
      "/processes/calculateClientsUnifiedScoring",
      JSON.stringify(body)
    );
  }

  public finalizeQuestionnaires(selectedQuestionnaires: any): Observable<any> {
    let body = {
      questionnaireIds: selectedQuestionnaires,
    };
    return this.postJson(
      "/processes/finalizeQuestionnaires",
      JSON.stringify(body)
    );
  }

  public addClientRegistrationForExistingClientProcess(
    selectedRecords: any,
    form: FormGroup
  ): Observable<any> {
    let body = {
      phaseId: form.controls.phase.value,
      clientIds: selectedRecords,
      userEmail: form.controls.onboardingUserFilter.value,
      portalQuestionnaireTypeId: form.controls.portalQuestionnaireTypeId.value,
    };
    return this.postJson(
      "/processes/addClientRegistrations",
      JSON.stringify(body)
    );
  }

  public addOngoingMonitoringClients(clientIds: any): Observable<any> {
    let body = {
      clientIds: clientIds,
    };
    return this.postJson(
      "/processes/addOngoingMonitoringClients",
      JSON.stringify(body)
    );
  }

  public removeOngoingMonitoringClients(clientIds: any): Observable<any> {
    let body = {
      clientIds: clientIds,
    };
    return this.postJson(
      "/processes/removeOngoingMonitoringClients",
      JSON.stringify(body)
    );
  }

  public flagIgnoredOngoingMonitoringClients(clientIds: any): Observable<any> {
    let body = {
      clientIds: clientIds,
    };
    return this.postJson(
      "/processes/flagIgnoredOngoingMonitoringClients",
      JSON.stringify(body)
    );
  }

  public getClientsAdvancedSearch(
    searchClientForm: FormGroup,
    customFieldIdIndividual: number,
    customFieldValuesIndividual: any,
    customFieldIdCorporate: number,
    customFieldValuesCorporate: any
  ): Observable<any> {
    let body = {
      clientStatusId: searchClientForm.controls.clientStatusId.value,
      clientCategoryId: searchClientForm.controls.clientCategoryId.value,
      regulationGroupId: searchClientForm.controls.regulationGroupId.value,
      firstName: searchClientForm.controls.firstName.value,
      lastName: searchClientForm.controls.lastName.value,
      externalReference:
        searchClientForm.controls.externalReference.value == ""
          ? null
          : searchClientForm.controls.externalReference.value,
      email: searchClientForm.controls.email.value,
      status: searchClientForm.controls.status.value,
      dateOfBirth: searchClientForm.controls.dateOfBirth.value,
      registeredName: searchClientForm.controls.registeredName.value,
      customFieldIdIndividual: customFieldIdIndividual,
      customFieldValuesIndividual: customFieldValuesIndividual,
      customFieldIdCorporate: customFieldIdCorporate,
      customFieldValuesCorporate: customFieldValuesCorporate,
    };

    return this.postJson("/clients", JSON.stringify(body));
  }

  public sendEGOVEmails(clientsId, requestScopes): Observable<any> {
    let body = {
      clientsId: clientsId,
      requestScopes: requestScopes,
    };

    return this.postJson("/processes/sendEGOVEmails", JSON.stringify(body));
  }

  public getClientsAdvancedSearchExport(
    searchClientForm: FormGroup
  ): Observable<any> {
    let body = {
      pageNumber: 0,
      pageSize: 999999999,
      clientStatusId: searchClientForm.controls.clientStatusId.value,
      clientCategoryId: searchClientForm.controls.clientCategoryId.value,
      regulationGroupId: searchClientForm.controls.regulationGroupId.value,
      firstName: searchClientForm.controls.firstName.value,
      lastName: searchClientForm.controls.lastName.value,
      externalReference:
        searchClientForm.controls.externalReference.value == ""
          ? null
          : searchClientForm.controls.externalReference.value,
      email: searchClientForm.controls.email.value,
      status: searchClientForm.controls.status.value,
      dateOfBirth: searchClientForm.controls.dateOfBirth.value,
      registeredName: searchClientForm.controls.registeredName.value,
    };

    return this.postJson("/clients", JSON.stringify(body));
  }

  public getClients(): Observable<any> {
    return this.fetch("/clientCommon/");
  }

  public getClientsFilter(): Observable<any> {
    return this.fetch("/clientCommon/filter");
  }

  public getFirms(): Observable<any> {
    return this.fetch("/firms/");
  }

  public getFirmPanel(id): Observable<any> {
    return this.fetch(`/firms/${id}/panel`);
  }

  public getFirmDashboard(id): Observable<any> {
    return this.fetch(`/firms/${id}/dashboard`);
  }

  public getFirmEvaluations(id): Observable<any> {
    return this.fetch(`/firms/${id}/evaluations`);
  }

  public getFirmSanctionEvaluations(id): Observable<any> {
    return this.fetch(`/firms/${id}/sanctionEvaluations`);
  }

  public getFirmEvaluationById(regulationGroupId, id): Observable<any> {
    return this.fetch(`/firms/${regulationGroupId}/evaluations/${id}`);
  }

  public getFirmSanctionEvaluationById(regulationGroupId, id): Observable<any> {
    return this.fetch(`/firms/${regulationGroupId}/sanctionEvaluations/${id}`);
  }

  public getFirmEvaluationReportData(regulationGroupId, id): Observable<any> {
    return this.fetch(`/firms/${regulationGroupId}/evaluations/${id}/report`);
  }

  public getFirmSanctionEvaluationReportData(
    regulationGroupId,
    id
  ): Observable<any> {
    return this.fetch(
      `/firms/${regulationGroupId}/sanctionEvaluations/${id}/report`
    );
  }

  public getFirmEvaluationCriteria(regulationGroupId, id): Observable<any> {
    return this.fetch(`/firms/${regulationGroupId}/evaluations/${id}/criteria`);
  }

  public getFirmSanctionEvaluationCriteria(
    regulationGroupId,
    id
  ): Observable<any> {
    return this.fetch(
      `/firms/${regulationGroupId}/sanctionEvaluations/${id}/criteria`
    );
  }

  public addFirmEvaluation(
    regulationGroupId,
    reasonForEvaluation
  ): Observable<any> {
    let body = {
      regulationGroupId: regulationGroupId,
      reasonForEvaluation: reasonForEvaluation,
    };
    return this.postJson(
      `/firms/${regulationGroupId}/evaluations`,
      JSON.stringify(body)
    );
  }

  public addFirmSanctionEvaluation(
    regulationGroupId,
    reasonForEvaluation
  ): Observable<any> {
    let body = {
      regulationGroupId: regulationGroupId,
      reasonForEvaluation: reasonForEvaluation,
    };
    return this.postJson(
      `/firms/${regulationGroupId}/sanctionEvaluations`,
      JSON.stringify(body)
    );
  }

  public performFirmEvaluation(regulationGroupId, id): Observable<any> {
    let body = {
      regulationGroupId: regulationGroupId,
      id: id,
    };
    return this.postJson(
      `/firms/${regulationGroupId}/evaluations/${id}/perform`,
      JSON.stringify(body)
    );
  }

  public performFirmSanctionEvaluation(regulationGroupId, id): Observable<any> {
    let body = {
      regulationGroupId: regulationGroupId,
      id: id,
    };
    return this.postJson(
      `/firms/${regulationGroupId}/sanctionEvaluations/${id}/perform`,
      JSON.stringify(body)
    );
  }

  public updateFirmEvaluationCriteriaAnswers(
    regulationGroupId,
    firmEvaluationId,
    answers
  ): Observable<any> {
    let body = {
      firmEvaluationId: firmEvaluationId,
      answers: answers,
    };
    return this.putJson(
      `/firms/${regulationGroupId}/evaluations/${firmEvaluationId}/answers`,
      JSON.stringify(body)
    );
  }

  public updateFirmSanctionEvaluationCriteriaAnswers(
    regulationGroupId,
    firmEvaluationId,
    answers
  ): Observable<any> {
    let body = {
      firmSanctionEvaluationId: firmEvaluationId,
      firmSanctionEvaluationAnswers: answers,
    };
    return this.putJson(
      `/firms/${regulationGroupId}/sanctionEvaluations/${firmEvaluationId}/answers`,
      JSON.stringify(body)
    );
  }

  public getAppVersion(): Observable<any> {
    return this.fetch("/user/getAppVersion");
  }

  public getEvaluationAlgorithmType(): Observable<any> {
    return this.fetch("/user/evaluationAlgorithmType");
  }

  public getTreeStructureType(): Observable<any> {
    return this.fetch("/user/treeStructureType");
  }

  public getClientsExport(query: string): Observable<any> {
    return this.fetch("/clientCommon/" + 0 + "/" + 999999999 + "/" + query);
  }

  public getClientIndividualPanelById(id: string): Observable<any> {
    return this.fetch("/clientIndividuals/" + id + "/panel");
  }

  public getClientWeeklyTransactionalBehaviorSummary(
    clientId: number
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/weeklyTransactionalBehaviorSummary/${clientId}`
    );
  }

  public getClientInternalBlacklistSearchDetails(id: string): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/blacklistSearchDetails");
  }

  public getClientJointPanelById(id: string): Observable<any> {
    return this.fetch("/clients/joints/" + id + "/panel");
  }

  public getClientTypeByClientId(id: string): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/type");
  }

  public getClientEGOVResultById(id): Observable<any> {
    return this.fetch("/clientIndividuals/eGOVResults/" + id);
  }

  public getClientElectronicIdentificationById(id): Observable<any> {
    return this.fetch("/clientIndividuals/electronicIdentification/" + id);
  }

  public getClientSumSubDetailById(id): Observable<any> {
    return this.fetch("/clientIndividuals/sumSubDetail/" + id);
  }

  public getClientCorporatePanelById(id: string): Observable<any> {
    return this.fetch("/clientCorporates/" + id + "/panel");
  }

  public addClientTag(addClientTagForm: FormGroup): Observable<any> {
    const today = new Date();

    let body = {
      clientId: addClientTagForm.controls.clientId.value,
      tagId: addClientTagForm.controls.tagId.value,
      referenceProcess: 3,
      dateFrom: today,
      dateTo: null,
    };

    return this.postJson("/clientCommon/tags", JSON.stringify(body));
  }

  public excludeClientsFromRules(form: FormGroup): Observable<any> {
    let body = {
      ruleId: form.controls.ruleId.value,
      executionName: form.controls.executionName.value,
      clientIds: form.controls.clientIds.value,
      dateFrom: form.controls.dateFrom.value,
      dateTo: form.controls.dateTo.value,
      reason: form.controls.reason.value,
    };

    return this.postJson("/management/rule-exclusions", JSON.stringify(body));
  }

  public getClientTags(id: any): Observable<any> {
    return this.fetch(`/clientCommon/${id}/tags`);
  }

  public deleteClientTag(name: string, id: any): Observable<any> {
    return this.delete(`/clientCommon/${name}/tags/${id}`, "");
  }

  public getInternalScreenNotifications(id: string): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/internalScreenNotifications");
  }

  public getAdditionalExternalReferences(): Observable<any> {
    return this.fetch("/dropdown/additionalExternalReferences");
  }

  public getClientIndividualProfileById(id: string): Observable<any> {
    return this.fetch("/clientIndividuals/" + id + "/profile");
  }

  public GetClientWorldCheckOngoingStatus(id: string): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/world-check-ongoing-status");
  }

  public getScreeningSearchCriteriaByClientId(id): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/screeningSearchCriteria");
  }

  public getTransactionScreeningSearchCriteria(
    transactionScreeningCasePersonId,
    transactionScreeningCaseBusinessId
  ): Observable<any> {
    let body = {
      transactionScreeningCasePersonId: transactionScreeningCasePersonId,
      transactionScreeningCaseBusinessId: transactionScreeningCaseBusinessId,
    };
    return this.postJson(
      `/clientCommon/transactionScreeningSearchCriteria`,
      JSON.stringify(body)
    );
  }

  public getClientJointProfileById(id: string): Observable<any> {
    return this.fetch("/clients/joints/" + id + "/profile");
  }

  public getClientCorporateProfileById(id: string): Observable<any> {
    return this.fetch("/clientCorporates/" + id + "/profile");
  }

  public getClientIndividualDashboardById(id: string): Observable<any> {
    return this.fetch("/clientIndividuals/" + id + "/dashboard");
  }

  public getClientJointDashboardById(id: string): Observable<any> {
    return this.fetch("/clients/joints/" + id + "/dashboard");
  }

  public getAdvancedDashboards(): Observable<any> {
    return this.fetch("/advanced-dashboard/dashboards");
  }

  public getClientEconomicProfileById(id: string): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/economic-profile");
  }

  public getClientCorporateDashboardById(id: string): Observable<any> {
    return this.fetch("/clientCorporates/" + id + "/dashboard");
  }

  public getGeneralDashboard(): Observable<any> {
    return this.fetch("/dashboard");
  }

  public getSystemAccountDashboard(): Observable<any> {
    return this.fetch("/dashboard/systemAccountDashboard");
  }

  public getCustomFieldById(id: string): Observable<any> {
    return this.fetch("/settings/customFields/" + id);
  }

  public getFieldLogSetupById(id: string): Observable<any> {
    return this.fetch("/administration/fieldLogSetup/" + id);
  }

  public getReferenceTables(): Observable<any> {
    return this.getCachedData("/enumerations/referenceTablesEnum");
  }

  public getPortalSectionFieldValidationTypeEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/portalSectionFieldValidationTypeEnum"
    );
  }

  public getQueryBasedParameterTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/queryBasedParameterTypeEnum");
  }

  public getPortalSectionTableEnum(): Observable<any> {
    return this.getCachedData("/enumerations/portalSectionTableEnum");
  }

  public getFieldLogTableEnum(): Observable<any> {
    return this.getCachedData("/enumerations/fieldLogTableEnum");
  }

  public getTriggerTables(): Observable<any> {
    return this.fetch("/enumerations/triggerTableEnum");
  }

  public getRecordModificationTableEnum(): Observable<any> {
    return this.getCachedData("/enumerations/recordModificationTableEnum");
  }

  public getRecordModificationTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/recordModificationTypeEnum");
  }

  public getRecordModificationLevelsEnum(): Observable<any> {
    return this.getCachedData("/enumerations/recordModificationLevelsEnum");
  }

  public getStates(): Observable<any> {
    return this.getCachedData("/enumerations/entityStateEnum");
  }

  public getScopeLevels(): Observable<any> {
    return this.getCachedData("/enumerations/scopeLevelsEnum");
  }

  public getFieldLevels(): Observable<any> {
    return this.getCachedData("/enumerations/fieldLevelEnum");
  }

  public getFieldLogLevels(): Observable<any> {
    return this.getCachedData("/enumerations/fieldLogLevelEnum");
  }

  public GetPortalSectionTypes(): Observable<any> {
    return this.getCachedData("/enumerations/portalSectionTypeEnum");
  }

  public handleRegTekOnboardingArrangements(
    form: FormGroup,
    clientRegistrationIds: any,
    emails: string,
    contactToEmail: any
  ): Observable<any> {
    let body = {
      phaseId: form.controls.phase.value || -1,
      clientRegistrationIds: clientRegistrationIds,
      informUsersForPhaseChange: form.controls.informUsersForPhaseChange.value,
      emails: emails,
      contactToEmail: contactToEmail,
    };
    return this.putJson(
      "/portalOnboarding/arrangements/handleArrangements",
      JSON.stringify(body)
    );
  }

  public getArrangementsAmendmentRequests(): Observable<any> {
    return this.fetch(`/portalOnboardingRegTek/arrangements/amendmentRequests`);
  }

  public getRegTekOnboardingClientRegistrationUrlByClientId(
    clientId: any
  ): Observable<any> {
    return this.fetch(`/clientCommon/${clientId}/clientRegistrationUrl`);
  }

  public getRegTekOnboardingUrl(): Observable<any> {
    return this.fetch(`/clientCommon/regTekOnboardingUrl`);
  }

  public getRegTekOnboardingArrangements(): Observable<any> {
    return this.fetch(`/portalOnboardingRegTek/arrangements`);
  }

  public exportXml(id: any): Observable<any> {
    return this.postJson(
      `/portalOnboarding/arrangements/${id}/generateArrangementXml`,
      " "
    );
  }

  public clearPortalCache(): Observable<any> {
    return this.postJson(`/portalOnboardingRegTek/clearCache`, " ");
  }

  public addClientRegistrationForExistingClient(
    clientId: any,
    form: FormGroup
  ): Observable<any> {
    let body = {
      phaseId: form.controls.phase.value,
      portalQuestionnaireTypeId: form.controls.portalQuestionnaireTypeId.value,
      clientId: clientId,
      onboardingUser: form.controls.onboardingUser.value,
    };
    return this.postJson(
      `/clientCommon/${clientId}/clientRegistration`,
      JSON.stringify(body)
    );
  }

  public editRegTekOnboardingUsersDetails(
    form: FormGroup,
    onboardingUsers: any
  ): Observable<any> {
    let body = {
      status: form.controls.status.value,
      role: form.controls.role.value,
      usersEmails: onboardingUsers,
    };
    return this.putJson(
      "/portalOnboarding/users/details",
      JSON.stringify(body)
    );
  }

  public handleRegTekOnboardingAmendmentRequests(
    form: FormGroup,
    amendmentRequestsIds: any
  ): Observable<any> {
    let body = {
      phaseId: form.controls.phase.value || -1,
      amendmentRequestResponseType: form.controls.responseType.value || -1,
      additionalInfo: form.controls.additionalInfo.value,
      amendmentRequestsIds: amendmentRequestsIds,
      deadlineDate: form.controls.deadlineDate.value,
    };
    return this.putJson(
      "/portalOnboarding/clientsRegistration/amendmentRequests",
      JSON.stringify(body)
    );
  }

  public handleRegTekOnboardingNotVerifiedDocuments(
    form: FormGroup,
    clientRegistrationDocumentIds: any
  ): Observable<any> {
    let body = {
      clientRegistrationDocumentIds: clientRegistrationDocumentIds,
      verificationStatus: form.controls.verificationStatus.value,
    };
    return this.putJson(
      "/portalOnboarding/documents/handleNotVerifiedDocuments",
      JSON.stringify(body)
    );
  }

  public handleRegTekOnboardingClientRegistrations(form: FormGroup, clientRegistrationIds: any, emails: string, contactToEmail: any): Observable<any> {
    let body = {
      "phaseId": form.controls['phase'].value || -1,
      "clientRegistrationIds": clientRegistrationIds,
      "informUsersForPhaseChange": form.controls['informUsersForPhaseChange'].value,
      "deadlineDate": form.controls['deadlineDate'].value,
      "emails": emails,
      "contactToEmail": contactToEmail,
      "relatedEntityPhases": form.controls['relatedEntityPhases'].value
    }

    return this.putJson("/portalOnboarding/clientsRegistration/handleClientRegistrations", JSON.stringify(body));
  }

  public handleRegTekOnboardingExpiredClientRegistrations(
    form: FormGroup,
    clientRegistrationPhasesId: any,
    emails: string,
    contactToEmail: any
  ): Observable<any> {
    let body = {
      registrationStatus: form.controls.registrationStatus.value,
      clientRegistrationPhasesId: clientRegistrationPhasesId,
      informUsersForStatusChange:
        form.controls.informUsersForStatusChange.value,
      emails: emails,
      contactToEmail: contactToEmail,
    };
    return this.putJson(
      "/portalOnboarding/clientsRegistration/handleExpiredClientRegistrations",
      JSON.stringify(body)
    );
  }

  public transferRegTekOnboardingClientRegistrations(
    form: FormGroup,
    clientRegistrationIds: any,
    contactToEmail
  ): Observable<any> {
    let body = {
      portalUserEmail: form.controls.portalUserEmail.value,
      clientRegistrationIds: clientRegistrationIds,
      informUserForPhaseChange:
        form.controls.informUserForRegistrationsTransfer.value,
      contactToEmail: contactToEmail,
    };
    return this.putJson(
      "/portalOnboarding/clientsRegistration/transferToUser",
      JSON.stringify(body)
    );
  }

  public getRegTekOnboardingUsers(): Observable<any> {
    return this.fetch(`/dropdown/portal/users`);
  }

  public getRegTekOnboardingNotVerifiedDocuments(): Observable<any> {
    return this.fetch(`/portalOnboarding/documents/notVerified`);
  }

  public getRegTekOnboardingUsersRoles(): Observable<any> {
    return this.fetch(`/dropdown/portal/users/roles`);
  }

  public getRegTekOnboardingAmendmentRequestResponseTypes(): Observable<any> {
    return this.fetch(`/portalOnboarding/amendmentRequestResponseTypes`);
  }

  public getRegTekOnboardingAmendmentRequests(): Observable<any> {
    return this.fetch(`/portalOnboarding/amendmentRequests`);
  }

  public getRegTekOnboardingClientRegistrations(): Observable<any> {
    return this.fetch(`/dropdown/clientRegistrations`);
  }

  public getRegTekOnboardingCustomQuestionnaires(): Observable<any> {
    return this.fetch(`/dropdown/portalCustomQuestionnaires`);
  }

  public getRegTekOnboardingClientRegistrationsWithEvaluationGrade(): Observable<any> {
    return this.fetch(`/dropdown/clientRegistrations-with-evaluation-grade`);
  }

  public getRegTekOnboardingClientRegistrationsProgressReport(): Observable<any> {
    return this.fetch(
      `/reportFilters/portal/clientRegistrationsProgressReport`
    );
  }

  public getUnfilteredRegTekOnboardingClientRegistrations(): Observable<any> {
    return this.fetch(`/dropdown/clientRegistrations/unfiltered`);
  }

  public GetPortalCaptionReferenceTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/portalCaptionReferenceTypeEnum");
  }

  public getClientContarctSurrenderTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/clientContarctSurrenderTypesEnum");
  }

  public getClientContractAssignedEnum(): Observable<any> {
    return this.getCachedData("/enumerations/clientContractAssignedEnum");
  }

  public getClientContractBeneficiaryRelationshipEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/clientContractBeneficiaryRelationshipEnum"
    );
  }

  public getReviewStatuses(): Observable<any> {
    return this.getCachedData("/enumerations/reviewStatuses");
  }

  public getActionTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/actionTypesEnum");
  }

  public getHashKeyTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/hashKeyTypes");
  }

  public getQueryTemplateTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/queryTemplateTypesEnum");
  }

  public getEmailStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/emailStatusEnum");
  }

  public getReferenceTablesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/referenceTablesEnum");
  }

  public getReferenceTableColumnsEnum(table: referenceTablesEnum): Observable<any> {
    return this.fetch("/translations/referenceTableColumns/" + table);
  }

  public getReferenceTableRecords(table: referenceTablesEnum): Observable<any> {
    return this.fetch("/translations/referenceTableRecords/" + table);
  }

  public getInvestmentAccountStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/investmentAccountStatusEnum");
  }

  public getPortalTypeSetupsEnum(): Observable<any> {
    return this.getCachedData("/enumerations/portalTypeSetupEnum");
  }

  public editCustomField(
    editCustomFieldsGroupForm: FormGroup,
    customFieldValues: string,
    gridColumnsString: string
  ): Observable<any> {
    let body = {
      id: editCustomFieldsGroupForm.controls.id.value,
      fieldName: editCustomFieldsGroupForm.controls.fieldName.value,
      values: customFieldValues,
      isEditable: editCustomFieldsGroupForm.controls.isEditable.value,
      isNullable: editCustomFieldsGroupForm.controls.isNullable.value,
      MappingReference:
        editCustomFieldsGroupForm.controls.mappingReference.value,
      isEconomicProfile:
        editCustomFieldsGroupForm.controls.isEconomicProfile.value,
      order: editCustomFieldsGroupForm.controls.order.value,
      openEnded: editCustomFieldsGroupForm.controls.openEnded.value,
      gridColumns: gridColumnsString,
    };
    return this.putJson("/settings/customFields", JSON.stringify(body));
  }

  public editFieldLogSetup(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      status: newData.status != undefined ? newData.status : oldData.status,
      refColumnValuesTable:
        newData.refColumnValuesTable != undefined
          ? newData.refColumnValuesTable
          : null,
      trackAdded:
        newData.trackAdded != undefined
          ? newData.trackAdded
          : oldData.trackAdded,
      trackModified:
        newData.trackModified != undefined
          ? newData.trackModified
          : oldData.trackModified,
      trackDeleted:
        newData.trackDeleted != undefined
          ? newData.trackDeleted
          : oldData.trackDeleted,
    };
    return this.putJson("/administration/fieldLogSetup", JSON.stringify(body));
  }

  public editRuleUpdateComment(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      ruleId: newData.ruleId != undefined ? newData.ruleId : oldData.ruleId,
      comment: newData.comment != undefined ? newData.comment : oldData.comment,
      dateUpdated:
        newData.dateUpdated != undefined
          ? newData.dateUpdated
          : oldData.dateUpdated,
      statusDescr:
        newData.statusDescr != undefined
          ? newData.statusDescr
          : oldData.statusDescr,
      message: newData.message != undefined ? newData.message : oldData.message,
    };
    return this.putJson(
      "/administration/rules/updateComment",
      JSON.stringify(body)
    );
  }

  public editDynamicReportUpdateComment(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      dynamicReportId:
        newData.dynamicReportId != undefined
          ? newData.dynamicReportId
          : oldData.dynamicReportId,
      comment: newData.comment != undefined ? newData.comment : oldData.comment,
      dateUpdated:
        newData.dateUpdated != undefined
          ? newData.dateUpdated
          : oldData.dateUpdated,
      statusDescr:
        newData.statusDescr != undefined
          ? newData.statusDescr
          : oldData.statusDescr,
      message: newData.message != undefined ? newData.message : oldData.message,
    };
    return this.putJson(
      "/systemSettings/dynamicReports/updateComment",
      JSON.stringify(body)
    );
  }

  public editPortalQuestionnaireType(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      isDefault:
        newData.isDefault != undefined ? newData.isDefault : oldData.isDefault,
      deadlineDate:
        newData.deadlineDate != undefined
          ? newData.deadlineDate
          : oldData.deadlineDate,
      isVisibleToClients:
        newData.isVisibleToClients != undefined
          ? newData.isVisibleToClients
          : oldData.isVisibleToClients,
    };
    return this.putJson(
      `/portalSetup/portalQuestionnaireTypes/${newData.id || oldData.id}`,
      JSON.stringify(body)
    );
  }

  public deleteCustomField(id: string): Observable<any> {
    return this.delete("/settings/customFields/" + id, "");
  }

  public deleteFieldLogSetup(id: string): Observable<any> {
    return this.delete("/administration/fieldLogSetup/" + id, "");
  }

  public deleteRuleUpdateComment(ruleId: string, ruleUpdateCommentId: string): Observable<any> {
    return this.delete(`/administration/systemRules/${ruleId}/updateComments/${ruleUpdateCommentId}`, '');
  }

  public deleteDynamicReportUpdateComment(
    dynamicReportId: string,
    dynamicReportUpdateCommentId: string
  ): Observable<any> {
    return this.delete(
      `/administration/dynamicReports/${dynamicReportId}/updateComments/${dynamicReportUpdateCommentId}`,
      ""
    );
  }

  public addCustomField(
    addCustomFieldForm: FormGroup,
    customFieldValues: string,
    gridColumnsString: string
  ): Observable<any> {
    let body = {
      fieldName: addCustomFieldForm.controls.fieldName.value,
      fieldType: addCustomFieldForm.controls.fieldType.value,
      referenceTable: addCustomFieldForm.controls.referenceTable.value,
      fieldDictionaryListTypeTable:
        addCustomFieldForm.controls.fieldDictionaryType.value,
      fieldDictionaryListTypeTableRefColumn:
        addCustomFieldForm.controls.fieldDictionaryListTypeTableRefColumn.value,
      regulationGroupId: addCustomFieldForm.controls.regulationGroupId.value,
      isEditable:
        addCustomFieldForm.controls.isEditable.value == ""
          ? false
          : addCustomFieldForm.controls.isEditable.value,
      isNullable:
        addCustomFieldForm.controls.isNullable.value == ""
          ? false
          : addCustomFieldForm.controls.isNullable.value,
      values: customFieldValues,
      MappingReference: addCustomFieldForm.controls.mappingReference.value,
      isEconomicProfile: addCustomFieldForm.controls.isEconomicProfile.value,
      order: addCustomFieldForm.controls.order.value,
      gridColumns: gridColumnsString,
      internalReference: addCustomFieldForm.controls.internalReference.value,
      openEnded: addCustomFieldForm.controls.openEnded.value,
    };
    return this.postJson("/settings/customFields", JSON.stringify(body));
  }

  public addFieldLogSetup(addFieldLogSetupForm: FormGroup): Observable<any> {
    let body = {
      fieldLogLevel: addFieldLogSetupForm.controls.fieldLogLevel.value,
      refTable: addFieldLogSetupForm.controls.refTable.value,
      refColumn: addFieldLogSetupForm.controls.refColumn.value,
      refColumnValuesTable:
        addFieldLogSetupForm.controls.refColumnValuesTable.value,
      refInternalReference:
        addFieldLogSetupForm.controls.refInternalReference.value,
      description: addFieldLogSetupForm.controls.description.value,
      trackAdded: addFieldLogSetupForm.controls.trackAdded.value,
      trackModified: addFieldLogSetupForm.controls.trackModified.value,
      trackDeleted: addFieldLogSetupForm.controls.trackDeleted.value,
    };
    return this.postJson("/administration/fieldLogSetup", JSON.stringify(body));
  }

  public getFieldTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/fieldTypeEnum");
  }

  public getDocumentUploadTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/documentUploadTypeEnum");
  }

  public getFTPUploadServerProtocolTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/ftpUploadServerProtocolTypeEnum");
  }

  public getFieldDictionaryListTypeTableEnum(): Observable<any> {
    return this.getCachedData("/enumerations/fieldDictionaryListTypeTableEnum");
  }

  public getFieldDictionaryListTypeTableColumns(tableEnum): Observable<any> {
    return this.fetch(
      `/settings/fieldDictionaryListTypeTableColumns/${tableEnum}`
    );
  }

  public getCriterionSetupTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/criterionSetupTypeEnum");
  }

  public getQuestionSetupTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/questionSetupTypeEnum");
  }

  public getRuleParameterTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/ruleParameterTypeEnum");
  }

  public getParameterReferenceTablesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/parameterReferenceTablesEnum");
  }

  public getDynamicReportParameterReferenceTablesEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/dynamicReportParameterReferenceTablesEnum"
    );
  }

  public getDynamicReportParameterTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/dynamicReportParameterTypeEnum");
  }

  public getClientTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/clientTypeEnum");
  }

  public getIsClientOptionEnum(): Observable<any> {
    return this.getCachedData("/enumerations/isClientOptionEnum");
  }

  public getCustomLogReferenceCallEnum(): Observable<any> {
    return this.getCachedData("/enumerations/customLogReferenceCallEnum");
  }

  public getPackagesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/packagesEnum");
  }

  public getDocumentTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/documentTypeEnum");
  }

  public getIdVerificationDataSupportLevelEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/idVerificationDataSupportLevelEnum"
    );
  }

  public getNotificationTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/notificationtypeenum");
  }

  public getNotificationTemplateTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/notificationtemplatetypeEnum");
  }

  public getHttpRequestMethodsEnum(): Observable<any> {
    return this.getCachedData("/enumerations/HttpRequestMethodsEnum");
  }

  public getAuthorizationTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/authorizationtypeEnum");
  }

  public getDocumentFormEnum(): Observable<any> {
    return this.getCachedData("/enumerations/documentFormEnum");
  }

  public getCollectionStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/collectionStatusEnum");
  }

  public getDocumentVerificationStatus(): Observable<any> {
    return this.getCachedData("/enumerations/documentVerificationStatusEnum");
  }

  public getRuleStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/ruleStatusEnum");
  }

  public getDynamicReportStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/dynamicReportStatusEnum");
  }

  public getRuleTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/ruleTypeEnum");
  }

  public getRuleOriginEnum(): Observable<any> {
    return this.getCachedData("/enumerations/ruleOriginEnum");
  }

  public getDynamicReportOriginEnum(): Observable<any> {
    return this.getCachedData("/enumerations/dynamicReportOriginEnum");
  }

  public getJobScheduleIntervalsEnum(): Observable<any> {
    return this.getCachedData("/enumerations/scheduleIntervalsEnum");
  }

  public getRuleFlagPolicyEnum(): Observable<any> {
    return this.getCachedData("/enumerations/ruleFlagPolicyEnum");
  }

  public getStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/statusEnum");
  }

  public getCurrencyTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/currencyTypeEnum");
  }

  public getActionCategoryTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/actionCategoryTypeEnum");
  }

  public getTypeOfAddressTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/typeOfAddressTypeEnum");
  }

  public getEvaluationGradeTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/EvaluationGradeTypeEnum");
  }

  public hasActiveOnboardingAccount(): Observable<any> {
    return this.fetch("/portalOnboarding/hasActiveOnboardingAccount");
  }

  public getEnvironmentEnum(): Observable<any> {
    return this.fetch("/enumerations/environmentEnum");
  }

  public getTransactionScreeningAcurisIndividualDatasets(): Observable<any> {
    return this.fetch(
      "/enumerations/transactionScreeningAcurisIndividualDatasets"
    );
  }

  public getTransactionScreeningAcurisLegalEntityDatasets(): Observable<any> {
    return this.fetch(
      "/enumerations/transactionScreeningAcurisLegalEntityDatasets"
    );
  }

  public getSanctionList(): Observable<any> {
    return this.fetch("/Sanctions/sanctions");
  }

  public saveSanctionsList(sanctions: any[]): Observable<any> {
    const body = sanctions.map(s => {
      return {
        CountryName: s.description,
      }
    });
    const bodyString = JSON.stringify(body);
    return this.postJson('/sanctions/sanctions', bodyString);
  }

  public hasActiveIdNowAccount(): Observable<any> {
    return this.fetch("/clientCorporates/hasActiveIdNowAccount");
  }

  public hasActiveIDenfyAccount(): Observable<any> {
    return this.fetch("/clientCommon/hasActiveIDenfyAccount");
  }

  public getScheduleStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/scheduleStatusEnum");
  }

  public getEnhancedEconomicProfileEnum(): Observable<any> {
    return this.getCachedData("/enumerations/enhancedEconomicProfileEnum");
  }

  public getClientStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/clientStatusEnum");
  }

  public getEvaluationStatusesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/evaluationStatusesEnum");
  }

  public getUpdatedEvaluationStatusActionEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/updatedEvaluationStatusActionEnum"
    );
  }

  public getUpdatedEvaluationStatusReasonEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/updatedEvaluationStatusReasonEnum"
    );
  }

  public getRequestedEvaluationEnum(): Observable<any> {
    return this.getCachedData("/enumerations/requestedEvaluationEnum");
  }

  public getSmtpClientImplementationTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/smtpClientImplementationTypeEnum");
  }

  public getHistoricalRecordTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/historicalRecordTypeEnum");
  }

  public getGenderEnum(): Observable<any> {
    return this.getCachedData("/enumerations/genderEnum");
  }

  public getShareholderCapacityEnum(): Observable<any> {
    return this.getCachedData("/enumerations/shareholderCapacityEnum");
  }

  public getControllingPersonTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/controllingPersonTypeEnum");
  }

  public getYesNoEnum(): Observable<any> {
    return this.getCachedData("/enumerations/yesNoEnum");
  }

  public getYesNoAllEnum(): Observable<any> {
    return this.getCachedData("/enumerations/yesNoAllEnum");
  }

  public getCvvEnum(): Observable<any> {
    return this.getCachedData("/enumerations/cvvEnum");
  }

  public getCardResponseEnum(): Observable<any> {
    return this.getCachedData("/enumerations/cardResponseEnum");
  }

  public getLastEvaluationGradeTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/lastEvaluationGradeTypeEnum");
  }

  public getDocumentExpirationTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/documentExpirationTypeEnum");
  }

  public getClientDocumentAttachmentTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/clientDocumentAttachmentTypeEnum");
  }

  public getAccountiKYCStatusesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/accountiKYCStatusesEnum");
  }

  public getAccountIdNowStatusesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/accountIdNowStatusesEnum");
  }

  public getAccountIDenfyStatusesAndServicesEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/accountIDenfyStatusesAndServicesEnum"
    );
  }

  public getAccountSumSubStatusesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/accountSumSubStatusesEnum");
  }

  public getAccountRegTekOnboardingStatusesEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/accountRegTekOnboardingStatusesEnum"
    );
  }

  public getAccountArtemisAlphaBankStatusesEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/accountArtemisAlphaBankStatusesEnum"
    );
  }

  public getAccountDowJonesStatusesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/accountDowJonesStatusesEnum");
  }

  public getAccountChainAnalysisStatusesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/accountChainAnalysisStatusesEnum");
  }

  public getAccountPowerBiStatusesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/accountPowerBiStatusesEnum");
  }

  public getAccountPaymentComponentsStatusesEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/accountPaymentComponentsStatusesEnum"
    );
  }

  public getAccountAutoPopulateEvaluationCriteriaAnswers(): Observable<any> {
    return this.fetch(
      "/administration/accountAutoPopulateEvaluationCriteriaAnswers"
    );
  }

  public getPopulateEvaluationCriteriaAnswers(clientId: any): Observable<any> {
    return this.fetch(
      `/clientCommon/${clientId}/populateEvaluationCriteriaAnswers/`
    );
  }

  public getIfClientApplicantIsCreated(clientId: any): Observable<any> {
    return this.fetch(`/clientCommon/${clientId}/hasSumSubApplicantId/`);
  }

  public getEvaluationAlgorithmTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/evaluationAlgorithmTypesEnum");
  }

  public getTreeStructureTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/treeStructureTypesEnum");
  }

  public getDocumentCheckFaceMatchTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/documentCheckFaceMatchTypeEnum");
  }

  public getDocumentCheckMessageTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/documentCheckMessageTypeEnum");
  }

  public getDocumentVerificationStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/documentVerificationStatusEnum");
  }

  public getIdentityCheckMessageTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/identityCheckMessageTypeEnum");
  }

  public getIdentityCheckReliabilityEnum(): Observable<any> {
    return this.getCachedData("/enumerations/identityCheckReliabilityEnum");
  }

  public getColorsEnum(): Observable<any> {
    return this.getCachedData("/enumerations/colorsEnum");
  }

  public getInternalBlacklistScreeningTypesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/internalBlacklistScreeningTypes");
  }

  public getWorkflowStatusesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/workflowStatuses");
  }

  public getWorkflowSchemeCodesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/workflowSchemeCodesEnum");
  }

  public getClientEvaluations(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/evaluations");
  }

  public getClientAdhocEvaluations(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/adhocevaluations");
  }

  public getPendingClientEvaluation(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/evaluations/pending");
  }

  public getClientQuestionnaires(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/questionnaires");
  }

  public getPendingClientQuestionnaires(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/questionnaires/pending");
  }

  public getClientEvaluationTree(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/clientEvaluationTree");
  }

  public getClientJointEvaluationTree(id: any): Observable<any> {
    return this.fetch("/clients/joints/" + id + "/clientEvaluationTree");
  }

  public getClientAccounts(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/accounts");
  }

  public getClientWallets(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/wallets");
  }

  public getClientWalletsDropdown(id: any): Observable<any> {
    return this.fetch("/dropdown/" + id + "/wallets");
  }

  public getClientWalletCurrencies(id: any, walletId: any): Observable<any> {
    return this.fetch(`/clientCommon/${id}/wallets/${walletId}/currencies`);
  }

  public getClientGamingAccounts(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/gamingaccounts");
  }

  public getClientGamingAccountHistories(
    id: any,
    accountId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + id + "/gamingAccounts/" + accountId + "/histories"
    );
  }

  public getClientAccountSignatories(id: any, accountId: any): Observable<any> {
    return this.fetch(
      "/clientCommon/" + id + "/accounts/" + accountId + "/signatories"
    );
  }

  public getClientsActionsReference() {
    return this.fetch("/clientCommon/actionsReference");
  }

  public getReportClientsActionsReference() {
    return this.fetch("/reportFilters/actionsReference");
  }

  public GetClientsCaseActionCaseNumbers() {
    return this.fetch("/clientCommon/actionsCaseNumber");
  }

  public GetReportClientsCaseActionCaseNumbers() {
    return this.fetch("/reportFilters/actionsCaseNumber");
  }

  public getClientContracts(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/contracts");
  }

  public getClientApplications(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/applications");
  }

  public getClientContractTransactions(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/contractTransactions");
  }

  public getClientContractTransactionById(
    id: any,
    contractTransactionId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + id + "/contractTransactions/" + contractTransactionId
    );
  }

  public getClientContractCollections(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/contractCollections");
  }

  public getClientContractCollectionById(
    id: any,
    contractCollectionId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + id + "/contractCollections/" + contractCollectionId
    );
  }

  public getClientActions(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/actions");
  }

  public getClientCaseActions(
    clientId: number,
    caseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/cases/" + caseId + "/actions"
    );
  }

  public getContractCalculatedFields(clientId: number): Observable<any> {
    var currentYear = new Date().getFullYear();
    return this.fetch(
      `/clientCommon/${clientId}/contracts/contractOveralls/${currentYear}`
    );
  }

  public getTransactionScreeningCaseRestrictedCountriesAnalysis(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/restrictedCountriesAnalysis"
    );
  }

  public getTransactionScreeningCaseRestrictedCitiesAnalysis(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/restrictedCitiesAnalysis"
    );
  }

  public getTransactionScreeningCaseKeywordsAnalysis(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/keywordsAnalysis"
    );
  }

  public getTransactionScreeningCaseHighRiskCountriesAnalysis(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/highRiskCountriesAnalysis"
    );
  }

  public getTransactionScreeningCaseAuditTrail(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/fieldLogs"
    );
  }

  public getTransactionScreeningCaseHitNameCount(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/hitNames/count"
    );
  }

  public getTransactionScreeningCasePersonMatch(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/person/profileMatch"
    );
  }

  public getTransactionScreeningCaseBusinessMatch(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/business/profileMatch"
    );
  }

  public getTransactionScreeningCaseInternalBlacklistsAnalysis(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/internalBlacklistsAnalysis"
    );
  }

  public getTransactionScreeningCaseInternalBlacklistsCorporatesAnalysis(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/internalBlacklistsCorporatesAnalysis"
    );
  }

  public getTransactionScreeningCasePersonsAnalysis(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientIndividuals/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/personsAnalysis"
    );
  }

  public getTransactionScreeningCaseBusinessesAnalysis(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/businessesAnalysis"
    );
  }

  public getTransactionScreeningCaseActions(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/actions"
    );
  }

  public getRuleActions(ruleId: string): Observable<any> {
    return this.fetch("/settings/rules/" + ruleId + "/actions");
  }

  public getApplicationPurposeOfTransactions(id: any): Observable<any> {
    return this.fetch("/settings/purposeOfTransactions");
  }

  public getClientCaseAnalysis(
    clientId: number,
    caseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/cases/" + caseId + "/analysis"
    );
  }

  public getClientCaseSegments(
    clientId: number,
    caseId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/cases/" + caseId + "/segments"
    );
  }

  public getClientCaseAnalysisById(
    clientId: number,
    analysisId: number
  ): Observable<any> {
    return this.fetch("/clientCommon/" + clientId + "/analysis/" + analysisId);
  }

  public getClientPersonSearchResults(id: any): Observable<any> {
    return this.fetch("/clientIndividuals/" + id + "/personSearchResults");
  }

  public getClientPersonSearchResultsHistory(id: any): Observable<any> {
    return this.fetch(
      "/clientIndividuals/" + id + "/personSearchResultsHistory"
    );
  }

  public getClientPersonSearchResultsDetails(id: any): Observable<any> {
    return this.fetch(
      "/clientIndividuals/" + id + "/personSearchResultsDetails"
    );
  }

  public getClientArtemisAlphaBankSearchResults(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/artemisAlphaBankSearchResults");
  }

  public getClientArtemisAlphaBankSearchStatus(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/artemisAlphaBankSearchStatus");
  }

  public getClientInternalBlackListsSearchResults(id: any): Observable<any> {
    return this.fetch(
      "/clientCommon/" + id + "/clientInternalBlackListsSearchResults"
    );
  }

  public editClientInternalBlackListsSearchResultsStatus(
    id: any,
    status: number
  ): Observable<any> {
    return this.putJson(
      "/clientCommon/" + id + "/clientInternalBlackListsSearchResults",
      JSON.stringify({ internalScreeningStatus: status })
    );
  }

  public getClientCorporateInternalBlackListsResults(id: any): Observable<any> {
    return this.fetch(
      "/clientCorporates/" + id + "/clientCorporateInternalBlackListsResults"
    );
  }

  public editClientCorporateInternalBlackListsResultsStatus(
    id: any,
    status: number
  ): Observable<any> {
    return this.putJson(
      "/clientCorporates/" + id + "/clientCorporateInternalBlackListsResults",
      JSON.stringify({ internalScreeningStatus: status })
    );
  }

  public getNegativeListSearchHistory(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/negativeListSearchHistory");
  }

  public getClientBusinessSearchResults(id: any): Observable<any> {
    return this.fetch("/clientCorporates/" + id + "/businessSearchResults");
  }

  public getClientBusinessSearchResultsHistory(id: any): Observable<any> {
    return this.fetch(
      "/clientCorporates/" + id + "/businessSearchResultsHistory"
    );
  }

  public getClientBusinessSearchResultsDetails(id: any): Observable<any> {
    return this.fetch(
      "/clientCorporates/" + id + "/businessSearchResultsDetails"
    );
  }

  public getClientPersonSearchMonitoringResults(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/personSearchMonitoringResults");
  }

  public getClientPersonSearchProfile(
    id: any,
    personSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientIndividuals/" +
      id +
      "/personSearchResults/" +
      personSearchProfileId
    );
  }

  public getClientPersonSearchProfileHistory(
    id: any,
    personSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientIndividuals/" +
      id +
      "/personSearchResultsHistory/" +
      personSearchProfileId
    );
  }

  public getClientBusinessSearchProfileHistory(
    id: any,
    businessSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/" +
      id +
      "/businessSearchResultsHistory/" +
      businessSearchProfileId
    );
  }

  public getTransactionScreeningCasePersonSearchProfile(
    id: any,
    personSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      `/clientIndividuals/transactionScreeningCasePerson/${id}/transactionScreeningCasePersonSearchResults/${personSearchProfileId}`
    );
  }

  public getTransactionScreeningCaseBusinessSearchProfile(
    id: any,
    businessSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      `/clientCorporates/transactionScreeningCaseBusiness/${id}/transactionScreeningCaseBusinessSearchResults/${businessSearchProfileId}`
    );
  }

  public getClientBusinessSearchProfile(
    id: any,
    businessSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/" +
      id +
      "/businessSearchResults/" +
      businessSearchProfileId
    );
  }

  public getClientPersonSearchMonitoringProfile(
    id: any,
    personSearchMonitoringProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" +
      id +
      "/personSearchMonitoringResults/" +
      personSearchMonitoringProfileId
    );
  }

  public getClientInvestmentAccounts(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/investmentAccounts");
  }

  public getClientAccountById(
    clientId: number,
    accountId: number
  ): Observable<any> {
    return this.fetch("/clientCommon/" + clientId + "/accounts/" + accountId);
  }

  public getClientWalletById(
    clientId: number,
    walletId: number
  ): Observable<any> {
    return this.fetch("/clientCommon/" + clientId + "/wallets/" + walletId);
  }

  public getClientGamingAccountById(
    clientId: number,
    accountId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/gamingaccounts/" + accountId
    );
  }

  public getClientContractById(
    clientId: number,
    contractId: number
  ): Observable<any> {
    return this.fetch("/clientCommon/" + clientId + "/contracts/" + contractId);
  }

  public getClientApplicationById(
    clientId: number,
    applicationId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/applications/" + applicationId
    );
  }

  public getClientPaymentMethodById(
    clientId: any,
    clientPaymentMethodId
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/paymentMethods/" + clientPaymentMethodId
    );
  }

  public getClientTransactions(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/transactions");
  }

  public getClientGamingTransactions(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/gamingtransactions");
  }

  public getClientTrades(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/trades");
  }

  public getClientPaymentMethods(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/paymentMethods");
  }

  public getClientCases(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/cases");
  }

  public getManualCreateCasesRules(clientId: any): Observable<any> {
    return this.fetch(`/clientCommon/manualCreateCasesRules/${clientId}`);
  }

  public getClientTransactionScreeningCases(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/transactionScreeningCases");
  }

  public getClientLiveTransactionCases(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/liveTransactionClientCases");
  }

  public getClientAuthorizedPersons(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/authorizedPersons");
  }

  public getClientBeneficiaries(id: any): Observable<any> {
    return this.fetch("/clientCorporates/" + id + "/beneficiaries");
  }

  public getClientShareholders(id: any): Observable<any> {
    return this.fetch("/clientCorporates/" + id + "/shareholders");
  }

  public getNumberOfConnectedCases(username: string) {
    return this.fetchBase("/authorization/numberOfConnectedCases/" + username);
  }

  public replaceUserConnections(username: string, usernameToReplace: string) {
    return this.postBase(
      "/authorization/replaceUserConnections/" +
      username +
      "/" +
      usernameToReplace,
      ""
    );
  }

  public changeUserStatus(username: string) {
    return this.postBase("/authorization/changeUserStatus/" + username, "");
  }

  public getClientUltimateBeneficialOwners(id: any): Observable<any> {
    return this.fetch("/clientCorporates/" + id + "/ultimateBeneficialOwners");
  }

  public getClientInternalNotes(id: any): Observable<any> {
    return this.fetch("/clientCommon/" + id + "/internalNotes");
  }

  public getPersonSearchInternalNotes(
    personSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientIndividuals/personSearchResults/" +
      personSearchProfileId +
      "/internalNotes"
    );
  }

  public getPersonSearchInternalNotesHistory(
    personSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientIndividuals/personSearchResults/" +
      personSearchProfileId +
      "/internalNotesHistory"
    );
  }

  public getPossibleMatchStatusHistoryIndividuals(
    personSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientIndividuals/personSearchResults/" +
      personSearchProfileId +
      "/matchStatusHistory"
    );
  }

  public getPossibleMatchStatusHistoryIndividualHistories(
    personSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientIndividuals/personSearchResults/" +
      personSearchProfileId +
      "/matchStatusHistoryHistories"
    );
  }

  public getPossibleMatchStatusHistoryCorporates(
    personSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/businessSearchResults/" +
      personSearchProfileId +
      "/matchStatusHistory"
    );
  }

  public getPossibleMatchStatusHistoryCorporateHistories(
    personSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/businessSearchResults/" +
      personSearchProfileId +
      "/matchStatusHistoryHistories"
    );
  }

  public getClientCaseInternalNotes(caseId: any): Observable<any> {
    return this.fetch("/clientCommon/cases/" + caseId + "/internalNotes");
  }

  public getTransactionScreeningCaseInternalNotes(
    transactionScreeningCaseId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/internalNotes"
    );
  }

  public getBusinessSearchInternalNotes(
    businessSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/businessSearchResults/" +
      businessSearchProfileId +
      "/internalNotes"
    );
  }

  public getBusinessSearchInternalNotesHistory(
    businessSearchProfileId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/businessSearchResults/" +
      businessSearchProfileId +
      "/internalNotesHistory"
    );
  }

  public getActionUsers(): Observable<any> {
    return this.fetch("/clientCommon/actionUsers");
  }

  public getcaseWorkflowStatuses(): Observable<any> {
    return this.fetch("/settings/caseWorkflowStatuses");
  }

  public getReportActionUsers(): Observable<any> {
    return this.fetch("/reportFilters/actionUsers");
  }

  public getAllUsers(): Observable<any> {
    return this.fetch("/reportFilters/users");
  }

  public getClientUsers(): Observable<any> {
    return this.fetch("/clientCommon/users");
  }

  public getClientTransactionById(
    clientId: number,
    transactionId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/transactions/" + transactionId
    );
  }

  public getClientGamingTransactionById(
    clientId: number,
    transactionId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/gamingtransactions/" + transactionId
    );
  }

  public getClientUltimateBeneficialOwnerById(
    clientId: number,
    ultimateBeneficialOwnerId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/" +
      clientId +
      "/ultimateBeneficialOwners/" +
      ultimateBeneficialOwnerId
    );
  }

  public getClientShareholderById(
    clientId: number,
    shareholderId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/" + clientId + "/shareholders/" + shareholderId
    );
  }

  public getClientBeneficiaryById(
    clientId: number,
    beneficiaryId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCorporates/" + clientId + "/beneficiaries/" + beneficiaryId
    );
  }

  public getClientAuthorizedPersonById(
    clientId: number,
    authorizedPersonId: number
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/authorizedPersons/" + authorizedPersonId
    );
  }

  public getActionById(actionId: string): Observable<any> {
    return this.fetch("/clientCommon/actions/" + actionId);
  }

  public getRuleActionRelatedExecutions(actionId: number): Observable<any> {
    return this.fetch(`/dropdown/actions/${actionId}/related-executions`);
  }

  public getClientTradeById(
    clientId: number,
    tradeId: number
  ): Observable<any> {
    return this.fetch("/clientCommon/" + clientId + "/trades/" + tradeId);
  }

  public getClientCaseById(clientId: number, caseId: number): Observable<any> {
    return this.fetch("/clientCommon/" + clientId + "/cases/" + caseId);
  }

  public getClientTransactionScreeningCaseById(
    clientId,
    transactionScreeningCaseId: any
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${clientId}/transactionScreeningCases/${transactionScreeningCaseId}`
    );
  }

  public getClientTransactionScreeningCasePaymentComponents(
    transactionScreeningCaseId: any
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/transactionScreeningCasePaymentComponents/${transactionScreeningCaseId}`
    );
  }

  public getClientEvaluationById(
    clientId: any,
    evaluationId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/evaluations/" + evaluationId
    );
  }

  public getClientOldEvaluationById(
    clientId: any,
    evaluationId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/oldevaluations/" + evaluationId
    );
  }

  public getClientAdhocEvaluationById(
    clientId: any,
    adhocevaluationId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/adhocevaluations/" + adhocevaluationId
    );
  }

  public getClientQuestionnaireById(
    clientId: any,
    questionnaireId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/questionnaires/" + questionnaireId
    );
  }

  public getClientDocumentById(
    clientId: any,
    clientDocumentId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/documents/" + clientDocumentId
    );
  }

  public getClientDocumentAttachmentsByClientDocumentId(
    clientId: any,
    clientDocumentId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" +
      clientId +
      "/documents/" +
      clientDocumentId +
      "/attachments"
    );
  }

  public getClientElectronicIdentificationAttachments(
    electronicIdentificationId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" +
      electronicIdentificationId +
      "/electronicIdentificationAttachments"
    );
  }

  public getClientProfiles(): Observable<any> {
    return this.fetch("/clientCommon/profiles");
  }

  public getClientProfileName(profileId: any): Observable<any> {
    return this.fetch("/clientCommon/profiles/" + profileId);
  }

  public getClientProfilesByClientLegalStructureType(
    clientId: any,
    type: any
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${clientId}/profilesByLegalStructureType/${type}`
    );
  }

  public getClientIndividualBasicInformation(clientId: any): Observable<ClientIndividualBasicInfoModel> {
    return this.fetch(`/clientIndividuals/${clientId}/clientBasicInformation`);
  }

  public getClientCorporateBasicInfoById(clientId: any): Observable<any> {
    return this.fetch(`/clientCorporates/${clientId}/clientBasicInformation`);
  }

  public getClientJointsBasicInfoById(clientId: any): Observable<any> {
    return this.fetch(`/clients/joints/${clientId}/clientBasicInformation`);
  }

  public getClientEvaluationTreeNodes(clientId: any): Observable<any> {
    return this.fetch(
      "/clientCommon/" + clientId + "/clientEvaluationTreeNodes"
    );
  }

  public getClientJointEvaluationTreeNodes(clientId: any): Observable<any> {
    return this.fetch(
      "/clients/joints/" + clientId + "/clientEvaluationTreeNodes"
    );
  }

  public getClientEvaluationTreeNodesByClientEvaluationId(
    clientId: any,
    clientEvaluationId: any
  ): Observable<any> {
    return this.fetch(
      "/clientCommon/" +
      clientId +
      "/clientEvaluationTreeNodes/" +
      clientEvaluationId
    );
  }

  public getClientJointEvaluationTreeNodesByClientEvaluationId(
    clientId: any,
    clientEvaluationId: any
  ): Observable<any> {
    return this.fetch(
      "/clients/joints/" +
      clientId +
      "/clientEvaluationTreeNodes/" +
      clientEvaluationId
    );
  }

  public getClientStructureTreeNodes(clientId: any): Observable<any> {
    return this.fetch(
      "/clientCorporates/" + clientId + "/clientStructureTreeNodes"
    );
  }

  public getCountries(): Observable<any> {
    return this.fetch("/settings/countries");
  }

  public getCountryById(id): Observable<any> {
    return this.getCachedData(`/settings/countries/${id}`);
  }

  public getCountriesSimplified(): Observable<any> {
    return this.fetch("/settings/countries/simplified");
  }

  public getCities(): Observable<any> {
    return this.fetch("/settings/cities");
  }

  public getKeywords(): Observable<any> {
    return this.fetch("/settings/keywords");
  }

  public getIkycCountries(): Observable<any> {
    return this.fetch("/clientCommon/ikycCountries");
  }

  public getProposedValueUpdateStatuses(): Observable<any> {

    return this.getCachedData("/enumerations/ProposedValueUpdateStatusEnum");
  }


  public getIdentificationTypes(): Observable<any> {
    return this.getCachedData("/enumerations/identificationTypesEnum");
  }

  public getCardPresentedStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/cardPresentedStatusEnum");
  }

  public getCardAuthorizationStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/cardAuthorizationStatusEnum");
  }

  public getCardTransactionStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/cardTransactionStatusEnum");
  }

  public getCountryEvaluationGrades(): Observable<any> {
    return this.fetch("/settings/countryevaluationgrades");
  }

  public getRecordCounts(tableName: string): Observable<any> {
    return this.fetch("/administration/getRecordCounts?tableName=" + tableName);
  }

  public getRoleById(id: string): Observable<any> {
    return this.fetch("/administration/roles/" + id);
  }

  public getRegulationGroupById(id: string): Observable<any> {
    return this.fetch("/settings/regulationGroups/" + id);
  }

  public getRegulationGroupBasicInformationById(id: any): Observable<any> {
    return this.fetch(`/settings/regulationGroups/${id}/information/basic`);
  }

  public getSubGroupsPerRegulationGroup(id: number): Observable<any> {
    return this.fetch("/settings/regulationGroup/" + id + "/subGroups");
  }

  public getSubGroupsPerRegulationGroupList(ids: any): Observable<any> {
    let body = {
      regulationGroupIds: ids,
    };

    return this.postJson(
      "/settings/regulationGroup/subGroups",
      JSON.stringify(body)
    );
  }

  public getSubGroups(): Observable<any> {
    return this.fetch("/settings/subGroups");
  }

  public getEvaluationGradeDropDown(): Observable<any> {
    return this.fetch("/dropdown/evaluationGrades");
  }

  public getReportEngineSetupById(id: string): Observable<any> {
    return this.fetch("/settings/reportEngineSetups/" + id);
  }

  public getDynamicReportEngineSetupById(id: string): Observable<any> {
    return this.fetch("/settings/dynamicReportEngineSetups/" + id);
  }

  public runReportEngineSetupById(id: string): Observable<any> {
    return this.fetch("/settings/reportEngineSetups/" + id + "/run");
  }

  public runDynamicReportEngineSetupById(id: string): Observable<any> {
    return this.fetch("/settings/dynamicReportEngineSetup/" + id + "/run");
  }

  public getReportEngineSetupByIdExecutions(id: string): Observable<any> {
    return this.fetch("/settings/reportEngineSetups/" + id + "/executions");
  }

  public getDynamicReportEngineSetupByIdExecutions(
    id: string
  ): Observable<any> {
    return this.fetch(
      "/settings/dynamicReportEngineSetups/" + id + "/executions"
    );
  }

  public getRegulationGroupByClientId(clientId: number): Observable<any> {
    return this.fetch("/settings/regulationGroups/clients/" + clientId);
  }

  public getExternalCredentialsSetupById(id: string): Observable<any> {
    return this.fetch("/administration/externalCredentialsSetup/" + id);
  }

  public getCriteriaCategoryById(id: string): Observable<any> {
    return this.fetch("/settings/criteriaCategories/" + id);
  }

  public getDocumentCategoryById(id: string): Observable<any> {
    return this.fetch("/settings/documentCategories/" + id);
  }

  public getQuestionsCategoryById(id: string): Observable<any> {
    return this.fetch("/settings/questionsCategories/" + id);
  }

  public getBanks(): Observable<any> {
    return this.fetch("/settings/banks");
  }

  public getMonitoringVisitTypes(): Observable<any> {
    return this.fetch("/settings/monitoringVisitType");
  }

  public getCaseStatusesRiskPoints(): Observable<any> {
    return this.fetch("/settings/caseStatusesRiskPoints");
  }

  public getCaseSuspicionLevelsRiskPoints(): Observable<any> {
    return this.fetch("/settings/caseSuspicionLevelsRiskPoints");
  }

  public getCurrencyRates(): Observable<any> {
    return this.fetch("/settings/currencyRates");
  }

  public getReasonsForTin(): Observable<any> {
    return this.fetch("/settings/reasonsForTin");
  }

  public getAddressesTypesByClientType(clientType: any): Observable<any> {
    return this.fetch("/settings/addressesTypes/" + clientType);
  }

  public getAddressesTypes(): Observable<any> {
    return this.fetch("/settings/addressesTypesAll");
  }

  public getCapacities(): Observable<any> {
    return this.fetch("/settings/capacities");
  }

  public getDropdownCapacities(): Observable<any> {
    return this.fetch("/dropdown/capacities");
  }

  public getIntegrationCapacitiesAll(): Observable<any> {
    return this.fetch("/settings/integrationCapacitiesAll");
  }

  //UniqueCode != null
  public getIntegrationCapacities(): Observable<any> {
    return this.fetch("/settings/integrationCapacities");
  }

  public getDefaultAddresses(): Observable<any> {
    return this.fetch("/settings/defaultaddresses");
  }

  public getChecklists(): Observable<any> {
    return this.fetch("/settings/checklists");
  }

  public getPaymentMethods(): Observable<any> {
    return this.fetch("/settings/paymentMethods");
  }

  public getMidTypes(): Observable<any> {
    return this.fetch("/settings/midTypes");
  }

  public getClientCategorizations(): Observable<any> {
    return this.fetch("/settings/clientCategorizations");
  }

  public getMidClasses(): Observable<any> {
    return this.fetch("/settings/midClasses");
  }

  public getPaymentMethodsFilter(): Observable<any> {
    return this.fetch("/settings/paymentMethodsFilter");
  }

  public getContractPolicyStatus(): Observable<any> {
    return this.fetch("/settings/contractPolicyStatus");
  }

  public getContractPolicyBeneficiaryRelations(): Observable<any> {
    return this.fetch("/settings/contractPolicyBeneficiaryRelations");
  }

  public getContractPolicyProduct(): Observable<any> {
    return this.fetch("/settings/contractPolicyProducts");
  }

  public getInvestmentAccountTypes(): Observable<any> {
    return this.fetch("/settings/investmentAccountTypes");
  }

  public getClientStatuses(): Observable<any> {
    return this.fetch("/settings/clientStatuses");
  }

  public getCurrencyInformations(): Observable<any> {
    return this.fetch("/settings/currencies");
  }

  public getCurrencyInformationsSetup(): Observable<any> {
    return this.fetch("/settings/currencyInformations");
  }

  public getClientStatusesSimplified(): Observable<any> {
    return this.fetch("/settings/clientStatuses/simplified");
  }

  public getGameTypes(): Observable<any> {
    return this.fetch("/settings/gameTypes");
  }

  public getGamingAccountStatuses(): Observable<any> {
    return this.fetch("/settings/GamingAccountStatuses");
  }

  public getGamingTransactionTypes(): Observable<any> {
    return this.fetch("/settings/GamingTransactionTypes");
  }

  public getClientCategories(): Observable<any> {
    return this.fetch("/settings/clientCategories");
  }

  public getPortalClientStatuses(): Observable<any> {
    return this.fetch("/portalSetup/clientStatuses");
  }

  public getFullyFilteredPortalDocuments(
    regulationGroupId,
    clientType,
    integrationCapacityId
  ): Observable<any> {
    integrationCapacityId = integrationCapacityId || -1;
    return this.fetch(
      `/portalSetup/fullyFilteredPortalDocuments/regulationGroups/${regulationGroupId}/clientTypes/${clientType}/integrationCapacities/${integrationCapacityId}`
    );
  }

  public getIntegrationCapacitiesForPortalSetup(): Observable<any> {
    return this.fetch("/portalSetup/integrationCapacities");
  }

  public getNextClientStatuses(previousClientStatusId: any): Observable<any> {
    return this.fetch(
      "/settings/nextClientStatuses/" + previousClientStatusId.toString()
    );
  }

  public getActionStatuses(): Observable<any> {
    return this.fetch("/settings/actionStatuses");
  }

  public getTransactionTypes(): Observable<any> {
    return this.fetch("/settings/transactionTypes");
  }

  public getFatcaStatuses(): Observable<any> {
    return this.fetch("/settings/fatcaStatuses");
  }

  public getFatcaEntityCategorizations(): Observable<any> {
    return this.fetch("/settings/fatcaEntityCategorizations");
  }

  public getFatcaDocuments(): Observable<any> {
    return this.fetch("/settings/fatcaDocuments");
  }

  public getFatcaCrsEntityCategorizations(): Observable<any> {
    return this.fetch("/settings/fatcaCrsEntityCategorizations");
  }

  public getTradeTypes(): Observable<any> {
    return this.fetch("/settings/tradeTypes");
  }

  public getRelatedWebsiteTypes(): Observable<any> {
    return this.fetch("/settings/relatedWebsiteTypes");
  }

  public getInternalBlacklists(): Observable<any> {
    return this.fetch("/administration/internalblacklists");
  }

  public getInternalBlacklistCorporates(): Observable<any> {
    return this.fetch("/administration/internalblacklistCorporates");
  }

  public getInternalBlacklistedReasons(): Observable<any> {
    return this.fetch("/administration/internalblacklistedreasons");
  }

  public getVisibleIdentifications(): Observable<any> {
    return this.fetch("/user/visibleIdentifications");
  }

  public getContractPolicyCategories(): Observable<any> {
    return this.fetch("/settings/contractPolicyCategories");
  }

  public getContractPolicyStatuses(): Observable<any> {
    return this.fetch("/settings/contractPolicyStatus");
  }

  public getContractPolicyProducts(): Observable<any> {
    return this.fetch("/settings/contractPolicyProducts");
  }

  public getContractTransactionPaymentMethods(): Observable<any> {
    return this.fetch("/settings/contractTransactionPaymentMethod");
  }

  public getTransactionMethods(): Observable<any> {
    return this.fetch("/settings/transactionMethods");
  }

  public getCriterionById(id: string): Observable<any> {
    return this.fetch("/settings/criteria/" + id);
  }

  public getFirmSanctionCriterionById(id: string): Observable<any> {
    return this.fetch("/settings/firms/firmSanctionCriterion/" + id);
  }

  public getCriterionAnswers(id: String): Observable<any> {
    return this.fetch("/settings/criteria/" + id + "/answers/");
  }

  public getFirmSanctionCriterionAnswers(id: String): Observable<any> {
    return this.fetch("/settings/firms/firmSanctionCriterionAnswers/" + id);
  }

  public getOldCriterionAnswers(criterion: String): Observable<any> {
    return this.fetch(`/settings/oldCriterionAnswers/${criterion}`);
  }

  public getCriterionAnswerById(id: String, answerId: String): Observable<any> {
    return this.fetch("/settings/criteria/" + id + "/answers/" + answerId);
  }

  public getQuestionById(id: string): Observable<any> {
    return this.fetch("/settings/questions/" + id);
  }

  public getQuestionAnswers(id: String): Observable<any> {
    return this.fetch("/settings/questions/" + id + "/answers/");
  }

  public getOpenEndedQuestionAnswers(id: String): Observable<any> {
    return this.fetch("/settings/openEnded/questions/" + id + "/answers/");
  }

  public getQuestionAnswerById(id: String, answerId: String): Observable<any> {
    return this.fetch("/settings/questions/" + id + "/answers/" + answerId);
  }

  public getEvaluationTypes(): Observable<any> {
    return this.fetch("/settings/evaluationTypes");
  }

  public getDynamicGridSelectOptions(): Observable<any> {
    return this.fetch("/settings/dynamicGridSelectOptions");
  }

  public getReportEngineSetups(): Observable<any> {
    return this.fetch("/settings/reportEngineSetups");
  }

  public getDynamicReportEngineSetups(): Observable<any> {
    return this.fetch("/settings/dynamicReportEngineSetups");
  }

  public getRuleById(id: string): Observable<any> {
    return this.fetch("/administration/rules/" + id);
  }

  public getSystemDynamicReportById(id: string): Observable<any> {
    return this.fetch("/administration/systemDynamicReports/" + id);
  }

  public getTuningRuleParameters(id: string): Observable<any> {
    return this.fetch("/administration/rules/" + id + "/tuning/parameters");
  }

  public getRuleSynchronizationHistory(id: string): Observable<any> {
    return this.fetch(`/administration/rule/${id}/synchronizationHistory`);
  }

  public getRuleUpdateComments(id: string): Observable<any> {
    return this.fetch("/administration/systemRules/" + id + "/updateComments");
  }

  public getDynamicReportUpdateComments(id: string): Observable<any> {
    return this.fetch(
      "/administration/systemDynamicReports/" + id + "/updateComments"
    );
  }

  public getTuningRuleParameterById(
    ruleId: string,
    tuningRuleParameterID: string
  ): Observable<any> {
    return this.fetch(
      `/administration/rules/${ruleId}/tuning/parameters/${tuningRuleParameterID}`
    );
  }

  public getTuningDynamicReportParameters(id: string): Observable<any> {
    return this.fetch(
      "/administration/systemDynamicReports/" + id + "/tuning/parameters"
    );
  }

  public getTuningLinkedRules(id: string): Observable<any> {
    return this.fetch("/administration/rules/" + id + "/tuning/ruleExecutions");
  }

  public getTuningLinkedRuleBlockCases(id: string): Observable<any> {
    return this.fetch(
      "/administration/rules/" + id + "/tuning/ruleExecutionsBlockCases"
    );
  }

  public getTuningLinkedRulesForSettings(id: string): Observable<any> {
    return this.fetch("/settings/rules/" + id + "/tuning/ruleExecutions");
  }

  public getIkycValidServices(): Observable<any> {
    return this.fetch("/dashboard/ikycValidServices");
  }

  public getAdhocSearchThresholds(): Observable<any> {
    return this.getCachedData("/clientCommon/adhoc-search-thresholds");
  }

  public getIkycTotalAcurisOngoingMonitoringEntities(): Observable<any> {
    return this.fetch("/dashboard/ikycTotalAcurisOngoingMonitoringEntities");
  }

  public getFirstActiveIkycService(
    serviceTypeName: string,
    action: string
  ): Observable<any> {
    return this.fetch(
      `/dashboard/firstActiveIkycService/${serviceTypeName}/${action}`
    );
  }

  public getIkycDashboard(): Observable<any> {
    return this.fetch("/dashboard/ikycUserDashboard");
  }

  public getTuningRuleParameterExecutions(id: string): Observable<any> {
    return this.fetch("/administration/rules/" + id + "/tuning/executions");
  }

  public getQueryBasedSetupParameterExecutions(id: string): Observable<any> {
    return this.fetch(
      "/administration/queryBasedSetups/" + id + "/tuning/executions"
    );
  }

  public getTuningDynamicReportParameterExecutions(
    id: string
  ): Observable<any> {
    return this.fetch(
      "/administration/systemDynamicReports/" + id + "/tuning/executions"
    );
  }

  public getDropdownTuningDynamicReportParameterExecutions(
    id: string
  ): Observable<any> {
    return this.fetch(
      "/reportFilters/systemDynamicReports/" + id + "/tuning/executions"
    );
  }

  public getReportTuningDynamicReportParameterExecutions(
    id: string
  ): Observable<any> {
    return this.fetch(
      "/reportFilters/systemDynamicReports/" + id + "/tuning/executions"
    );
  }

  public getTuningRuleParameterExecutionNames(id: string): Observable<any> {
    return this.fetch("/administration/rules/" + id + "/tuning/executionNames");
  }

  public getRulesForLinkedRule(id: string): Observable<any> {
    return this.fetch("/administration/rulesForLinkedRule/" + id);
  }

  public getBankById(id: string): Observable<any> {
    return this.fetch("/settings/banks/" + id);
  }

  public getClientStatusById(id: string): Observable<any> {
    return this.fetch("/settings/clientStatuses/" + id);
  }

  public getCurrencyInformationById(id: string): Observable<any> {
    return this.fetch("/settings/currencies/" + id);
  }

  public getClientCategoryById(id: string): Observable<any> {
    return this.fetch("/settings/clientCategories/" + id);
  }

  public getTransactionTypeById(id: string): Observable<any> {
    return this.fetch("/settings/transactionTypes/" + id);
  }

  public getTransactionMethodById(id: string): Observable<any> {
    return this.fetch("/settings/transactionMethods/" + id);
  }

  public getDocumentById(id: string): Observable<any> {
    return this.fetch("/settings/documents/" + id);
  }

  public getCaseWorkflowStatusById(id: string): Observable<any> {
    return this.fetch("/settings/caseWorkflowStatuses/" + id);
  }

  public getTagById(id: string): Observable<any> {
    return this.fetch("/settings/tags/" + id);
  }

  public getMandatoryDocumentsCategoryById(id: string): Observable<any> {
    return this.fetch("/settings/mandatoryDocumentsCategories/" + id);
  }

  public getEmailTemplateById(id: string): Observable<any> {
    return this.fetch("/settings/emailTemplates/" + id);
  }

  public getFullEmailTemplateById(id: string): Observable<any> {
    return this.fetch("/settings/fullEmailTemplates/" + id);
  }

  public getFullEmailTemplates(): Observable<any> {
    return this.fetch("/settings/fullEmailTemplates");
  }

  public getIntroEndingById(id: string): Observable<any> {
    return this.fetch("/settings/introEndings/" + id);
  }

  public getClientDocumentAttachmentNoDataById(
    id: string,
    clientDocumentId: string,
    clientDocumentAttachmentId: string
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${id}/documents/${clientDocumentId}/attachments/${clientDocumentAttachmentId}`
    );
  }

  public GetClientSegmentDetails(id: string): Observable<any> {
    return this.fetch(`/clientCommon/${id}/amlSegmentDetails`);
  }

  public getClientDocumentAttachmentDataById(
    id: string,
    clientDocumentId: string,
    clientDocumentAttachmentId: string
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${id}/documents/${clientDocumentId}/attachments/${clientDocumentAttachmentId}/data`
    );
  }

  public getClientDocumentAttachmentsData(clientId: string, documentIds: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('documentIds', documentIds.join(','));
    return this.fetch(`/clientCommon/${clientId}/documents/attachments`, params);
  }

  public getClientApplicationAttachmentById(
    clientId: string,
    clientApplicationId: string,
    clientApplicationAttachmentId: string
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${clientId}/applications/${clientApplicationId}/attachments/${clientApplicationAttachmentId}`
    );
  }

  public getClientApplicationAttachmentByIdData(
    clientId: string,
    clientApplicationId: string,
    clientApplicationAttachmentId: string
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${clientId}/applications/${clientApplicationId}/attachments/${clientApplicationAttachmentId}/data`
    );
  }

  public getClientCaseAttachmentById(
    clientId: string,
    clientCaseId: string,
    clientCaseAttachmentId: string
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${clientId}/cases/${clientCaseId}/attachments/${clientCaseAttachmentId}`
    );
  }

  public getClientCaseAttachmentByIdData(
    clientId: string,
    clientCaseId: string,
    clientCaseAttachmentId: string
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${clientId}/cases/${clientCaseId}/attachments/${clientCaseAttachmentId}/data`
    );
  }

  public getResetPasswordToken(input: string): Observable<any> {
    let body = new URLSearchParams();
    body.set("Input", input);
    return this.postBase("/authorization/getResetPasswordToken", body);
  }

  public getClientsCountNotMonitoredByStatusIds(
    clientStatusIds: string
  ): Observable<any> {
    let body = new URLSearchParams();
    body.set("clientStatusIds", clientStatusIds);
    return this.postBase(
      "/administration/getClientsCountNotMonitoredByStatusIds",
      body
    );
  }

  public getUserResetPasswordToken(
    email: string,
    userName: string
  ): Observable<any> {
    let body = new URLSearchParams();
    body.set("Username", userName);
    body.set("Email", email);
    return this.postBase("/authorization/getUserResetPasswordToken", body);
  }

  public resetPassword(
    resetPasswordForm: FormGroup,
    email: string,
    userName: string,
    token: string
  ): Observable<any> {
    let body = new URLSearchParams();
    body.set("Username", userName);
    body.set("Email", email);
    body.set("Token", token);
    body.set("HashKey", resetPasswordForm.controls.hash.value);
    body.set("NewPassword", resetPasswordForm.controls.newPassword.value);
    body.set(
      "ConfirmPassword",
      resetPasswordForm.controls.confirmPassword.value
    );
    return this.postBase("/authorization/resetPassword", body);
  }

  public resetPasswordWithConfirm(
    userName: string,
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ): Observable<any> {
    var raw = JSON.stringify({
      Username: userName,
      OldPassword: oldPassword,
      NewPassword: newPassword,
      ConfirmPassword: confirmPassword,
    });
    return this.postJsonBase("/authorization/confirm/resetPassword", raw);
  }

  public editLicence(licence: string): Observable<any> {
    let body = new URLSearchParams();
    body.set("Licence", licence);
    return this.postBase("/authorization/licence", body);
  }

  public isLicenceValid(): Observable<any> {
    return this.fetchBase("/authorization/licence");
  }

  public editAccount(
    editAccountForm: FormGroup,
    editAccountSettingsForm: FormGroup,
    logoFiles: any,
    splashScreenLogoFiles: any,
    editSmtpCredentialsForm: FormGroup,
    editSMSProviderCredentialsForm: FormGroup,
    editAzureBlobCredentialsForm: FormGroup,
    editInternalBlacklistScreeningTypeForm: FormGroup,
    editPortalAccountsExpirationForm: FormGroup,
    editMachineLearningForm: FormGroup,
    editTransactionScreeningSetupsForm: FormGroup,
    editUserInterfaceSetupsForm: FormGroup
  ): Observable<any> {
    let body = new FormData();
    var logoImageFile = logoFiles[0];
    var splashScreenLogoImageFile = splashScreenLogoFiles[0];

    body.append("Id", editAccountForm.controls.id.value);
    body.append("Name", editAccountForm.controls.name.value);
    body.append("DisplayName", editAccountForm.controls.displayName.value);
    body.append("ContactName", editAccountForm.controls.contactName.value);
    body.append("ContactPhone", editAccountForm.controls.contactPhone.value);
    body.append("ContactEmail", editAccountForm.controls.contactEmail.value);
    body.append("AddressLine1", editAccountForm.controls.addressLine1.value);
    body.append("AddressLine2", editAccountForm.controls.addressLine2.value);
    body.append("Town", editAccountForm.controls.town.value);
    body.append("PostalCode", editAccountForm.controls.postalCode.value);
    body.append("POBox", editAccountForm.controls.poBox.value);
    body.append("Country", editAccountForm.controls.country.value);
    body.append("Logo", logoImageFile);
    body.append("SplashScreenLogo", splashScreenLogoImageFile);
    body.append(
      "PrivacyPolicyLink",
      editAccountForm.controls.privacyPolicyLink.value
    );
    body.append(
      "TermsAndConditionsLink",
      editAccountForm.controls.termsAndConditionsLink.value
    );
    body.append("DevelopedBy", editAccountForm.controls.developedBy.value);
    body.append("Licence", editAccountForm.controls.licence.value);
    body.append(
      "UboSharePercThreshold",
      editAccountSettingsForm.controls.uboSharePercThreshold.value
    );
    body.append(
      "SharePercentageIncludedInEvaluation",
      editAccountSettingsForm.controls.sharePercentageIncludedInEvaluation.value
    );
    body.append(
      "DisableConcurrentLogins",
      editAccountForm.controls.disableConcurrentLogins.value
    );
    body.append(
      "DisplayCopyrights",
      editAccountForm.controls.displayCopyrights.value
    );
    body.append(
      "RegTekCodePrefix",
      editAccountSettingsForm.controls.regTekCodePrefix.value
    );
    body.append(
      "RegTekCodeLength",
      editAccountSettingsForm.controls.regTekCodeLength.value
    );
    body.append(
      "screeningSearchHoursThreshold",
      editAccountSettingsForm.controls.screeningSearchHoursThreshold.value
    );
    body.append(
      "shouldPerformScreeningSearch",
      editAccountSettingsForm.controls.shouldPerformScreeningSearch.value ||
      false
    );
    body.append(
      "percentageOfLastEvaluationScore",
      editAccountSettingsForm.controls.percentageOfLastEvaluationScore.value
    );
    body.append(
      "showBehavioralAndUnifiedScoreInClientPanel",
      editAccountSettingsForm.controls
        .showBehavioralAndUnifiedScoreInClientPanel.value
    );
    body.append(
      "percentageOfLastCasesBehavioralScore",
      editAccountSettingsForm.controls.percentageOfLastCasesBehavioralScore
        .value
    );
    body.append(
      "clientsProfileReportVisibleFooter",
      editAccountSettingsForm.controls.clientsProfileReportVisibleFooter
        .value || false
    );
    body.append(
      "RegTekCodeSuffix",
      editAccountSettingsForm.controls.regTekCodeSuffix.value
    );
    body.append(
      "ShowOnClientPanel",
      editAccountSettingsForm.controls.showOnClientPanel.value
    );
    body.append("ftpHost", editAccountSettingsForm.controls.ftpHost.value);
    body.append(
      "ftpUsername",
      editAccountSettingsForm.controls.ftpUsername.value
    );
    body.append(
      "ftpPassword",
      editAccountSettingsForm.controls.ftpPassword.value
    );
    body.append("ftpPort", editAccountSettingsForm.controls.ftpPort.value);
    body.append(
      "ftpProtocol",
      editAccountSettingsForm.controls.ftpProtocol.value
    );
    body.append(
      "ftpRootPath",
      editAccountSettingsForm.controls.ftpRootPath.value
    );
    body.append(
      "localServerRootDocumentPath",
      editAccountSettingsForm.controls.localServerRootDocumentPath.value
    );
    body.append(
      "healthCheckEmailReceiveConfirmation",
      editAccountSettingsForm.controls.healthCheckEmailReceiveConfirmation.value
    );
    body.append(
      "healthCheckEmailToReceive",
      editAccountSettingsForm.controls.healthCheckEmailReceiveConfirmation
        .value == true
        ? editAccountSettingsForm.controls.healthCheckEmailToReceive.value
        : null
    );
    body.append(
      "smtpFromEmailAddress",
      editSmtpCredentialsForm.controls.smtpFromEmailAddress.value
    );
    body.append(
      "smtpClientImplementationType",
      editSmtpCredentialsForm.controls.smtpClientImplementationType.value
    );
    body.append("smtpHost", editSmtpCredentialsForm.controls.smtpHost.value);
    body.append("smtpPort", editSmtpCredentialsForm.controls.smtpPort.value);
    body.append(
      "smtpUsername",
      editSmtpCredentialsForm.controls.smtpUsername.value
    );
    body.append(
      "smtpPassword",
      editSmtpCredentialsForm.controls.smtpPassword.value
    );
    body.append(
      "smsSenderName",
      editSMSProviderCredentialsForm.controls.smsSenderName.value
    );
    body.append(
      "smsHost",
      editSMSProviderCredentialsForm.controls.smsHost.value
    );
    body.append(
      "smsPort",
      editSMSProviderCredentialsForm.controls.smsPort.value
    );
    body.append(
      "smsUsername",
      editSMSProviderCredentialsForm.controls.smsUsername.value
    );
    body.append(
      "smsPassword",
      editSMSProviderCredentialsForm.controls.smsPassword.value
    );
    body.append(
      "smtpEnableSsl",
      editSmtpCredentialsForm.controls.smtpEnableSsl.value
    );
    body.append(
      "azureBlobConnectionString",
      editAzureBlobCredentialsForm.controls.azureBlobConnectionString.value
    );
    body.append(
      "azureBlobContainerName",
      editAzureBlobCredentialsForm.controls.azureBlobContainerName.value
    );
    body.append(
      "totalClientTransactionlimitationRows",
      editAccountSettingsForm.controls.totalClientTransactionlimitationRows
        .value || 0
    );
    body.append(
      "lastEvaluationGradeType",
      editAccountSettingsForm.controls.lastEvaluationGradeType.value
    );
    body.append(
      "showHealthCheckDisksSize",
      editAccountSettingsForm.controls.showHealthCheckDisksSize.value
    );
    body.append(
      "isComplianceOfficerReviewStatusEnabled",
      editAccountSettingsForm.controls.isComplianceOfficerReviewStatusEnabled
        .value
    );
    body.append(
      "numberOfRequiredIdentificationDocuments",
      editAccountSettingsForm.controls.numberOfRequiredIdentificationDocuments
        .value
    );
    body.append(
      "externalNotificationCallAttempts",
      editAccountSettingsForm.controls.externalNotificationCallAttempts.value
    );
    body.append(
      "showReviewNeededForScreeningUpdates",
      editAccountSettingsForm.controls.showReviewNeededForScreeningUpdates.value
    );
    body.append(
      "informAdministrators",
      editAccountSettingsForm.controls.informAdministrators.value
    );
    body.append(
      "expirePasswordAfterDays",
      editAccountSettingsForm.controls.expirePasswordAfterDays.value
    );

    // Screening Thresholds
    body.append(
      "ongoingMonitoringMatchThreshold",
      editAccountSettingsForm.controls.ongoingMonitoringMatchThreshold.value
    );
    body.append(
      "personAdhocScreeningMatchThreshold",
      editAccountSettingsForm.controls.personAdhocScreeningMatchThreshold.value
    );
    body.append(
      "businessAdhocScreeningMatchThreshold",
      editAccountSettingsForm.controls.businessAdhocScreeningMatchThreshold
        .value
    );
    body.append(
      "transactionScreeningAllTransactionsMatchThreshold",
      editAccountSettingsForm.controls
        .transactionScreeningAllTransactionsMatchThreshold.value
    );
    body.append(
      "transactionScreeningFastPaymentsMatchThreshold",
      editAccountSettingsForm.controls
        .transactionScreeningFastPaymentsMatchThreshold.value
    );
    body.append(
      "restrictedCitiesMatchPercent",
      editAccountSettingsForm.controls.restrictedCitiesMatchPercent.value
    );
    body.append(
      "internalBlacklistsMatchPercent",
      editAccountSettingsForm.controls.internalBlacklistsMatchPercent.value
    );

    body.append(
      "transactionScreeningInternalBlacklistCheck",
      editAccountForm.controls.transactionScreeningInternalBlacklistCheck.value
    );
    body.append(
      "autoSetDefaultEvaluationCriteriaAnswers",
      editAccountSettingsForm.controls.autoSetDefaultEvaluationCriteriaAnswers
        .value
    );
    body.append(
      "autoPopulateEvaluationCriteriaAnswers",
      editAccountSettingsForm.controls.autoPopulateEvaluationCriteriaAnswers
        .value
    );
    body.append(
      "autoDetectDuplicateProfiles",
      editAccountSettingsForm.controls.autoDetectDuplicateProfiles.value ||
      false
    );
    body.append(
      "activateScreeningTasks",
      editAccountSettingsForm.controls.activateScreeningTasks.value || false
    );
    body.append(
      "activateEvaluationTasks",
      editAccountSettingsForm.controls.activateEvaluationTasks.value || false
    );
    body.append(
      "activateMandatoryDocumentsTasks",
      editAccountSettingsForm.controls.activateMandatoryDocumentsTasks.value ||
      false
    );
    body.append(
      "activateDocumentVerificationTasks",
      editAccountSettingsForm.controls.activateDocumentVerificationTasks
        .value || false
    );
    body.append(
      "activateIdentityVerificationTasks",
      editAccountSettingsForm.controls.activateIdentityVerificationTasks
        .value || false
    );
    body.append(
      "activateOnboardingTasks",
      editAccountSettingsForm.controls.activateOnboardingTasks.value || false
    );

    body.append(
      "ongoingMonitoringResultsReceiveConfirmation",
      editAccountSettingsForm.controls
        .ongoingMonitoringResultsReceiveConfirmation.value
    );
    body.append(
      "ongoingMonitoringResultsEmailToReceive",
      editAccountSettingsForm.controls
        .ongoingMonitoringResultsReceiveConfirmation.value == true
        ? editAccountSettingsForm.controls
          .ongoingMonitoringResultsEmailToReceive.value
        : null
    );

    // Blacklist form
    body.append(
      "internalBlacklistsScreeningType",
      editInternalBlacklistScreeningTypeForm.controls
        .internalBlacklistScreeningType.value
    );
    body.append(
      "updateInternalBlacklistByDeletingExistingRecords",
      editInternalBlacklistScreeningTypeForm.controls
        .updateInternalBlacklistByDeletingExistingRecords.value
    );

    // Settings - Staging Area
    body.append(
      "stagingAreaAddFieldLogOnInsert",
      editAccountSettingsForm.controls.stagingAreaAddFieldLogOnInsert.value
    );
    body.append(
      "stagingAreaAddFieldLogOnUpdate",
      editAccountSettingsForm.controls.stagingAreaAddFieldLogOnUpdate.value
    );

    // Portal Accounts Expiration
    body.append(
      "isPortalAccountExpirationEnabled",
      editPortalAccountsExpirationForm.controls.isPortalAccountExpirationEnabled
        .value
    );
    body.append(
      "portalAccountExpirationThresholdInHours",
      editPortalAccountsExpirationForm.controls
        .portalAccountExpirationThresholdInHours.value
    );
    body.append(
      "portalAccountExpirationDeletionThresholdInHours",
      editPortalAccountsExpirationForm.controls
        .portalAccountExpirationDeletionThresholdInHours.value || 0
    );

    //Screening
    body.append(
      "keepAdhocScreeningHistory",
      editAccountSettingsForm.controls.keepAdhocScreeningHistory.value
    );
    body.append(
      "keepOngoingMonitoringScreeningHistory",
      editAccountSettingsForm.controls.keepOngoingMonitoringScreeningHistory
        .value
    );
    body.append(
      "monthsOfKeepingHistory",
      editAccountSettingsForm.controls.monthsOfKeepingHistory.value
    );

    // Send Notification on new release
    body.append(
      "sendNotificationEmailOnNewRelease",
      editAccountSettingsForm.controls.sendNotificationEmailOnNewRelease.value
    );
    body.append(
      "changeVersionEnvironmentNotification",
      editAccountSettingsForm.controls.changeVersionEnvironmentNotification
        .value
    );

    //Machine Learning
    body.append(
      "maximumNumberOfTransactionsToSyncWithML",
      editMachineLearningForm.controls.maximumNumberOfTransactionsToSyncWithML
        .value
    );

    //Fraud Detection
    body.append(
      "thresholdForFraudCheckInDays",
      editAccountSettingsForm.controls.thresholdForFraudCheckInDays.value
    );

    //Transaction Screening
    if (
      editTransactionScreeningSetupsForm.controls
        .transactionScreeningIndividualDatasets.value
    ) {
      editTransactionScreeningSetupsForm.controls.transactionScreeningIndividualDatasets.value.map(
        (el) => {
          body.append("transactionScreeningIndividualDatasets", el.value);
        }
      );
    }

    if (
      editTransactionScreeningSetupsForm.controls
        .transactionScreeningLegalEntityDatasets.value
    ) {
      editTransactionScreeningSetupsForm.controls.transactionScreeningLegalEntityDatasets.value.map(
        (el) => {
          body.append("transactionScreeningLegalEntityDatasets", el.value);
        }
      );
    }

    // Client Evaluation Form - Default Expand
    body.append(
      "evaluationFormDefaultExpand",
      editUserInterfaceSetupsForm.controls.evaluationFormDefaultExpand.value
    );

    // Fast Payment Transaction Screening
    if (
      editTransactionScreeningSetupsForm.controls
        .fastPaymentTransactionScreeningIndividualDatasets.value
    ) {
      editTransactionScreeningSetupsForm.controls.fastPaymentTransactionScreeningIndividualDatasets.value.map(
        (el) => {
          body.append(
            "fastPaymentTransactionScreeningIndividualDatasets",
            el.value
          );
        }
      );
    }

    if (
      editTransactionScreeningSetupsForm.controls
        .fastPaymentTransactionScreeningLegalEntityDatasets.value
    ) {
      editTransactionScreeningSetupsForm.controls.fastPaymentTransactionScreeningLegalEntityDatasets.value.map(
        (el) => {
          body.append(
            "fastPaymentTransactionScreeningLegalEntityDatasets",
            el.value
          );
        }
      );
    }

    body.append("advancedSearchScreenTek",
      editTransactionScreeningSetupsForm.controls.advancedSearchScreenTek.value);

    return this.putForm("/administration/accounts", body);
  }

  public createClientTasks(taskType: any): Observable<any> {
    let body = {
      type: taskType,
    };

    return this.postJson(
      "/administration/clientTasksJob",
      JSON.stringify(body)
    );
  }

  public changeLinkedCriterion(
    questionId: any,
    changeLinkedCriterionForm: any
  ): Observable<any> {
    let body = {
      QuestionId: questionId,
      CriterionId: changeLinkedCriterionForm.controls.criterionId.value,
    };

    return this.postJson(
      "/settings/changeLinkedCriterion",
      JSON.stringify(body)
    );
  }

  public getComplianceOfficerStatusFlag(): Observable<any> {
    return this.fetch("/user/complianceOfficerReviewStatusFlag");
  }

  public checkSmtpConnection(
    editSmtpCredentialsForm: FormGroup
  ): Observable<any> {
    let body = new FormData();
    body.append(
      "smtpFromEmailAddress",
      editSmtpCredentialsForm.controls.smtpFromEmailAddress.value
    );
    body.append(
      "smtpClientImplementationType",
      editSmtpCredentialsForm.controls.smtpClientImplementationType.value
    );
    body.append("smtpHost", editSmtpCredentialsForm.controls.smtpHost.value);
    body.append("smtpPort", editSmtpCredentialsForm.controls.smtpPort.value);
    body.append(
      "smtpUsername",
      editSmtpCredentialsForm.controls.smtpUsername.value
    );
    body.append(
      "smtpPassword",
      editSmtpCredentialsForm.controls.smtpPassword.value
    );
    body.append(
      "smtpEnableSsl",
      editSmtpCredentialsForm.controls.smtpEnableSsl.value
    );

    return this.putForm("/administration/checkSmtpConnection", body);
  }

  public checkAzureBlobConnection(
    editAzureBlobCredentialsForm: FormGroup
  ): Observable<any> {
    let body = new FormData();
    body.append(
      "azureBlobConnectionString",
      editAzureBlobCredentialsForm.controls.azureBlobConnectionString.value
    );
    body.append(
      "azureBlobContainerName",
      editAzureBlobCredentialsForm.controls.azureBlobContainerName.value
    );

    return this.putForm("/administration/checkAzureBlobConnection", body);
  }

  public synchronizeMachineLearning(module: string): Observable<any> {
    return this.putForm(`/administration/syncMachineLearning/${module}`, "{}");
  }

  public editRole(editRoleForm: FormGroup): Observable<any> {
    let body = {
      Id: editRoleForm.controls.id.value,
      Name: editRoleForm.controls.name.value,
      IsAdmin: editRoleForm.controls.isAdmin.value || false,
      PortalViewOnlyAccess:
        editRoleForm.controls.portalViewOnlyAccess.value || false,
      Description: editRoleForm.controls.description.value,
    };

    return this.putJson("/administration/roles", JSON.stringify(body));
  }

  public editDataAccessGroup(
    editGroupForm: FormGroup,
    groupId: any
  ): Observable<any> {
    let body = {
      Id: groupId,
      Name: editGroupForm.controls.name.value,
      Description: editGroupForm.controls.description.value,
    };

    return this.putJson(
      "/administration/dataAccessGroups",
      JSON.stringify(body)
    );
  }

  public checkClientEvaluationChildCriteria(
    clientId: any,
    clientEvaluations: any
  ): Observable<any> {
    var clientEvaluationsRequest = [];

    for (var clientEvaluation of clientEvaluations) {
      clientEvaluationsRequest.push(clientEvaluation);
    }

    var clientEvaluationId = clientEvaluations.filter(
      (x) => x.clientId == clientId
    )[0].id;

    let body = {
      clientEvaluations: clientEvaluationsRequest,
    };
    return this.postJson(
      `/clientCorporates/${clientId}/checkClientEvaluationChildCriteria`,
      JSON.stringify(body)
    );
  }

  public editDataAccessField(
    editFieldForm: FormGroup,
    field: any,
    dataAccessGroupRegulationGroupId: any,
    selectedCustomField: any,
    customFieldTextValues: any,
    customFieldListSelectedValues: any
  ): Observable<any> {
    let body = {
      id: field.id,
      dataAccessGroupRegulationGroupId: field.dataAccessGroupRegulationGroupId,
      fieldType: field.fieldType,
      referenceColumn: selectedCustomField.fieldName,
      referenceTable: field.referenceTable,
      values:
        selectedCustomField.fieldType == 6
          ? Array.prototype.map
            .call(customFieldListSelectedValues, function (item) {
              var returnitem: any;
              typeof item == "object"
                ? (returnitem = item.value)
                : (returnitem = item);
              return returnitem;
            })
            .join(",")
          : Array.prototype.map
            .call(customFieldTextValues, function (item) {
              var returnitem: any;
              typeof item == "object"
                ? (returnitem = item.value)
                : (returnitem = item);
              return returnitem;
            })
            .join(","),
    };

    return this.putJson(
      "/administration/dataAccessFields",
      JSON.stringify(body)
    );
  }

  public editDataAccessGroupRegulationGroups(
    regulationGroups: any,
    groupId: any
  ): Observable<any> {
    let regulationGroupIds = [],
      subGroupIds = [],
      hasPersmissions = [];

    for (var regulationGroup of regulationGroups) {
      regulationGroupIds.push(regulationGroup.id);
      subGroupIds.push(regulationGroup.subGroupId);
      hasPersmissions.push(regulationGroup.hasPermissions);
    }

    let body = {
      dataAccessGroupId: groupId,
      regulationGroupIds: regulationGroupIds,
      hasPermissions: hasPersmissions,
      subGroupIds: subGroupIds,
    };

    return this.postJson(
      "/administration/dataAccessGroup/" + groupId + "/regulationGroups",
      JSON.stringify(body)
    );
  }

  public editRegulationGroup(
    editRegulationGroup: FormGroup,
    restrictedCountries: any,
    restrictedCities: any,
    forbiddenCountries: any,
    keywords: any
  ): Observable<any> {
    let body = {
      id: editRegulationGroup.controls.id.value,
      name: editRegulationGroup.controls.name.value,
      status: editRegulationGroup.controls.status.value,
      authorityCountryId: editRegulationGroup.controls.authorityCountryId.value,
      currencyInformationId: editRegulationGroup.controls.currency.value,
      mappingReference: editRegulationGroup.controls.mappingReference.value,
      automaticUpdateGradesForRestrictedCountries:
        editRegulationGroup.controls.automaticUpdateGradesForRestrictedCountries
          .value,
      forbiddenCountries: forbiddenCountries,
      restrictedCountries: restrictedCountries,
      restrictedCities: restrictedCities,
      keywords: keywords,
    };

    return this.putJson("/settings/regulationGroups", JSON.stringify(body));
  }

  public editArrangementQuestionnaire(
    form: FormGroup,
    arrangementId: any,
    customFields: any
  ): Observable<any> {
    let body = {
      id: arrangementId,
      customFields: customFields,
      externalReference: form.controls.externalReference.value,
    };

    return this.putJson("/arrangements/questionnaire", JSON.stringify(body));
  }

  public editReportEngineSetup(
    editReportEngineSetup: FormGroup,
    resultProperties: any,
    usernamesToNotify: any,
    filters: any,
    directEmails
  ): Observable<any> {
    let body = {
      id: editReportEngineSetup.controls.id.value,
      regulationGroupId: editReportEngineSetup.controls.regulationGroupId.value,
      emailTemplateId: editReportEngineSetup.controls.emailTemplateId.value,
      name: editReportEngineSetup.controls.name.value,
      filterProperties: filters,
      directEmails: directEmails,
      resultProperties: resultProperties,
      usernamesToNotify: usernamesToNotify,
      jobScheduleStatus: editReportEngineSetup.controls.jobScheduleStatus.value,
      jobScheduleInterval:
        editReportEngineSetup.controls.jobScheduleInterval.value,
      jobScheduleValue: editReportEngineSetup.controls.jobScheduleValue.value,
      jobScheduleTime: editReportEngineSetup.controls.jobScheduleTime.value,
      jobScheduleStartDate:
        editReportEngineSetup.controls.jobScheduleStartDate.value,
    };

    return this.putJson("/settings/reportEngineSetups", JSON.stringify(body));
  }

  public editExternalCredentialSetup(
    externalCredentialSetup: any
  ): Observable<any> {
    let body = {
      id: externalCredentialSetup.id,
      providerName: externalCredentialSetup.providerName,
      url: externalCredentialSetup.url,
      healthCheckUrl: externalCredentialSetup.healthCheckUrl,
      authorizationType: externalCredentialSetup.authorizationType,
      ConnectionCredentialsObject:
        externalCredentialSetup.connectionCredentialsObject,
      disableSSLCertificateVerification:
        externalCredentialSetup.disableSSLCertificateVerification,
    };

    return this.putJson(
      "/administration/externalcredentialssetup",
      JSON.stringify(body)
    );
  }

  public editActionCategory(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      type: newData.type != undefined ? newData.type : oldData.type,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/actionCategories", JSON.stringify(body));
  }

  public editPurposeOfTransaction(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/purposeOfTransactions",
      JSON.stringify(body)
    );
  }

  public editActionStatus(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
      isVisibleInDashboard:
        newData.isVisibleInDashboard != undefined
          ? newData.isVisibleInDashboard
          : oldData.isVisibleInDashboard,
    };
    return this.putJson("/settings/actionStatuses", JSON.stringify(body));
  }

  public editRuleCategory(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/ruleCategories", JSON.stringify(body));
  }

  public editCriteriaCategory(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
      weightFactor:
        newData.weightFactor != undefined
          ? newData.weightFactor
          : oldData.weightFactor,
      riskCategory:
        newData.riskCategory != undefined
          ? newData.riskCategory
          : oldData.riskCategory,
    };
    return this.putJson("/settings/criteriaCategories", JSON.stringify(body));
  }

  public editDocumentCategory(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/documentCategories", JSON.stringify(body));
  }

  public editSubGroup(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      description:
        newData.description != undefined
          ? newData.description
          : oldData.description,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/subGroups", JSON.stringify(body));
  }

  public editCountryCategory(
    editCountryCategory: FormGroup,
    countryCategoryCountries: any
  ): Observable<any> {
    let body = {
      id: editCountryCategory.controls.id.value,
      name: editCountryCategory.controls.name.value,
      mappingReference: editCountryCategory.controls.mappingReference.value,
      countryCategoryCountries: countryCategoryCountries,
    };

    return this.putJson("/settings/countryCategories", JSON.stringify(body));
  }

  public editQuestionsCategory(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      questionnaireTypeId:
        newData.questionnaireTypeId != undefined
          ? newData.questionnaireTypeId
          : oldData.questionnaireTypeId,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
      weightFactor:
        newData.weightFactor != undefined
          ? newData.weightFactor
          : oldData.weightFactor,
    };
    return this.putJson("/settings/questionsCategories", JSON.stringify(body));
  }

  public editQuestionnaireGrade(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      scorePointFrom:
        newData.scorePointFrom != undefined
          ? newData.scorePointFrom
          : oldData.scorePointFrom,
      questionnaireTypeId:
        newData.questionnaireTypeId != undefined
          ? newData.questionnaireTypeId
          : oldData.questionnaireTypeId,
      scorePointTo:
        newData.scorePointTo != undefined
          ? newData.scorePointTo
          : oldData.scorePointTo,
      color: newData.color != undefined ? newData.color : oldData.color,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/questionnaireGrades", JSON.stringify(body));
  }

  public editQuestionnaireType(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      hasScoring:
        newData.hasScoring != undefined
          ? newData.hasScoring
          : oldData.hasScoring,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/questionnaireTypes", JSON.stringify(body));
  }

  public editCriteria(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      riskPoint:
        newData.riskPoint != undefined ? newData.riskPoint : oldData.riskPoint,
    };
    return this.putJson("/settings/editRowCriteria", JSON.stringify(body));
  }

  public editFirmSanctionCriteria(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      riskPoint:
        newData.riskPoint != undefined ? newData.riskPoint : oldData.riskPoint,
    };
    return this.putJson(
      "/settings/firms/editRowFirmSanctionCriterion",
      JSON.stringify(body)
    );
  }

  public editCriterion(
    editCriterionForm: FormGroup,
    criterion: any,
    integrationCapacities: any,
    clientCategories: any,
    excludedIntegrationCapacities
  ): Observable<any> {
    let body = {
      id: criterion.id,
      name: editCriterionForm.controls.name.value,
      requiresAnswerJustification:
        editCriterionForm.controls.requiresAnswerJustification.value,
      riskPoint: editCriterionForm.controls.riskPoint.value,
      bringScoringGradeHigh:
        editCriterionForm.controls.bringScoringGradeHigh.value,
      minimumEvaluationGrade:
        editCriterionForm.controls.minimumEvaluationGrade.value,
      regulationGroupsCriterion: criterion.regulationGroupsCriterion,
      criteriaCategoryId: editCriterionForm.controls.criteriaCategoryId.value,
      criterionEvaluationTypes: criterion.criterionEvaluationTypes,
      clientType: editCriterionForm.controls.clientType.value,
      status: editCriterionForm.controls.status.value,
      MappingReference: editCriterionForm.controls.mappingReference.value,
      integrationCapacitiesId: integrationCapacities,
      excludedIntegrationCapacitiesId: excludedIntegrationCapacities,
      clientCategoriesId: clientCategories,
      isEconomicProfile: editCriterionForm.controls.isEconomicProfile.value,
      minNumOfAnswers: editCriterionForm.controls.minNumOfAnswers.value,
      maxNumOfAnswers: editCriterionForm.controls.maxNumOfAnswers.value,
      includeInEvaluation: editCriterionForm.controls.includeInEvaluation.value,
      ignoreClientCategory:
        editCriterionForm.controls.ignoreClientCategory.value,
    };

    return this.putJson("/settings/criteria", JSON.stringify(body));
  }

  public editFirmSanctionCriterion(
    editCriterionForm: FormGroup
  ): Observable<any> {
    let body = {
      Id: editCriterionForm.controls.id.value,
      Name: editCriterionForm.controls.name.value,
      RiskPoint: editCriterionForm.controls.riskPoint.value,
      BringScoringGradeHigh:
        editCriterionForm.controls.bringScoringGradeHigh.value,
      FirmSanctionCriterionCategoryId:
        editCriterionForm.controls.firmSanctionCriterionCategoryId.value,
      Status: editCriterionForm.controls.status.value,
      MappingReference: editCriterionForm.controls.mappingReference.value,
      MinNumOfAnswers: editCriterionForm.controls.minNumOfAnswers.value,
      MaxNumOfAnswers: editCriterionForm.controls.maxNumOfAnswers.value,
    };

    return this.putJson(
      "/settings/firms/firmSanctionCriterion",
      JSON.stringify(body)
    );
  }

  public editQuestion(
    editQuestionForm: FormGroup,
    question: any
  ): Observable<any> {
    let body = {
      id: question.id,
      name: editQuestionForm.controls.name.value,
      regulationGroupsQuestion: question.regulationGroupsQuestion,
      questionsCategoryId: editQuestionForm.controls.questionsCategoryId.value,
      clientType: editQuestionForm.controls.clientType.value,
      status: editQuestionForm.controls.status.value,
      criterionId: editQuestionForm.controls.criterionId.value,
      mappingReference: editQuestionForm.controls.mappingReference.value,
      minNumOfAnswers: editQuestionForm.controls.minNumOfAnswers.value,
      maxNumOfAnswers: editQuestionForm.controls.maxNumOfAnswers.value,
      isRequired: editQuestionForm.controls.isRequired.value,
      isEconomicProfile: editQuestionForm.controls.isEconomicProfile.value,
      order: editQuestionForm.controls.order.value,
      riskPoint: !editQuestionForm.controls.riskPoint.value
        ? 0
        : editQuestionForm.controls.riskPoint.value,
    };

    return this.putJson("/settings/questions", JSON.stringify(body));
  }

  public editPaymentMethod(paymentMethodForm: FormGroup): Observable<any> {
    let body = {
      id: paymentMethodForm.controls.id.value,
      name: paymentMethodForm.controls.name.value,
      status: paymentMethodForm.controls.status.value,
      historicalRecordType:
        paymentMethodForm.controls.historicalRecordType.value,
      enhancedEconomicProfile:
        paymentMethodForm.controls.enhancedEconomicProfile.value,
      mappingReference: paymentMethodForm.controls.mappingReference.value,
    };

    return this.putJson("/settings/paymentMethods", JSON.stringify(body));
  }

  public editPaymentMethodVolume(
    newData: any,
    oldData: any,
    paymentMethodId: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      paymentMethodId: paymentMethodId,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };

    return this.putJson(
      `/settings/paymentMethods/${paymentMethodId}/volumes`,
      JSON.stringify(body)
    );
  }

  public generateSecurityHash(): Observable<any> {
    const dateNow = new Date();

    var day = dateNow.getDate();
    var month = dateNow.getMonth() + 1;
    var year = dateNow.getFullYear();

    var customDate = day + "/" + month + "/" + year;

    let body = {
      value: customDate,
    };

    return this.postJson("/initialization/securityHash", JSON.stringify(body));
  }

  public initializeDatabase(initDbForm: FormGroup): Observable<any> {
    let body = {
      Package: initDbForm.controls.package.value,
      hash: initDbForm.controls.hash.value,
    };

    return this.postJson("/initialization/applyPackage", JSON.stringify(body));
  }

  public createHashKey(
    createHashKeyForm: FormGroup,
    dynamicReports: any,
    rules: any
  ): Observable<any> {
    let body = {
      hashKeyType: createHashKeyForm.controls.hashKeyTypeEnum.value,
      password: createHashKeyForm.controls.password.value,

      numberOfRegulationGroups:
        createHashKeyForm.controls.numberOfRegulationGroups.value,
      dateOfExpiration: createHashKeyForm.controls.dateOfExpiration.value,

      dynamicReports: dynamicReports,
      dateOfDynamicReportsExpiration:
        createHashKeyForm.controls.dateOfDynamicReportsExpiration.value,

      rules: rules,
      dateOfRulesExpiration:
        createHashKeyForm.controls.dateOfRulesExpiration.value,
    };

    return this.postJson("/systemsettings/createHashKey", JSON.stringify(body));
  }

  public getCaseMLAnalysisOfLiveTransaction(
    liveTransactionId: number
  ): Observable<any> {
    return this.fetch(
      `/liveTransactions/${liveTransactionId}/case/ml-analysis`
    );
  }

  public getTransactionReferenceByOriginAndId(
    transactionId: number,
    type: string
  ): Observable<any> {
    return this.fetch(`/liveTransactions/${type}/${transactionId}/reference`);
  }

  public getLiveTransactionStatusHistory(
    clientCaseId: number
  ): Observable<any> {
    return this.fetch(`/liveTransactions/case/${clientCaseId}/status-history`);
  }

  public getLatestTransactionScreeningCaseByLiveTransactionExternalReference(
    liveTransactionExternalReference: string
  ): Observable<any> {
    const body = {
      externalReference: liveTransactionExternalReference,
    };

    return this.postJson(
      `/liveTransactions/latest-transaction-screening-case`,
      JSON.stringify(body)
    );
  }

  public GetTransactionScreeningCasePossibleMatchesCount(
    liveTransactionExternalReference: string
  ): Observable<any> {
    const body = {
      externalReference: liveTransactionExternalReference,
    };

    return this.postJson(
      `/liveTransactions/latest-transaction-screening-case/possible-match-count`,
      JSON.stringify(body)
    );
  }

  public getKYTDefaultFilters(): Observable<any> {
    return this.getCachedData(`/liveTransactions/default-filter`);
  }

  public getMachineLearningParameters(): Observable<any> {
    return this.fetch("/systemSettings/machineLearningParameters");
  }

  public isChainAnalysisActive(): Observable<any> {
    return this.fetch("/user/chainAnalysisStatus");
  }

  public isPowerBiActive(): Observable<any> {
    return this.fetch("/user/powerBiStatus");
  }

  public isDocumentVerificationActive(): Observable<any> {
    return this.fetch("/clientCommon/isDocumentVerificationActive");
  }

  public getUserByClientId(id: any): Observable<any> {
    return this.fetch(`/clientCommon/${id}/userDetails`);
  }

  public addMachineLearningParameters(
    addMachineLearningParametersForm: FormGroup
  ): Observable<any> {
    const controls = addMachineLearningParametersForm.controls;
    let body = {
      // ML Thresholds

      // Moved to Account
      // dowJonesFuzzynessIndividualThreshold:
      // controls.dowJonesFuzzynessIndividualThreshold.value,
      // dowJonesFuzzynessCorporateThreshold:
      // controls.dowJonesFuzzynessCorporateThreshold.value,

      individualNumberNearestN: controls.individualNumberNearestN.value,
      corporateNumberNearestN: controls.corporateNumberNearestN.value
    };

    return this.postJson(
      "/systemSettings/machineLearningParameters",
      JSON.stringify(body)
    );
  }

  public editCriterionAnswer(
    criterionId: string,
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      criterionId: criterionId,
      evaluationGradeId:
        newData.evaluationGradeId != undefined
          ? newData.evaluationGradeId
          : oldData.evaluationGradeId,
      value: newData.value != undefined ? newData.value : oldData.value,
      minimumValue:
        newData.minimumValue != undefined
          ? newData.minimumValue == "" || newData.minimumValue == null
            ? 0
            : newData.minimumValue
          : oldData.minimumValue == "" || oldData.minimumValue == null
            ? 0
            : oldData.minimumValue,
      maximumValue:
        newData.maximumValue != undefined
          ? newData.maximumValue == "" || newData.maximumValue == null
            ? 0
            : newData.maximumValue
          : oldData.maximumValue == "" || oldData.maximumValue == null
            ? 0
            : oldData.maximumValue,
      status: newData.status != undefined ? newData.status : oldData.status,
      isDefault:
        newData.isDefault != undefined ? newData.isDefault : oldData.isDefault,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/criteria/" + criterionId + "/answers",
      JSON.stringify(body)
    );
  }

  public editFirmSanctionCriterionAnswer(
    firmSanctionCriterionId: string,
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      firmSanctionCriterionId: firmSanctionCriterionId,
      firmSanctionImpactScoreId:
        newData.firmSanctionImpactScoreId != undefined
          ? newData.firmSanctionImpactScoreId
          : oldData.firmSanctionImpactScoreId,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      isDefault:
        newData.isDefault != undefined ? newData.isDefault : oldData.isDefault,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/firms/sanctionCriteria/" +
      firmSanctionCriterionId +
      "/answers",
      JSON.stringify(body)
    );
  }

  public editQuestionAnswer(
    questionId: string,
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      questionId: questionId,
      value: newData.value != undefined ? newData.value : oldData.value,
      minimumValue:
        newData.minimumValue != undefined
          ? newData.minimumValue == "" || newData.minimumValue == null
            ? 0
            : newData.minimumValue
          : oldData.minimumValue == "" || oldData.minimumValue == null
            ? 0
            : oldData.minimumValue,
      maximumValue:
        newData.maximumValue != undefined
          ? newData.maximumValue == "" || newData.maximumValue == null
            ? 0
            : newData.maximumValue
          : oldData.maximumValue == "" || oldData.maximumValue == null
            ? 0
            : oldData.maximumValue,
      score:
        newData.score != undefined
          ? newData.score == "" || newData.score == null
            ? 0
            : newData.score
          : oldData.score == "" || oldData.score == null
            ? 0
            : oldData.score,
      status: newData.status != undefined ? newData.status : oldData.status,
      isDefault:
        newData.isDefault != undefined ? newData.isDefault : oldData.isDefault,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/questions/" + questionId + "/answers",
      JSON.stringify(body)
    );
  }

  public editClientIndividualProfile(
    editProfileForm: FormGroup,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: editProfileForm.controls.clientId.value,
      clientStatusId: editProfileForm.controls.clientStatusId.value,
      ignoreAutoOngoingMonitoringStatus:
        editProfileForm.controls.ignoreAutoOngoingMonitoringStatus.value,
      clientCategoryId: editProfileForm.controls.clientCategoryId.value,
      regulationGroupId: editProfileForm.controls.regulationGroupId.value,
      joinedDate: editProfileForm.controls.joinedDate.value,
      leftDate: editProfileForm.controls.leftDate.value,
      notes: editProfileForm.controls.notes.value,
      firstName: editProfileForm.controls.firstName.value,
      lastName: editProfileForm.controls.lastName.value,
      middleName: editProfileForm.controls.middleName.value,
      externalReference:
        editProfileForm.controls.externalReference.value == ""
          ? null
          : editProfileForm.controls.externalReference.value,
      email: editProfileForm.controls.email.value,
      phone: editProfileForm.controls.phone.value,
      gender: editProfileForm.controls.gender.value,
      ssn: editProfileForm.controls.ssn.value,
      taxIdentificationNumber:
        editProfileForm.controls.taxIdentificationNumber.value,
      countryOfBirthId: editProfileForm.controls.countryOfBirthId.value,
      dateOfBirth: editProfileForm.controls.dateOfBirth.value,
      customFields: customFields,
      ipAddress: editProfileForm.controls.ipAddress.value,
      subGroupId: editProfileForm.controls.subGroupId.value,
    };

    return this.putJson("/clientIndividuals", JSON.stringify(body));
  }

  public editClientJointProfile(
    editProfileForm: FormGroup,
    clients: any,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: editProfileForm.controls.clientId.value,
      clientStatusId: editProfileForm.controls.clientStatusId.value,
      ignoreAutoOngoingMonitoringStatus:
        editProfileForm.controls.ignoreAutoOngoingMonitoringStatus.value,
      clientCategoryId: editProfileForm.controls.clientCategoryId.value,
      regulationGroupId: editProfileForm.controls.regulationGroupId.value,
      joinedDate: editProfileForm.controls.joinedDate.value,
      leftDate: editProfileForm.controls.leftDate.value,
      notes: editProfileForm.controls.notes.value,
      name: editProfileForm.controls.name.value,
      customFields: customFields,
      clientJointClientIds: clients,
      subGroupId: editProfileForm.controls.subGroupId.value,
      externalReference: editProfileForm.controls.externalReference.value,
    };

    return this.putJson("/clients/joints/", JSON.stringify(body));
  }

  public editClientFatca(editClientFatcaForm: FormGroup, clientId: any, fatcaDocuments: any, customFields: any): Observable<any> {
    let body = {
      "id": 0,
      "clientId": clientId,
      "fatcaStatusId": editClientFatcaForm.controls['fatcaStatusId'].value,
      "fatcaEntityCategorizationId": editClientFatcaForm.controls['fatcaEntityCategorizationId'].value,
      "fatcaCrsEntityCategorizationId": editClientFatcaForm.controls['fatcaCrsEntityCategorizationId'].value,
      "fatcaGiin": editClientFatcaForm.controls['fatcaGiin'].value,
      "fatcaIndicia": editClientFatcaForm.controls['fatcaIndicia'].value,
      "clientFatcaDocuments": fatcaDocuments,
      "customFields": customFields
    }

    return this.postJson("/clientCommon/" + clientId + "/fatca", JSON.stringify(body));
  }

  public editOngoingMonitoringClientStatuses(
    includeClientStatusIds: any,
    excludeClientStatusIds: any,
    includeWholeCorporateClientStructure: boolean
  ): Observable<any> {
    let body = {
      includeClientStatusIds: includeClientStatusIds,
      excludeClientStatusIds: excludeClientStatusIds,
      includeWholeCorporateClientStructure:
        includeWholeCorporateClientStructure,
    };

    return this.postJson(
      `/processes/ongoingMonitoringClientStatuses`,
      JSON.stringify(body)
    );
  }

  public editClientCorporateProfile(
    editProfileForm: FormGroup,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: editProfileForm.controls.clientId.value,
      clientStatusId: editProfileForm.controls.clientStatusId.value,
      ignoreAutoOngoingMonitoringStatus:
        editProfileForm.controls.ignoreAutoOngoingMonitoringStatus.value,
      clientCategoryId: editProfileForm.controls.clientCategoryId.value,
      regulationGroupId: editProfileForm.controls.regulationGroupId.value,
      joinedDate: editProfileForm.controls.joinedDate.value,
      leftDate: editProfileForm.controls.leftDate.value,
      notes: editProfileForm.controls.notes.value,
      externalReference:
        editProfileForm.controls.externalReference.value == ""
          ? null
          : editProfileForm.controls.externalReference.value,
      email: editProfileForm.controls.email.value,
      ipAddress: editProfileForm.controls.ipAddress.value,
      phone: editProfileForm.controls.phone.value,
      registeredName: editProfileForm.controls.registeredName.value,
      registrationNumber: editProfileForm.controls.registrationNumber.value,
      authorisedCapital: editProfileForm.controls.authorisedCapital.value,
      issuedCapital: editProfileForm.controls.issuedCapital.value,
      defaultFullStructureEvaluation:
        editProfileForm.controls.defaultFullStructureEvaluation.value,
      countryOfIncorporationId:
        editProfileForm.controls.countryOfIncorporationId.value,
      customFields: customFields,
      subGroupId: editProfileForm.controls.subGroupId.value,
    };

    return this.putJson("/clientCorporates", JSON.stringify(body));
  }

  public addClientApplication(
    addClientApplicationForm: FormGroup,
    clientId: number,
    attachments: any[]
  ): Observable<any> {
    let body = {
      clientId: clientId,
      purposeOfTransactionId:
        addClientApplicationForm.controls.purposeOfTransactionId.value,
      applicationReferenceNumber:
        addClientApplicationForm.controls.applicationReferenceNumber.value,
      approvedByUsername:
        addClientApplicationForm.controls.approvedByUsername.value,
      approvalDate: addClientApplicationForm.controls.approvalDate.value,
      comments: addClientApplicationForm.controls.comments.value,
      receiverEmail: addClientApplicationForm.controls.receiverEmail.value,
      sendEmail: addClientApplicationForm.controls.sendEmail.value,
      attachments: attachments,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/applications",
      JSON.stringify(body)
    );
  }

  public addClientRelatedWebsite(
    form: FormGroup,
    clientId: any,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      relatedWebsitetypeId: form.controls.relatedWebsitetypeId.value,
      website: form.controls.website.value,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/relatedWebsites",
      JSON.stringify(body)
    );
  }

  public addClientCertificate(form: FormGroup, clientId: any): Observable<any> {
    let body = {
      clientId: clientId,
      certificateNumber: form.controls.certificateNumber.value,
      certificateType: form.controls.certificateType.value,
      issueDate: form.controls.issueDate.value,
      cancellationDate: form.controls.cancellationDate.value,
      professionalIndemnityPolicy:
        form.controls.professionalIndemnityPolicy.value,
    };
    return this.postJson(
      `/clientCommon/${clientId}/certificates`,
      JSON.stringify(body)
    );
  }

  public addClientVideoIdentification(
    form: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      profileId: form.controls.profileId.value,
      isLegalRepresentative: form.controls.isLegalRepresentative.value || false,
    };
    return this.postJson(
      "/clientCorporates/" + clientId + "/videoIdentifications",
      JSON.stringify(body)
    );
  }

  public addClientAuthorizedPortalUser(
    form: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      firstName: form.controls.firstName.value,
      lastName: form.controls.lastName.value,
      email: form.controls.email.value,
      isActive: form.controls.isActive.value,
    };

    return this.postJson(
      `/clientCorporates/${clientId}/authorizedPortalUsers`,
      JSON.stringify(body)
    );
  }

  public editClientAuthorizedPortalUser(row: any, clientId): Observable<any> {
    let body = {
      id: row.id,
      clientId: row.clientId,
      isActive: row.isActive,
    };
    return this.putJson(
      `/clientCorporates/${clientId}/authorizedPortalUsers/${row.id}`,
      JSON.stringify(body)
    );
  }

  public deleteClientAuthorizedPortalUser(clientId, id): Observable<any> {
    return this.delete(
      `/clientCorporates/${clientId}/authorizedPortalUsers/${id}`,
      ""
    );
  }

  public checkIfClientElectronicIdentificationIsEnabled(
    form: any,
    clientId: any,
    isInitial: boolean
  ): Observable<any> {
    return this.fetch("/clientIndividuals/checkElectronicIdentifications");
  }

  public addClientIndividualElectronicIdentification(
    form: any,
    clientId: any,
    isInitial: boolean
  ): Observable<any> {
    var tokenType = form == null ? null : form.controls.tokenType.value;

    let body = {
      ClientId: clientId,
      IsInitial: isInitial,
      TokenType: tokenType == "" ? null : tokenType,
    };
    return this.postJson(
      "/clientIndividuals/electronicIdentifications",
      JSON.stringify(body)
    );
  }

  public addClientCorporateElectronicIdentification(
    form: any,
    ClientCorporateId: any,
    profileId: any
  ): Observable<any> {
    var tokenType = form == null ? null : form.controls.tokenType.value;

    let body = {
      ClientId: profileId,
      ClientCorporateId: ClientCorporateId,
      TokenType: tokenType == "" ? null : tokenType,
    };
    return this.postJson(
      "/clientCorporates/electronicIdentifications",
      JSON.stringify(body)
    );
  }

  public editClientRelatedWebsite(
    form: any,
    clientId: any,
    id: any,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      id: id,
      relatedWebsitetypeId: form.controls.relatedWebsiteTypeId.value,
      website: form.controls.website.value,
      customFields: customFields,
    };
    return this.putJson(
      "/clientCommon/" + clientId + "/relatedWebsites",
      JSON.stringify(body)
    );
  }

  public editClientCertificate(data: any, clientId): Observable<any> {
    let body = {
      id: data.id,
      clientId: clientId,
      certificateNumber: data.certificateNumber,
      certificateType: data.certificateType,
      issueDate: data.issueDate,
      cancellationDate: data.cancellationDate,
      professionalIndemnityPolicy: data.professionalIndemnityPolicy,
    };
    return this.putJson(
      `/clientCommon/${clientId}/certificates/${data.id}`,
      JSON.stringify(body)
    );
  }

  public addClientMidAccount(
    form: FormGroup,
    clientId: any,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      midClassId: form.controls.midClassId.value,
      midTypeId: form.controls.midTypeId.value,
      bankAccountId: form.controls.bankAccountId.value,
      name: form.controls.name.value,
      code: form.controls.code.value,
      currencyInformationId: form.controls.currency.value,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/midAccounts",
      JSON.stringify(body)
    );
  }

  public editClientMidAccount(
    form: any,
    clientId: any,
    id: any,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      id: id,
      midClassId: form.controls.midClassId.value,
      midTypeId: form.controls.midTypeId.value,
      bankAccountId: form.controls.bankAccountId.value,
      name: form.controls.name.value,
      code: form.controls.code.value,
      currencyInformationId: form.controls.currency.value,
      customFields: customFields,
    };
    return this.putJson(
      "/clientCommon/" + clientId + "/midAccounts",
      JSON.stringify(body)
    );
  }

  public generateMidCode(clientId: any, id: any): Observable<any> {
    let body = {
      clientId: clientId,
      id: id,
    };
    return this.putJson(
      "/clientCommon/" + clientId + "/midAccounts/code",
      JSON.stringify(body)
    );
  }

  public editClientApplication(
    editClientApplicationForm: FormGroup,
    clientId: number,
    applicationId: number,
    attachments: any[]
  ): Observable<any> {
    let body = {
      id: applicationId,
      clientId: clientId,
      purposeOfTransactionId:
        editClientApplicationForm.controls.purposeOfTransactionId.value,
      applicationReferenceNumber:
        editClientApplicationForm.controls.applicationReferenceNumber.value,
      approvedByUsername:
        editClientApplicationForm.controls.approvedByUsername.value,
      approvalDate: editClientApplicationForm.controls.approvalDate.value,
      comments: editClientApplicationForm.controls.comments.value,
      receiverEmail: editClientApplicationForm.controls.receiverEmail.value,
      sendEmail: editClientApplicationForm.controls.emailSent.value,
      attachments: attachments,
    };

    return this.putJson(
      "/clientCommon/" + clientId + "/applications",
      JSON.stringify(body)
    );
  }

  public editClientDocumentsCollectionStatus(
    clientId: any,
    collectionStatus: any
  ): Observable<any> {
    return this.put(`/clientCommon/${clientId}/documents/${collectionStatus}`);
  }

  public editClientDocument(
    editClientDocumentForm: FormGroup,
    clientId: any,
    clientDocumentId: any,
    documentId: any,
    attachments: any[],
    customFields: any
  ): Observable<any> {
    // Id Document
    if (
      editClientDocumentForm.controls.idFrontFileBase64.value != null &&
      editClientDocumentForm.controls.idFrontFileBase64.value != ""
    ) {
      let base64String = editClientDocumentForm.controls.idFrontFileBase64.value
        ? editClientDocumentForm.controls.idFrontFileBase64.value
        : "";

      let base64Data = base64String.split(",")[1];
      let base64Type = base64String.split(",")[0];

      let type = 0;

      if (base64Type.includes("image/png")) {
        type = 1;
      } else if (base64Type.includes("image/jpeg")) {
        type = 2;
      } else if (base64Type.includes("application/pdf")) {
        type = 3;
      } else if (base64Type.includes("text/plain")) {
        type = 4;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        type = 5;
      } else if (base64Type.includes("application/msword")) {
        type = 6;
      } else if (base64Type.includes("application/vnd.ms-excel")) {
        type = 7;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        type = 8;
      } else if (base64Type.includes("video/mp4")) {
        type = 11;
      } else if (base64Type.includes("video/webm")) {
        type = 12;
      } else {
        type = 1;
      }

      let attachment = {
        idCardType: 1,
        fileName: editClientDocumentForm.controls.idFrontFileName.value,
        type: type,
        data: base64Data,
      };
      attachments.push(attachment);
    }

    if (
      editClientDocumentForm.controls.idBackFileBase64.value != null &&
      editClientDocumentForm.controls.idBackFileBase64.value != ""
    ) {
      let base64String = editClientDocumentForm.controls.idBackFileBase64.value
        ? editClientDocumentForm.controls.idBackFileBase64.value
        : "";

      let base64Data = base64String.split(",")[1];
      let base64Type = base64String.split(",")[0];

      let type = 0;

      if (base64Type.includes("image/png")) {
        type = 1;
      } else if (base64Type.includes("image/jpeg")) {
        type = 2;
      } else if (base64Type.includes("application/pdf")) {
        type = 3;
      } else if (base64Type.includes("text/plain")) {
        type = 4;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        type = 5;
      } else if (base64Type.includes("application/msword")) {
        type = 6;
      } else if (base64Type.includes("application/vnd.ms-excel")) {
        type = 7;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        type = 8;
      } else if (base64Type.includes("video/mp4")) {
        type = 11;
      } else if (base64Type.includes("video/webm")) {
        type = 12;
      } else {
        type = 1;
      }

      let attachment = {
        idCardType: 2,
        fileName: editClientDocumentForm.controls.idBackFileName.value,
        type: type,
        data: base64Data,
      };
      attachments.push(attachment);
    }

    if (
      editClientDocumentForm.controls.idSelfieFileBase64.value != null &&
      editClientDocumentForm.controls.idSelfieFileBase64.value != ""
    ) {
      let base64String = editClientDocumentForm.controls.idSelfieFileBase64
        .value
        ? editClientDocumentForm.controls.idSelfieFileBase64.value
        : "";

      let base64Data = base64String.split(",")[1];
      let base64Type = base64String.split(",")[0];

      let type = 0;

      if (base64Type.includes("image/png")) {
        type = 1;
      } else if (base64Type.includes("image/jpeg")) {
        type = 2;
      } else if (base64Type.includes("application/pdf")) {
        type = 3;
      } else if (base64Type.includes("text/plain")) {
        type = 4;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        type = 5;
      } else if (base64Type.includes("application/msword")) {
        type = 6;
      } else if (base64Type.includes("application/vnd.ms-excel")) {
        type = 7;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        type = 8;
      } else if (base64Type.includes("video/mp4")) {
        type = 11;
      } else if (base64Type.includes("video/webm")) {
        type = 12;
      } else {
        type = 1;
      }

      let attachment = {
        idCardType: 3,
        fileName: editClientDocumentForm.controls.idSelfieFileName.value,
        type: type,
        data: base64Data,
      };
      attachments.push(attachment);
    }

    // Passport Document

    if (
      editClientDocumentForm.controls.passportFileBase64.value != null &&
      editClientDocumentForm.controls.passportFileBase64.value != ""
    ) {
      let base64String = editClientDocumentForm.controls.passportFileBase64
        .value
        ? editClientDocumentForm.controls.passportFileBase64.value
        : "";

      let base64Data = base64String.split(",")[1];
      let base64Type = base64String.split(",")[0];

      let type = 0;

      if (base64Type.includes("image/png")) {
        type = 1;
      } else if (base64Type.includes("image/jpeg")) {
        type = 2;
      } else if (base64Type.includes("application/pdf")) {
        type = 3;
      } else if (base64Type.includes("text/plain")) {
        type = 4;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        type = 5;
      } else if (base64Type.includes("application/msword")) {
        type = 6;
      } else if (base64Type.includes("application/vnd.ms-excel")) {
        type = 7;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        type = 8;
      } else if (base64Type.includes("video/mp4")) {
        type = 11;
      } else if (base64Type.includes("video/webm")) {
        type = 12;
      } else {
        type = 1;
      }

      let attachment = {
        fileName: editClientDocumentForm.controls.passportFileName.value,
        type: type,
        data: base64Data,
      };
      attachments.push(attachment);
    }

    // Driving License Document
    if (
      editClientDocumentForm.controls.drivingLicenseFrontFileBase64.value !=
      null &&
      editClientDocumentForm.controls.drivingLicenseFrontFileBase64.value != ""
    ) {
      let base64String = editClientDocumentForm.controls
        .drivingLicenseFrontFileBase64.value
        ? editClientDocumentForm.controls.drivingLicenseFrontFileBase64.value
        : "";

      let base64Data = base64String.split(",")[1];
      let base64Type = base64String.split(",")[0];

      let type = 0;

      if (base64Type.includes("image/png")) {
        type = 1;
      } else if (base64Type.includes("image/jpeg")) {
        type = 2;
      } else if (base64Type.includes("application/pdf")) {
        type = 3;
      } else if (base64Type.includes("text/plain")) {
        type = 4;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        type = 5;
      } else if (base64Type.includes("application/msword")) {
        type = 6;
      } else if (base64Type.includes("application/vnd.ms-excel")) {
        type = 7;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        type = 8;
      } else {
        type = 1;
      }

      let attachment = {
        drivingLicenseType: 1,
        fileName:
          editClientDocumentForm.controls.drivingLicenseFrontFileName.value,
        type: type,
        data: base64Data,
      };
      attachments.push(attachment);
    }

    if (
      editClientDocumentForm.controls.drivingLicenseBackFileBase64.value !=
      null &&
      editClientDocumentForm.controls.drivingLicenseBackFileBase64.value != ""
    ) {
      let base64String = editClientDocumentForm.controls
        .drivingLicenseBackFileBase64.value
        ? editClientDocumentForm.controls.drivingLicenseBackFileBase64.value
        : "";

      let base64Data = base64String.split(",")[1];
      let base64Type = base64String.split(",")[0];

      let type = 0;

      if (base64Type.includes("image/png")) {
        type = 1;
      } else if (base64Type.includes("image/jpeg")) {
        type = 2;
      } else if (base64Type.includes("application/pdf")) {
        type = 3;
      } else if (base64Type.includes("text/plain")) {
        type = 4;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        type = 5;
      } else if (base64Type.includes("application/msword")) {
        type = 6;
      } else if (base64Type.includes("application/vnd.ms-excel")) {
        type = 7;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        type = 8;
      } else {
        type = 1;
      }

      let attachment = {
        drivingLicenseType: 2,
        fileName:
          editClientDocumentForm.controls.drivingLicenseBackFileName.value,
        type: type,
        data: base64Data,
      };
      attachments.push(attachment);
    }

    // Attachments

    if (
      editClientDocumentForm.controls.attachmentFileBase64.value != null &&
      editClientDocumentForm.controls.attachmentFileBase64.value != ""
    ) {
      let base64String = editClientDocumentForm.controls.attachmentFileBase64
        .value
        ? editClientDocumentForm.controls.attachmentFileBase64.value
        : "";

      let base64Data = base64String.split(",")[1];
      let base64Type = base64String.split(",")[0];

      let type = 0;

      if (base64Type.includes("image/png")) {
        type = 1;
      } else if (base64Type.includes("image/jpeg")) {
        type = 2;
      } else if (base64Type.includes("application/pdf")) {
        type = 3;
      } else if (base64Type.includes("text/plain")) {
        type = 4;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        type = 5;
      } else if (base64Type.includes("application/msword")) {
        type = 6;
      } else if (base64Type.includes("application/vnd.ms-excel")) {
        type = 7;
      } else if (
        base64Type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        type = 8;
      } else if (base64Type.includes("video/mp4")) {
        type = 11;
      } else if (base64Type.includes("video/webm")) {
        type = 12;
      } else {
        type = 1;
      }

      let attachment = {
        fileName: editClientDocumentForm.controls.attachmentFileName.value,
        type: type,
        data: base64Data,
      };
      attachments.push(attachment);
    }

    let body = {
      id: clientDocumentId,
      clientId: clientId,
      documentId: documentId,
      collectionStatus:
        editClientDocumentForm.controls.collectionStatusId.value,
      verificationStatus:
        editClientDocumentForm.controls.verificationStatusId != undefined
          ? editClientDocumentForm.controls.verificationStatusId.value
          : "",
      verificationMessage:
        editClientDocumentForm.controls.verificationMessage != undefined
          ? editClientDocumentForm.controls.verificationMessage.value
          : "",
      documentForm: editClientDocumentForm.controls.documentFormId.value,
      documentNumber: editClientDocumentForm.controls.documentNumber.value,
      countryOfIssueId: editClientDocumentForm.controls.countryOfIssueId.value,
      dateOfIssue: editClientDocumentForm.controls.dateOfIssue.value,
      expirationDate: editClientDocumentForm.controls.expirationDate.value,
      collectionDate: editClientDocumentForm.controls.collectionDate.value,
      nextCollectionDate:
        editClientDocumentForm.controls.nextCollectionDate.value,
      certifiedDate: editClientDocumentForm.controls.certifiedDate.value,
      certifiedByUsername:
        editClientDocumentForm.controls.certifiedByUsername.value != ""
          ? editClientDocumentForm.controls.certifiedByUsername.value
          : null,
      reviewedByUsername:
        editClientDocumentForm.controls.reviewedByUsername.value != ""
          ? editClientDocumentForm.controls.reviewedByUsername.value
          : null,
      attachments: attachments,
      mandatory: editClientDocumentForm.controls.mandatory.value,
      directoryPathOrUrl:
        editClientDocumentForm.controls.directoryPathOrUrl.value,
      customFields: customFields,
      comment: editClientDocumentForm.controls.comment.value,
    };

    return this.putJson(
      `/clientCommon/${clientId}/documents`,
      JSON.stringify(body)
    );
  }

  public getClientDocumentNextCollectionDate(
    editClientDocumentForm: FormGroup,
    clientId: number
  ): Observable<any> {
    let body = {
      ClientId: clientId,
      CollectionDate: editClientDocumentForm.controls.collectionDate.value,
      NextCollectionDate:
        editClientDocumentForm.controls.nextCollectionDate.value,
    };
    return this.postJson(
      `/clientCommon/${clientId}/documentNextCollectionDate`,
      JSON.stringify(body)
    );
  }

  public editPersonSearchResultsStatus(
    clientId: any,
    personSearchProfileId: any,
    status: any,
    notes: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      personSearchResultId: personSearchProfileId,
      status: status,
      notes: notes,
    };

    return this.putJson(
      `/clientIndividuals/${clientId}/personSearchResults`,
      JSON.stringify(body)
    );
  }

  public editTransactionScreeningCasePersonSearchResultsStatus(
    clientId: any,
    personSearchProfileId: any,
    status: any,
    notes: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      personSearchResultId: personSearchProfileId,
      status: status,
      notes: notes,
    };

    return this.putJson(
      `/clientIndividuals/transactionScreeningCasePersonSearchResultStatus`,
      JSON.stringify(body)
    );
  }

  public editTransactionScreeningCaseBusinessSearchResultsStatus(
    clientId: any,
    businessSearchProfileId: any,
    status: any,
    notes: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      businessSearchResultId: businessSearchProfileId,
      status: status,
      notes: notes,
    };

    return this.putJson(
      `/clientCorporates/transactionScreeningCaseBusinessSearchResultStatus`,
      JSON.stringify(body)
    );
  }

  public editTransactionScreeningCaseInternalBlacklistResultsStatus(
    id: any,
    status: any
  ): Observable<any> {
    let body = {
      id: id,
      status: status,
    };

    return this.putJson(
      `/clientIndividuals/transactionScreeningCaseInternalBlacklistResultStatus`,
      JSON.stringify(body)
    );
  }

  public editTransactionScreeningCaseInternalBlacklistCorporateResultsStatus(
    id: any,
    status: any
  ): Observable<any> {
    let body = {
      id: id,
      status: status,
    };

    return this.putJson(
      `/clientCorporates/transactionScreeningCaseInternalBlacklistCorporateResultStatus`,
      JSON.stringify(body)
    );
  }

  public editTransactionScreeningCaseBusinessSearchResultsStatusList(
    selectedMatches: number[]
  ): Observable<any> {
    let body = {
      searchResultIDs: selectedMatches,
    };

    return this.putJson(
      `/clientCorporates/transactionScreeningCaseBusinessSearchResultsStatusList`,
      JSON.stringify(body)
    );
  }

  public editPersonSearchResultsStatusBulk(clientId: any): Observable<any> {
    let body = {};

    return this.putJson(
      `/clientIndividuals/${clientId}/personSearchResultsBulk`,
      JSON.stringify(body)
    );
  }

  public editPersonSearchResultsStatusList(
    clientId: any,
    selectedMatches: any
  ): Observable<any> {
    let body = {
      searchResultIDs: selectedMatches,
    };

    return this.putJson(
      `/clientIndividuals/${clientId}/personSearchResultsList`,
      JSON.stringify(body)
    );
  }

  public editTransactionScreeningCasePersonSearchResultsStatusList(
    selectedMatches: number[]
  ): Observable<any> {
    let body = {
      searchResultIDs: selectedMatches,
    };

    return this.putJson(
      `/clientIndividuals/transactionScreeningCasePersonSearchResultsStatusList`,
      JSON.stringify(body)
    );
  }

  public editTransactionScreeningCaseBlacklistResultsStatusList(
    selectedMatches: number[]
  ): Observable<any> {
    let body = {
      searchResultIDs: selectedMatches,
    };

    return this.putJson(
      `/clientIndividuals/transactionScreeningCaseBlacklistResultsStatusList`,
      JSON.stringify(body)
    );
  }

  public editTransactionScreeningCaseBlacklistCorporateResultsStatusList(
    selectedMatches: number[]
  ): Observable<any> {
    let body = {
      searchResultIDs: selectedMatches,
    };

    return this.putJson(
      `/clientCorporates/transactionScreeningCaseBlacklistCorporateResultsStatusList`,
      JSON.stringify(body)
    );
  }

  public markCompanySearchResultAsMatch(
    clientId,
    clientCompanySearchId,
    clientCompanySearchResultId,
    cost
  ): Observable<any> {
    let body = {
      clientCompanySearchId: clientCompanySearchId,
      clientCompanySearchResultId: clientCompanySearchResultId,
      cost: cost,
    };

    return this.putJson(
      `/clientCorporates/${clientId}/companySearchResults/match`,
      JSON.stringify(body)
    );
  }

  public finalizeCompanySearchOrder(
    clientId,
    clientCompanySearchId
  ): Observable<any> {
    let body = {
      clientCompanySearchId: clientCompanySearchId,
    };

    return this.putJson(
      `/clientCorporates/${clientId}/companySearches/finalize`,
      JSON.stringify(body)
    );
  }

  public saveCompanySearchOrder(
    clientId,
    clientCompanySearchId,
    datasetValue,
    documentsState
  ): Observable<any> {
    let body = {
      clientCompanySearchId: clientCompanySearchId,
      datasetValue: datasetValue,
      documentsState: documentsState,
    };

    return this.putJson(
      `/clientCorporates/${clientId}/companySearches/save`,
      JSON.stringify(body)
    );
  }

  public editBusinessSearchResultsStatus(
    clientId: any,
    businessSearchProfileId: any,
    status: any,
    notes: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      businessSearchResultId: businessSearchProfileId,
      status: status,
      notes: notes,
    };

    return this.putJson(
      `/clientCorporates/${clientId}/businessSearchResults`,
      JSON.stringify(body)
    );
  }

  public editSearchResultsToChecked(
    searchResultProfiles: {
      clientId: number;
      profileId: number;
      clientType: number;
    }[]
  ): Observable<any> {
    let body = {
      searchResultProfiles: searchResultProfiles,
    };

    return this.putJson(
      `/clientCommon/searchResults/checked`,
      JSON.stringify(body)
    );
  }

  public editBusinessSearchResultsStatusBulk(clientId: any): Observable<any> {
    let body = {};

    return this.putJson(
      `/clientCorporates/${clientId}/businessSearchResultsBulk`,
      JSON.stringify(body)
    );
  }

  public editBusinessSearchResultsStatusList(
    clientId: any,
    selectedMatches: any
  ): Observable<any> {
    let body = {
      searchResultIDs: selectedMatches,
    };

    return this.putJson(
      `/clientCorporates/${clientId}/businessSearchResultsList`,
      JSON.stringify(body)
    );
  }

  public editpersonSearchMonitoringResultsStatus(
    clientId: any,
    personSearchMonitoringProfileId: any,
    status: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      personSearchMonitoringResultId: personSearchMonitoringProfileId,
      status: status,
    };

    return this.putJson(
      `/clientCommon/${clientId}/personSearchMonitoringResults`,
      JSON.stringify(body)
    );
  }

  public editClientInvestmentAccount(
    editClientInvestmentAccountForm: FormGroup,
    clientId: number,
    investmentAccountId: number,
    customFields: any
  ): Observable<any> {
    let controls = editClientInvestmentAccountForm.controls;

    let body = {
      Id: investmentAccountId,
      ClientId: clientId,
      AccountName: controls.accountName.value,
      AccountNumber: controls.accountNumber.value,
      tradingAccount: controls.tradingAccount.value,
      AccountTypeId: controls.accountType.value,
      AccountStatus: controls.accountStatus.value,
      GroupName: controls.groupName.value,
      Balance: controls.balance.value,
      CurrencyInformationId: controls.currency.value,
      PreviousDayEquity: controls.previousDayEquity.value,
      ExternalReference:
        controls.externalReference.value == ""
          ? null
          : controls.externalReference.value,
      Comment: controls.comment.value,
      dateCreated: controls.dateCreated.value,
      dateClosed: controls.dateClosed.value,
      dateDeleted: controls.dateDeleted.value,
      customFields: customFields,
    };

    return this.putJson(
      "/clientCommon/" + clientId + "/investmentAccounts",
      JSON.stringify(body)
    );
  }

  public editClientInternalScreenNotificiation(
    internalNotification: any
  ): Observable<any> {
    let body = {
      Id: internalNotification.id,
      MarkAsViewed: true,
    };

    return this.putJson(
      "/clientCommon/" +
      internalNotification.clientId +
      "/internalScreenNotifications",
      JSON.stringify(body)
    );
  }

  public updateRuleStatus(selectedRules: any, status: any): Observable<any> {
    let body = {
      ruleIds: selectedRules,
      status: status,
    };
    return this.postJson("/processes/updateRuleStatus", JSON.stringify(body));
  }

  public updateRuleSchedule(
    selectedRules: any,
    jobScheduleForm: FormGroup
  ): Observable<any> {
    let body = {
      ruleIds: selectedRules,
      jobScheduleInterval: jobScheduleForm.controls.jobScheduleInterval.value,
      jobScheduleValue: jobScheduleForm.controls.jobScheduleValue.value,
      jobScheduleTime: jobScheduleForm.controls.jobScheduleTime.value,
      jobScheduleStartDate: jobScheduleForm.controls.jobScheduleStartDate.value,
    };
    return this.postJson("/processes/updateRuleSchedule", JSON.stringify(body));
  }

  public addElectronicIdentificationProcess(
    clients: any[],
    form: FormGroup
  ): Observable<any> {
    var tokenType = form == null ? null : form.controls.tokenType.value;

    let body = {
      clientIds: clients,
      tokenType: tokenType,
    };

    return this.postJson(
      "/processes/electronicIdentifications",
      JSON.stringify(body)
    );
  }

  public executeRules(selectedRulesId: number[]): Observable<any> {
    let body = {
      ruleIds: selectedRulesId,
    };
    return this.postJson(
      "/processes/execute-recurring-rules",
      JSON.stringify(body)
    );
  }

  public editRule(editRuleForm: FormGroup, rule: any): Observable<any> {
    let body = {
      id: rule.id,
      name: editRuleForm.controls.name.value,
      description: editRuleForm.controls.description.value,
      type: editRuleForm.controls.type.value,
      origin: editRuleForm.controls.origin.value,
      status: editRuleForm.controls.status.value,
      casesCreationFlagPolicy:
        editRuleForm.controls.casesCreationFlagPolicy.value,
      tuningQuery: editRuleForm.controls.tuningQuery.value,
      tuningSelectQuery: editRuleForm.controls.tuningSelectQuery.value,
      query: editRuleForm.controls.query.value,
      regulationGroupsRule: rule.regulationGroupsRule,
      jobScheduleInterval: editRuleForm.controls.jobScheduleInterval.value,
      jobScheduleValue: editRuleForm.controls.jobScheduleValue.value,
      jobScheduleTime: editRuleForm.controls.jobScheduleTime.value,
      jobScheduleStartDate: editRuleForm.controls.jobScheduleStartDate.value,
      autoCloseCases: editRuleForm.controls.autoCloseCases.value,
      ruleCategoryId: editRuleForm.controls.ruleCategoryId.value,
      statusFilter: editRuleForm.controls.statusFilter.value,
      includeDeletedClients: editRuleForm.controls.includeDeletedClients.value,
      includeArchivedClients:
        editRuleForm.controls.includeArchivedClients.value,
      includeNonClients: editRuleForm.controls.includeNonClients.value,
      manualCreateCases: editRuleForm.controls.manualCreateCases.value,
      freezeCases: editRuleForm.controls.freezeCases.value,
      referenceCode: editRuleForm.controls.referenceCode.value,
      isMachineLearningRule: editRuleForm.controls.isMachineLearningRule.value,
      packages: editRuleForm.controls.packages.value,
      ruleLogic: editRuleForm.controls.ruleLogic.value,
      requiredFields: editRuleForm.controls.requiredFields.value,
    };
    return this.putJson("/administration/rules", JSON.stringify(body));
  }

  public editSystemDynamicReport(
    editDynamicReportForm: FormGroup,
    dynamicReport: any
  ): Observable<any> {
    let body = {
      id: dynamicReport.id,
      name: editDynamicReportForm.controls.name.value,
      origin: editDynamicReportForm.controls.origin.value,
      referenceCode: editDynamicReportForm.controls.referenceCode.value,
      description: editDynamicReportForm.controls.description.value,
      status: editDynamicReportForm.controls.status.value,
      query: editDynamicReportForm.controls.query.value,
      saveOnHistory: editDynamicReportForm.controls.saveOnHistory.value,
      instructions: editDynamicReportForm.controls.instructions.value,
    };
    return this.putJson(
      "/administration/systemDynamicReports",
      JSON.stringify(body)
    );
  }

  public editIdVerificationDataSupport(
    idVerificationDataSupport: any
  ): Observable<any> {
    let body = {
      id: idVerificationDataSupport.id,
      countryId: idVerificationDataSupport.countryId,
      nameLevel: idVerificationDataSupport.nameLevel,
      addressLevel: idVerificationDataSupport.addressLevel,
      localityLevel: idVerificationDataSupport.localityLevel,
      dateOfBirthLevel: idVerificationDataSupport.dateOfBirthLevel,
      nationalIdLevel: idVerificationDataSupport.nationalIdLevel,
      phoneLevel: idVerificationDataSupport.phoneLevel,
      emailLevel: idVerificationDataSupport.emailLevel,
      notes: idVerificationDataSupport.notes,
    };

    return this.putJson(
      "/systemSettings/idVerificationDataSupports",
      JSON.stringify(body)
    );
  }

  public editSystemNotificationTemplate(systemNotificationTemplate: FormGroup, notificationTemplateId: any): Observable<any> {
    let body = {
      id: notificationTemplateId,
      title: systemNotificationTemplate.controls['title'].value,
      description: systemNotificationTemplate.controls['description'].value,
      endpointUrl: systemNotificationTemplate.controls['endpointUrl'].value,
      endpointHeader: systemNotificationTemplate.controls['endpointHeader'].value,
      endpointBody: systemNotificationTemplate.controls['endpointBody'].value,
      method: systemNotificationTemplate.controls['method'].value,
      unescapeBody: systemNotificationTemplate.controls['unescapeBody'].value,
      mappingReference: systemNotificationTemplate.controls['mappingReference'].value,
    };

    return this.putJson("/settings/notificationTemplate", JSON.stringify(body));
  }

  public editNotificationTemplate(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      title: newData.title != undefined ? newData.title : oldData.title,
      description:
        newData.description != undefined
          ? newData.description
          : oldData.description,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/notificationTemplate", JSON.stringify(body));
  }

  public editTuningRuleParameter(
    editTuningRuleParameterForm: FormGroup,
    ruleId: string
  ): Observable<any> {
    let body = {
      id: editTuningRuleParameterForm.controls.id.value,
      ruleId: ruleId,
      paramName: editTuningRuleParameterForm.controls.paramName.value,
      name: editTuningRuleParameterForm.controls.name.value,
      type: editTuningRuleParameterForm.controls.type.value,
      referenceTable: editTuningRuleParameterForm.controls.referenceTable.value,
    };
    return this.putJson(
      "/administration/rules/" + ruleId + "/tuning/parameters",
      JSON.stringify(body)
    );
  }

  public editTuningDynamicReportParameter(
    editTuningDynamicReportParameterForm: FormGroup,
    dynamicReportId: string
  ): Observable<any> {
    let body = {
      id: editTuningDynamicReportParameterForm.controls.id.value,
      dynamicReportId: dynamicReportId,
      paramName: editTuningDynamicReportParameterForm.controls.paramName.value,
      name: editTuningDynamicReportParameterForm.controls.name.value,
      type: editTuningDynamicReportParameterForm.controls.type.value,
      referenceTable:
        editTuningDynamicReportParameterForm.controls.referenceTable.value,
      parameterQueryId:
        editTuningDynamicReportParameterForm.controls.parameterQueryId.value,
    };
    return this.putJson(
      "/administration/systemDynamicReports/" +
      dynamicReportId +
      "/tuning/parameters",
      JSON.stringify(body)
    );
  }

  public editTuningRuleParameterExecution(
    newData: any,
    oldData: any,
    ruleId: string
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      ruleId: newData.ruleId != undefined ? newData.ruleId : oldData.ruleId,
      tuningRuleParameterId:
        newData.tuningRuleParameterId != undefined
          ? newData.tuningRuleParameterId
          : oldData.tuningRuleParameterId,
      executionName:
        newData.executionName != undefined
          ? newData.executionName
          : oldData.executionName,
      value: newData.value != undefined ? newData.value : oldData.value,
      liveTransactionStatus:
        newData.liveTransactionStatus != undefined
          ? newData.liveTransactionStatus
          : oldData.liveTransactionStatus,
      weight: newData.weight != undefined ? newData.weight : oldData.weight,
      autoCalculatedThresholds:
        newData.autoCalculatedThresholds != undefined
          ? newData.autoCalculatedThresholds
          : oldData.autoCalculatedThresholds,
    };

    return this.putJson(
      "/administration/rules/" + ruleId + "/tuning/executions",
      JSON.stringify(body)
    );
  }

  public editQueryBasedSetupParameterExecution(
    newData: any,
    oldData: any,
    queryBasedSetupId: string
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      queryBasedSetupId:
        newData.queryBasedSetupId != undefined
          ? newData.queryBasedSetupId
          : oldData.queryBasedSetupId,
      queryBasedSetupParameterId:
        newData.queryBasedSetupParameterId != undefined
          ? newData.queryBasedSetupParameterId
          : oldData.queryBasedSetupParameterId,
      executionName:
        newData.executionName != undefined
          ? newData.executionName
          : oldData.executionName,
      value: newData.value != undefined ? newData.value : oldData.value,
    };

    return this.putJson(
      "/administration/queryBasedSetups/" +
      queryBasedSetupId +
      "/tuning/executions",
      JSON.stringify(body)
    );
  }

  public editTuningDynamicReportParameterExecution(
    newData: any,
    oldData: any,
    dynamicReportId: string
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      dynamicReportId:
        newData.dynamicReportId != undefined
          ? newData.dynamicReportId
          : oldData.dynamicReportId,
      tuningDynamicReportParameterId:
        newData.tuningDynamicReportParameterId != undefined
          ? newData.tuningDynamicReportParameterId
          : oldData.tuningDynamicReportParameterId,
      executionName:
        newData.executionName != undefined
          ? newData.executionName
          : oldData.executionName,
      value: newData.value != undefined ? newData.value : oldData.value,
    };

    return this.putJson(
      "/administration/systemDynamicReports/" +
      dynamicReportId +
      "/tuning/executions",
      JSON.stringify(body)
    );
  }

  public editSystemAccount(
    editSystemAccountForm: FormGroup,
    editSystemAccountSettingsForm: FormGroup
  ): Observable<any> {
    let body = {
      id: editSystemAccountForm.controls.id.value,
      iKYCStatus: editSystemAccountForm.controls.iKYCStatus.value,
      iKYCEndPointLive: editSystemAccountForm.controls.iKYCEndPointLive.value,
      iKYCEndPointDemo: editSystemAccountForm.controls.iKYCEndPointDemo.value,
      iKYCTypeLive: editSystemAccountForm.controls.iKYCTypeLive.value,
      iKYCTypeDemo: editSystemAccountForm.controls.iKYCTypeDemo.value,
      iKYCClientIdLive: editSystemAccountForm.controls.iKYCClientIdLive.value,
      iKYCClientIdDemo: editSystemAccountForm.controls.iKYCClientIdDemo.value,
      iKYCClientSecretLive:
        editSystemAccountForm.controls.iKYCClientSecretLive.value,
      iKYCClientSecretDemo:
        editSystemAccountForm.controls.iKYCClientSecretDemo.value,
      iKYCMlEnabledLive: editSystemAccountForm.controls.iKYCMlEnabledLive.value,
      iKYCMlEnabledDemo: editSystemAccountForm.controls.iKYCMlEnabledDemo.value,
      iKYCMlSyncBatchSize:
        editSystemAccountForm.controls.iKYCMlSyncBatchSize.value,
      idNowStatus: editSystemAccountForm.controls.idNowStatus.value,
      idNowEndPointLive: editSystemAccountForm.controls.idNowEndPointLive.value,
      idNowEndPointDemo: editSystemAccountForm.controls.idNowEndPointDemo.value,
      idNowCustomerLive: editSystemAccountForm.controls.idNowCustomerLive.value,
      idNowCustomerDemo: editSystemAccountForm.controls.idNowCustomerDemo.value,
      idNowApiKeyLive: editSystemAccountForm.controls.idNowApiKeyLive.value,
      idNowApiKeyDemo: editSystemAccountForm.controls.idNowApiKeyDemo.value,
      idNowVerificationUrlLive:
        editSystemAccountForm.controls.idNowVerificationUrlLive.value,
      idNowVerificationUrlDemo:
        editSystemAccountForm.controls.idNowVerificationUrlDemo.value,
      idNowCustomerWithEsignLive:
        editSystemAccountForm.controls.idNowCustomerWithEsignLive.value,
      idNowCustomerWithEsignDemo:
        editSystemAccountForm.controls.idNowCustomerWithEsignDemo.value,
      idNowApiKeyWithEsignLive:
        editSystemAccountForm.controls.idNowApiKeyWithEsignLive.value,
      idNowApiKeyWithEsignDemo:
        editSystemAccountForm.controls.idNowApiKeyWithEsignDemo.value,
      jsonExportPath:
        editSystemAccountSettingsForm.controls.jsonExportPath.value,
      autoAddMandatoryDocumentsToClient:
        editSystemAccountSettingsForm.controls.autoAddMandatoryDocumentsToClient
          .value,
      autoAddChecklistsToClient:
        editSystemAccountSettingsForm.controls.autoAddChecklistsToClient.value,
      disableLiveHealthCheck:
        editSystemAccountSettingsForm.controls.disableLiveHealthCheck.value,
      enableLoginOTP:
        editSystemAccountSettingsForm.controls.enableLoginOTP.value,
      showRegTekDemoButtons:
        editSystemAccountSettingsForm.controls.showRegTekDemoButtons.value,
      keepDeleteLogsForDays:
        editSystemAccountSettingsForm.controls.keepDeleteLogsForDays.value,
      degreeOfParallelism:
        editSystemAccountSettingsForm.controls.degreeOfParallelism.value,
      documentUploadType:
        editSystemAccountSettingsForm.controls.documentUploadType.value,
      treeStructureType:
        editSystemAccountSettingsForm.controls.treeStructureType.value,
      NewClientStatusId:
        editSystemAccountSettingsForm.controls.newClientStatusId.value,
      regTekOnboardingStatus:
        editSystemAccountForm.controls.regTekOnboardingStatus.value,
      regTekOnboardingEndPointLive:
        editSystemAccountForm.controls.regTekOnboardingEndPointLive.value,
      regTekOnboardingEndPointDemo:
        editSystemAccountForm.controls.regTekOnboardingEndPointDemo.value,
      regTekOnboardingUrlLive:
        editSystemAccountForm.controls.regTekOnboardingUrlLive.value,
      regTekOnboardingUrlDemo:
        editSystemAccountForm.controls.regTekOnboardingUrlDemo.value,
      regTekOnboardingUsernameLive:
        editSystemAccountForm.controls.regTekOnboardingUsernameLive.value,
      regTekOnboardingUsernameDemo:
        editSystemAccountForm.controls.regTekOnboardingUsernameDemo.value,
      regTekOnboardingPasswordLive:
        editSystemAccountForm.controls.regTekOnboardingPasswordLive.value,
      regTekOnboardingPasswordDemo:
        editSystemAccountForm.controls.regTekOnboardingPasswordDemo.value,

      // Artemis Alpha Bank
      artemisAlphaBankStatus:
        editSystemAccountForm.controls.artemisAlphaBankStatus.value,
      artemisAlphaBankUrlLive:
        editSystemAccountForm.controls.artemisAlphaBankUrlLive.value,
      artemisAlphaBankUrlDemo:
        editSystemAccountForm.controls.artemisAlphaBankUrlDemo.value,
      artemisAlphaBankAutoCall:
        editSystemAccountForm.controls.artemisAlphaBankAutoCall.value,
      // Internal blacklists
      visibleIdentifications: editSystemAccountForm.controls
        .visibleIdentifications.value
        ? editSystemAccountForm.controls.visibleIdentifications.value.map(
          (el) => el.value
        )
        : null,
      fuzzinessThreshold:
        editSystemAccountForm.controls.fuzzinessThreshold.value,
      checkInternalBlackLists:
        editSystemAccountForm.controls.checkInternalBlackLists.value,
      // DowJones
      dowJonesStatus: editSystemAccountForm.controls.dowJonesStatus.value,
      dowJonesAutoCall: editSystemAccountForm.controls.dowJonesAutoCall.value,
      dowJonesMachineLearningNamesBatchSize:
        editSystemAccountForm.controls.dowJonesMachineLearningNamesBatchSize
          .value,

      //ChainAnalysis
      chainAnalysisStatus:
        editSystemAccountForm.controls.chainAnalysisStatus.value,
      chainAnalysisBaseUrlLive:
        editSystemAccountForm.controls.chainAnalysisBaseUrlLive.value,
      chainAnalysisTokenLive:
        editSystemAccountForm.controls.chainAnalysisTokenLive.value,
      chainAnalysisBaseUrlDemo:
        editSystemAccountForm.controls.chainAnalysisBaseUrlDemo.value,
      chainAnalysisTokenDemo:
        editSystemAccountForm.controls.chainAnalysisTokenDemo.value,
      chainAnalysisAutoCallOnTransaction:
        editSystemAccountForm.controls.chainAnalysisAutoCallOnTransaction.value,
      chainAnalysisCustomFieldInternalReference:
        editSystemAccountForm.controls.chainAnalysisCustomFieldInternalReference
          .value,
      chainAnalysisLastSyncDate:
        editSystemAccountForm.controls.chainAnalysisLastSyncDate.value,

      //PowerBi
      powerBiStatus: editSystemAccountForm.controls.powerBiStatus.value,
      powerBiClientIdDemo:
        editSystemAccountForm.controls.powerBiClientIdDemo.value,
      powerBiClientSecretDemo:
        editSystemAccountForm.controls.powerBiClientSecretDemo.value,
      powerBiResourceUriDemo:
        editSystemAccountForm.controls.powerBiResourceUriDemo.value,
      powerBiApiVersionDemo:
        editSystemAccountForm.controls.powerBiApiVersionDemo.value,
      powerBiWorkspaceIdDemo:
        editSystemAccountForm.controls.powerBiWorkspaceIdDemo.value,
      powerBiTenantIdDemo:
        editSystemAccountForm.controls.powerBiTenantIdDemo.value,
      powerBiUsernameDemo:
        editSystemAccountForm.controls.powerBiUsernameDemo.value,
      powerBiPasswordDemo:
        editSystemAccountForm.controls.powerBiPasswordDemo.value,

      powerBiClientIdLive:
        editSystemAccountForm.controls.powerBiClientIdLive.value,
      powerBiClientSecretLive:
        editSystemAccountForm.controls.powerBiClientSecretLive.value,
      powerBiResourceUriLive:
        editSystemAccountForm.controls.powerBiResourceUriLive.value,
      powerBiApiVersionLive:
        editSystemAccountForm.controls.powerBiApiVersionLive.value,
      powerBiWorkspaceIdLive:
        editSystemAccountForm.controls.powerBiWorkspaceIdLive.value,
      powerBiTenantIdLive:
        editSystemAccountForm.controls.powerBiTenantIdLive.value,
      powerBiUsernameLive:
        editSystemAccountForm.controls.powerBiUsernameLive.value,
      powerBiPasswordLive:
        editSystemAccountForm.controls.powerBiPasswordLive.value,

      evaluationAlgorithmType:
        editSystemAccountSettingsForm.controls.evaluationAlgorithmType.value,
      contractCollectionCashMethodMappingReference:
        editSystemAccountSettingsForm.controls
          .contractCollectionCashMethodMappingReference.value,
      contractCollectionChequeMethodMappingReference:
        editSystemAccountSettingsForm.controls
          .contractCollectionChequeMethodMappingReference.value,
      contractCollectionBankTransferMethodMappingReference:
        editSystemAccountSettingsForm.controls
          .contractCollectionBankTransferMethodMappingReference.value,
      contractCollectionPaymentsWithinCompanyMethodMappingReference:
        editSystemAccountSettingsForm.controls
          .contractCollectionPaymentsWithinCompanyMethodMappingReference.value,
      addressTypeResidenceMappingReference:
        editSystemAccountSettingsForm.controls
          .addressTypeResidenceMappingReference.value,
      // Staging Area Synchronization Settings
      clientBatchSize: editSystemAccountForm.controls.clientBatchSize.value,
      clientDetailLineBatchSize:
        editSystemAccountForm.controls.clientDetailLineBatchSize.value,
      stagingAreaMethodsRepetition:
        editSystemAccountForm.controls.stagingAreaMethodsRepetition.value,
      commitCount: editSystemAccountForm.controls.commitCount.value,
      // Email Notification for New Release
      notificationEmailSentTo:
        editSystemAccountForm.controls.notificationEmailSentTo.value,

      // eGOV
      eGOVStatus: editSystemAccountForm.controls.eGOVStatus.value || 1,
      eGOVClientIdDemo: editSystemAccountForm.controls.eGOVClientIdDemo.value,
      eGOVClientSecretDemo:
        editSystemAccountForm.controls.eGOVClientSecretDemo.value,
      eGOVAuthorizationEndpointDemo:
        editSystemAccountForm.controls.eGOVAuthorizationEndpointDemo.value,
      eGOVTokenEndpointDemo:
        editSystemAccountForm.controls.eGOVTokenEndpointDemo.value,
      eGOVResourceServerDemo:
        editSystemAccountForm.controls.eGOVResourceServerDemo.value,
      eGOVCallbackUrlDemo:
        editSystemAccountForm.controls.eGOVCallbackUrlDemo.value,
      eGOVDomainNameDemo:
        editSystemAccountForm.controls.eGOVDomainNameDemo.value,
      eGOVClientIdLive: editSystemAccountForm.controls.eGOVClientIdLive.value,
      eGOVClientSecretLive:
        editSystemAccountForm.controls.eGOVClientSecretLive.value,
      eGOVAuthorizationEndpointLive:
        editSystemAccountForm.controls.eGOVAuthorizationEndpointLive.value,
      eGOVTokenEndpointLive:
        editSystemAccountForm.controls.eGOVTokenEndpointLive.value,
      eGOVResourceServerLive:
        editSystemAccountForm.controls.eGOVResourceServerLive.value,
      eGOVCallbackUrlLive:
        editSystemAccountForm.controls.eGOVCallbackUrlLive.value,
      eGOVDomainNameLive:
        editSystemAccountForm.controls.eGOVDomainNameLive.value,

      //iDenfy
      iDenfyStatus: editSystemAccountForm.controls.iDenfyStatus.value,
      iDenfyUrlLive: editSystemAccountForm.controls.iDenfyUrlLive.value,
      iDenfyUrlDemo: editSystemAccountForm.controls.iDenfyUrlDemo.value,
      iDenfyUsernameLive:
        editSystemAccountForm.controls.iDenfyUsernameLive.value,
      iDenfyUsernameDemo:
        editSystemAccountForm.controls.iDenfyUsernameDemo.value,
      iDenfyPasswordLive:
        editSystemAccountForm.controls.iDenfyPasswordLive.value,
      iDenfyPasswordDemo:
        editSystemAccountForm.controls.iDenfyPasswordDemo.value,
      iDenfyAutoCall: editSystemAccountForm.controls.iDenfyAutoCall.value,
      iDenfyServiceOnAutoCall:
        editSystemAccountForm.controls.iDenfyServiceOnAutoCall.value,

      //SumSub
      sumSubStatus: editSystemAccountForm.controls.sumSubStatus.value,
      sumSubUrlLive: editSystemAccountForm.controls.sumSubUrlLive.value,
      sumSubUrlDemo: editSystemAccountForm.controls.sumSubUrlDemo.value,
      sumSubTokenKeyLive:
        editSystemAccountForm.controls.sumSubTokenKeyLive.value,
      sumSubTokenKeyDemo:
        editSystemAccountForm.controls.sumSubTokenKeyDemo.value,
      sumSubSecretKeyLive:
        editSystemAccountForm.controls.sumSubSecretKeyLive.value,
      sumSubSecretKeyDemo:
        editSystemAccountForm.controls.sumSubSecretKeyDemo.value,
      sumSubAutoCall: editSystemAccountForm.controls.sumSubAutoCall.value,
      sumSubActivatedModules: editSystemAccountForm.controls['sumSubActivatedModules'].value
        ? editSystemAccountForm.controls['sumSubActivatedModules'].value.map((el) => el.value)
        : null,

      //MaxMind
      maxMindInformationToTrack: editSystemAccountForm.controls['maxMindInformationToTrack'].value
        ? editSystemAccountForm.controls['maxMindInformationToTrack'].value.map((el) => el.value)
        : null,

      // Task Scheduler Activation Feature Types
      taskSchedulerActivationTypes: editSystemAccountForm.controls['taskSchedulerActivation'].value
        ? editSystemAccountForm.controls['taskSchedulerActivation'].value.map((el) => el.value)
        : null,

      timeZoneInfoType: editSystemAccountForm.controls['timeZoneInfoType'].value,

      // Thresholds
      numOfAllowedClients: editSystemAccountSettingsForm.controls['numOfAllowedClients'].value,
      numOfAllowedUsers: editSystemAccountSettingsForm.controls['numOfAllowedUsers'].value
    };

    return this.putForm("/systemSettings/systemAccounts", body);
  }

  public editSystemAccountClaims(
    showClaims: any,
    showReports: any,
    showProcesses: any,
    showModules: any
  ): Observable<any> {
    let body = {
      showClaims: showClaims,
      showReports: showReports,
      showProcesses: showProcesses,
      showModules: showModules,
    };
    return this.putForm("/systemSettings/systemAccountsClaims", body);
  }

  public editCaseStatuses(newData: any, oldData: any) {
    let body = {
      "id": newData.id != undefined ? newData.id : oldData.id,
      "caseStatusId": newData.caseStatusId != undefined ? newData.caseStatusId : oldData.caseStatusId,
      "caseStatusValue": newData.caseStatusValue != undefined ? newData.caseStatusValue : oldData.caseStatusValue,
      "description": newData.description != undefined ? newData.description : oldData.description,
      "isActive": newData.isActive != undefined ? newData.isActive : oldData.isActive,
      "show": newData.show != undefined ? newData.show : oldData.show,
      "isActiveTransactionScreening": newData.isActiveTransactionScreening != undefined ? newData.isActiveTransactionScreening : oldData.isActiveTransactionScreening,
    }
    return this.putJson("/settings/caseStatuses", JSON.stringify(body));
  }

  public editCaseStatusesShow(caseStatusesShow: any) {
    let body = {
      CaseStatuses: caseStatusesShow,
    };

    return this.putJson("/settings/caseStatusesShow", JSON.stringify(body));
  }

  public editHealthCheckDashboards(healthCheckDashboardsShow: any) {
    let body = {
      healthCheckDashboards: healthCheckDashboardsShow,
    };

    return this.putJson(
      "/healthcheck/healthCheckDashboards",
      JSON.stringify(body)
    );
  }

  public checkCaseStatuses() {
    return this.fetch("/settings/checkCaseStatuses");
  }

  public editBank(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      code: newData.code != undefined ? newData.code : oldData.code,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
      includeInXml:
        newData.includeInXml != undefined
          ? newData.includeInXml
          : oldData.includeInXml,
      blocked: newData.blocked != undefined ? newData.blocked : oldData.blocked,
    };
    return this.putJson("/settings/banks", JSON.stringify(body));
  }

  public editMonitoringVisitType(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/monitoringVisitType", JSON.stringify(body));
  }

  public editCaseStatusRiskPoint(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      caseStatus:
        newData.caseStatus != undefined
          ? newData.caseStatus
          : oldData.caseStatus,
      riskPoint:
        newData.riskPoint != undefined ? newData.riskPoint : oldData.riskPoint,
    };

    return this.putJson(
      `/settings/caseStatusesRiskPoints/${oldData.id}`,
      JSON.stringify(body)
    );
  }

  public editCaseSuspicionLevelRiskPoint(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      caseSuspicionLevel:
        newData.caseSuspicionLevel != undefined
          ? newData.caseSuspicionLevel
          : oldData.caseSuspicionLevel,
      riskPoint:
        newData.riskPoint != undefined ? newData.riskPoint : oldData.riskPoint,
    };

    return this.putJson(
      `/settings/caseSuspicionLevelsRiskPoints/${oldData.id}`,
      JSON.stringify(body)
    );
  }

  public editCurrencyRate(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      date: newData.date != undefined ? newData.date : oldData.date,
      rate: newData.rate != undefined ? newData.rate : oldData.rate,
    };
    return this.putJson("/settings/currencyRates", JSON.stringify(body));
  }

  public editCountry(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      isoAlpha2Code:
        newData.isoAlpha2Code != undefined
          ? newData.isoAlpha2Code
          : oldData.isoAlpha2Code,
      isoAlpha3Code:
        newData.isoAlpha3Code != undefined
          ? newData.isoAlpha3Code
          : oldData.isoAlpha3Code,
      nationality:
        newData.nationality != undefined
          ? newData.nationality
          : oldData.nationality,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
      callingCOde:
        newData.callingCode != undefined
          ? newData.callingCode
          : oldData.callingCode,
      latitude:
        newData.latitude != undefined ? newData.latitude : oldData.latitude,
      longitude:
        newData.longitude != undefined ? newData.longitude : oldData.longitude,
    };
    return this.putJson("/settings/countries", JSON.stringify(body));
  }

  public editCity(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/cities", JSON.stringify(body));
  }

  public editKeyword(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/keyword", JSON.stringify(body));
  }

  public editAutoRunScript(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      ignoreHealthCheck:
        newData.ignoreHealthCheck != undefined
          ? newData.ignoreHealthCheck
          : oldData.ignoreHealthCheck,
    };
    return this.postJson(
      "/administration/editAutoRunScript",
      JSON.stringify(body)
    );
  }

  public executeAutoRunScript(id): Observable<any> {
    let body = {
      id: id,
    };
    return this.postJson(
      "/administration/executeAutoRunScript",
      JSON.stringify(body)
    );
  }

  public editReasonForTin(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      code: newData.code != undefined ? newData.code : oldData.code,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/reasonsForTin", JSON.stringify(body));
  }

  public editAddressType(
    editAddressTypeForm: any,
    clientTypes: any
  ): Observable<any> {
    let body = {
      id: editAddressTypeForm.controls.id.value,
      name: editAddressTypeForm.controls.name.value,
      code: editAddressTypeForm.controls.code.value,
      isVisibleToPortal:
        editAddressTypeForm.controls.isVisibleToPortal.value || false,
      MappingReference: editAddressTypeForm.controls.mappingReference.value,
      useIneIDV: editAddressTypeForm.controls.useIneIDV.value,
      typeOfAddressType: editAddressTypeForm.controls.typeOfAddressType.value,
      addressTypeClientTypes: clientTypes,
    };
    return this.putJson("/settings/addressType", JSON.stringify(body));
  }

  public editEvaluationType(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/evaluationtypes", JSON.stringify(body));
  }

  public editCapacity(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      includeInEvaluation: newData.includeInEvaluation != undefined
        ? newData.includeInEvaluation
        : oldData.includeInEvaluation,
      includeInTreeStructure: newData.includeInTreeStructure != undefined
        ? newData.includeInTreeStructure
        : oldData.includeInTreeStructure,
      status: newData.status != undefined ? newData.status : oldData.status,
      clientType: newData.clientType != undefined
        ? newData.clientType
        : oldData.clientType,
      mappingReference: newData.mappingReference != undefined
        ? newData.mappingReference
        : oldData.mappingReference,
      includePercentageOfShares: newData.includePercentageOfShares != undefined
        ? newData.includePercentageOfShares
        : oldData.includePercentageOfShares,
      integrationCapacityId: newData.integrationCapacityId != undefined
        ? newData.integrationCapacityId
        : oldData.integrationCapacityId,
      allowSameProfileConnection: newData.allowSameProfileConnection != undefined
        ? newData.allowSameProfileConnection
        : oldData.allowSameProfileConnection,
    };

    return this.putJson("/settings/capacities", JSON.stringify(body));
  }

  public editIntegrationCapacity(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      capacityName:
        newData.capacityName != undefined
          ? newData.capacityName
          : oldData.capacityName,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/integrationCapacities",
      JSON.stringify(body)
    );
  }

  public editDefaultAddress(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      address: newData.address != undefined ? newData.address : oldData.address,
      countryId:
        newData.countryId != undefined ? newData.countryId : oldData.countryId,
      postalCode:
        newData.postalCode != undefined
          ? newData.postalCode
          : oldData.postalCode,
      locality:
        newData.locality != undefined ? newData.locality : oldData.locality,
      province:
        newData.province != undefined ? newData.province : oldData.province,
      district:
        newData.district != undefined ? newData.district : oldData.district,
      addressTypeId:
        newData.addressTypeId != undefined
          ? newData.addressTypeId
          : oldData.addressTypeId,
    };
    return this.putJson("/settings/defaultaddresses", JSON.stringify(body));
  }

  public editChecklist(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      priority:
        newData.priority != undefined ? newData.priority : oldData.priority,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/checklists", JSON.stringify(body));
  }

  public editCountryEvaluationGrade(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      evaluationGradeId:
        newData.evaluationGradeId != undefined
          ? newData.evaluationGradeId
          : oldData.evaluationGradeId,
    };
    return this.putJson(
      "/settings/countryevaluationgrades",
      JSON.stringify(body)
    );
  }

  public editInvestmentAccountType(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/investmentAccountTypes",
      JSON.stringify(body)
    );
  }

  public editClientStatus(form: any, nextClientStatuses: any): Observable<any> {
    let body = {
      id: form.controls.id.value,
      name: form.controls.name.value,
      status: form.controls.status.value,
      mappingReference: form.controls.mappingReference.value,
      nextClientStatuses: nextClientStatuses,
      color: form.controls.color.value,
      relatedActivationStatus: form.controls.relatedActivationStatus.value,
    };
    return this.putJson("/settings/clientStatuses", JSON.stringify(body));
  }

  public editCurrencyInformation(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      code: newData.code != undefined ? newData.code : oldData.code,
      status: newData.status != undefined ? newData.status : oldData.status,
      symbol: newData.symbol != undefined ? newData.symbol : oldData.symbol,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
      type: newData.type != undefined ? newData.type : oldData.type,
    };
    return this.putJson("/settings/currencies", JSON.stringify(body));
  }

  public editGameType(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };

    return this.putJson("/settings/gameTypes", JSON.stringify(body));
  }

  public editGamingAccountStatus(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };

    return this.putJson(
      "/settings/gamingAccountStatuses",
      JSON.stringify(body)
    );
  }

  public editGamingTransactionType(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };

    return this.putJson(
      "/settings/gamingTransactionTypes",
      JSON.stringify(body)
    );
  }

  public editClientCategory(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };

    return this.putJson("/settings/clientCategories", JSON.stringify(body));
  }

  public editPortalClientStatus(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      portalCaption:
        newData.portalCaption != undefined
          ? newData.portalCaption
          : oldData.portalCaption,
    };

    return this.putJson("/portalSetup/clientStatuses", JSON.stringify(body));
  }

  public editPortalCriterionAnswer(
    newData: any,
    oldData: any,
    criterionid: string
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      criterionId: criterionid,
      isVisibleToPortal:
        newData.isVisibleToPortal != undefined
          ? newData.isVisibleToPortal
          : oldData.isVisibleToPortal,
    };
    return this.putJson(
      "/portalSetup/criteria/" + criterionid + "/answers",
      JSON.stringify(body)
    );
  }

  public editPortalQuestionAnswer(
    newData: any,
    oldData: any,
    questionId: string
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      questionId: questionId,
      isVisibleToPortal:
        newData.isVisibleToPortal != undefined
          ? newData.isVisibleToPortal
          : oldData.isVisibleToPortal,
    };
    return this.putJson(
      "/portalSetup/questions/" + questionId + "/answers",
      JSON.stringify(body)
    );
  }

  public editPortalDocument(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      isVisibleToPortal:
        newData.isVisibleToPortal != undefined
          ? newData.isVisibleToPortal
          : oldData.isVisibleToPortal,
    };

    return this.putJson(
      "/portalSetup/documents/" + oldData.id,
      JSON.stringify(body)
    );
  }

  public editPortalCustomField(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      isVisibleToPortal:
        newData.isVisibleToPortal != undefined
          ? newData.isVisibleToPortal
          : oldData.isVisibleToPortal,
    };

    return this.putJson(
      "/portalSetup/customFields/" + oldData.id,
      JSON.stringify(body)
    );
  }

  public editPortalPrivacyPolicy(
    editPortalPrivacyPolicyForm: FormGroup,
    id: any,
    privacyPolicyBody: string
  ): Observable<any> {
    let body = {
      id: id,
      name: editPortalPrivacyPolicyForm.controls.name.value,
      body: privacyPolicyBody,
      status: editPortalPrivacyPolicyForm.controls.status.value,
      regulationGroupId:
        editPortalPrivacyPolicyForm.controls.regulationGroupId.value,
    };
    return this.putJson("/portalSetup/privacyPolicies", JSON.stringify(body));
  }

  public editTransactionMethod(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };

    return this.putJson("/settings/transactionMethods", JSON.stringify(body));
  }

  public editInternalBlacklistedReason(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      externalReference:
        newData.externalReference != undefined
          ? newData.externalReference
          : oldData.externalReference,
    };

    return this.putJson(
      "/administration/internalblacklistedreasons",
      JSON.stringify(body)
    );
  }

  public editTransactionType(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
      incoming:
        newData.incoming != undefined ? newData.incoming : oldData.incoming,
    };
    return this.putJson("/settings/transactionTypes", JSON.stringify(body));
  }

  public editFatcaStatus(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/fatcaStatus", JSON.stringify(body));
  }

  public editFatcaEntityCategorization(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/fatcaEntityCategorization",
      JSON.stringify(body)
    );
  }

  public editFatcaDocument(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/fatcaDocument", JSON.stringify(body));
  }

  public editFatcaCrsEntityCategorization(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/fatcaCrsEntityCategorization",
      JSON.stringify(body)
    );
  }

  public editTradeType(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/tradeTypes", JSON.stringify(body));
  }

  public editParameterQuery(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      query: newData.query != undefined ? newData.query : oldData.query,
    };

    return this.putJson(
      "/systemsettings/parameterQueries",
      JSON.stringify(body)
    );
  }

  public editRelatedWebsiteType(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/relatedWebsiteTypes", JSON.stringify(body));
  }

  public editMidType(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/midTypes", JSON.stringify(body));
  }

  public editClientCategorization(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/clientCategorizations",
      JSON.stringify(body)
    );
  }

  public editMidClass(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/midClasses", JSON.stringify(body));
  }

  public editInternalBlacklist(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      markedDate:
        newData.markedDate != undefined
          ? newData.markedDate
          : oldData.markedDate,
      dateOfBirth:
        newData.dateOfBirth !== undefined
          ? newData.dateOfBirth
          : oldData.dateOfBirth,
      internalBlacklistedReasonId:
        newData.internalBlacklistedReasonId != undefined
          ? newData.internalBlacklistedReasonId
          : oldData.internalBlacklistedReasonId,
      firstName:
        newData.firstName != undefined ? newData.firstName : oldData.firstName,
      lastName:
        newData.lastName != undefined ? newData.lastName : oldData.lastName,
      idNumber:
        newData.idNumber != undefined ? newData.idNumber : oldData.idNumber,
      taxIdentificationNumber:
        newData.taxIdentificationNumber != undefined
          ? newData.taxIdentificationNumber
          : oldData.taxIdentificationNumber,
    };

    return this.putJson(
      "/administration/internalblacklists",
      JSON.stringify(body)
    );
  }

  public editInternalBlacklistCorporate(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      markedDate:
        newData.markedDate != undefined
          ? newData.markedDate
          : oldData.markedDate,
      internalBlacklistedReasonId:
        newData.internalBlacklistedReasonId != undefined
          ? newData.internalBlacklistedReasonId
          : oldData.internalBlacklistedReasonId,
      registeredName:
        newData.registeredName != undefined
          ? newData.registeredName
          : oldData.registeredName,
      registrationNumber:
        newData.registrationNumber != undefined
          ? newData.registrationNumber
          : oldData.registrationNumber,
      taxIdentificationNumber:
        newData.taxIdentificationNumber != undefined
          ? newData.taxIdentificationNumber
          : oldData.taxIdentificationNumber,
    };

    return this.putJson(
      "/administration/internalblacklistCorporates",
      JSON.stringify(body)
    );
  }

  public editContractPolicyCategory(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/contractPolicyCategories",
      JSON.stringify(body)
    );
  }

  public editContractPolicyStatus(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson("/settings/contractPolicyStatus", JSON.stringify(body));
  }

  public editContractPolicyBeneficiaryRelation(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/contractPolicyBeneficiaryRelation",
      JSON.stringify(body)
    );
  }

  public editContractPolicyProduct(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      contractPolicyCategoryId:
        newData.contractPolicyCategoryId != undefined
          ? newData.contractPolicyCategoryId
          : oldData.contractPolicyCategoryId,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/contractPolicyProducts",
      JSON.stringify(body)
    );
  }

  public editContractTransactionPaymentMethod(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      status: newData.status != undefined ? newData.status : oldData.status,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
    };
    return this.putJson(
      "/settings/contractTransactionPaymentMethod",
      JSON.stringify(body)
    );
  }

  public editDocument(
    editDocumentForm: FormGroup,
    clientCategories: any,
    documentCapacities: any,
    existingTemplateFileBase64
  ): Observable<any> {
    let documentTemplateFile = { fileName: null, data: null };
    if (
      editDocumentForm.controls.templateFileBase64.value != null &&
      editDocumentForm.controls.templateFileBase64.value != ""
    ) {
      let base64String = editDocumentForm.controls.templateFileBase64.value
        ? editDocumentForm.controls.templateFileBase64.value
        : "";

      let base64Data = base64String.split(",")[1];
      documentTemplateFile = {
        fileName: editDocumentForm.controls.templateFileName.value,
        data: base64Data,
      };
    }

    if (
      existingTemplateFileBase64 != null &&
      existingTemplateFileBase64 != ""
    ) {
      documentTemplateFile = {
        fileName: editDocumentForm.controls.templateFileName.value,
        data: existingTemplateFileBase64,
      };
    }

    let body = {
      id: editDocumentForm.controls.id.value,
      clientType: editDocumentForm.controls.clientType.value,
      documentType: editDocumentForm.controls.documentType.value,
      name: editDocumentForm.controls.name.value,
      nameInPortal: editDocumentForm.controls.nameInPortal.value,
      refCode: editDocumentForm.controls.refCode.value,
      mandatory: editDocumentForm.controls.mandatory.value,
      regulationGroupId: editDocumentForm.controls.regulationGroupId.value,
      clientCategoriesId: clientCategories,
      documentCapacitiesId: documentCapacities,
      status: editDocumentForm.controls.status.value,
      MappingReference: editDocumentForm.controls.mappingReference.value,
      isIdentificationDocument:
        editDocumentForm.controls.isIdentificationDocument.value || false,
      templateFileData: documentTemplateFile.data,
      templateFileName: documentTemplateFile.fileName,
      ignoreClientCategory:
        editDocumentForm.controls.ignoreClientCategory.value,
      isVisibleToPortal:
        editDocumentForm.controls.isVisibleToPortal.value || false,
      documentCategoryId: editDocumentForm.controls.documentCategoryId.value,
    };

    return this.putJson("/settings/documents", JSON.stringify(body));
  }

  public editCaseWorkflowStatus(
    editCaseWorkflowStatusForm: FormGroup,
    dataAccessGroups: any,
    visibleToDataAccessGroups: any,
    caseStatuses: any
  ): Observable<any> {
    let body = {
      id: editCaseWorkflowStatusForm.controls.id.value,
      name: editCaseWorkflowStatusForm.controls.name.value,
      isDefault: editCaseWorkflowStatusForm.controls.isDefault.value,
      dataAccessGroupsId: dataAccessGroups,
      visibleToDataAccessGroupsId: visibleToDataAccessGroups,
      caseStatuses: caseStatuses,
      mappingReference:
        editCaseWorkflowStatusForm.controls.mappingReference.value,
    };

    return this.putJson("/settings/caseWorkflowStatuses", JSON.stringify(body));
  }

  public editTag(editForm: FormGroup): Observable<any> {
    let body = {
      id: editForm.controls.id.value,
      name: editForm.controls.name.value,
      description: editForm.controls.description.value,
      mappingReference: editForm.controls.mappingReference.value,
    };

    return this.putJson("/settings/tags", JSON.stringify(body));
  }

  public editMandatoryDocumentsCategory(
    form: FormGroup,
    documentsId: any
  ): Observable<any> {
    let body = {
      id: form.controls.id.value,
      name: form.controls.name.value,
      numOfMandatoryDocuments: form.controls.numOfMandatoryDocuments.value,
      mappingReference: form.controls.mappingReference.value,
      documentsId: documentsId,
    };

    return this.putJson(
      "/settings/mandatoryDocumentsCategories",
      JSON.stringify(body)
    );
  }

  public editEmailTemplate(
    editEmailTemplateForm: FormGroup,
    emailBody: string,
    directEmails: string
  ): Observable<any> {
    let body = {
      Id: editEmailTemplateForm.get("id").value,
      Subject: editEmailTemplateForm.get("subject").value,
      Body: emailBody,
      IntroEndingId: editEmailTemplateForm.get("introEndingId").value,
      status: editEmailTemplateForm.get("status").value,
      directEmails: directEmails,
      MappingReference: editEmailTemplateForm.get("mappingReference").value,
      recurringSendTimesThreshold: editEmailTemplateForm.get(
        "recurringSendTimesThreshold"
      ).value,
    };
    return this.putJson("/settings/emailTemplates", JSON.stringify(body));
  }

  public editIntroEnding(
    editIntroEndingForm: FormGroup,
    ending: string
  ): Observable<any> {
    let body = {
      Id: editIntroEndingForm.get("id").value,
      Intro: editIntroEndingForm.get("intro").value,
      Ending: ending,
      Name: editIntroEndingForm.get("name").value,
      status: editIntroEndingForm.get("status").value,
      MappingReference: editIntroEndingForm.get("mappingReference").value,
    };
    return this.putJson("/settings/introEndings", JSON.stringify(body));
  }

  public editUser(editUserForm: FormGroup): Observable<any> {
    let body = {
      Email: editUserForm.controls.email.value,
      Role: editUserForm.controls.role.value,
      IsActive: editUserForm.controls.isActive.value,
      Username: editUserForm.controls.username.value,
      MappingReference: editUserForm.controls.mappingReference.value,
      FirstName: editUserForm.controls.firstName.value,
      LastName: editUserForm.controls.lastName.value,
      phoneNumber: editUserForm.controls.phoneNumber.value,
      DataAccessGroupId: editUserForm.controls.dataAccessGroupId.value,
      portalUserEmail: editUserForm.controls.portalUserEmail.value,
    };
    return this.putJson("/administration/users", JSON.stringify(body));
  }

  public updateUserLanguage(language: any): Observable<any> {
    let body = {
      key: language.key,
      title: language.title,
      alt: language.alt,
    };

    return this.putJson(`/user/language`, JSON.stringify(body));
  }

  public editEvaluationGrades(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      name: newData.name != undefined ? newData.name : oldData.name,
      riskPointFrom:
        newData.riskPointFrom != undefined
          ? newData.riskPointFrom
          : oldData.riskPointFrom,
      riskPointTo:
        newData.riskPointTo != undefined
          ? newData.riskPointTo
          : oldData.riskPointTo,
      score: newData.score != undefined ? newData.score : oldData.score,
      nextEvaluationDays:
        newData.nextEvaluationDays != undefined
          ? newData.nextEvaluationDays
          : oldData.nextEvaluationDays,
      defaultBringScoreHigh:
        newData.defaultBringScoreHigh != undefined
          ? newData.defaultBringScoreHigh
          : oldData.defaultBringScoreHigh,
      color: newData.color != undefined ? newData.color : oldData.color,
      evaluationGradeType:
        newData.evaluationGradeType != undefined
          ? newData.evaluationGradeType
          : oldData.evaluationGradeType,
      isIneligibleGrade:
        newData.isIneligibleGrade != undefined
          ? newData.isIneligibleGrade
          : oldData.isIneligibleGrade,
      mappingReference:
        newData.mappingReference != undefined
          ? newData.mappingReference
          : oldData.mappingReference,
      overallEvaluationGradeId:
        newData.overallEvaluationGradeId != undefined
          ? newData.overallEvaluationGradeId
          : oldData.overallEvaluationGradeId,
    };
    return this.putJson("/settings/evaluationGrades", JSON.stringify(body));
  }

  public editAddresses(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      clientId:
        newData.clientId != undefined ? newData.clientId : oldData.clientId,
      address: newData.address != undefined ? newData.address : oldData.address,
      countryId:
        newData.countryId != undefined ? newData.countryId : oldData.countryId,
      postalCode:
        newData.postalCode != undefined
          ? newData.postalCode
          : oldData.postalCode,
      locality:
        newData.locality != undefined ? newData.locality : oldData.locality,
      province:
        newData.province != undefined ? newData.province : oldData.province,
      district:
        newData.district != undefined ? newData.district : oldData.district,
      addressTypeId:
        newData.addressTypeId != undefined
          ? newData.addressTypeId
          : oldData.addressTypeId,
      phoneNumber:
        newData.phoneNumber != undefined
          ? newData.phoneNumber
          : oldData.phoneNumber,
    };
    return this.putJson(
      "/clientCommon/" + oldData.clientId + "/addresses",
      JSON.stringify(body)
    );
  }

  public editRuleClientExclusions(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      dateFrom:
        newData.dateFrom != undefined ? newData.dateFrom : oldData.dateFrom,
      dateTo: newData.dateTo != undefined ? newData.dateTo : oldData.dateTo,
      reason: newData.reason != undefined ? newData.reason : oldData.reason,
    };
    return this.putJson(`/management/rule-exclusions`, JSON.stringify(body));
  }

  public editChecklists(newData: any, oldData: any): Observable<any> {
    let body = {
      Id: newData.id != undefined ? newData.id : oldData.id,
      Priority:
        newData.priority != undefined ? newData.priority : oldData.priority,
      Checked: newData.checked != undefined ? newData.checked : oldData.checked,
    };
    return this.putJson(
      "/clientCommon/" + oldData.clientId + "/checklists",
      JSON.stringify(body)
    );
  }

  public editTaxResidencies(
    newData: any,
    oldData: any,
    clientId: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      clientId:
        newData.clientId != undefined ? newData.clientId : oldData.clientId,
      countryId:
        newData.countryId != undefined ? newData.countryId : oldData.countryId,
      tin: newData.tin != undefined ? newData.tin : oldData.tin,
      notes: newData.notes != undefined ? newData.notes : oldData.notes,
      reasonForTinId:
        newData.reasonForTinId != undefined
          ? newData.reasonForTinId
          : oldData.reasonForTinId,
      explanation:
        newData.explanation != undefined
          ? newData.explanation
          : oldData.explanation,
    };
    return this.putJson(
      "/clientCommon/" + oldData.clientId + "/tax-residencies",
      JSON.stringify(body)
    );
  }

  public editClientAuthorizedPersonRow(row: any): Observable<any> {
    let body = {
      Id: row.id,
      IncludeInEvaluation: row.includeInEvaluation,
      IsNominee: row.isNominee,
      RateWeightPercentage: row.rateWeightPercentage,
      PercentageOfShares: row.percentageOfShares,
    };
    return this.putJson(
      "/clientCommon/" + row.clientId + "/authorizedPersons",
      JSON.stringify(body)
    );
  }

  public editClientAuthorizedPerson(
    editClientAuthorizedPersonForm: any,
    clientId: any,
    customFields: any
  ): Observable<any> {
    let body = {
      Id: editClientAuthorizedPersonForm.controls.id.value,
      IncludeInEvaluation:
        editClientAuthorizedPersonForm.controls.includeInEvaluation.value,
      IsNominee: editClientAuthorizedPersonForm.controls.isNominee.value,
      isLegalRepresentative:
        editClientAuthorizedPersonForm.controls.isLegalRepresentative.value,
      IsControllingPerson:
        editClientAuthorizedPersonForm.controls.isControllingPerson.value,
      controllingPersonType:
        editClientAuthorizedPersonForm.controls.controllingPersonTypeId.value,
      controllingPersonTypeOther:
        editClientAuthorizedPersonForm.controls.controllingPersonTypeOther
          .value,
      RateWeightPercentage:
        editClientAuthorizedPersonForm.controls.rateWeightPercentage.value,
      appointmentDate:
        editClientAuthorizedPersonForm.controls.appointmentDate.value,
      resignationDate:
        editClientAuthorizedPersonForm.controls.resignationDate.value,
      percentageOfShares:
        editClientAuthorizedPersonForm.controls.percentageOfShares.value,
      customFields: customFields,
    };
    return this.putJson(
      "/clientCommon/" + clientId + "/authorizedPersons",
      JSON.stringify(body)
    );
  }

  public editClientBeneficiary(
    editClientBeneficiaryForm: any,
    clientId: any,
    customFields: any
  ): Observable<any> {
    let body = {
      Id: editClientBeneficiaryForm.controls.id.value,
      IncludeInEvaluation:
        editClientBeneficiaryForm.controls.includeInEvaluation.value,
      IsControllingPerson:
        editClientBeneficiaryForm.controls.isControllingPerson.value,
      RateWeightPercentage:
        editClientBeneficiaryForm.controls.rateWeightPercentage.value,
      NumberOfShares: editClientBeneficiaryForm.controls.numberOfShares.value,
      appointmentDate: editClientBeneficiaryForm.controls.appointmentDate.value,
      resignationDate: editClientBeneficiaryForm.controls.resignationDate.value,
      controllingPersonType:
        editClientBeneficiaryForm.controls.controllingPersonTypeId.value,
      controllingPersonTypeOther:
        editClientBeneficiaryForm.controls.controllingPersonTypeOther.value,
      CustomFields: customFields,
    };
    return this.putJson(
      "/clientCorporates/" + clientId + "/beneficiaries",
      JSON.stringify(body)
    );
  }

  public editClientBeneficiaryRow(row: any): Observable<any> {
    let body = {
      Id: row.id,
      IncludeInEvaluation: row.includeInEvaluation,
      IsControllingPerson: row.isControllingPerson,
      RateWeightPercentage: row.rateWeightPercentage,
      NumberOfShares: row.numberOfShares,
    };
    return this.putJson(
      "/clientCorporates/" + row.clientId + "/beneficiaries",
      JSON.stringify(body)
    );
  }

  public editClientShareholderRow(row: any): Observable<any> {
    let body = {
      Id: row.id,
      IncludeInEvaluation: row.includeInEvaluation,
      IsNominee: row.isNominee,
      IsControllingPerson: row.isControllingPerson,
      RateWeightPercentage: row.rateWeightPercentage,
      NumberOfShares: row.numberOfShares,
    };
    return this.putJson(
      "/clientCorporates/" + row.clientId + "/shareholders",
      JSON.stringify(body)
    );
  }

  public editClientShareholder(
    editClientShareholderForm: any,
    clientId: any,
    customFields: any
  ): Observable<any> {
    let body = {
      Id: editClientShareholderForm.controls.id.value,
      IncludeInEvaluation:
        editClientShareholderForm.controls.includeInEvaluation.value,
      IsNominee: editClientShareholderForm.controls.isNominee.value,
      IsControllingPerson:
        editClientShareholderForm.controls.isControllingPerson.value,
      isLegalRepresentative:
        editClientShareholderForm.controls.isLegalRepresentative.value,
      RateWeightPercentage:
        editClientShareholderForm.controls.rateWeightPercentage.value,
      NumberOfShares: editClientShareholderForm.controls.numberOfShares.value,
      appointmentDate: editClientShareholderForm.controls.appointmentDate.value,
      resignationDate: editClientShareholderForm.controls.resignationDate.value,
      controllingPersonType:
        editClientShareholderForm.controls.controllingPersonTypeId.value,
      controllingPersonTypeOther:
        editClientShareholderForm.controls.controllingPersonTypeOther.value,
      customFields: customFields,
    };
    return this.putJson(
      "/clientCorporates/" + clientId + "/shareholders",
      JSON.stringify(body)
    );
  }

  public editClientUltimateBeneficialOwner(
    editClientUltimateBeneficialOwnerForm: any,
    clientId: any,
    customFields: any
  ): Observable<any> {
    let body = {
      Id: editClientUltimateBeneficialOwnerForm.controls.id.value,
      includeInEvaluation:
        editClientUltimateBeneficialOwnerForm.controls.includeInEvaluation
          .value,
      isLegalRepresentative:
        editClientUltimateBeneficialOwnerForm.controls.isLegalRepresentative
          .value,
      rateWeightPercentage:
        editClientUltimateBeneficialOwnerForm.controls.rateWeightPercentage
          .value,
      percentageOfShares:
        editClientUltimateBeneficialOwnerForm.controls.percentageOfShares.value,
      appointmentDate:
        editClientUltimateBeneficialOwnerForm.controls.appointmentDate.value,
      resignationDate:
        editClientUltimateBeneficialOwnerForm.controls.resignationDate.value,
      isControllingPerson:
        editClientUltimateBeneficialOwnerForm.controls.isControllingPerson
          .value,
      controllingPersonType:
        editClientUltimateBeneficialOwnerForm.controls.controllingPersonTypeId
          .value,
      controllingPersonTypeOther:
        editClientUltimateBeneficialOwnerForm.controls
          .controllingPersonTypeOther.value,
      customFields: customFields,
    };
    return this.putJson(
      "/clientCorporates/" + clientId + "/ultimateBeneficialOwners",
      JSON.stringify(body)
    );
  }

  public editClientUltimateBeneficialOwnerRow(row: any): Observable<any> {
    let body = {
      Id: row.id,
      includeInEvaluation: row.includeInEvaluation,
      rateWeightPercentage: row.rateWeightPercentage,
      percentageOfShares: row.percentageOfShares,
      isControllingPerson: row.isControllingPerson,
    };
    return this.putJson(
      "/clientCommon/" + row.clientId + "/ultimateBeneficialOwners",
      JSON.stringify(body)
    );
  }

  public editWorkflowStatus(
    newData: any,
    oldData: any,
    schemeCode: any
  ): Observable<any> {
    let body = {
      Status: newData.status != undefined ? newData.status : oldData.status,
      SchemeCode: schemeCode,
    };
    return this.postJson("/Workflows/EditWorkflowStatus", JSON.stringify(body));
  }

  public getWorkflows(): Observable<any> {
    return this.fetch("/dropdown/workflows");
  }

  public synchronizeRule(id: any): Observable<any> {
    return this.putJson("/settings/rules/" + id + "/synchronize", "");
  }

  public synchronizeSystemRule(id: any): Observable<any> {
    return this.putJson("/administration/rules/" + id + "/synchronize", "");
  }

  public UpdatePredefinedRules(id: any, form: any): Observable<any> {
    let ruleIDs: Array<number> = [id];

    let body = {
      ruleIds: ruleIDs,
      updateComment: form.controls.updateComment.value,
    };

    return this.postJson(
      "/systemSettings/systemRules/updatePredefinedRules",
      JSON.stringify(body)
    );
  }

  public GetJSONCustomRule(id: any): Observable<any> {
    return this.fetch("/systemSettings/systemRules/exportCustomRule/" + id);
  }

  public GetJSONQueryBasedSetup(id: any): Observable<any> {
    return this.fetch(
      "/systemSettings/queryBasedSetups/exportQueryBasedSetup/" + id
    );
  }

  public UpdatePredefinedDynamicReports(id: any, form: any): Observable<any> {
    let dynamicReportIDs: Array<number> = [id];

    let body = {
      dynamicReportIds: dynamicReportIDs,
      updateComment: form.controls.updateComment.value,
    };

    return this.postJson(
      "/administration/systemDynamicReports/updatePredefinedDynamicReports",
      JSON.stringify(body)
    );
  }

  public addPredefinedRules(
    regulationGroups: any,
    fileNames: any
  ): Observable<any> {
    let body = {
      RegulationGroupIds: regulationGroups,
      JsonRuleFileNames: fileNames,
    };

    return this.postJson(
      "/systemSettings/systemRules/addPredefinedRulesFromJson",
      JSON.stringify(body)
    );
  }

  public addRuleFromJson(jsonRecord: any): Observable<any> {
    let body = {
      RuleInJson: JSON.parse(jsonRecord),
    };

    return this.postJson(
      "/systemSettings/systemRules/addRuleFromJson",
      JSON.stringify(body)
    );
  }

  public editRuleFromJson(jsonRecord: any, ruleId: any): Observable<any> {
    let body = {
      RuleInJson: JSON.parse(jsonRecord),
    };

    return this.postJson(
      "/systemSettings/systemRules/editRuleFromJson/" + ruleId,
      JSON.stringify(body)
    );
  }

  public addPredefinedDynamicReportsFromJson(fileNames: any): Observable<any> {
    let body = {
      JsonDynamicReportFileNames: fileNames,
    };

    return this.postJson(
      "/administration/systemDynamicReports/addPredefinedDynamicReportsFromJson",
      JSON.stringify(body)
    );
  }

  public deleteUser(username: string): Observable<any> {
    return this.delete("/administration/users/" + username, "");
  }

  public deleteRule(id: string): Observable<any> {
    return this.delete("/administration/rules/" + id, "");
  }

  public deleteSystemDynamicReport(id: string): Observable<any> {
    return this.delete("/administration/systemDynamicReports/" + id, "");
  }

  public deleteIdVerificationDataSupport(id: string): Observable<any> {
    return this.delete("/systemSettings/idVerificationDataSupports/" + id, "");
  }

  public deleteSystemNotificationTemplate(id: string): Observable<any> {
    return this.delete("/systemSettings/notificationTemplate/" + id, "");
  }

  public deleteNotificationTemplate(id: string): Observable<any> {
    return this.delete("/settings/notificationTemplate/" + id, "");
  }

  public deleteTuningRuleParameterExecution(
    ruleId: string,
    executionName: string
  ): Observable<any> {
    let body = {
      ruleId: ruleId,
      executionName: executionName,
    };
    return this.postJson(
      "/administration/rules/" + ruleId + "/delete/tuning/executions",
      JSON.stringify(body)
    );
  }

  public deleteQueryBasedSetupParameterExecution(
    queryBasedSetupId: string,
    executionName: string
  ): Observable<any> {
    let body = {
      queryBasedSetupId: queryBasedSetupId,
      executionName: executionName,
    };
    return this.postJson(
      "/administration/queryBasedSetups/" +
      queryBasedSetupId +
      "/delete/tuning/executions",
      JSON.stringify(body)
    );
  }

  public deleteTuningDynamicReportParameterExecution(
    dynamicReportId: string,
    executionName: string
  ): Observable<any> {
    let body = {
      dynamicReportId: dynamicReportId,
      executionName: executionName,
    };
    return this.postJson(
      "/administration/systemDynamicReports/" +
      dynamicReportId +
      "/delete/tuning/executions",
      JSON.stringify(body)
    );
  }

  public deleteActionStatus(id: string): Observable<any> {
    return this.delete("/settings/actionStatuses/" + id, "");
  }

  public deleteActionCategory(id: string): Observable<any> {
    return this.delete("/settings/actionCategories/" + id, "");
  }

  public deletePurposeOfTransaction(id: string): Observable<any> {
    return this.delete("/settings/purposeOfTransactions/" + id, "");
  }

  public deleteTuningRuleParameter(
    ruleId: string,
    tuningRuleParameterId: string
  ): Observable<any> {
    return this.delete(
      "/administration/rules/tuning/parameters/" + tuningRuleParameterId,
      ""
    );
  }

  public deleteTuningDynamicReportParameter(
    dynamicReportId: string,
    tuningDynamicReportParameterId: string
  ): Observable<any> {
    return this.delete(
      "/administration/systemDynamicReports/" +
      dynamicReportId +
      "/tuning/parameters/" +
      tuningDynamicReportParameterId,
      ""
    );
  }

  public deleteTuningLinkedRule(
    ruleId: string,
    tuningLinkedRuleId: string
  ): Observable<any> {
    return this.delete(
      "/administration/rules/" +
      ruleId +
      "/tuning/ruleExecutions/" +
      tuningLinkedRuleId,
      ""
    );
  }

  public deleteTuningLinkedRuleBlockCase(
    ruleId: string,
    tuningLinkedRuleId: string
  ): Observable<any> {
    return this.delete(
      "/administration/rules/" +
      ruleId +
      "/tuning/ruleExecutionsBlockCases/" +
      tuningLinkedRuleId,
      ""
    );
  }

  public deleteRole(roleId: string): Observable<any> {
    return this.delete("/administration/roles/" + roleId, "");
  }

  public deleteDataAccessGroup(groupId: number): Observable<any> {
    return this.delete("/administration/dataAccessGroups/" + groupId, "");
  }

  public deleteDataAccessField(fieldId: number): Observable<any> {
    return this.delete("/administration/dataAccessFields/" + fieldId, "");
  }

  public deleteSubGroupPerRegulationGroup(
    subGroupId: string,
    regulationGroupId: string
  ): Observable<any> {
    return this.delete(
      "/settings/regulationGroup/" +
      regulationGroupId +
      "/subGroup/" +
      subGroupId,
      ""
    );
  }

  public deleteRegulationGroup(regulationGroupId: string): Observable<any> {
    return this.delete("/settings/regulationGroups/" + regulationGroupId, "");
  }

  public deleteReportEngineSetup(reportEngineSetupId: string): Observable<any> {
    return this.delete(
      "/settings/reportEngineSetups/" + reportEngineSetupId,
      ""
    );
  }

  public deleteDynamicReportEngineSetup(
    dynamicReportEngineSetupId: string
  ): Observable<any> {
    return this.delete(
      "/settings/dynamicReportEngineSetups/" + dynamicReportEngineSetupId,
      ""
    );
  }

  public deleteExternalCredentialSetup(
    externalCredentialSetupId: string
  ): Observable<any> {
    return this.delete(
      "/administration/externalCredentialsSetup/" + externalCredentialSetupId,
      ""
    );
  }

  public deleteCriteriaCategory(criteriaCategoryId: string): Observable<any> {
    return this.delete(
      "/settings/criteriaCategories/" + criteriaCategoryId,
      ""
    );
  }

  public deleteDocumentCategory(documentCategoryId: string): Observable<any> {
    return this.delete(
      "/settings/documentCategories/" + documentCategoryId,
      ""
    );
  }

  public deleteSubGroup(subGroupId: string): Observable<any> {
    return this.delete("/settings/subGroups/" + subGroupId, "");
  }

  public deleteQuestionsCategory(questionsCategoryId: string): Observable<any> {
    return this.delete(
      "/settings/questionsCategories/" + questionsCategoryId,
      ""
    );
  }

  public deleteQuestionnaireGrade(id: any): Observable<any> {
    return this.delete("/settings/questionnaireGrades/" + id, "");
  }

  public deleteDocument(documentId: string): Observable<any> {
    return this.delete("/settings/documents/" + documentId, "");
  }

  public deleteCaseWorkflowStatus(
    caseWorkflowStatusId: string
  ): Observable<any> {
    return this.delete(
      "/settings/caseWorkflowStatuses/" + caseWorkflowStatusId,
      ""
    );
  }

  public deleteMandatoryDocumentsCategory(id: string): Observable<any> {
    return this.delete("/settings/mandatoryDocumentsCategory/" + id, "");
  }

  public deleteEmailTemplate(emailTemplateId: string): Observable<any> {
    return this.delete("/settings/emailTemplates/" + emailTemplateId, "");
  }

  public deleteIntroEnding(introEndingId: string): Observable<any> {
    return this.delete("/settings/introEndings/" + introEndingId, "");
  }

  public deleteBank(bankId: string): Observable<any> {
    return this.delete("/settings/banks/" + bankId, "");
  }

  public deleteMonitoringVisitType(id: string): Observable<any> {
    return this.delete(`/settings/monitoringVisitType/${id}`, "");
  }

  public deleteCurrencyRate(id: string): Observable<any> {
    return this.delete("/settings/currencyRates/" + id, "");
  }

  public deleteCountry(countryId: string): Observable<any> {
    return this.delete("/settings/countries/" + countryId, "");
  }

  public deleteCity(cityId: string): Observable<any> {
    return this.delete("/settings/cities/" + cityId, "");
  }

  public deleteKeyword(keywordId: string): Observable<any> {
    return this.delete("/settings/keyword/" + keywordId, "");
  }

  public deleteReasonForTin(reasonForTinId: string): Observable<any> {
    return this.delete("/settings/reasonsForTin/" + reasonForTinId, "");
  }

  public deleteAddressType(addressTypeId: string): Observable<any> {
    return this.delete("/settings/addressType/" + addressTypeId, "");
  }

  public deleteEvaluationType(evaluationTypeId: string): Observable<any> {
    return this.delete("/settings/evaluationtypes/" + evaluationTypeId, "");
  }

  public deleteEvaluationGrade(evaluationGradeId: string): Observable<any> {
    return this.delete("/settings/evaluationGrades/" + evaluationGradeId, "");
  }

  public deleteCapacity(capacityId: string): Observable<any> {
    return this.delete("/settings/capacities/" + capacityId, "");
  }

  public deleteIntegrationCapacity(
    integrationCapacityId: string
  ): Observable<any> {
    return this.delete(
      `/settings/integrationCapacities/${integrationCapacityId}`,
      ""
    );
  }

  public deleteDefaultAddress(defaultAddressId: string): Observable<any> {
    return this.delete("/settings/defaultAddresses/" + defaultAddressId, "");
  }

  public deleteChecklist(checklistId: string): Observable<any> {
    return this.delete("/settings/checklists/" + checklistId, "");
  }

  public deleteInvestmentAccountType(
    investmentAccountType: string
  ): Observable<any> {
    return this.delete(
      "/settings/investmentAccountTypes/" + investmentAccountType,
      ""
    );
  }

  public deleteClientStatus(clientStatusId: string): Observable<any> {
    return this.delete("/settings/clientStatuses/" + clientStatusId, "");
  }

  public deleteAttachment(referenceType: string, id: number): Observable<any> {
    return this.delete(
      `/clientCommon/deleteAttachment/${referenceType}/${id}`,
      ""
    );
  }

  public deleteCaseAttachment(
    referenceType: string,
    id: number
  ): Observable<any> {
    return this.delete(
      `/clientCommon/deleteCaseAttachment/${referenceType}/${id}`,
      ""
    );
  }

  public deleteApplicationAttachment(
    referenceType: string,
    id: number
  ): Observable<any> {
    return this.delete(
      `/clientCommon/deleteApplicationAttachment/${referenceType}/${id}`,
      ""
    );
  }

  public deleteCurrencyInformation(
    currencyInformationId: string
  ): Observable<any> {
    return this.delete("/settings/currencies/" + currencyInformationId, "");
  }

  public deleteGameType(gameTypeId: string): Observable<any> {
    return this.delete("/settings/gameTypes/" + gameTypeId, "");
  }

  public deleteGamingAccountStatus(
    gamingAccountStatusId: string
  ): Observable<any> {
    return this.delete(
      "/settings/gamingAccountStatuses/" + gamingAccountStatusId,
      ""
    );
  }

  public deleteGamingTransactionType(
    gamingTransactionTypeId: string
  ): Observable<any> {
    return this.delete(
      "/settings/gamingTransactionTypes/" + gamingTransactionTypeId,
      ""
    );
  }

  public deleteClientCategory(clientCategoryId: string): Observable<any> {
    return this.delete("/settings/clientCategories/" + clientCategoryId, "");
  }

  public deleteCriterion(criterionId: string): Observable<any> {
    return this.delete("/settings/criteria/" + criterionId, "");
  }

  public deletePaymentMethod(paymentMethodId: string): Observable<any> {
    return this.delete("/settings/paymentMethods/" + paymentMethodId, "");
  }

  public deletePaymentMethodVolume(
    paymentMethodId: string,
    volumeId
  ): Observable<any> {
    return this.delete(
      "/settings/paymentMethods/" + paymentMethodId + "/volumes/" + volumeId,
      ""
    );
  }

  public deleteCriterionAnswer(
    criterionId: string,
    criterionAnswerId
  ): Observable<any> {
    return this.delete(
      "/settings/criteria/" + criterionId + "/answers/" + criterionAnswerId,
      ""
    );
  }

  public deleteFirmSanctionCriterionAnswer(
    criterionId: string,
    criterionAnswerId
  ): Observable<any> {
    return this.delete(
      "/settings/firms/sanctionCriterion/" +
      criterionId +
      "/answers/" +
      criterionAnswerId,
      ""
    );
  }

  public deleteCriterionAnswerBulk(
    criterionId: any,
    criteriaAnswerIds: any
  ): Observable<any> {
    let body = {
      ids: criteriaAnswerIds,
    };

    return this.deleteJson(
      `/settings/criteria/${criterionId}/answersBulk`,
      JSON.stringify(body)
    );
  }

  public deleteQueryParametersBulk(
    dynamicReportId: any,
    parameterIds: any
  ): Observable<any> {
    let body = {
      ids: parameterIds,
    };

    return this.deleteJson(
      `/administration/dynamicReport/${dynamicReportId}/queryParamsBulk`,
      JSON.stringify(body)
    );
  }

  public deleteQuestion(questionId: string): Observable<any> {
    return this.delete("/settings/questions/" + questionId, "");
  }

  public deleteQuestionnaireType(id: any): Observable<any> {
    return this.delete("/settings/questionnaireTypes/" + id, "");
  }

  public deleteQuestionAnswer(
    questionId: string,
    questionAnswerId
  ): Observable<any> {
    return this.delete(
      "/settings/questions/" + questionId + "/answers/" + questionAnswerId,
      ""
    );
  }

  public softDeleteClient(clientId: string): Observable<any> {
    return this.delete("/clientCommon/" + clientId + "/soft", "");
  }

  public deleteTransactionType(transactionTypeId: string): Observable<any> {
    return this.delete("/settings/transactionTypes/" + transactionTypeId, "");
  }

  public deleteFatcaStatus(fatcaStatusId: string): Observable<any> {
    return this.delete("/settings/fatcaStatus/" + fatcaStatusId, "");
  }

  public deleteFatcaEntityCategorization(
    fatcaEntityCategorizationId: string
  ): Observable<any> {
    return this.delete(
      "/settings/fatcaEntityCategorization/" + fatcaEntityCategorizationId,
      ""
    );
  }

  public deleteFatcaDocument(fatcaDocumentId: string): Observable<any> {
    return this.delete("/settings/fatcaDocument/" + fatcaDocumentId, "");
  }

  public deleteFatcaCrsEntityCategorization(
    fatcaCrsEntityCategorizationId: string
  ): Observable<any> {
    return this.delete(
      "/settings/fatcaCrsEntityCategorization/" +
      fatcaCrsEntityCategorizationId,
      ""
    );
  }

  public deleteTradeType(tradeTypeId: string): Observable<any> {
    return this.delete("/settings/tradeTypes/" + tradeTypeId, "");
  }

  public deleteParameterQuery(parameterQueryId: string): Observable<any> {
    return this.delete(
      "/systemsettings/ParameterQueries/" + parameterQueryId,
      ""
    );
  }

  public deleteTag(tagId: string): Observable<any> {
    return this.delete("/settings/tags/" + tagId, "");
  }

  public deleteRelatedWebsiteType(
    relatedWebsiteTypeId: string
  ): Observable<any> {
    return this.delete(
      "/settings/relatedWebsiteTypes/" + relatedWebsiteTypeId,
      ""
    );
  }

  public deleteMidType(midTypeId: any): Observable<any> {
    return this.delete("/settings/midTypes/" + midTypeId, "");
  }

  public deleteClientCategorization(id: any): Observable<any> {
    return this.delete("/settings/clientCategorizations/" + id, "");
  }

  public deleteMidClass(midClassId: any): Observable<any> {
    return this.delete("/settings/midClasses/" + midClassId, "");
  }

  public deleteRuleCategory(ruleCategoryId: string): Observable<any> {
    return this.delete("/settings/ruleCategories/" + ruleCategoryId, "");
  }

  public deleteInternalBlacklist(internalBlacklistId: string): Observable<any> {
    return this.delete(
      "/administration/internalblacklists/" + internalBlacklistId,
      ""
    );
  }

  public deleteInternalBlacklistCorporate(
    internalBlacklistCorporateId: string
  ): Observable<any> {
    return this.delete(
      "/administration/internalblacklistCorporates/" +
      internalBlacklistCorporateId,
      ""
    );
  }

  public deleteContractPolicyCategory(
    contractPolicyCategoryId: string
  ): Observable<any> {
    return this.delete(
      "/settings/contractPolicyCategories/" + contractPolicyCategoryId,
      ""
    );
  }

  public deleteContractPolicyStatus(
    contractPolicyStatusId: string
  ): Observable<any> {
    return this.delete(
      "/settings/contractPolicyStatus/" + contractPolicyStatusId,
      ""
    );
  }

  public deleteContractPolicyBeneficiaryRelation(
    contractPolicyBeneficiaryRelationId: string
  ): Observable<any> {
    return this.delete(
      "/settings/contractPolicyBeneficiaryRelation/" +
      contractPolicyBeneficiaryRelationId,
      ""
    );
  }

  public deleteContractPolicyProduct(
    contractPolicyStatusId: string
  ): Observable<any> {
    return this.delete(
      "/settings/contractPolicyProducts/" + contractPolicyStatusId,
      ""
    );
  }

  public deleteContractTransactionPaymentMethod(
    contractTransactionPaymentMethodId: string
  ): Observable<any> {
    return this.delete(
      "/settings/contractTransactionPaymentMethod/" +
      contractTransactionPaymentMethodId,
      ""
    );
  }

  public deleteTransactionMethod(transactionMethodId: string): Observable<any> {
    return this.delete(
      "/settings/transactionMethods/" + transactionMethodId,
      ""
    );
  }

  public deleteInternalBlacklistedReason(
    internalBlacklistedReasonId: string
  ): Observable<any> {
    return this.delete(
      "/administration/internalblacklistedreasons/" +
      internalBlacklistedReasonId,
      ""
    );
  }

  public deleteAddress(clientId: string, addressId: string): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/addresses/" + addressId,
      ""
    );
  }

  public deleteRuleClientExclusions(id: string): Observable<any> {
    return this.delete(`/management/rule-exclusions/${id}`, "");
  }

  public deleteAlternativeName(
    clientId: string,
    alternativeNameId: string
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/alternative-names/" + alternativeNameId,
      ""
    );
  }

  public deleteClientChecklist(
    clientId: string,
    checklistId: string
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/checklists/" + checklistId,
      ""
    );
  }

  public deleteClientTaxResidency(
    clientId: string,
    taxResidencyId: string
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/tax-residencies/" + taxResidencyId,
      ""
    );
  }

  public deleteClientAuthorizedPerson(
    clientId: string,
    authorizedPersonId: string
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/authorizedPersons/" + authorizedPersonId,
      ""
    );
  }

  public deleteClientVideoIdentification(
    clientId: string,
    videoIdentificationId: string
  ): Observable<any> {
    return this.delete(
      "/clientCorporates/" +
      clientId +
      "/videoIdentifications/" +
      videoIdentificationId,
      ""
    );
  }

  public deleteClientRelatedWebsite(
    clientId: string,
    relatedWebsiteId: string
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/relatedWebsites/" + relatedWebsiteId,
      ""
    );
  }

  public deleteClientCertificate(clientId, id): Observable<any> {
    return this.delete(`/clientCommon/${clientId}/certificates/${id}`, "");
  }

  public deleteClientMidAccount(
    clientId: string,
    midAccountId: string
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/midAccounts/" + midAccountId,
      ""
    );
  }

  public deleteClientShareholder(
    clientId: string,
    shareholderId: string
  ): Observable<any> {
    return this.delete(
      "/clientCorporates/" + clientId + "/shareholders/" + shareholderId,
      ""
    );
  }

  public deleteClientUltimateBeneficialOwner(
    clientId: string,
    ultimateBeneficialOwnerId: string
  ): Observable<any> {
    return this.delete(
      "/clientCorporates/" +
      clientId +
      "/ultimateBeneficialOwners/" +
      ultimateBeneficialOwnerId,
      ""
    );
  }

  public deleteClientBeneficiary(
    clientId: string,
    beneficiaryId: string
  ): Observable<any> {
    return this.delete(
      "/clientCorporates/" + clientId + "/beneficiaries/" + beneficiaryId,
      ""
    );
  }

  public deleteClientAccount(
    clientId: number,
    accountId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/accounts/" + accountId,
      ""
    );
  }

  public deleteClientWallet(
    clientId: number,
    walletId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/wallets/" + walletId,
      ""
    );
  }

  public deleteClientGamingAccount(
    clientId: number,
    accountId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/gamingaccounts/" + accountId,
      ""
    );
  }

  public deleteClientAccountSignatory(
    clientId: number,
    accountId: number,
    signatoryId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" +
      clientId +
      "/accounts/" +
      accountId +
      "/signatories/" +
      signatoryId,
      ""
    );
  }

  public deleteClientGamingAccountHistory(
    clientId: number,
    accountId: number,
    historyId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" +
      clientId +
      "/gamingaccounts/" +
      accountId +
      "/histories/" +
      historyId,
      ""
    );
  }

  public deleteClientQuestionnaire(
    clientId: number,
    clientQuestionnaireId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/questionnaires/" + clientQuestionnaireId,
      ""
    );
  }

  public deleteClientInvestmentAccount(
    clientId: number,
    investmentAccountId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" +
      clientId +
      "/investmentAccounts/" +
      investmentAccountId,
      ""
    );
  }

  public deleteClientContract(
    clientId: number,
    contractId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/contracts/" + contractId,
      ""
    );
  }

  public deleteClientContractAgent(
    clientContractAgentId: any
  ): Observable<any> {
    return this.delete(
      "/clientCommon/contracts/agents/" + clientContractAgentId,
      ""
    );
  }

  public deleteClientContractSurrender(
    clientContractSurrenderId: any
  ): Observable<any> {
    return this.delete(
      "/clientCommon/contracts/surrenders/" + clientContractSurrenderId,
      ""
    );
  }

  public deleteClientContractBooster(
    clientContractBoosterId: any
  ): Observable<any> {
    return this.delete(
      "/clientCommon/contracts/boosters/" + clientContractBoosterId,
      ""
    );
  }

  public deleteClientDocument(
    clientId: number,
    clientDocumentId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/documents/" + clientDocumentId,
      ""
    );
  }

  public deleteClientApplication(
    clientId: number,
    applicationId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/applications/" + applicationId,
      ""
    );
  }

  public deleteClientTransaction(
    clientId: number,
    clientTransactionId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/transactions/" + clientTransactionId,
      ""
    );
  }

  public deleteClientGamingTransaction(
    clientId: number,
    clientTransactionId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" +
      clientId +
      "/gamingtransactions/" +
      clientTransactionId,
      ""
    );
  }

  public deleteClientContractTransaction(
    clientId: number,
    contractTransactionId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" +
      clientId +
      "/contractTransactions/" +
      contractTransactionId,
      ""
    );
  }

  public deleteClientContractCollection(
    clientId: number,
    contractCollectionId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" +
      clientId +
      "/contractCollections/" +
      contractCollectionId,
      ""
    );
  }

  public deleteClientCase(clientId: number, caseId: number): Observable<any> {
    return this.delete("/clientCommon/" + clientId + "/cases/" + caseId, "");
  }

  public deleteClientCasePermanently(
    clientId: number,
    caseId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/cases/" + caseId + "/permanentDelete",
      ""
    );
  }

  public deleteClientTransactionScreeningCase(
    transactionScreeningCaseId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/transactionScreeningCases/" + transactionScreeningCaseId,
      ""
    );
  }

  public deleteClientTrade(
    clientId: number,
    clientTradeId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/trades/" + clientTradeId,
      ""
    );
  }

  public deleteClientContractBeneficiary(
    beneficiaryId: number
  ): Observable<any> {
    return this.delete(
      `/clientCommon/contracts/beneficiaries/${beneficiaryId}`,
      ""
    );
  }

  public removePersonMonitoring(
    clientId: number,
    monitoringReference: string
  ): Observable<any> {
    return this.delete(
      "/clientIndividuals/" +
      clientId +
      "/removePersonMonitoring/" +
      monitoringReference,
      ""
    );
  }

  public removeBusinessMonitoring(
    clientId: number,
    monitoringReference: string
  ): Observable<any> {
    return this.delete(
      "/clientCorporates/" +
      clientId +
      "/removeBusinessMonitoring/" +
      monitoringReference,
      ""
    );
  }

  public deleteAction(actionId: string): Observable<any> {
    return this.delete("/clientCommon/actions/" + actionId, "");
  }

  public deleteClientPaymentMethod(
    paymentMethodId: any,
    clientId: any
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/paymentMethods/" + paymentMethodId,
      ""
    );
  }

  public deleteInternalNote(
    id: any,
    internalNoteTable: number
  ): Observable<any> {
    return this.delete(
      `/clientCommon/internalNotes/${id}/table/${internalNoteTable}`,
      ""
    );
  }

  public deleteLayout(
    dataGridId: string,
    componentName: string,
    isCompanyLayout: boolean,
    clientId: number = null
  ): Observable<any> {
    let body = {
      ComponentName: componentName,
      DataGridId: dataGridId,
      IsCompanyLayout: isCompanyLayout,
      ClientId: clientId,
    };
    return this.postJson("/user/layout/delete", JSON.stringify(body));
  }

  public getLayout(dataGridId: string, componentName: string, clientId: number | null = null): Observable<any> {
    let body = {
      ComponentName: componentName,
      DataGridId: dataGridId,
      ...(clientId && {
        ClientId: clientId,
      })
    };
    return this.postJson("/user/layout", JSON.stringify(body));
  }

  public getLayoutWithCustomFields(
    dataGridId: string,
    componentName: string,
    layout: any,
    clientId: number
  ): Observable<any> {
    let body = {
      ComponentName: componentName,
      DataGridId: dataGridId,
      Layout: JSON.stringify(layout),
      ClientId: clientId,
    };
    return this.getCachedLayoutsData("/user/layout/customFields", body);
  }

  public addOrEditLayout(
    dataGridId: string,
    componentName: string,
    layout: string,
    isCompanyLayout: boolean,
    clientId: number = null,
    ignoreCustomFields: boolean = false
  ): Observable<any> {
    let body = {
      ComponentName: componentName,
      DataGridId: dataGridId,
      Layout: layout,
      IsCompanyLayout: isCompanyLayout,
      ClientId: clientId,
      IgnoreCustomFields: ignoreCustomFields,
    };
    return this.postJson("/user/layouts", JSON.stringify(body));
  }

  public copyLayouts(
    userToCopyLayoutsFrom: string,
    usersToCopyLayoutsTo: string[]
  ) {
    let body = {
      userToCopyLayoutsFrom: userToCopyLayoutsFrom,
      usersToCopyLayoutsTo: usersToCopyLayoutsTo,
    };
    return this.postJson(
      "/administration/users/copyLayouts",
      JSON.stringify(body)
    );
  }

  public addClientAddress(
    addAddressForm: FormGroup,
    clientId: string
  ): Observable<any> {
    let body = {
      clientId: clientId,
      address: addAddressForm.controls.address.value,
      countryId: addAddressForm.controls.countryId.value,
      postalCode: addAddressForm.controls.postalCode.value,
      locality: addAddressForm.controls.locality.value,
      province: addAddressForm.controls.province.value,
      district: addAddressForm.controls.district.value,
      addressTypeId: addAddressForm.controls.addressTypeId.value,
      AddDefaultAddress: false,
      phoneNumber: addAddressForm.controls.phoneNumber.value,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/addresses",
      JSON.stringify(body)
    );
  }

  public addClientAlternativeName(
    addForm: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      alternativeName: addForm.controls.alternativeName.value,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/alternative-names",
      JSON.stringify(body)
    );
  }

  public addClientAdditionalExternalReference(
    form: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      additionalExternalReference:
        form.controls.additionalExternalReference.value,
    };
    return this.postJson(
      `/clientCommon/${clientId}/additionalExternalReferences`,
      JSON.stringify(body)
    );
  }

  public editClientAdditionalExternalReference(
    newData: any,
    oldData: any,
    clientId: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      clientId: clientId,
      additionalExternalReference:
        newData.additionalExternalReference != undefined
          ? newData.additionalExternalReference
          : oldData.additionalExternalReference,
    };
    return this.putJson(
      `/clientCommon/${clientId}/additionalExternalReferences`,
      JSON.stringify(body)
    );
  }

  public deleteClientAdditionalExternalReference(
    clientId,
    id
  ): Observable<any> {
    return this.delete(
      `/clientCommon/${clientId}/additionalExternalReferences/${id}`,
      {}
    );
  }

  public deleteClientPortalMonitoringVisit(clientId, id): Observable<any> {
    return this.delete(
      `/clientCorporates/${clientId}/monitoringVisit/${id}`,
      {}
    );
  }

  public addClientAddressWithAddDefault(
    addAddressForm: FormGroup,
    clientId: string
  ): Observable<any> {
    let body = {
      clientId: clientId,
      address: addAddressForm.controls.address.value,
      countryId: addAddressForm.controls.countryId.value,
      postalCode: addAddressForm.controls.postalCode.value,
      locality: addAddressForm.controls.locality.value,
      province: addAddressForm.controls.province.value,
      district: addAddressForm.controls.district.value,
      type: addAddressForm.controls.type.value,
      AddDefaultAddress: true,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/addresses",
      JSON.stringify(body)
    );
  }

  public addClientChecklist(
    addChecklistForm: FormGroup,
    clientId: string
  ): Observable<any> {
    let body = {
      clientId: clientId,
      checklistId: addChecklistForm.controls.checklistId.value,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/checklists",
      JSON.stringify(body)
    );
  }

  public addClientTaxResidency(
    addTaxResidencyForm: FormGroup,
    clientId: string
  ): Observable<any> {
    let body = {
      clientId: clientId,
      countryId: addTaxResidencyForm.controls.countryId.value,
      tin: addTaxResidencyForm.controls.tin.value,
      notes: addTaxResidencyForm.controls.notes.value,
      reasonForTinId: addTaxResidencyForm.controls.reasonForTinId.value,
      explanation: addTaxResidencyForm.controls.explanation.value,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/tax-residencies",
      JSON.stringify(body)
    );
  }

  public addClientWallet(
    addClientWalletForm: FormGroup,
    id: any,
    selectedCurrencies
  ): Observable<any> {
    let body = {
      clientId: id,
      address: addClientWalletForm.controls.address.value,
      name: addClientWalletForm.controls.name.value,
      currencies: selectedCurrencies,
    };
    return this.postJson(`/clientCommon/${id}/wallets`, JSON.stringify(body));
  }

  public addSubGroupPerRegulationGroup(
    addForm: FormGroup,
    regulationGroupId: string
  ): Observable<any> {
    let body = {
      subGroupId: addForm.controls.subGroupId.value,
      regulationGroupId: regulationGroupId,
    };
    return this.postJson(
      "/settings/regulationGroup/" + regulationGroupId + "/subGroups",
      JSON.stringify(body)
    );
  }

  public addClientAuthorizedPerson(
    addAuthorizedPersonForm: FormGroup,
    clientId: string,
    customFieldsIndividual: any,
    customFieldsCorporate: any,
    customFields: any,
    profileId: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      capacityId: addAuthorizedPersonForm.controls.capacityId.value,
      includeInEvaluation:
        addAuthorizedPersonForm.controls.includeInEvaluation.value,
      isNominee: addAuthorizedPersonForm.controls.isNominee.value,
      isLegalRepresentative:
        addAuthorizedPersonForm.controls.isLegalRepresentative.value,
      isControllingPerson:
        addAuthorizedPersonForm.controls.isControllingPerson.value,
      controllingPersonType:
        addAuthorizedPersonForm.controls.controllingPersonTypeId.value,
      controllingPersonTypeOther:
        addAuthorizedPersonForm.controls.controllingPersonTypeOther.value,
      profileId: profileId,
      rateWeightPercentage:
        addAuthorizedPersonForm.controls.rateWeightPercentage.value,
      percentageOfShares:
        addAuthorizedPersonForm.controls.percentageOfShares.value,
      clientType: addAuthorizedPersonForm.controls.clientType.value,
      externalReference:
        addAuthorizedPersonForm.controls.externalReference.value == ""
          ? null
          : addAuthorizedPersonForm.controls.externalReference.value,
      clientStatusId: addAuthorizedPersonForm.controls.clientStatus.value,
      ignoreAutoOngoingMonitoringStatus:
        addAuthorizedPersonForm.controls.ignoreAutoOngoingMonitoringStatus
          .value,
      clientCategoryId: addAuthorizedPersonForm.controls.clientCategory.value,
      joinedDate: addAuthorizedPersonForm.controls.joinedDate.value,
      notes: addAuthorizedPersonForm.controls.notes.value,
      phone: addAuthorizedPersonForm.controls.phone.value,
      email: addAuthorizedPersonForm.controls.email.value,

      clientIndividual: {
        firstName: addAuthorizedPersonForm.controls.firstName.value,
        lastName: addAuthorizedPersonForm.controls.lastName.value,
        middleName: addAuthorizedPersonForm.controls.middleName.value,
        gender: addAuthorizedPersonForm.controls.gender.value,
        ssn: addAuthorizedPersonForm.controls.ssn.value,
        taxIdentificationNumber:
          addAuthorizedPersonForm.controls.taxIdentificationNumber.value,
        countryOfBirthId:
          addAuthorizedPersonForm.controls.countryOfBirthId.value,
        dateOfBirth: addAuthorizedPersonForm.controls.dateOfBirth.value,
        customFields: customFieldsIndividual,
      },

      clientCorporate: {
        registeredName: addAuthorizedPersonForm.controls.registeredName.value,
        registrationNumber:
          addAuthorizedPersonForm.controls.registrationNumber.value,
        authorisedCapital:
          addAuthorizedPersonForm.controls.authorisedCapital.value,
        issuedCapital: addAuthorizedPersonForm.controls.issuedCapital.value,
        countryOfIncorporationId:
          addAuthorizedPersonForm.controls.countryOfIncorporationId.value,
        customFields: customFieldsCorporate,
        defaultFullStructureEvaluation:
          addAuthorizedPersonForm.controls.defaultFullStructureEvaluation.value,
      },

      appointmentDate: addAuthorizedPersonForm.controls.appointmentDate.value,
      resignationDate: addAuthorizedPersonForm.controls.resignationDate.value,

      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/authorizedPersons",
      JSON.stringify(body)
    );
  }

  public addClientShareholder(
    addShareholderForm: FormGroup,
    clientId: string,
    customFieldsIndividual: any,
    customFieldsCorporate: any,
    customFields: any,
    profileId: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      shareholderCapacity:
        addShareholderForm.controls.shareholderCapacity.value,
      includeInEvaluation:
        addShareholderForm.controls.includeInEvaluation.value,
      isControllingPerson:
        addShareholderForm.controls.isControllingPerson.value,
      controllingPersonType:
        addShareholderForm.controls.controllingPersonTypeId.value,
      controllingPersonTypeOther:
        addShareholderForm.controls.controllingPersonTypeOther.value,
      isNominee: addShareholderForm.controls.isNominee.value,
      appointmentDate: addShareholderForm.controls.appointmentDate.value,
      resignationDate: addShareholderForm.controls.resignationDate.value,
      isLegalRepresentative:
        addShareholderForm.controls.isLegalRepresentative.value,
      profileId: profileId,
      rateWeightPercentage:
        addShareholderForm.controls.rateWeightPercentage.value,
      numberOfShares: addShareholderForm.controls.numberOfShares.value,
      clientType: addShareholderForm.controls.clientType.value,
      externalReference:
        addShareholderForm.controls.externalReference.value == ""
          ? null
          : addShareholderForm.controls.externalReference.value,
      clientStatusId: addShareholderForm.controls.clientStatus.value,
      ignoreAutoOngoingMonitoringStatus:
        addShareholderForm.controls.ignoreAutoOngoingMonitoringStatus.value,
      clientCategoryId: addShareholderForm.controls.clientCategory.value,
      joinedDate: addShareholderForm.controls.joinedDate.value,
      notes: addShareholderForm.controls.notes.value,
      phone: addShareholderForm.controls.phone.value,
      email: addShareholderForm.controls.email.value,

      clientIndividual: {
        firstName: addShareholderForm.controls.firstName.value,
        lastName: addShareholderForm.controls.lastName.value,
        middleName: addShareholderForm.controls.middleName.value,
        gender: addShareholderForm.controls.gender.value,
        ssn: addShareholderForm.controls.ssn.value,
        taxIdentificationNumber:
          addShareholderForm.controls.taxIdentificationNumber.value,
        countryOfBirthId: addShareholderForm.controls.countryOfBirthId.value,
        dateOfBirth: addShareholderForm.controls.dateOfBirth.value,
        customFields: customFieldsIndividual,
      },

      clientCorporate: {
        registeredName: addShareholderForm.controls.registeredName.value,
        registrationNumber:
          addShareholderForm.controls.registrationNumber.value,
        authorisedCapital: addShareholderForm.controls.authorisedCapital.value,
        issuedCapital: addShareholderForm.controls.issuedCapital.value,
        countryOfIncorporationId:
          addShareholderForm.controls.countryOfIncorporationId.value,
        appointmentDate: addShareholderForm.controls.appointmentDate.value,
        resignationDate: addShareholderForm.controls.resignationDate.value,
        defaultFullStructureEvaluation:
          addShareholderForm.controls.defaultFullStructureEvaluation.value,
        customFields: customFieldsCorporate,
      },

      customFields: customFields,
    };
    return this.postJson(
      "/clientCorporates/" + clientId + "/shareholders",
      JSON.stringify(body)
    );
  }

  public addClientUltimateBeneficialOwner(
    addClientUltimateBeneficialOwnerForm: FormGroup,
    clientId: string,
    customFieldsIndividual: any,
    customFields: any,
    profileId: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      includeInEvaluation:
        addClientUltimateBeneficialOwnerForm.controls.includeInEvaluation.value,
      profileId: profileId,
      isControllingPerson:
        addClientUltimateBeneficialOwnerForm.controls.isControllingPerson.value,
      controllingPersonType:
        addClientUltimateBeneficialOwnerForm.controls.controllingPersonTypeId
          .value,
      controllingPersonTypeOther:
        addClientUltimateBeneficialOwnerForm.controls.controllingPersonTypeOther
          .value,
      isLegalRepresentative:
        addClientUltimateBeneficialOwnerForm.controls.isLegalRepresentative
          .value,
      rateWeightPercentage:
        addClientUltimateBeneficialOwnerForm.controls.rateWeightPercentage
          .value,
      percentageOfShares:
        addClientUltimateBeneficialOwnerForm.controls.percentageOfShares.value,
      externalReference:
        addClientUltimateBeneficialOwnerForm.controls.externalReference.value ==
          ""
          ? null
          : addClientUltimateBeneficialOwnerForm.controls.externalReference
            .value,
      clientStatusId:
        addClientUltimateBeneficialOwnerForm.controls.clientStatus.value,
      ignoreAutoOngoingMonitoringStatus:
        addClientUltimateBeneficialOwnerForm.controls
          .ignoreAutoOngoingMonitoringStatus.value,
      clientCategoryId:
        addClientUltimateBeneficialOwnerForm.controls.clientCategory.value,
      joinedDate:
        addClientUltimateBeneficialOwnerForm.controls.joinedDate.value,
      notes: addClientUltimateBeneficialOwnerForm.controls.notes.value,
      phone: addClientUltimateBeneficialOwnerForm.controls.phone.value,
      email: addClientUltimateBeneficialOwnerForm.controls.email.value,

      clientIndividual: {
        firstName:
          addClientUltimateBeneficialOwnerForm.controls.firstName.value,
        lastName: addClientUltimateBeneficialOwnerForm.controls.lastName.value,
        middleName:
          addClientUltimateBeneficialOwnerForm.controls.middleName.value,
        gender: addClientUltimateBeneficialOwnerForm.controls.gender.value,
        ssn: addClientUltimateBeneficialOwnerForm.controls.ssn.value,
        taxIdentificationNumber:
          addClientUltimateBeneficialOwnerForm.controls.taxIdentificationNumber
            .value,
        countryOfBirthId:
          addClientUltimateBeneficialOwnerForm.controls.countryOfBirthId.value,
        dateOfBirth:
          addClientUltimateBeneficialOwnerForm.controls.dateOfBirth.value,
        appointmentDate:
          addClientUltimateBeneficialOwnerForm.controls.appointmentDate.value,
        resignationDate:
          addClientUltimateBeneficialOwnerForm.controls.resignationDate.value,
        customFields: customFieldsIndividual,
      },

      customFields: customFields,
    };
    return this.postJson(
      "/clientCorporates/" + clientId + "/ultimateBeneficialOwners",
      JSON.stringify(body)
    );
  }

  public addClientBeneficiary(
    addBeneficiaryForm: FormGroup,
    clientId: string,
    customFieldsIndividual: any,
    customFieldsCorporate: any,
    customFields: any,
    profileId: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      clientShareholderId:
        addBeneficiaryForm.controls.clientShareholderId.value,
      includeInEvaluation:
        addBeneficiaryForm.controls.includeInEvaluation.value,
      isControllingPerson:
        addBeneficiaryForm.controls.isControllingPerson.value,
      controllingPersonType:
        addBeneficiaryForm.controls.controllingPersonTypeId.value,
      controllingPersonTypeOther:
        addBeneficiaryForm.controls.controllingPersonTypeOther.value,
      profileId: profileId,
      rateWeightPercentage:
        addBeneficiaryForm.controls.rateWeightPercentage.value,
      numberOfShares: addBeneficiaryForm.controls.numberOfShares.value,
      clientType: addBeneficiaryForm.controls.clientType.value,
      externalReference:
        addBeneficiaryForm.controls.externalReference.value == ""
          ? null
          : addBeneficiaryForm.controls.externalReference.value,
      clientStatusId: addBeneficiaryForm.controls.clientStatus.value,
      ignoreAutoOngoingMonitoringStatus:
        addBeneficiaryForm.controls.ignoreAutoOngoingMonitoringStatus.value,
      clientCategoryId: addBeneficiaryForm.controls.clientCategory.value,
      joinedDate: addBeneficiaryForm.controls.joinedDate.value,
      notes: addBeneficiaryForm.controls.notes.value,
      phone: addBeneficiaryForm.controls.phone.value,
      email: addBeneficiaryForm.controls.email.value,

      clientIndividual: {
        firstName: addBeneficiaryForm.controls.firstName.value,
        lastName: addBeneficiaryForm.controls.lastName.value,
        middleName: addBeneficiaryForm.controls.middleName.value,
        gender: addBeneficiaryForm.controls.gender.value,
        ssn: addBeneficiaryForm.controls.ssn.value,
        taxIdentificationNumber:
          addBeneficiaryForm.controls.taxIdentificationNumber.value,
        countryOfBirthId: addBeneficiaryForm.controls.countryOfBirthId.value,
        dateOfBirth: addBeneficiaryForm.controls.dateOfBirth.value,
        customFields: customFieldsIndividual,
      },

      clientCorporate: {
        registeredName: addBeneficiaryForm.controls.registeredName.value,
        registrationNumber:
          addBeneficiaryForm.controls.registrationNumber.value,
        authorisedCapital: addBeneficiaryForm.controls.authorisedCapital.value,
        issuedCapital: addBeneficiaryForm.controls.issuedCapital.value,
        countryOfIncorporationId:
          addBeneficiaryForm.controls.countryOfIncorporationId.value,
        customFields: customFieldsCorporate,
        defaultFullStructureEvaluation:
          addBeneficiaryForm.controls.defaultFullStructureEvaluation.value,
      },
      appointmentDate: addBeneficiaryForm.controls.appointmentDate.value,
      resignationDate: addBeneficiaryForm.controls.resignationDate.value,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCorporates/" + clientId + "/beneficiaries",
      JSON.stringify(body)
    );
  }

  public addClientAction(actionBody: any, clientId: number): Observable<any> {
    actionBody.clientId = clientId;
    return this.postJson(
      "/clientCommon/" + clientId + "/actions",
      JSON.stringify(actionBody)
    );
  }

  public addClientCaseAction(
    actionBody: any,
    clientId: number,
    caseId: number
  ): Observable<any> {
    actionBody.caseId = caseId;
    return this.postJson(
      "/clientCommon/" + clientId + "/cases/" + caseId + "/actions",
      JSON.stringify(actionBody)
    );
  }

  public addTransactionScreeningCaseAction(
    actionBody: any,
    transactionScreeningCaseId: number
  ): Observable<any> {
    actionBody.transactionScreeningCaseId = transactionScreeningCaseId;
    return this.postJson(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/actions",
      JSON.stringify(actionBody)
    );
  }

  public addRuleAction(actionBody: any, ruleId: string): Observable<any> {
    actionBody.ruleId = ruleId;
    return this.postJson(
      "/settings/rules/" + ruleId + "/actions",
      JSON.stringify(actionBody)
    );
  }

  public addClientCaseAnalysis(
    analysisForm: FormGroup,
    clientId: number,
    caseId: number
  ): Observable<any> {
    var body = {
      clientCaseId: caseId,
      name: analysisForm.controls.name.value,
      value: analysisForm.controls.value.value,
      source: analysisForm.controls.source.value,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/cases/" + caseId + "/analysis",
      JSON.stringify(body)
    );
  }

  public manuallyRunRulesForClient(
    ruleId: any,
    clientId: number
  ): Observable<any> {
    let ruleIDs: Array<number> = [ruleId];
    var body = {
      clientId: clientId,
      createCases: false,
      ruleIds: ruleIDs,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/cases/manuallyRunRulesForClient",
      JSON.stringify(body)
    );
  }

  public addClientDocument(
    addDocumentForm: FormGroup,
    clientId: string,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      documentId: addDocumentForm.controls.documentId.value,
      collectionStatus: addDocumentForm.controls.collectionStatusId.value,
      documentForm: addDocumentForm.controls.documentFormId.value,
      documentNumber: addDocumentForm.controls.documentNumber.value,
      countryOfIssueId: addDocumentForm.controls.countryOfIssueId.value,
      dateOfIssue: addDocumentForm.controls.dateOfIssue.value,
      expirationDate: addDocumentForm.controls.expirationDate.value,
      collectionDate: addDocumentForm.controls.collectionDate.value,
      certifiedDate: addDocumentForm.controls.certifiedDate.value,
      certifiedByUsername:
        addDocumentForm.controls.certifiedByUsername.value != ""
          ? addDocumentForm.controls.certifiedByUsername.value
          : null,
      reviewedByUsername:
        addDocumentForm.controls.reviewedByUsername.value != ""
          ? addDocumentForm.controls.reviewedByUsername.value
          : null,
      customFields: customFields,
      comment: addDocumentForm.controls.comment.value,
      directoryPathOrUrl: addDocumentForm.controls.directoryPathOrUrl.value,
    };
    return this.postJson(
      `/clientCommon/${clientId}/documents`,
      JSON.stringify(body)
    );
  }

  public openClientDocumentDirectory(
    clientId: any,
    documentId: any,
    path: string
  ): Observable<any> {
    let body = {
      clientId: clientId,
      documentId: documentId,
      path: path,
    };
    return this.postJson(
      `/clientCommon/${clientId}/documents/${documentId}`,
      JSON.stringify(body)
    );
  }

  public addEmailTemplate(
    addEmailTemplateForm: FormGroup,
    emailBody: string
  ): Observable<any> {
    let body = {
      Subject: addEmailTemplateForm.get("subject").value,
      Body: emailBody,
      IntroEndingId: addEmailTemplateForm.get("introEndingId").value,
      MappingReference: addEmailTemplateForm.get("mappingReference").value,
    };
    return this.postJson(`/settings/emailTemplates`, JSON.stringify(body));
  }

  public addIntroEnding(
    addIntroEndingForm: FormGroup,
    ending: string
  ): Observable<any> {
    let body = {
      Intro: addIntroEndingForm.get("intro").value,
      Ending: ending,
      Name: addIntroEndingForm.get("name").value,
      MappingReference: addIntroEndingForm.get("mappingReference").value,
    };
    return this.postJson(`/settings/introEndings`, JSON.stringify(body));
  }

  public addClientAccount(
    addClientAccountForm: FormGroup,
    clientId: number,
    paymentMethods: any,
    customFields: any
  ): Observable<any> {
    let body = {
      ClientId: clientId,
      BankId: addClientAccountForm.controls.bank.value,
      SwiftCode: addClientAccountForm.controls.swiftCode.value,
      CountryId: addClientAccountForm.controls.country.value,
      Iban: addClientAccountForm.controls.iban.value,
      AccountNumber: addClientAccountForm.controls.accountNumber.value,
      DateCreated: addClientAccountForm.controls.dateCreated.value,
      CurrencyInformationId: addClientAccountForm.controls.currency.value,
      Status: addClientAccountForm.controls.status.value,
      paymentMethodIds: paymentMethods,
      comment: addClientAccountForm.controls.comment.value,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/accounts",
      JSON.stringify(body)
    );
  }

  public addClientCard(
    addClientCardForm: FormGroup,
    clientId: number
  ): Observable<any> {
    let body = {
      cardNumber: addClientCardForm.controls.cardNumber.value,
      cardExpiryDate: addClientCardForm.controls.cardExpiryDate.value,
      cardholder: addClientCardForm.controls.cardholder.value,
      clientAccountId: addClientCardForm.controls.bankAccount.value,
      bankId: addClientCardForm.controls.bank.value,
      externalReference: addClientCardForm.controls.externalReference.value,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/cards",
      JSON.stringify(body)
    );
  }

  public editClientCard(
    editClientCardForm: FormGroup,
    clientId: number,
    cardId: number
  ): Observable<any> {
    let body = {
      id: cardId,
      cardNumber: editClientCardForm.controls.cardNumber.value,
      cardExpiryDate: editClientCardForm.controls.cardExpiryDate.value,
      cardholder: editClientCardForm.controls.cardholder.value,
      clientAccountId: editClientCardForm.controls.bankAccount.value,
      bankId: editClientCardForm.controls.bank.value,
      externalReference: editClientCardForm.controls.externalReference.value,
    };
    return this.putJson(
      "/clientCommon/" + clientId + "/cards",
      JSON.stringify(body)
    );
  }

  public getClientCardById(clientId: number, cardId: number): Observable<any> {
    return this.fetch(`/clientCommon/${clientId}/cards/${cardId}`);
  }

  public deleteClientCard(clientId: number, cardId: number): Observable<any> {
    return this.delete(`/clientCommon/${clientId}/cards/${cardId}`, "");
  }

  public addClientGamingAccount(
    addClientGamingAccountForm: FormGroup,
    clientId: number,
    customFields: any
  ): Observable<any> {
    let formControls = addClientGamingAccountForm.controls;

    let body = {
      ClientId: clientId,
      GamingAccountStatusId:
        addClientGamingAccountForm.controls.gamingAccountStatusId.value,
      ExternalReference:
        addClientGamingAccountForm.controls.externalReference.value,
      SignUpDate: addClientGamingAccountForm.controls.signUpDate.value,
      SignUpIP: addClientGamingAccountForm.controls.signUpIP.value,
      SignUpDevice: addClientGamingAccountForm.controls.signUpDevice.value,
      Comment: addClientGamingAccountForm.controls.comment.value,
      CustomFields: customFields,
      Balance: formControls.balance.value,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/gamingaccounts",
      JSON.stringify(body)
    );
  }

  public addClientAccountSignatory(
    addClientAccountForm: FormGroup,
    clientId: number,
    accountId: number,
    profileId: number
  ): Observable<any> {
    let body = {
      ClientAccountId: accountId,
      ProfileId: profileId,
      AppointmentDate: addClientAccountForm.controls.appointmentDate.value,
      ResignationDate: addClientAccountForm.controls.resignationDate.value,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/accounts/" + accountId + "/signatories",
      JSON.stringify(body)
    );
  }

  public addClientGamingAccountHistory(
    addForm: FormGroup,
    clientId: number,
    accountId: number
  ): Observable<any> {
    let body = {
      ClientId: clientId,
      ClientGamingAccountId: accountId,
      HistoryDate: addForm.controls.historyDate.value,
      LoginIpAddress:
        addForm.controls.loginIpAddress.value == ""
          ? null
          : addForm.controls.loginIpAddress.value,
      LoginCountry:
        addForm.controls.loginCountry.value == ""
          ? null
          : addForm.controls.loginCountry.value,
      LoginStatus:
        addForm.controls.loginStatus.value == ""
          ? null
          : addForm.controls.loginStatus.value,
      LoginType:
        addForm.controls.loginType.value == ""
          ? null
          : addForm.controls.loginType.value,
      DeviceUsed:
        addForm.controls.deviceUsed.value == ""
          ? null
          : addForm.controls.deviceUsed.value,
    };
    return this.postJson(
      "/clientCommon/" +
      clientId +
      "/gamingaccounts/" +
      accountId +
      "/histories",
      JSON.stringify(body)
    );
  }

  public addClientContract(
    addClientcontractForm: FormGroup,
    clientId: number,
    customFields: any
  ): Observable<any> {
    let body = {
      ClientId: clientId,
      PolicyNumber: addClientcontractForm.controls.policyNumber.value,
      PolicyParticulars: addClientcontractForm.controls.policyParticulars.value,
      InitialAmount: addClientcontractForm.controls.initialAmount.value,
      YearlyAmount: addClientcontractForm.controls.yearlyAmount.value,
      ContractPolicyStatusId:
        addClientcontractForm.controls.contractPolicyStatusId.value,
      ContractPolicyProductId:
        addClientcontractForm.controls.contractPolicyProductId.value,
      PolicyInceptionDate:
        addClientcontractForm.controls.policyInceptionDate.value,
      PolicyExpirationDate:
        addClientcontractForm.controls.policyExpirationDate.value,
      policyPlan: addClientcontractForm.controls.policyPlan.value,
      assignedTo: addClientcontractForm.controls.assignedTo.value,
      applicationApprovalReference:
        addClientcontractForm.controls.applicationApprovalReference.value,
      bankName: addClientcontractForm.controls.bankName.value,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/contracts",
      JSON.stringify(body)
    );
  }

  public addClientContractAgent(
    addClientContractAgentForm: FormGroup,
    clientId: number,
    clientContractId: number,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      clientContractId: clientContractId,
      agentName: addClientContractAgentForm.controls.name.value,
      agentCode: addClientContractAgentForm.controls.code.value,
      phoneNumber: addClientContractAgentForm.controls.phoneNumber.value,
      split: addClientContractAgentForm.controls.split.value,
      customFields: customFields,
    };
    return this.postJson(
      `/clientCommon/" + ${clientId} + "/contracts/${clientContractId}/agents`,
      JSON.stringify(body)
    );
  }

  public addClientContractSurrender(
    addClientContractSurrenderForm: FormGroup,
    clientId: number,
    clientContractId: number,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      clientContractId: clientContractId,
      surrender: addClientContractSurrenderForm.controls.surrender.value,
      amount: addClientContractSurrenderForm.controls.amount.value,
      date: addClientContractSurrenderForm.controls.date.value,
      surrenderType:
        addClientContractSurrenderForm.controls.surrenderType.value,
      customFields: customFields,
    };
    return this.postJson(
      `/clientCommon/" + ${clientId} + "/contracts/${clientContractId}/surrenders`,
      JSON.stringify(body)
    );
  }

  public addClientContractBooster(
    addClientContractBoosterForm: FormGroup,
    clientId: number,
    clientContractId: number,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      clientContractId: clientContractId,
      amount: addClientContractBoosterForm.controls.amount.value,
      date: addClientContractBoosterForm.controls.date.value,
      customFields: customFields,
    };
    return this.postJson(
      `/clientCommon/" + ${clientId} + "/contracts/${clientContractId}/boosters`,
      JSON.stringify(body)
    );
  }

  public addClientContractBeneficiary(
    addClientContractBeneficiaryForm: FormGroup,
    clientId: number,
    clientContractId: number,
    profileId: number,
    customFieldsIndividual: any,
    customFieldsCorporate: any,
    customFields: any
  ): Observable<any> {
    let body = {
      clientContractId: clientContractId,
      profileId: profileId,
      contractPolicyBeneficiaryRelationId:
        addClientContractBeneficiaryForm.controls.beneficiaryRelationId.value,
      clientType: addClientContractBeneficiaryForm.controls.clientType.value,
      externalReference:
        addClientContractBeneficiaryForm.controls.externalReference.value == ""
          ? null
          : addClientContractBeneficiaryForm.controls.externalReference.value,
      clientStatusId:
        addClientContractBeneficiaryForm.controls.clientStatus.value,
      ignoreAutoOngoingMonitoringStatus:
        addClientContractBeneficiaryForm.controls
          .ignoreAutoOngoingMonitoringStatus.value,
      clientCategoryId:
        addClientContractBeneficiaryForm.controls.clientCategory.value,
      joinedDate: addClientContractBeneficiaryForm.controls.joinedDate.value,
      notes: addClientContractBeneficiaryForm.controls.notes.value,
      phone: addClientContractBeneficiaryForm.controls.phone.value,
      email: addClientContractBeneficiaryForm.controls.email.value,

      clientIndividual: {
        firstName: addClientContractBeneficiaryForm.controls.firstName.value,
        lastName: addClientContractBeneficiaryForm.controls.lastName.value,
        middleName: addClientContractBeneficiaryForm.controls.middleName.value,
        gender: addClientContractBeneficiaryForm.controls.gender.value,
        ssn: addClientContractBeneficiaryForm.controls.ssn.value,
        taxIdentificationNumber:
          addClientContractBeneficiaryForm.controls.taxIdentificationNumber
            .value,
        countryOfBirthId:
          addClientContractBeneficiaryForm.controls.countryOfBirthId.value,
        dateOfBirth:
          addClientContractBeneficiaryForm.controls.dateOfBirth.value,
        customFields: customFieldsIndividual,
      },

      clientCorporate: {
        registeredName:
          addClientContractBeneficiaryForm.controls.registeredName.value,
        registrationNumber:
          addClientContractBeneficiaryForm.controls.registrationNumber.value,
        authorisedCapital:
          addClientContractBeneficiaryForm.controls.authorisedCapital.value,
        issuedCapital:
          addClientContractBeneficiaryForm.controls.issuedCapital.value,
        countryOfIncorporationId:
          addClientContractBeneficiaryForm.controls.countryOfIncorporationId
            .value,
        customFields: customFieldsCorporate,
        defaultFullStructureEvaluation:
          addClientContractBeneficiaryForm.controls
            .defaultFullStructureEvaluation.value,
      },
      appointmentDate:
        addClientContractBeneficiaryForm.controls.appointmentDate.value,
      resignationDate:
        addClientContractBeneficiaryForm.controls.resignationDate.value,

      customFields: customFields,
    };

    return this.postJson(
      `/clientCommon/${clientId}/contracts/${clientContractId}/beneficiaries`,
      JSON.stringify(body)
    );
  }

  public addClientContractTransaction(
    addClientContractTransactionForm: FormGroup,
    clientId: number,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      clientContractId:
        addClientContractTransactionForm.controls.clientContractId.value,
      paymentAmount:
        addClientContractTransactionForm.controls.paymentAmount.value,
      paymentDate: addClientContractTransactionForm.controls.paymentDate.value,
      paymentDescription:
        addClientContractTransactionForm.controls.paymentDescription.value,
      reasonForTransaction:
        addClientContractTransactionForm.controls.reasonForTransaction.value,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/contractTransactions",
      JSON.stringify(body)
    );
  }

  public addClientContractCollection(
    addClientContractCollectionForm: FormGroup,
    clientId: number,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      clientContractId:
        addClientContractCollectionForm.controls.clientContractId.value,
      paymentMethodId:
        addClientContractCollectionForm.controls.paymentMethodId.value,
      paymentAmount:
        addClientContractCollectionForm.controls.paymentAmount.value,
      paymentDate: addClientContractCollectionForm.controls.paymentDate.value,
      paymentDescription:
        addClientContractCollectionForm.controls.paymentDescription.value,
      reasonForTransaction:
        addClientContractCollectionForm.controls.reasonForTransaction.value,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/contractCollections",
      JSON.stringify(body)
    );
  }

  public GetClassificationMeans(
    clientType: any,
    regulationGroupId: any
  ): Observable<any> {
    let body = {
      clientType: clientType,
      regulationGroupId: regulationGroupId,
    };

    return this.postJson(
      "/mlreports/classificationMeans",
      JSON.stringify(body)
    );
  }

  public getDocumentsReport(
    searchForm: FormGroup,
    documentExpirationTypes: any,
    documentTypes: any
  ): Observable<any> {
    let body = {
      DocumentExpirationTypes: documentExpirationTypes,
      Mandatory: searchForm.controls.mandatory.value,
      DateCreatedFrom: searchForm.controls.createdFromDate.value,
      DateCreatedTo: searchForm.controls.createdToDate.value,
      ModifiedBy: searchForm.controls.modifiedByParam.value,
      DocumentTypes: documentTypes,
    };
    return this.postJson(
      "/gridreports/getdocumentsreport",
      JSON.stringify(body)
    );
  }

  public getLoginLogReport(searchForm: FormGroup): Observable<any> {
    let body = {
      DateFrom: searchForm.controls.dateFrom.value,
      DateTo: searchForm.controls.dateTo.value,
    };
    return this.postJson(
      "/administration/getLoginLogReport",
      JSON.stringify(body)
    );
  }

  public getCustomLogsReport(searchForm: FormGroup): Observable<any> {
    let body = {
      ReferenceCall: searchForm.controls.referenceCall.value,
      SessionStartFrom: searchForm.controls.sessionStartFrom.value,
      SessionStartTo: searchForm.controls.sessionStartTo.value,
    };
    return this.postJson(
      "/administration/getCustomLogsReport",
      JSON.stringify(body)
    );
  }

  public getFieldLogSetups(): Observable<any> {
    return this.fetch("/administration/fieldLogSetup");
  }

  public ExportXmlCbc(searchform: any): Observable<any> {
    let body = {
      ExportDate: searchform.controls.exportDate.value,
      Email: searchform.controls.email.value,
      EnableEmail: searchform.controls.enableEmail.value,
      GetXmlWithDetails: searchform.controls.getXmlWithDetails.value,
    };

    return this.postJson("/processes/xml/cbc", JSON.stringify(body));
  }

  public getHangfireJobLogReport(searchForm: FormGroup): Observable<any> {
    let body = {
      DateFrom: searchForm.controls.dateFrom.value,
      DateTo: searchForm.controls.dateTo.value,
    };
    return this.postJson(
      "/administration/getHangfireJobLogReport",
      JSON.stringify(body)
    );
  }

  public getEmailsLogs(searchForm: FormGroup): Observable<any> {
    let body = {
      dateCreatedFrom: searchForm.controls.createdFromDate.value,
      dateCreatedTo: searchForm.controls.createdToDate.value,
      status: searchForm.controls.status.value,
    };
    return this.postJson("/administration/getEmailsLogs", JSON.stringify(body));
  }

  public getClientsPerCriterionAnswerReport(
    searchForm: FormGroup,
    criterionAnswers: any,
    clientStatuses: any
  ): Observable<any> {
    let body = {
      CriterionId: searchForm.controls.criterionId.value,
      CriterionAnswerIds: criterionAnswers,
      DateCreatedFrom: searchForm.controls.createdFromDate.value,
      DateCreatedTo: searchForm.controls.createdToDate.value,
      ModifiedBy: searchForm.controls.modifiedByParam.value,
      clientStatusIds: clientStatuses,
    };
    return this.postJson(
      "/gridreports/getclientspercriterionanswerreport",
      JSON.stringify(body)
    );
  }

  public getClientsOldEvaluationPerCriterionAnswerReport(
    searchForm: FormGroup
  ): Observable<any> {
    let body = {
      Criterion: searchForm.controls.criterion.value,
      Answer: searchForm.controls.criterionAnswers.value,
      IncludeAllClientOldEvaluations:
        searchForm.controls.includeAllClientOldEvaluations.value,
      EvaluationGradeID: searchForm.controls.evaluationGradeId.value,
    };
    return this.postJson(
      "/gridreports/getclientsoldevaluationpercriterionanswerreport",
      JSON.stringify(body)
    );
  }

  public getClientsPerQuestionAnswerReport(
    searchForm: FormGroup,
    questionAnswers: any,
    clientStatuses: any
  ): Observable<any> {
    let body = {
      QuestionId: searchForm.controls.questionId.value,
      QuestionAnswerIds: questionAnswers,
      DateCreatedFrom: searchForm.controls.createdFromDate.value,
      DateCreatedTo: searchForm.controls.createdToDate.value,
      ModifiedBy: searchForm.controls.modifiedByParam.value,
      clientStatusIds: clientStatuses,
    };
    return this.postJson(
      "/gridreports/getclientsperquestionanswerreport",
      JSON.stringify(body)
    );
  }

  /*
  public getCasesReport(searchForm: FormGroup, caseStatuses: any, caseSuspicionLevels: any, clients: any, clientStatuses: any, clientCategories: any, regulationGroups: any, rules: any): Observable<any> {
    let body = {
      "DateCreatedFrom": searchForm.controls.createdFromDate.value,
      "DateCreatedTo": searchForm.controls.createdToDate.value,
      "ModifiedBy": searchForm.controls.modifiedByParam.value,
      "CaseStatuses": caseStatuses,
      "CaseSuspicionLevels": caseSuspicionLevels,
      "ClientIds": clients,
      "clientStatuses": clientStatuses,
      "clientCategories": clientCategories,
      "regulationGroups": regulationGroups,
      "rules": rules,
    }
    return this.postJson("/gridreports/getcasesreport", JSON.stringify(body));
  }
*/
  public getLastEvaluationResultsReport(clients: any): Observable<any> {
    let body = {
      clientIds: clients,
    };
    return this.postJson(
      "/gridreports/getlastevaluationresultsreport",
      JSON.stringify(body)
    );
  }

  public getEntityPositionReport(clientId: number): Observable<any> {
    let body = {
      clientId: clientId,
    };
    return this.postJson(
      "/gridreports/getentitypositionreport",
      JSON.stringify(body)
    );
  }

  public getProfilesReport(clients: any): Observable<any> {
    let body = {
      clientIds: JSON.stringify(clients),
    };
    return this.postJson(
      "/gridreports/getprofilesreport",
      JSON.stringify(body)
    );
  }

  public getFollowUpEvaluationsReport(clients: any): Observable<any> {
    let body = {
      ClientIds: clients,
    };
    return this.postJson(
      "/gridreports/getfollowupevaluationsreport",
      JSON.stringify(body)
    );
  }

  public getScreeningListReport(clients: any): Observable<any> {
    let body = {
      ClientIds: clients,
    };
    return this.postJson(
      "/gridreports/getscreeninglistreport",
      JSON.stringify(body)
    );
  }

  public getTransactionsEvaluationReport(clients: any): Observable<any> {
    let body = {
      ClientIds: clients,
    };
    return this.postJson(
      "/gridreports/gettransactionsevaluationreport",
      JSON.stringify(body)
    );
  }

  public getUBOOfClientsReport(body: any): Observable<any> {
    return this.postJson(
      "/gridreports/getuboofclientsreport",
      JSON.stringify(body)
    );
  }

  public getInternalScreenNotificationLogsReport(
    searchForm: FormGroup
  ): Observable<any> {
    let body = {
      dateCreatedFrom: searchForm.controls.createdFromDate.value,
      dateCreatedTo: searchForm.controls.createdToDate.value,
      notificationStatus:
        searchForm.controls.notificationStatus.value == ""
          ? searchForm.controls.notificationStatus.value
          : +searchForm.controls.notificationStatus.value,
    };
    return this.postJson(
      "/gridreports/getInternalScreenNotificationLogsReport",
      JSON.stringify(body)
    );
  }

  public getCasesReportAndChecked(
    searchForm: FormGroup,
    rules: any,
    regulationGroups: any,
    caseStatuses: any,
    caseSuspicionLevels: any,
    clients: any,
    customFieldIdIndividual: number,
    customFieldValuesIndividual: any,
    customFieldIdCorporate: number,
    customFieldValuesCorporate: any,
    clientStatuses: any,
    caseWorkflowStatuses: any,
    selectedQuestionValue: any,
    selectedQuestionValueTypeId: any,
    selectedCriterionValue: any,
    criterionAnswers: any,
    questionAnswers: any,
    questionAnswerOpenEndedComments: any
  ): Observable<any> {
    let body = {
      dateCreatedFrom: searchForm.controls.createdFromDate.value,
      dateCreatedTo: searchForm.controls.createdToDate.value,
      ignoreCasesWithActions: searchForm.controls.ignoreCasesWithActions.value,
      preTickDuplicateCases: searchForm.controls.preTickDuplicateCases.value,
      caseStatuses: caseStatuses,
      caseSuspicionLevels: caseSuspicionLevels,
      rules: rules,
      regulationGroups: regulationGroups,
      customFieldIdIndividual: customFieldIdIndividual,
      customFieldValuesIndividual: customFieldValuesIndividual,
      customFieldIdCorporate: customFieldIdCorporate,
      customFieldValuesCorporate: customFieldValuesCorporate,
      clientIds: clients,

      clientStatuses: clientStatuses,
      caseWorkflowStatuses: caseWorkflowStatuses,
      questionId: selectedQuestionValue,
      questionnaireTypeId: selectedQuestionValueTypeId,
      criterionId: selectedCriterionValue,
      criterionAnswerIds: criterionAnswers,
      questionAnswerIds: questionAnswers,
      questionAnswerOpenEndedComments: questionAnswerOpenEndedComments,
    };
    return this.postJson(
      "/processes/getcasesreportandchecked",
      JSON.stringify(body)
    );
  }

  public retrieveKompanyComFailedDocuments(ids: any[]): Observable<any> {
    let body = {
      ids: ids,
    };

    return this.postJson(
      "/processes/retrieveKompanyComFailedDocuments",
      JSON.stringify(body)
    );
  }

  public handleTransactionScreeningCases(
    searchForm: FormGroup,
    regulationGroups: any,
    caseStatuses: any,
    clients: any,
    customFieldIdIndividual: number,
    customFieldValuesIndividual: any,
    customFieldIdCorporate: number,
    customFieldValuesCorporate: any
  ): Observable<any> {
    let body = {
      dateCreatedFrom: searchForm.controls.createdFromDate.value,
      dateCreatedTo: searchForm.controls.createdToDate.value,
      caseStatuses: caseStatuses,
      regulationGroups: regulationGroups,
      customFieldIdIndividual: customFieldIdIndividual,
      customFieldValuesIndividual: customFieldValuesIndividual,
      customFieldIdCorporate: customFieldIdCorporate,
      customFieldValuesCorporate: customFieldValuesCorporate,
      clientIds: clients,
    };
    return this.postJson(
      "/processes/handleTransactionScreeningCases",
      JSON.stringify(body)
    );
  }

  public handleTransactionScreeningCasesCountStatus(body): Observable<any> {

    return this.postJson("/processes/handleTransactionScreeningCasesCountStatus", JSON.stringify(body));
  }

  public deleteClientDocuments(clientDocumentIds: number[]): Observable<any> {
    let body = {
      clientDocumentIds: clientDocumentIds,
    };
    return this.postJson(
      "/processes/handleClientDocuments/delete-client-documents",
      JSON.stringify(body)
    );
  }

  public updateDocumentsStatus(
    clientDocumentIds: number[],
    collectionStatus: any
  ): Observable<any> {
    let body = {
      clientDocumentIds: clientDocumentIds,
      collectionStatus: collectionStatus,
    };
    return this.putJson(
      "/processes/handleClientDocuments/update-documents-status",
      JSON.stringify(body)
    );
  }

  public updateClientCases(
    updateClientCasesForm: FormGroup,
    selectedClientCases: any
  ): Observable<any> {
    let body = {
      status:
        updateClientCasesForm.controls.status.value != ""
          ? updateClientCasesForm.controls.status.value
          : null,
      suspicionLevel:
        updateClientCasesForm.controls.suspicionLevel.value != ""
          ? updateClientCasesForm.controls.suspicionLevel.value
          : null,
      priority:
        updateClientCasesForm.controls.priority.value != ""
          ? updateClientCasesForm.controls.priority.value
          : null,
      ownerUsername:
        updateClientCasesForm.controls.ownerUsername.value != ""
          ? updateClientCasesForm.controls.ownerUsername.value
          : null,
      clientCases: selectedClientCases,
      suspicionLevelComment:
        updateClientCasesForm.controls.suspicionLevelComment.value,
    };
    return this.putJson("/processes/updateClientCases", JSON.stringify(body));
  }

  public addDocumentsToClients(
    selectedClients: any,
    selectedDocuments: any
  ): Observable<any> {
    let body = {
      clientIds: selectedClients,
      documentIds: selectedDocuments,
    };
    return this.putJson(
      "/processes/addDocumentsToClients",
      JSON.stringify(body)
    );
  }

  public addClientTags(selectedClients: any, selectedTags: any, dateFrom: any, dateTo: any): Observable<any> {
    let body = {
      "clientIds": selectedClients,
      "tagIds": selectedTags,
      "dateFrom": dateFrom,
      "dateTo": dateTo
    }
    return this.putJson("/processes/AddClientTags", JSON.stringify(body));
  }

  public clientTaskExists(selectedClients: any, selectedTags: any, dateFrom: any, dateTo: any): Observable<any> {
    let body = {
      "clientIds": selectedClients,
      "tagIds": selectedTags,
      "dateFrom": dateFrom,
      "dateTo": dateTo
    }
    return this.postJson("/processes/ClientTagExists", JSON.stringify(body));
  }

  public changeToClient(id: any): Observable<any> {
    let body = {};
    return this.putJson(
      `/clientCommon/${id}/changeToClient`,
      JSON.stringify(body)
    );
  }

  public changeClientStatus(id: any): Observable<any> {
    let body = {};
    return this.putJson(
      `/clientCommon/${id}/changeStatus`,
      JSON.stringify(body)
    );
  }

  public updateTransactionScreeningCases(
    form: FormGroup,
    selectedTransactionScreeningCases: any
  ): Observable<any> {
    let body = {
      caseStatus:
        form.controls.caseStatus.value != ""
          ? form.controls.caseStatus.value
          : null,
      manualStatus:
        form.controls.manualStatus.value != ""
          ? form.controls.manualStatus.value
          : null,
      ownerUsername:
        form.controls.ownerUsername.value != ""
          ? form.controls.ownerUsername.value
          : null,
      transactionScreeningCaseIds: selectedTransactionScreeningCases,
    };
    return this.putJson(
      "/processes/updateTransactionScreeningCases",
      JSON.stringify(body)
    );
  }

  public addCustomActionToCases(
    actionBody: any,
    selectedClientCases: any
  ): Observable<any> {
    let body = {
      Action: actionBody,
      ClientCases: selectedClientCases,
    };
    return this.putJson(
      "/processes/addCustomActionToCases",
      JSON.stringify(body)
    );
  }

  public addCustomActionToTransactionScreeningCases(
    actionBody: any,
    selectedTransactionScreeningCaseIds: any
  ): Observable<any> {
    let body = {
      action: actionBody,
      transactionScreeningCaseIds: selectedTransactionScreeningCaseIds,
    };
    return this.putJson(
      "/processes/addCustomActionToTransactionScreeningCases",
      JSON.stringify(body)
    );
  }

  public addMultipleActionsToCases(
    selectedClientCases: any,
    selectedCaseActions: any
  ): Observable<any> {
    let body = {
      ClientCases:
        selectedClientCases && selectedClientCases.length > 0
          ? selectedClientCases.map((el) => ({ id: el }))
          : [],
      ActionsIds: selectedCaseActions,
    };
    return this.putJson(
      "/processes/addMultipleActionsToCases",
      JSON.stringify(body)
    );
  }

  public addMultipleActionsToTransactionScreeningCases(
    selectedTransactionScreeningCaseIds: any,
    selectedCaseActionIds: any
  ): Observable<any> {
    let body = {
      transactionScreeningCaseIds: selectedTransactionScreeningCaseIds,
      caseActionsIds: selectedCaseActionIds,
    };
    return this.putJson(
      "/processes/addMultipleActionsToTransactionScreeningCases",
      JSON.stringify(body)
    );
  }

  public updateClientActions(
    updateClientActionsForm: FormGroup,
    selectedClientActions: any
  ): Observable<any> {
    let body = {
      caseStatus:
        updateClientActionsForm.controls.caseStatus.value != ""
          ? updateClientActionsForm.controls.caseStatus.value
          : null,
      actionStatusId:
        updateClientActionsForm.controls.actionStatusId.value != ""
          ? updateClientActionsForm.controls.actionStatusId.value
          : null,
      actionPriority:
        updateClientActionsForm.controls.actionPriority.value != ""
          ? updateClientActionsForm.controls.actionPriority.value
          : null,
      ownerUsername:
        updateClientActionsForm.controls.ownerUsername.value != ""
          ? updateClientActionsForm.controls.ownerUsername.value
          : null,
      assigneeUsername:
        updateClientActionsForm.controls.assigneeUsername.value != ""
          ? updateClientActionsForm.controls.assigneeUsername.value
          : null,
      targetDate:
        updateClientActionsForm.controls.targetDate.value != ""
          ? updateClientActionsForm.controls.targetDate.value
          : null,
      revisedDate:
        updateClientActionsForm.controls.revisedDate.value != ""
          ? updateClientActionsForm.controls.revisedDate.value
          : null,
      implementationPlan:
        updateClientActionsForm.controls.implementationPlan.value != ""
          ? updateClientActionsForm.controls.implementationPlan.value
          : null,
      notes:
        updateClientActionsForm.controls.notes.value != ""
          ? updateClientActionsForm.controls.notes.value
          : null,

      clientActions: selectedClientActions,
    };
    return this.putJson("/processes/updateClientActions", JSON.stringify(body));
  }

  public anonymizeClientCases(selectedClientCases: any): Observable<any> {
    let body = {
      clientCases: selectedClientCases,
    };
    return this.deleteJson(
      "/processes/anonymizeClientCases",
      JSON.stringify(body)
    );
  }

  public deleteClientCases(selectedClientCases: any): Observable<any> {
    let body = {
      clientCases: selectedClientCases,
    };
    return this.deleteJson(
      "/processes/deleteClientCases",
      JSON.stringify(body)
    );
  }

  public deleteTransactionScreeningCases(
    selectedTransactionScreeningCaseIds: any
  ): Observable<any> {
    let body = {
      transactionScreeningCaseIds: selectedTransactionScreeningCaseIds,
    };
    return this.deleteJson(
      "/processes/deleteTransactionScreeningCases",
      JSON.stringify(body)
    );
  }

  public deleteClientActions(selectedClientActions: any): Observable<any> {
    let body = {
      clientActions: selectedClientActions,
    };
    return this.deleteJson(
      "/processes/deleteClientActions",
      JSON.stringify(body)
    );
  }

  public getActionsReport(
    searchForm: FormGroup,
    actionStatuses: any,
    actionPriorities: any,
    clients: any,
    references: any,
    caseNumbers: any
  ): Observable<any> {
    let body = {
      DateTargetRevisedFrom: searchForm.controls.dateTargetRevisedFrom.value,
      DateTargetRevisedTo: searchForm.controls.dateTargetRevisedTo.value,
      ActionStatuses: actionStatuses,
      ActionPriorities: actionPriorities,
      ClientIds: clients,
      DateCreatedFrom: searchForm.controls.createdFromDate.value,
      DateCreatedTo: searchForm.controls.createdToDate.value,
      ModifiedBy: searchForm.controls.modifiedByParam.value,
      ActionType: searchForm.controls.actionTypeParam.value,
      References: references,
      CaseNumbers: caseNumbers,
    };
    return this.postJson("/gridreports/getactionsreport", JSON.stringify(body));
  }

  public getClientGeneralInfo(body: any): Observable<any> {
    return this.postJson(
      "/gridreports/getClientGeneralInfo",
      JSON.stringify(body)
    );
  }

  public getDynamicReportResult(
    selectedDynamicReport: any,
    selectedDynamicReportExecution: any,
    dynamicReportParameterSetups: any
  ): Observable<any> {
    let body = {
      dynamicReportId: selectedDynamicReport,
      dynamicReportExecutionName: selectedDynamicReportExecution,
      dynamicReportParameterSetups: dynamicReportParameterSetups,
    };

    return this.postJson(
      "/gridreports/getDynamicReportResult",
      JSON.stringify(body)
    );
  }

  public getDynamicReportResultViaEmail(
    selectedDynamicReport: any,
    selectedDynamicReportExecution: any
  ): Observable<any> {
    let body = {
      dynamicReportId: selectedDynamicReport,
      dynamicReportExecutionName: selectedDynamicReportExecution,
    };

    return this.postJson(
      "/gridreports/dynamicReportResultViaEmail",
      JSON.stringify(body)
    );
  }

  public GetDynamicReportEngineSetupByDynamicReportId(
    selectedDynamicReport: any,
    selectedDynamicReportExecution: any
  ): Observable<any> {
    return this.fetch(
      "/settings/dynamicReportEngineSetup?dynamicReportId=" +
      selectedDynamicReport +
      "&dynamicReportExecutionName='" +
      selectedDynamicReportExecution +
      "'"
    );
  }

  public getArchivedClientsReport(
    page: any,
    searchForm: FormGroup,
    regulationGroups: any,
    clientTypes: any
  ): Observable<any> {
    let body = {
      pageNumber: page.pageNumber,
      pageSize: page.size,
      DateOfDeletionFrom: searchForm.controls.deletedFromDate.value,
      DateOfDeletionTo: searchForm.controls.deletedToDate.value,
      RegulationGroups: regulationGroups,
      ClientTypes: clientTypes,
    };
    return this.postJson(
      "/gridreports/getArchivedClientsReport",
      JSON.stringify(body)
    );
  }

  public getDynamicReportEngineSetupParametersByIdAndExecution(
    dynamicReportId: any,
    executionName: any
  ): Observable<any> {
    let body = {
      dynamicReportId: dynamicReportId,
      executionName: executionName,
    };
    return this.postJson(
      "/settings/dynamicReportenginesetups",
      JSON.stringify(body)
    );
  }

  public getAutoOngoingMonitoringClientsReport(
    page: any,
    searchForm: FormGroup,
    regulationGroups: any,
    clientStatuses: any
  ): Observable<any> {
    let body = {
      pageNumber: page.pageNumber,
      pageSize: page.size,
      regulationGroups: regulationGroups,
      clientStatuses: clientStatuses,
    };
    return this.postJson(
      "/gridreports/getAutoOngoingMonitoringClientsReport",
      JSON.stringify(body)
    );
  }

  public handleProfiles(
    selectedProfileIDs: any,
    action: any,
    restoreConnections: any = false
  ): Observable<any> {
    let body = {
      Profiles: selectedProfileIDs,
      Action: action,
      RestoreConnections: restoreConnections,
    };
    return this.putJson("/processes/handleProfiles", JSON.stringify(body));
  }

  public syncProfilesToOtherRegulationGroups(
    selectedProfileIds: any,
    syncProfilesForm: FormGroup
  ) {
    let body = {
      Profiles: selectedProfileIds,
      DestinationRegulationGroupIds:
        syncProfilesForm.controls.regulationGroupIds.value.map(({ id }) => id),
    };
    return this.putJson(
      "/processes/syncProfilesToOtherRegulationGroups",
      JSON.stringify(body)
    );
  }

  public mergeProfiles(
    mainProfileId: any,
    selectedProfileIDs: any
  ): Observable<any> {
    let body = {
      MainProfileId: mainProfileId,
      ProfileIds: selectedProfileIDs,
    };
    return this.postJson("/processes/mergeProfiles", JSON.stringify(body));
  }

  public getPendingVerificationsReport(
    searchForm: FormGroup,
    regulationGroups: any
  ): Observable<any> {
    let body = {
      joinedFromDate: searchForm.controls.joinedFromDate.value,
      joinedToDate: searchForm.controls.joinedToDate.value,
      clientStatusId: searchForm.controls.clientStatusId.value,
      clientCategoryId: searchForm.controls.clientCategoryId.value,
      regulationGroups: regulationGroups,
    };
    return this.postJson(
      "/gridreports/getpendingverificationsreport",
      JSON.stringify(body)
    );
  }

  public addClientEvaluation(
    clientId: any,
    addClientEvaluationForm: FormGroup
  ): Observable<any> {
    let body = {
      clientId: clientId,
      reasonForEvaluation:
        addClientEvaluationForm.controls.reasonForEvaluation.value,
      fullStructureEvaluation:
        addClientEvaluationForm.controls.fullStructureEvaluation != null
          ? addClientEvaluationForm.controls.fullStructureEvaluation.value
          : null,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/evaluations",
      JSON.stringify(body)
    );
  }

  public addClientQuestionnaire(
    clientId: any,
    addClientQuestionnaireForm: FormGroup
  ): Observable<any> {
    let body = {
      clientId: clientId,
      reasonForQuestionnaire:
        addClientQuestionnaireForm.controls.reasonForQuestionnaire.value,
      questionnaireTypeId:
        addClientQuestionnaireForm.controls.questionnaireType.value,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/questionnaires",
      JSON.stringify(body)
    );
  }

  public addClientEvaluationCriterionJustification(
    clientId: any,
    clientEvaluationId: any,
    clientEvaluationCriterionId: any,
    clientEvaluationCriterionJustification: any
  ): Observable<any> {
    let body = {
      clientEvaluationCriterionId: parseInt(clientEvaluationCriterionId),
      description: clientEvaluationCriterionJustification.description,
      year: parseInt(
        clientEvaluationCriterionJustification.year == ""
          ? new Date().getFullYear()
          : clientEvaluationCriterionJustification.year
      ),
      amount: parseFloat(
        clientEvaluationCriterionJustification.amount == ""
          ? null
          : clientEvaluationCriterionJustification.amount
      ),
    };
    return this.postJson(
      `/clientCommon/${clientId}/evaluations/${clientEvaluationId}/criteria/${clientEvaluationCriterionId}/justifications`,
      JSON.stringify(body)
    );
  }

  public editClientEvaluationCriterionJustification(
    clientId: any,
    clientEvaluationId: any,
    clientEvaluationCriterionId: any,
    clientEvaluationCriterionJustification: any
  ): Observable<any> {
    let body = {
      id: clientEvaluationCriterionJustification.id,
      clientEvaluationCriterionId: parseInt(clientEvaluationCriterionId),
      description: clientEvaluationCriterionJustification.description,
      year: parseInt(
        clientEvaluationCriterionJustification.year == ""
          ? new Date().getFullYear()
          : clientEvaluationCriterionJustification.year
      ),
      amount: parseFloat(
        clientEvaluationCriterionJustification.amount == ""
          ? 0.0
          : clientEvaluationCriterionJustification.amount
      ),
    };
    return this.putJson(
      `/clientCommon/${clientId}/evaluations/${clientEvaluationId}/criteria/${clientEvaluationCriterionId}/justifications/${clientEvaluationCriterionJustification.id}`,
      JSON.stringify(body)
    );
  }

  public deleteClientEvaluationCriterionJustification(
    clientId: any,
    clientEvaluationId: any,
    clientEvaluationCriterionId: any,
    clientEvaluationCriterionJustificationId: any
  ): Observable<any> {
    return this.delete(
      `/clientCommon/${clientId}/evaluations/${clientEvaluationId}/criteria/${clientEvaluationCriterionId}/justifications/${clientEvaluationCriterionJustificationId}`,
      ""
    );
  }

  public addClientInvestmentAccount(
    addClientInvestmentAccountForm: FormGroup,
    clientId: number,
    customFields: any
  ): Observable<any> {
    let controls = addClientInvestmentAccountForm.controls;
    let body = {
      ClientId: clientId,
      AccountName: controls.accountName.value,
      AccountNumber: controls.accountNumber.value,
      tradingAccount: controls.tradingAccount.value,
      AccountTypeId: controls.accountType.value,
      AccountStatus: controls.accountStatus.value,
      GroupName: controls.groupName.value,
      Balance: controls.balance.value,
      CurrencyInformationId: controls.currency.value,
      PreviousDayEquity: controls.previousDayEquity.value,
      ExternalReference:
        controls.externalReference.value == ""
          ? null
          : controls.externalReference.value,
      Comment: controls.comment.value,
      dateCreated: controls.dateCreated.value,
      customFields: customFields,
    };

    return this.postJson(
      "/clientCommon/" + clientId + "/investmentAccounts",
      JSON.stringify(body)
    );
  }

  public addClientTransaction(
    addClientTransactionForm: FormGroup,
    clientId: number,
    customFields: any,
    cardDetailsFilled: boolean
  ): Observable<any> {
    let formControls = addClientTransactionForm.controls;
    let body = {
      ClientId: clientId,
      ClientAccountId: formControls.clientAccount.value,
      BaseAmount: formControls.baseAmount.value,
      ForeignAmount: formControls.foreignAmount.value,
      ForeignCurrencyInformationId: formControls.foreignCurrency.value,
      TransactionDate: formControls.transactionDate.value,
      ApprovalDate: formControls.approvalDate.value,
      Description: formControls.description.value,
      IncomingCountryId: formControls.incomingCountry.value,
      OutgoingCountryId: formControls.outgoingCountry.value,
      CounterpartyAccount: formControls.counterpartyAccount.value,
      CounterpartyName: formControls.counterpartyName.value,
      TransactionMethodId: formControls.transactionMethod.value,
      TransactionTypeId: formControls.transactionType.value,
      ExternalReference:
        formControls.externalReference.value == ""
          ? null
          : formControls.externalReference.value,
      ClientWalletId: formControls.clientWallet.value,
      CounterpartyWalletAddress: formControls.counterpartyWalletAddress.value,
      TransferReference: formControls.transferReference.value,
      ClientWalletCurrencyInformationId:
        formControls.clientWalletCurrencyInformation.value,
      ClientCardTransaction: cardDetailsFilled
        ? {
          ClientCardId: formControls.clientCardId.value,
          Chargeback: formControls.chargeback.value,
          MCC: formControls.mcc.value,
          Merchant: formControls.merchant.value,
          MerchantExternalReference:
            formControls.merchantExternalReference.value,
          PanNumber: formControls.panNumber.value,
          CardPresentedStatus: formControls.cardPresentedStatus.value,
          MagneticStripe: formControls.magneticStripe.value,
          CardAuthorizationStatus: formControls.cardAuthorizationStatus.value,
          Moto: formControls.moto.value,
          KeyEntry: formControls.keyEntry.value,
          CardTransactionStatus: formControls.cardTransactionStatus.value,
        }
        : null,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/transactions",
      JSON.stringify(body)
    );
  }

  public addClientGamingTransaction(
    addClientGamingTransactionForm: FormGroup,
    clientId: number,
    customFields: any
  ): Observable<any> {
    let formControls = addClientGamingTransactionForm.controls;
    let body = {
      ClientId: clientId,
      ClientGamingAccountId: formControls.clientGamingAccountId.value,
      TransactionDate: formControls.transactionDate.value,
      IPAddress: formControls.ipAddress.value,
      Comments: formControls.comments.value,
      DeviceUsed: formControls.deviceUsed.value,
      GamingTransactionTypeId: formControls.gamingTransactionTypeId.value,
      GameTypeId: formControls.gameTypeId.value,
      CountryId: formControls.countryId.value,
      Volume: formControls.volume.value,
      PlayTime: formControls.playTime.value,
      // "Balance": formControls.balance.value,
      Location: formControls.location.value,
      TotalPlayTimeSinceLast: formControls.totalPlayTimeSinceLast.value,
      PromotionAmount: formControls.promotionAmount.value,
      WithdrawalLimit: formControls.withdrawalLimit.value,
      AmountOnHold: formControls.amountOnHold.value,
      ExternalReference:
        formControls.externalReference.value == ""
          ? null
          : formControls.externalReference.value,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/gamingTransactions",
      JSON.stringify(body)
    );
  }

  public addClientPaymentMethod(
    dateTime: any,
    paymentMethods: any,
    clientId: number,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      date: dateTime,
      paymentMethodIds: paymentMethods,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/paymentMethods",
      JSON.stringify(body)
    );
  }

  public addClientTrade(
    addClientTradeForm: FormGroup,
    clientId: number,
    customFields: any
  ): Observable<any> {
    let controls = addClientTradeForm.controls;
    let body = {
      clientId: clientId,
      createdOn: controls.createdOn.value,
      investmentAccountId: controls.investmentAccount.value,
      tradeTypeId: controls.tradeType.value,
      baseOpenPrice: controls.baseOpenPrice.value,
      baseClosePrice: controls.baseClosePrice.value,
      foreignOpenPrice: controls.foreignOpenPrice.value,
      foreignClosePrice: controls.foreignClosePrice.value,
      openTime: controls.openTime.value,
      closeTime: controls.closeTime.value,
      orderReference: controls.orderReference.value,
      volume: controls.volume.value,
      symbol: controls.symbol.value,
      symbolGroup: controls.symbolGroup.value,
      foreignCurrencyInformationId: controls.foreignCurrency.value,
      baseProfit: controls.baseProfit.value,
      foreignProfit: controls.foreignProfit.value,
      baseTradeAmount: controls.baseTradeAmount.value,
      foreignTradeAmount: controls.foreignTradeAmount.value,
      frequency: controls.frequency.value,
      externalReference:
        controls.externalReference.value == ""
          ? null
          : controls.externalReference.value,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/trades",
      JSON.stringify(body)
    );
  }

  public addClientCase(
    addClientCaseForm: FormGroup,
    clientId: number,
    selectedAnalysis,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      name: addClientCaseForm.controls.name.value,
      description: addClientCaseForm.controls.description.value,
      status: addClientCaseForm.controls.status.value,
      suspicionLevel: addClientCaseForm.controls.suspicionLevel.value,
      priority: addClientCaseForm.controls.priority.value,
      ownerUsername: addClientCaseForm.controls.ownerUsername.value,
      attachments: [],
      ruleId: addClientCaseForm.controls.ruleId.value || null,
      selectedAnalysis: selectedAnalysis,
      customFields: customFields,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/cases",
      JSON.stringify(body)
    );
  }

  public addClientInternalNote(
    message: string,
    clientId: number
  ): Observable<any> {
    let body = {
      clientId: clientId,
      message: message,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/internalNotes",
      JSON.stringify(body)
    );
  }

  public getMonitoringDetails(
    clientId: number,
    uniqueId: string
  ): Observable<any> {
    let body = {
      clientId: clientId,
      uniqueId: uniqueId,
    };
    return this.postJson(
      "/processes/clientmonitoringdetails",
      JSON.stringify(body)
    );
  }

  public addPortalRegulationGroups(regulationGroupIds: any[]): Observable<any> {
    let body = {
      regulationGroupIds: regulationGroupIds,
    };
    return this.postJson("/portalSetup/regulationGroups", JSON.stringify(body));
  }

  public addPortalCapacities(capacityIds: any[]): Observable<any> {
    let body = {
      capacityIds: capacityIds,
    };
    return this.postJson("/portalSetup/capacities", JSON.stringify(body));
  }

  public addPortalCaption(addPortalCaptionForm: FormGroup): Observable<any> {
    let body = {
      reference: addPortalCaptionForm.controls.reference.value,
      caption: addPortalCaptionForm.controls.caption.value,
      isVisibleToPortal: addPortalCaptionForm.controls.isVisibleToPortal.value,
      defaultRegulationGroupId:
        addPortalCaptionForm.controls.defaultRegulationGroupId.value,
      defaultClientType: addPortalCaptionForm.controls.defaultClientType.value,
    };
    return this.postJson("/portalSetup/captions", JSON.stringify(body));
  }

  public addPortalPrivacyPolicy(
    addPortalPrivacyPolicyForm: FormGroup,
    privacyPolicyBody: string,
    regulationGroups: any
  ): Observable<any> {
    let body = {
      name: addPortalPrivacyPolicyForm.controls.name.value,
      body: privacyPolicyBody,
      regulationGroupIds: regulationGroups,
    };
    return this.postJson(`/portalSetup/privacyPolicies`, JSON.stringify(body));
  }

  public addPortalSectionGroup(
    addPortalSectionGroupForm: FormGroup,
    description: string,
    sections: any
  ): Observable<any> {
    let body = {
      name: addPortalSectionGroupForm.controls.name.value,
      showGroupSum:
        addPortalSectionGroupForm.controls.showGroupSum.value || false,
      groupSumMessage: addPortalSectionGroupForm.controls.groupSumMessage.value,
      description: description,
      sectionIds: sections,
    };
    return this.postJson(`/portalSetup/sectionsGroups`, JSON.stringify(body));
  }

  public addPersonSearchInternalNote(
    message: string,
    personSearchProfileId: number
  ): Observable<any> {
    let body = {
      PersonSearchResultId: personSearchProfileId,
      message: message,
    };
    return this.postJson(
      "/clientIndividuals/personSearchResults/" +
      personSearchProfileId +
      "/internalNotes",
      JSON.stringify(body)
    );
  }

  public addClientCaseInternalNote(message: string, clientId: number, caseId: number, caseNumber: string): Observable<any> {
    let body = {
      "caseId": caseId,
      "caseNumber": caseNumber,
      "message": message
    };
    return this.postJson("/clientCommon/" + clientId + "/cases/internalNotes", JSON.stringify(body));
  }

  public addClientCaseInternalNoteWithChainalysis(
    message: string,
    clientId: number,
    caseId: number,
    chainalysisAlertReviewStatus: number,
    alertIdentifiers: string[]
  ): Observable<any> {
    let body = {
      CaseId: caseId,
      message: message,
      chainalysisAlertReviewStatus: chainalysisAlertReviewStatus,
      alertIdentifiers: alertIdentifiers,
    };
    return this.postJson(
      "/clientCommon/" +
      clientId +
      "/cases/" +
      caseId +
      "/internalNotes/chainalysis",
      JSON.stringify(body)
    );
  }

  public addTransactionScreeningCaseInternalNote(
    message: string,
    transactionScreeningCaseId: number
  ): Observable<any> {
    let body = {
      transactionScreeningCaseId: transactionScreeningCaseId,
      message: message,
    };
    return this.postJson(
      "/clientCommon/transactionScreeningCases/" +
      transactionScreeningCaseId +
      "/internalNotes",
      JSON.stringify(body)
    );
  }

  public addBusinessSearchInternalNote(
    message: string,
    businessSearchProfileId: number
  ): Observable<any> {
    let body = {
      BusinessSearchResultId: businessSearchProfileId,
      message: message,
    };
    return this.postJson(
      "/clientCorporates/businessSearchResults/" +
      businessSearchProfileId +
      "/internalNotes",
      JSON.stringify(body)
    );
  }

  public addUser(addUserForm: FormGroup, contactToEmail: any): Observable<any> {
    let body = {
      userName: addUserForm.controls.username.value,
      mappingReference: addUserForm.controls.mappingReference.value,
      firstName: addUserForm.controls.firstName.value,
      lastName: addUserForm.controls.lastName.value,
      email: addUserForm.controls.email.value,
      password: addUserForm.controls.password.value,
      phoneNumber: addUserForm.controls.phoneNumber.value,
      domain: addUserForm.controls.domain.value,
      role: addUserForm.controls.role.value,
      dataAccessGroupId: addUserForm.controls.dataAccessGroupId.value,
      informUser: addUserForm.controls.informUser.value,
      contactToEmail: contactToEmail,
      portalUserEmail: addUserForm.controls.portalUserEmail.value,
    };
    return this.postJson("/administration/users", JSON.stringify(body));
  }

  public addReportEngineSetup(
    addReportEngineSetupForm: FormGroup,
    resultProperties: any,
    usernamesToNotify: any,
    filters: any,
    directEmails
  ): Observable<any> {
    let body = {
      regulationGroupId:
        addReportEngineSetupForm.controls.regulationGroupId.value,
      emailTemplateId: addReportEngineSetupForm.controls.emailTemplateId.value,
      name: addReportEngineSetupForm.controls.name.value,
      filterProperties: filters,
      directEmails: directEmails,
      resultProperties: resultProperties,
      usernamesToNotify: usernamesToNotify,
      jobScheduleStatus:
        addReportEngineSetupForm.controls.jobScheduleStatus.value,
      jobScheduleInterval:
        addReportEngineSetupForm.controls.jobScheduleInterval.value,
      jobScheduleValue:
        addReportEngineSetupForm.controls.jobScheduleValue.value,
      jobScheduleTime: addReportEngineSetupForm.controls.jobScheduleTime.value,
      jobScheduleStartDate:
        addReportEngineSetupForm.controls.jobScheduleStartDate.value,
    };
    return this.postJson("/settings/reportEngineSetups", JSON.stringify(body));
  }

  public addDynamicReportEngineSetup(
    frm: FormGroup,
    dynamicReport: any,
    usernamesToNotify: any,
    directEmails
  ): Observable<any> {
    let body = {
      dynamicReportId: dynamicReport[0].id,
      emailTemplateId: frm.controls.emailTemplateId.value,
      executionName: frm.controls.dynamicReportExecution.value,
      status: frm.controls.status.value,
      usernamesToNotify: usernamesToNotify,
      directEmails: directEmails,
      jobScheduleStatus: frm.controls.jobScheduleStatus.value,
      jobScheduleInterval: frm.controls.jobScheduleInterval.value,
      jobScheduleValue: frm.controls.jobScheduleValue.value,
      jobScheduleTime: frm.controls.jobScheduleTime.value,
      jobScheduleStartDate: frm.controls.jobScheduleStartDate.value,
    };
    return this.postJson(
      "/settings/dynamicReportEngineSetup",
      JSON.stringify(body)
    );
  }

  public editDynamicReportEngineSetup(
    frm: FormGroup,
    usernamesToNotify: any,
    directEmails
  ): Observable<any> {
    let body = {
      id: frm.controls.id.value,
      directEmails: directEmails,
      dynamicReportId: frm.controls.dynamicReportId.value,
      emailTemplateId: frm.controls.emailTemplateId.value,
      executionName: frm.controls.executionName.value,
      status: frm.controls.status.value,
      jobScheduleStatus: frm.controls.jobScheduleStatus.value,
      jobScheduleInterval: frm.controls.jobScheduleInterval.value,
      jobScheduleValue: frm.controls.jobScheduleValue.value,
      jobScheduleTime: frm.controls.jobScheduleTime.value,
      jobScheduleStartDate: frm.controls.jobScheduleStartDate.value,
      userNamesToNotify: usernamesToNotify,
    };

    return this.putJson(
      "/settings/dynamicReportEngineSetup",
      JSON.stringify(body)
    );
  }

  public addRegulationGroup(
    addRegulationGroupForm: FormGroup,
    selectedRestrictedCountries,
    selectedRestrictedCities,
    selectedForbiddenCountries
  ): Observable<any> {
    let body = {
      name: addRegulationGroupForm.controls.name.value,
      currencyInformationId: addRegulationGroupForm.controls.currency.value,
      MappingReference: addRegulationGroupForm.controls.mappingReference.value,
      automaticUpdateGradesForRestrictedCountries:
        addRegulationGroupForm.controls
          .automaticUpdateGradesForRestrictedCountries.value,
      authorityCountryId:
        addRegulationGroupForm.controls.authorityCountryId.value,
      restrictedCountries: selectedRestrictedCountries,
      forbiddenCountries: selectedForbiddenCountries,
      restrictedCities: selectedRestrictedCities,
      hash: addRegulationGroupForm.controls.hash.value,
    };
    return this.postJson("/settings/regulationGroups", JSON.stringify(body));
  }

  public ignoreReEvaluation(
    IgnoreReEvaluationForm: FormGroup,
    clientId: number
  ): Observable<any> {
    let body = {
      decisionExplanation:
        IgnoreReEvaluationForm.controls.decisionExplanation.value,
      clientId: clientId,
    };
    return this.postJson(
      "/clientCommon/IgnoreReEvaluation",
      JSON.stringify(body)
    );
  }

  public synchronizeRegulationGroups(
    regulationGroupToSyncFromId,
    regulationGroupsToBeSyncedIds,
    setupTypes,
    questionsIds,
    criteriaIds,
    firmCriteriaIds,
    firmSanctionCriteriaIds,
    rulesIds,
    customFieldsIds,
    documentsIds
  ): Observable<any> {
    let body = {
      regulationGroupToSyncFromId: regulationGroupToSyncFromId,
      regulationGroupsToBeSyncedIds: regulationGroupsToBeSyncedIds,
      setupTypes: setupTypes,
      questionsIds: questionsIds,
      criteriaIds: criteriaIds,
      firmCriteriaIds: firmCriteriaIds,
      firmSanctionCriteriaIds: firmSanctionCriteriaIds,
      rulesIds: rulesIds,
      customFieldsIds: customFieldsIds,
      documentsIds: documentsIds,
    };
    return this.postJson(
      "/settings/synchronizeRegulationGroups",
      JSON.stringify(body)
    );
  }

  public addExternalCredentialsSetup(
    addExternalCredentialsSetupForm: FormGroup
  ): Observable<any> {
    let body = {
      providerName: addExternalCredentialsSetupForm.controls.providerName.value,
      url: addExternalCredentialsSetupForm.controls.url.value,
      authorizationType:
        addExternalCredentialsSetupForm.controls.authorizationType.value,
      connectionCredentialsObject: { credentials: [] },
      healthCheckUrl:
        addExternalCredentialsSetupForm.controls.healthCheckUrl.value,
      disableSSLCertificateVerification:
        addExternalCredentialsSetupForm.controls
          .disableSSLCertificateVerification.value,
    };
    return this.postJson(
      "/administration/externalCredentialsSetup",
      JSON.stringify(body)
    );
  }

  public addActionCategory(addActionCategoryForm: FormGroup): Observable<any> {
    let body = {
      name: addActionCategoryForm.controls.name.value,
      MappingReference: addActionCategoryForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/actionCategories", JSON.stringify(body));
  }

  public addPurposeOfTransaction(
    addPurposeOfTransactionForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addPurposeOfTransactionForm.controls.name.value,
      MappingReference:
        addPurposeOfTransactionForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/purposeOfTransactions",
      JSON.stringify(body)
    );
  }

  public addActionStatus(addActionStatusForm: FormGroup): Observable<any> {
    let body = {
      name: addActionStatusForm.controls.name.value,
      MappingReference: addActionStatusForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/actionStatuses", JSON.stringify(body));
  }

  public addRuleCategory(addRuleCategoryForm: FormGroup): Observable<any> {
    let body = {
      name: addRuleCategoryForm.controls.name.value,
      MappingReference: addRuleCategoryForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/ruleCategories", JSON.stringify(body));
  }

  public addCriteriaCategory(
    addCriteriaCategoryForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addCriteriaCategoryForm.controls.name.value,
      MappingReference: addCriteriaCategoryForm.controls.mappingReference.value,
      weightFactor: addCriteriaCategoryForm.controls.weightFactor.value,
      riskCategory: addCriteriaCategoryForm.controls.riskCategory.value,
    };
    return this.postJson("/settings/criteriaCategories", JSON.stringify(body));
  }

  public addDocumentCategory(
    addDocumentCategoryForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addDocumentCategoryForm.controls.name.value,
      MappingReference: addDocumentCategoryForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/documentCategories", JSON.stringify(body));
  }

  public addSubGroup(addSubGroupForm: FormGroup): Observable<any> {
    let body = {
      name: addSubGroupForm.controls.name.value,
      description: addSubGroupForm.controls.description.value,
      MappingReference: addSubGroupForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/subGroups", JSON.stringify(body));
  }

  public addCountryCategory(
    addCountryCategoryForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addCountryCategoryForm.controls.name.value,
      MappingReference: addCountryCategoryForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/countryCategories", JSON.stringify(body));
  }

  public addQuestionsCategory(
    addQuestionsCategoryForm: FormGroup,
    questionnaireTypesId: number[]
  ): Observable<any> {
    let body = {
      name: addQuestionsCategoryForm.controls.name.value,
      questionnaireTypesId: questionnaireTypesId,
      mappingReference:
        addQuestionsCategoryForm.controls.mappingReference.value,
      weightFactor: addQuestionsCategoryForm.controls.weightFactor.value,
    };
    return this.postJson("/settings/questionsCategories", JSON.stringify(body));
  }

  public addQuestionnaireGrade(form: FormGroup): Observable<any> {
    let body = {
      name: form.controls.name.value,
      scorePointFrom: form.controls.scorePointFrom.value,
      questionnaireTypeId: form.controls.questionnaireTypeId.value,
      scorePointTo: form.controls.scorePointTo.value,
      color: form.controls.color.value,
      mappingReference: form.controls.mappingReference.value,
    };
    return this.postJson("/settings/questionnaireGrades", JSON.stringify(body));
  }

  public addQuestionnaireType(form: FormGroup): Observable<any> {
    let body = {
      name: form.controls.name.value,
      hasScoring: form.controls.hasScoring.value,
      mappingReference: form.controls.mappingReference.value,
    };
    return this.postJson("/settings/questionnaireTypes", JSON.stringify(body));
  }

  public addPaymentMethod(addPaymentMethodForm: FormGroup): Observable<any> {
    let body = {
      name: addPaymentMethodForm.controls.name.value,
      historicalRecordType:
        addPaymentMethodForm.controls.historicalRecordType.value,
      enhancedEconomicProfile:
        addPaymentMethodForm.controls.enhancedEconomicProfile.value,
      mappingReference: addPaymentMethodForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/paymentMethods", JSON.stringify(body));
  }

  public addPaymentMethodVolume(
    addPaymentMethodVolumeForm: FormGroup,
    paymentMethodId
  ): Observable<any> {
    let body = {
      paymentMethodId: paymentMethodId,
      name: addPaymentMethodVolumeForm.controls.name.value,
      mappingReference:
        addPaymentMethodVolumeForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/paymentMethods/" + paymentMethodId + "/volumes",
      JSON.stringify(body)
    );
  }

  public addDocument(
    addDocumentForm: FormGroup,
    integrationCapacities: any,
    clientCategories: any
  ): Observable<any> {
    let documentTemplateFile = { fileName: null, data: null };
    if (
      addDocumentForm.controls.templateFileBase64 != null &&
      addDocumentForm.controls.templateFileBase64.value != null &&
      addDocumentForm.controls.templateFileBase64.value != ""
    ) {
      let base64String = addDocumentForm.controls.templateFileBase64.value
        ? addDocumentForm.controls.templateFileBase64.value
        : "";

      let base64Data = base64String.split(",")[1];
      documentTemplateFile = {
        fileName: addDocumentForm.controls.templateFileName.value,
        data: base64Data,
      };
    }

    let body = {
      name: addDocumentForm.controls.name.value,
      nameInPortal: addDocumentForm.controls.nameInPortal.value,
      clientType: addDocumentForm.controls.clientType.value,
      documentType: addDocumentForm.controls.documentType.value,
      mandatory: addDocumentForm.controls.mandatory.value,
      regulationGroupId: addDocumentForm.controls.regulationGroupId.value,
      clientCategoriesId: clientCategories,
      mappingReference: addDocumentForm.controls.mappingReference.value,
      integrationCapacitiesId: integrationCapacities,
      isIdentificationDocument:
        addDocumentForm.controls.isIdentificationDocument.value || false,
      templateFileData: documentTemplateFile.data,
      templateFileName: documentTemplateFile.fileName,
      ignoreClientCategory: addDocumentForm.controls.ignoreClientCategory.value,
      isVisibleToPortal:
        addDocumentForm.controls.isVisibleToPortal.value || false,
      documentCategoryId: addDocumentForm.controls.documentCategoryId.value,
      status: addDocumentForm.controls.status.value
    };

    return this.postJson("/settings/documents", JSON.stringify(body));
  }

  public addCaseWorkflowStatus(
    caseWorkflowStatusForm: FormGroup,
    dataAccessGroups: any,
    caseStatuses: any,
    visibleToDataAccessGroups: any
  ): Observable<any> {
    let body = {
      name: caseWorkflowStatusForm.controls.name.value,
      isDefault: caseWorkflowStatusForm.controls.isDefault.value,
      dataAccessGroupsId: dataAccessGroups,
      visibleToDataAccessGroupsId: visibleToDataAccessGroups,
      caseStatuses: caseStatuses,
      mappingReference: caseWorkflowStatusForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/caseWorkflowStatuses",
      JSON.stringify(body)
    );
  }

  public addCaseStatus(caseStatus: any) {
    let body = {
      caseStatusId: caseStatus.caseStatusId,
      caseStatusValue: caseStatus.caseStatusValue,
      description: caseStatus.description,
      isActive: caseStatus.isActive,
      show: caseStatus.show,
    };

    return this.postJson("/settings/caseStatuses", JSON.stringify(body));
  }

  public addMandatoryDocumentsCategory(
    addMandatoryDocumentsCategoryForm: FormGroup,
    documentsId: any
  ): Observable<any> {
    let body = {
      name: addMandatoryDocumentsCategoryForm.controls.name.value,
      clientType: addMandatoryDocumentsCategoryForm.controls.clientType.value,
      regulationGroupId:
        addMandatoryDocumentsCategoryForm.controls.regulationGroupId.value,
      numOfMandatoryDocuments:
        addMandatoryDocumentsCategoryForm.controls.numOfMandatoryDocuments
          .value,
      mappingReference:
        addMandatoryDocumentsCategoryForm.controls.mappingReference.value,
      documentsId: documentsId,
    };
    return this.postJson(
      "/settings/mandatoryDocumentsCategories",
      JSON.stringify(body)
    );
  }

  public addCriterion(
    addCriterionForm: FormGroup,
    criterionAnswers: any,
    criterionEvaluationTypes: any,
    integrationCapacities: any,
    clientCategories: any,
    excludedIntegrationCapacities
  ): Observable<any> {
    let body = {
      name: addCriterionForm.controls.name.value,
      requiresAnswerJustification:
        addCriterionForm.controls.requiresAnswerJustification.value,
      riskPoint: addCriterionForm.controls.riskPoint.value,
      bringScoringGradeHigh:
        addCriterionForm.controls.bringScoringGradeHigh.value,
      minimumEvaluationGrade:
        addCriterionForm.controls.minimumEvaluationGrade.value,
      criterionAnswers: criterionAnswers,
      regulationGroupId: addCriterionForm.controls.regulationGroupId.value,
      criterionEvaluationTypes: criterionEvaluationTypes,
      criterionSetupType: addCriterionForm.controls.criterionSetupType.value,
      criteriaCategoryId: addCriterionForm.controls.criteriaCategoryId.value,
      clientType: addCriterionForm.controls.clientType.value,
      MappingReference: addCriterionForm.controls.mappingReference.value,
      integrationCapacitiesId: integrationCapacities,
      excludedIntegrationCapacitiesId: excludedIntegrationCapacities,
      clientCategoriesId: clientCategories,
      isEconomicProfile: addCriterionForm.controls.isEconomicProfile.value,
      minNumOfAnswers: addCriterionForm.controls.minNumOfAnswers.value,
      maxNumOfAnswers: addCriterionForm.controls.maxNumOfAnswers.value,
      includeInEvaluation: addCriterionForm.controls.includeInEvaluation.value,
      ignoreClientCategory:
        addCriterionForm.controls.ignoreClientCategory.value,
    };
    return this.postJson("/settings/criteria", JSON.stringify(body));
  }

  public addFirmSanctionCriterion(
    addFirmSanctionCriterionForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addFirmSanctionCriterionForm.controls.name.value,
      riskPoint: addFirmSanctionCriterionForm.controls.riskPoint.value,
      bringScoringGradeHigh:
        addFirmSanctionCriterionForm.controls.bringScoringGradeHigh.value,
      regulationGroupId:
        addFirmSanctionCriterionForm.controls.regulationGroupId.value,
      firmSanctionCriterionCategoryId:
        addFirmSanctionCriterionForm.controls.firmSanctionCriterionCategoryId
          .value,
      MappingReference:
        addFirmSanctionCriterionForm.controls.mappingReference.value,
      minNumOfAnswers:
        addFirmSanctionCriterionForm.controls.minNumOfAnswers.value,
      maxNumOfAnswers:
        addFirmSanctionCriterionForm.controls.maxNumOfAnswers.value,
    };
    return this.postJson(
      "/settings/firms/firmSanctionCriterion",
      JSON.stringify(body)
    );
  }

  public addCriterionToRelatedEntities(
    criterionId: any,
    integrationCapacities: any
  ): Observable<any> {
    let body = {
      id: criterionId,
      integrationCapacitiesId: integrationCapacities,
    };

    return this.postJson(
      `/settings/criteria/${criterionId}/addCriterionToEntities`,
      JSON.stringify(body)
    );
  }

  public removeCriterionFromRelatedEntities(
    criterionId: any,
    integrationCapacities: any
  ): Observable<any> {
    let body = {
      id: criterionId,
      integrationCapacitiesId: integrationCapacities,
    };

    return this.postJson(
      `/settings/criteria/${criterionId}/removeCriterionFromEntities`,
      JSON.stringify(body)
    );
  }

  public addQuestionToRelatedEntities(questionId: any, integrationCapacities: any): Observable<any> {
    let body = {
      id: questionId,
      integrationCapacitiesId: integrationCapacities,
    };

    return this.postJson(`/settings/questions/${questionId}/addQuestionToEntities`, JSON.stringify(body));
  }

  public addDocumentToRelatedEntities(
    documentId: any,
    integrationCapacities: any
  ): Observable<any> {
    let body = {
      id: documentId,
      integrationCapacitiesId: integrationCapacities,
    };

    return this.postJson(
      `/settings/documents/${documentId}/addDocumentToEntities`,
      JSON.stringify(body)
    );
  }

  addCriterionAnswer(criterionId: any, addAnswerForm: any) {
    let body = {
      criterionId: criterionId,
      evaluationGradeId: addAnswerForm.controls.evaluationGrade.value,
      value: addAnswerForm.controls.value.value,
      minimumValue:
        addAnswerForm.get("minimumValue").value == "" ||
          addAnswerForm.get("minimumValue").value == null
          ? 0
          : addAnswerForm.get("minimumValue").value,
      maximumValue:
        addAnswerForm.get("maximumValue").value == "" ||
          addAnswerForm.get("maximumValue").value == null
          ? 0
          : addAnswerForm.get("maximumValue").value,
      isDefault:
        addAnswerForm.get("isDefault").value == "" ||
          addAnswerForm.get("isDefault").value == null
          ? 0
          : addAnswerForm.get("isDefault").value,
    };

    return this.postJson(
      "/settings/criteria/" + criterionId + "/answers",
      JSON.stringify(body)
    );
  }

  addFirmSanctionCriterionAnswer(criterionId: any, addAnswerForm: any) {
    let body = {
      firmSanctionCriterionId: criterionId,
      firmSanctionImpactScoreId:
        addAnswerForm.controls.firmSanctionImpactScoreId.value,
      name: addAnswerForm.controls.name.value,
      isDefault:
        addAnswerForm.get("isDefault").value == "" ||
          addAnswerForm.get("isDefault").value == null
          ? 0
          : addAnswerForm.get("isDefault").value,
    };

    return this.postJson(
      "/settings/firms/sanctionCriteria/" + criterionId + "/answers",
      JSON.stringify(body)
    );
  }

  updateCountryEvaluationGradesFromFile(countryEvaluationGrades: any) {
    let body = {
      countryEvaluationGrades: countryEvaluationGrades,
    };

    return this.postJson(
      "/settings/countryevaluationgradesfromfile",
      JSON.stringify(body)
    );
  }

  addCriterionAnswerFromFile(
    criterionId: any,
    regulationGroupId: any,
    criterionAnswers: any
  ) {
    let body = {
      criterionId: criterionId,
      regulationGroupId: regulationGroupId,
      criterionAnswers: criterionAnswers,
    };

    return this.postJson(
      "/settings/criteria/" + criterionId + "/answersFromFile",
      JSON.stringify(body)
    );
  }

  addCriteriaFromFile(criteria: any) {
    let body = {
      criteria: criteria,
    };

    return this.postJson(
      "/settings/criteria/criteriaFromFile",
      JSON.stringify(body)
    );
  }

  addTranslationsFromFile(translations: any) {
    //map translations to new object 
    let newTranslations = [];
    for (let i = 0; i < translations.length; i++) {
      let translation = {
        language: translations[i]['Language *'],
        referenceTable: translations[i]['Reference Table *'],
        mappingReference: translations[i]['Mapping Reference *'],
        referenceColumn: translations[i]['Reference Column *'],
        translation: translations[i]['Translation *'],
        translationDescription: translations[i]['Translation Description'],
      }
      newTranslations.push(translation);
    }

    let body = {
      translations: newTranslations,
    };

    return this.postJson(
      "/translations/translationsFromFile",
      JSON.stringify(body)
    );
  }

  addClientWorldcheckReferencesFromFile(clientWorldcheckReferences: any) {
    let body = {
      clientWorldcheckReferences: clientWorldcheckReferences,
    };

    return this.postJson(
      "/processes/addClientWorldcheckReferencesFromFile",
      JSON.stringify(body)
    );
  }

  addInternalBlackListFromFile(
    internalBlackList: any[],
    deleteExistingRecords: any
  ) {
    const list = JSON.parse(JSON.stringify(internalBlackList));
    for (const el of list) {
      const datePartsMarked = el.MarkedDate.split('/');
      el.MarkedDate = new Date(+datePartsMarked[2], (+datePartsMarked[1]) - 1, +datePartsMarked[0]).toDateString();

      if (el.DateOfBirth) {
        const datePartsBirth = el.DateOfBirth.split('/');
        el.DateOfBirth = new Date(+datePartsBirth[2], (+datePartsBirth[1]) - 1, +datePartsBirth[0]).toDateString();
      } else {
        el.DateOfBirth = null
      }
    }
    let body = {
      deleteExistingRecords:
        deleteExistingRecords == null ? false : deleteExistingRecords,
      internalBlackList: list,
    };

    return this.postJson(
      "/administration/internalBlacklistsFromFile",
      JSON.stringify(body)
    );
  }

  addInternalBlackListReasonsFromFile(internalBlackListReasons: any[]) {
    const list = JSON.parse(JSON.stringify(internalBlackListReasons));

    let body = {
      internalBlackListReasons: list,
    };

    return this.postJson(
      "/administration/internalBlacklistsReasonsFromFile",
      JSON.stringify(body)
    );
  }

  addInternalBlackListCorporateFromFile(
    internalBlackList: any[],
    deleteExistingRecords: any
  ) {
    const list = JSON.parse(JSON.stringify(internalBlackList));
    for (const el of list) {
      const dateParts = el.MarkedDate.split("/");
      const year: string = dateParts[2];
      // month is 0-based, that's why we need dateParts[1] - 1
      el.MarkedDate = new Date(
        +year,
        +dateParts[1] - 1,
        +dateParts[0]
      ).toDateString();
    }

    let body = {
      deleteExistingRecords:
        deleteExistingRecords == null ? false : deleteExistingRecords,
      internalBlackListCorporate: list,
    };

    return this.postJson(
      "/administration/internalBlacklistsCorporateFromFile",
      JSON.stringify(body)
    );
  }

  addIndividualsFromFile(ClientsIndividual: any[]) {
    let body = {
      ClientsIndividual: ClientsIndividual,
    };

    return this.postJson(
      "/clientIndividuals/addIndividualsFromFile",
      JSON.stringify(body)
    );
  }

  addCorporatesFromFile(ClientsCorporateList: any[]) {
    let body = {
      ClientsCorporate: ClientsCorporateList,
    };

    return this.postJson(
      "/clientCorporates/addCorporatesFromFile",
      JSON.stringify(body)
    );
  }

  addCountriesFromFile(countries: any) {
    let body = {
      countries: countries,
    };

    return this.postJson(
      "/settings/countries/countriesFromFile",
      JSON.stringify(body)
    );
  }

  addTranslatedCountriesFromFile(countries: any) {
    let body = {
      countries: countries,
    };

    return this.postJson(
      "/settings/countries/translatedCountriesFromFile",
      JSON.stringify(body)
    );
  }

  addCitiesFromFile(cities: any) {
    let body = {
      cities: cities,
    };

    return this.postJson(
      "/settings/cities/citiesFromFile",
      JSON.stringify(body)
    );
  }

  addKeywordsFromFile(keywords: any) {
    let body = {
      keywords: keywords,
    };
    return this.postJson(
      "/settings/keywords/keywordsFromFile",
      JSON.stringify(body)
    );
  }

  addDocumentsFromFile(documents: any) {
    let body = {
      documents: documents,
    };

    return this.postJson(
      "/settings/documents/documentsFromFile",
      JSON.stringify(body)
    );
  }

  addCriteriaAnswersFromFile(criteriaAnswers: any) {
    let body = {
      criteriaAnswers: criteriaAnswers,
    };

    return this.postJson(
      "/settings/criteria/criteriaAnswersFromFile",
      JSON.stringify(body)
    );
  }

  addQuestionsFromFile(questions: any) {
    let body = {
      questions: questions,
    };

    return this.postJson(
      "/settings/questions/questionsFromFile",
      JSON.stringify(body)
    );
  }

  addQuestionsAnswersFromFile(questionsAnswers: any) {
    let body = {
      questionsAnswers: questionsAnswers,
    };

    return this.postJson(
      "/settings/questions/questionsAnswersFromFile",
      JSON.stringify(body)
    );
  }

  addQuestionAnswerFromFile(questionId: any, questionAnswers: any) {
    let body = {
      questionId: questionId,
      questionAnswers: questionAnswers,
    };

    return this.postJson(
      "/settings/questions/" + questionId + "/answersFromFile",
      JSON.stringify(body)
    );
  }

  public addQuestion(
    addQuestionForm: FormGroup,
    questionAnswers: any,
    integrationCapacities: any[]
  ): Observable<any> {
    let body = {
      name: addQuestionForm.controls.name.value,
      questionAnswers: questionAnswers,
      regulationGroupId: addQuestionForm.controls.regulationGroupId.value,
      questionSetupType: addQuestionForm.controls.questionSetupType.value,
      questionsCategoryId: addQuestionForm.controls.questionsCategoryId.value,
      clientType: addQuestionForm.controls.clientType.value,
      criterionId: addQuestionForm.controls.criterionId.value,
      minNumOfAnswers: addQuestionForm.controls.minNumOfAnswers.value,
      maxNumOfAnswers: addQuestionForm.controls.maxNumOfAnswers.value,
      mappingReference: addQuestionForm.controls.mappingReference.value,
      isRequired: addQuestionForm.controls.isRequired.value,
      isEconomicProfile: addQuestionForm.controls.isEconomicProfile.value,
      order: addQuestionForm.controls.order.value,
      integrationCapacitiesId: integrationCapacities,
      riskPoint: addQuestionForm.controls.riskPoint.value,
    };
    return this.postJson("/settings/questions", JSON.stringify(body));
  }

  addQuestionAnswer(questionId: any, addAnswerForm: any) {
    let body = {
      questionId: questionId,
      value: addAnswerForm.controls.value.value,
      minimumValue:
        addAnswerForm.get("minimumValue").value == "" ||
          addAnswerForm.get("minimumValue").value == null
          ? 0
          : addAnswerForm.get("minimumValue").value,
      maximumValue:
        addAnswerForm.get("maximumValue").value == "" ||
          addAnswerForm.get("maximumValue").value == null
          ? 0
          : addAnswerForm.get("maximumValue").value,
      score: addAnswerForm.controls.score.value,
      isDefault:
        addAnswerForm.get("isDefault").value == "" ||
          addAnswerForm.get("isDefault").value == null
          ? 0
          : addAnswerForm.get("isDefault").value,
    };

    return this.postJson(
      "/settings/questions/" + questionId + "/answers",
      JSON.stringify(body)
    );
  }

  public addClientIndividual(
    addClientIndividualForm: FormGroup,
    customFields: any
  ): Observable<any> {
    let body = {
      ClientStatusId: addClientIndividualForm.controls.clientStatus.value,
      ClientCategoryId: addClientIndividualForm.controls.clientCategory.value,
      ignoreAutoOngoingMonitoringStatus: addClientIndividualForm.controls.ignoreAutoOngoingMonitoringStatus.value == null
        || addClientIndividualForm.controls.ignoreAutoOngoingMonitoringStatus.value == undefined
        || addClientIndividualForm.controls.ignoreAutoOngoingMonitoringStatus.value == ''
        ? false
        : addClientIndividualForm.controls.ignoreAutoOngoingMonitoringStatus.value,
      RegulationGroupId: addClientIndividualForm.controls.regulationGroup.value,
      JoinedDate: addClientIndividualForm.controls.joinedDate.value,
      Notes: addClientIndividualForm.controls.notes.value,
      FirstName: addClientIndividualForm.controls.firstName.value,
      LastName: addClientIndividualForm.controls.lastName.value,
      MiddleName: addClientIndividualForm.controls.middleName.value,
      externalReference:
        addClientIndividualForm.controls.externalReference.value == ""
          ? null
          : addClientIndividualForm.controls.externalReference.value,
      phone: addClientIndividualForm.controls.phone.value,
      Email: addClientIndividualForm.controls.email.value,
      Gender: addClientIndividualForm.controls.gender.value,
      SSN: addClientIndividualForm.controls.ssn.value,
      TaxIdentificationNumber:
        addClientIndividualForm.controls.taxIdentificationNumber.value,
      CountryOfBirthId: addClientIndividualForm.controls.countryOfBirthId.value,
      DateOfBirth: addClientIndividualForm.controls.dateOfBirth.value,
      customFields: customFields,
      ipAddress: addClientIndividualForm.controls.ipAddress.value,
      SubGroupId: addClientIndividualForm.controls.subGroup.value,
    };

    return this.postJson("/clientIndividuals", JSON.stringify(body));
  }

  public checkForSimilarClientIndividual(addClientIndividualForm: FormGroup): Observable<any> {
    let body = {
      'FirstName': addClientIndividualForm.controls['firstName'].value,
      'LastName': addClientIndividualForm.controls['lastName'].value,
      'RegulationGroupId': addClientIndividualForm.controls['regulationGroup'].value
    };

    return this.postJson("/clientIndividuals/checkForSimilarClientIndividual", JSON.stringify(body));
  }

  public checkForSimilarClientCorporate(
    addClientCorporateForm: FormGroup
  ): Observable<any> {
    let body = {
      RegisteredName: addClientCorporateForm.controls.registeredName.value,
    };
    return this.postJson(
      "/clientCorporates/checkForSimilarClientCorporate",
      JSON.stringify(body)
    );
  }

  public addClientCorporate(
    addClientCorporateForm: FormGroup,
    customFields: any
  ): Observable<any> {
    const ignoreAutoOngoingMonitoringStatus = addClientCorporateForm.controls.ignoreAutoOngoingMonitoringStatus.value;
    let body = {
      ClientStatusId: addClientCorporateForm.controls.clientStatus.value,
      ignoreAutoOngoingMonitoringStatus: ignoreAutoOngoingMonitoringStatus === null ? false : ignoreAutoOngoingMonitoringStatus,
      ClientCategoryId: addClientCorporateForm.controls.clientCategory.value,
      RegulationGroupId: addClientCorporateForm.controls.regulationGroup.value,
      JoinedDate: addClientCorporateForm.controls.joinedDate.value,
      Notes: addClientCorporateForm.controls.notes.value,
      RegisteredName: addClientCorporateForm.controls.registeredName.value,
      RegistrationNumber:
        addClientCorporateForm.controls.registrationNumber.value,
      authorisedCapital:
        addClientCorporateForm.controls.authorisedCapital.value,
      issuedCapital: addClientCorporateForm.controls.issuedCapital.value,
      externalReference:
        addClientCorporateForm.controls.externalReference.value == ""
          ? null
          : addClientCorporateForm.controls.externalReference.value,
      Phone: addClientCorporateForm.controls.phone.value,
      Email: addClientCorporateForm.controls.email.value,
      IpAddress: addClientCorporateForm.controls.ipAddress.value,
      DefaultFullStructureEvaluation:
        addClientCorporateForm.controls.defaultFullStructureEvaluation.value,
      countryOfIncorporationId:
        addClientCorporateForm.controls.countryOfIncorporationId.value,
      customFields: customFields,
      SubGroupId: addClientCorporateForm.controls.subGroup.value,
    };
    return this.postJson("/clientCorporates", JSON.stringify(body));
  }

  public addClientJoint(
    addClientJointForm: FormGroup,
    clients: any,
    customFields: any
  ): Observable<any> {
    let body = {
      clientStatusId: addClientJointForm.controls.clientStatus.value,
      ignoreAutoOngoingMonitoringStatus: addClientJointForm.controls.ignoreAutoOngoingMonitoringStatus.value == "" ? false : addClientJointForm.controls.ignoreAutoOngoingMonitoringStatus.value,
      ClientCategoryId: addClientJointForm.controls.clientCategory.value,
      regulationGroupId: addClientJointForm.controls.regulationGroup.value,
      joinedDate: addClientJointForm.controls.joinedDate.value,
      notes: addClientJointForm.controls.notes.value,
      name: addClientJointForm.controls.name.value,
      externalReference:
        addClientJointForm.controls.externalReference.value == ""
          ? null
          : addClientJointForm.controls.externalReference.value,
      phone: addClientJointForm.controls.phone.value,
      email: addClientJointForm.controls.email.value,
      defaultFullStructureEvaluation:
        addClientJointForm.controls.defaultFullStructureEvaluation.value,
      customFields: customFields,
      clientJointClientIds: clients,
      subGroupId: addClientJointForm.controls.subGroup.value,
    };
    return this.postJson("/clients/joints", JSON.stringify(body));
  }

  public addEvaluationType(addEvaluationTypeForm: FormGroup): Observable<any> {
    let body = {
      name: addEvaluationTypeForm.controls.name.value,
      MappingReference: addEvaluationTypeForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/evaluationtypes", JSON.stringify(body));
  }

  public addBank(addBankForm: FormGroup): Observable<any> {
    let body = {
      name: addBankForm.controls.name.value,
      code: addBankForm.controls.code.value,
      MappingReference: addBankForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/banks", JSON.stringify(body));
  }

  public addMonitoringVisitType(
    addMonitoringVisitTypeForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addMonitoringVisitTypeForm.controls.name.value,
      MappingReference:
        addMonitoringVisitTypeForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/monitoringVisitType", JSON.stringify(body));
  }

  public addCurrencyRate(addCurrencyRateForm: FormGroup): Observable<any> {
    let body = {
      fromCurrencyInformationId:
        addCurrencyRateForm.controls.fromCurrency.value,
      toCurrencyInformationId: addCurrencyRateForm.controls.toCurrency.value,
      date: addCurrencyRateForm.controls.date.value,
      rate: addCurrencyRateForm.controls.rate.value,
    };
    return this.postJson("/settings/currencyRates", JSON.stringify(body));
  }

  public addReasonForTin(addReasonForTinForm: FormGroup): Observable<any> {
    let body = {
      name: addReasonForTinForm.controls.name.value,
      code: addReasonForTinForm.controls.code.value,
      MappingReference: addReasonForTinForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/reasonsForTin", JSON.stringify(body));
  }

  public addAddressType(
    addAddressTypeForm: FormGroup,
    clientTypes: any
  ): Observable<any> {
    let body = {
      name: addAddressTypeForm.controls.name.value,
      code: addAddressTypeForm.controls.code.value,
      isVisibleToPortal:
        addAddressTypeForm.controls.isVisibleToPortal.value || false,
      MappingReference: addAddressTypeForm.controls.mappingReference.value,
      useIneIDV: addAddressTypeForm.controls.useIneIDV.value,
      typeOfAddressType: addAddressTypeForm.controls.typeOfAddressType.value,
      addressTypeClientTypes: clientTypes,
    };
    return this.postJson("/settings/addressType", JSON.stringify(body));
  }

  public addCapacity(addCapacityForm: FormGroup): Observable<any> {
    let body = {
      name: addCapacityForm.controls.name.value,
      clientType: addCapacityForm.controls.clientType.value,
      includeInEvaluation: addCapacityForm.controls.includeInEvaluation.value,
      includeInTreeStructure:
        addCapacityForm.controls.includeInTreeStructure.value,
      includePercentageOfShares:
        addCapacityForm.controls.includePercentageOfShares.value,
      MappingReference: addCapacityForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/capacities", JSON.stringify(body));
  }

  public addIntegrationCapacity(
    addIntegrationCapacityForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addIntegrationCapacityForm.controls.name.value,
      MappingReference:
        addIntegrationCapacityForm.controls.mappingReference.value,
    };

    return this.postJson(
      "/settings/integrationCapacities",
      JSON.stringify(body)
    );
  }

  public addDefaultAddress(addDefaultAddressForm: FormGroup): Observable<any> {
    let body = {
      address: addDefaultAddressForm.controls.address.value,
      countryId: addDefaultAddressForm.controls.countryId.value,
      postalCode: addDefaultAddressForm.controls.postalCode.value,
      locality: addDefaultAddressForm.controls.locality.value,
      province: addDefaultAddressForm.controls.province.value,
      district: addDefaultAddressForm.controls.district.value,
      addressTypeId: addDefaultAddressForm.controls.addressTypeId.value,
    };
    return this.postJson("/settings/defaultaddresses", JSON.stringify(body));
  }

  public addChecklist(addChecklistForm: FormGroup): Observable<any> {
    let body = {
      name: addChecklistForm.controls.name.value,
      priority: addChecklistForm.controls.priority.value,
      regulationGroupId: addChecklistForm.controls.regulationGroupId.value,
      MappingReference: addChecklistForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/checklists", JSON.stringify(body));
  }

  public addInvestmentAccountType(
    addInvestmentAccountTypeForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addInvestmentAccountTypeForm.controls.name.value,
      mappingReference:
        addInvestmentAccountTypeForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/investmentAccountTypes",
      JSON.stringify(body)
    );
  }

  public addClientStatus(addClientStatusForm: FormGroup): Observable<any> {
    let body = {
      name: addClientStatusForm.controls.name.value,
      mappingReference: addClientStatusForm.controls.mappingReference.value,
      color: addClientStatusForm.controls.color.value,
      relatedActivationStatus:
        addClientStatusForm.controls.relatedActivationStatus.value,
    };
    return this.postJson("/settings/clientStatuses", JSON.stringify(body));
  }

  public addCurrencyInformation(
    addCurrencyInformationForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addCurrencyInformationForm.controls.name.value,
      code: addCurrencyInformationForm.controls.code.value,
      symbol: addCurrencyInformationForm.controls.symbol.value,
      status: addCurrencyInformationForm.controls.status.value,
      type: addCurrencyInformationForm.controls.type.value,
      mappingReference:
        addCurrencyInformationForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/currencies", JSON.stringify(body));
  }

  public addGameType(addGameTypeForm: FormGroup): Observable<any> {
    let body = {
      name: addGameTypeForm.controls.name.value,
      mappingReference: addGameTypeForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/GameTypes", JSON.stringify(body));
  }

  public addGamingAccountStatus(
    addGamingAccountStatusForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addGamingAccountStatusForm.controls.name.value,
      mappingReference:
        addGamingAccountStatusForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/GamingAccountStatuses",
      JSON.stringify(body)
    );
  }

  public addGamingTransactionType(
    addGamingTransactionTypeForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addGamingTransactionTypeForm.controls.name.value,
      mappingReference:
        addGamingTransactionTypeForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/GamingTransactionTypes",
      JSON.stringify(body)
    );
  }

  public addClientCategory(addClientCategoryForm: FormGroup): Observable<any> {
    let body = {
      name: addClientCategoryForm.controls.name.value,
      mappingReference: addClientCategoryForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/clientCategories", JSON.stringify(body));
  }

  public addTransactionMethod(
    addTransactionMethodForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addTransactionMethodForm.controls.name.value,
      mappingReference:
        addTransactionMethodForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/transactionMethods", JSON.stringify(body));
  }

  public addInternalBlacklistedReason(
    addInternalBlacklistedReasonForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addInternalBlacklistedReasonForm.controls.name.value,
      externalReference:
        addInternalBlacklistedReasonForm.controls.externalReference.value,
    };
    return this.postJson(
      "/administration/internalblacklistedreasons",
      JSON.stringify(body)
    );
  }

  public addTransactionType(
    addTransactionTypeForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addTransactionTypeForm.controls.name.value,
      incoming: addTransactionTypeForm.controls.incoming.value,
      mappingReference: addTransactionTypeForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/transactionTypes", JSON.stringify(body));
  }

  public addFatcaStatus(addFatcaStatusForm: FormGroup): Observable<any> {
    let body = {
      name: addFatcaStatusForm.controls.name.value,
      mappingReference: addFatcaStatusForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/fatcaStatus", JSON.stringify(body));
  }

  public addFatcaEntityCategorization(
    addFatcaEntityCategorizationForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addFatcaEntityCategorizationForm.controls.name.value,
      mappingReference:
        addFatcaEntityCategorizationForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/fatcaEntityCategorization",
      JSON.stringify(body)
    );
  }

  public addFatcaDocument(addFatcaDocumentForm: FormGroup): Observable<any> {
    let body = {
      name: addFatcaDocumentForm.controls.name.value,
      mappingReference: addFatcaDocumentForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/fatcaDocument", JSON.stringify(body));
  }

  public addFatcaCrsEntityCategorization(
    addFatcaCrsEntityCategorizationForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addFatcaCrsEntityCategorizationForm.controls.name.value,
      mappingReference:
        addFatcaCrsEntityCategorizationForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/fatcaCrsEntityCategorization",
      JSON.stringify(body)
    );
  }

  public addTradeType(addTradeTypeForm: FormGroup): Observable<any> {
    let body = {
      name: addTradeTypeForm.controls.name.value,
      mappingReference: addTradeTypeForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/tradeTypes", JSON.stringify(body));
  }

  public addParameterQuery(form: FormGroup): Observable<any> {
    let body = {
      name: form.controls.name.value,
      query: form.controls.query.value,
    };
    return this.postJson(
      "/systemsettings/parameterQueries",
      JSON.stringify(body)
    );
  }

  public addTag(addTagForm: FormGroup): Observable<any> {
    let body = {
      name: addTagForm.controls.name.value,
    };

    return this.postJson("/settings/tags", JSON.stringify(body));
  }

  public addRelatedWebsiteType(form: FormGroup): Observable<any> {
    let body = {
      name: form.controls.name.value,
      mappingReference: form.controls.mappingReference.value,
    };
    return this.postJson("/settings/relatedWebsiteTypes", JSON.stringify(body));
  }

  public addMidType(form: FormGroup): Observable<any> {
    let body = {
      name: form.controls.name.value,
      mappingReference: form.controls.mappingReference.value,
    };
    return this.postJson("/settings/midTypes", JSON.stringify(body));
  }

  public addClientCategorization(form: FormGroup): Observable<any> {
    let body = {
      name: form.controls.name.value,
      mappingReference: form.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/clientCategorizations",
      JSON.stringify(body)
    );
  }

  public addMidClass(form: FormGroup): Observable<any> {
    let body = {
      name: form.controls.name.value,
      mappingReference: form.controls.mappingReference.value,
    };
    return this.postJson("/settings/midClasses", JSON.stringify(body));
  }

  public addInternalBlacklist(
    addInternalBlacklistForm: FormGroup
  ): Observable<any> {
    let body = {
      markedDate: addInternalBlacklistForm.controls.markedDate.value,
      dateOfBirth: addInternalBlacklistForm.controls.dateOfBirth.value,
      internalBlacklistedReasonId:
        addInternalBlacklistForm.controls.internalBlacklistedReasonId.value,
      firstName: addInternalBlacklistForm.controls.firstName.value,
      lastName: addInternalBlacklistForm.controls.lastName.value,
      idNumber: addInternalBlacklistForm.controls.idNumber.value,
      taxIdentificationNumber:
        addInternalBlacklistForm.controls.taxIdentificationNumber.value,
    };
    return this.postJson(
      "/administration/internalblacklists",
      JSON.stringify(body)
    );
  }

  public addInternalBlacklistCorporate(
    addInternalBlacklistCorporateForm: FormGroup
  ): Observable<any> {
    let body = {
      markedDate: addInternalBlacklistCorporateForm.controls.markedDate.value,
      internalBlacklistedReasonId:
        addInternalBlacklistCorporateForm.controls.internalBlacklistedReasonId
          .value,
      registeredName:
        addInternalBlacklistCorporateForm.controls.registeredName.value,
      registrationNumber:
        addInternalBlacklistCorporateForm.controls.registrationNumber.value,
      taxIdentificationNumber:
        addInternalBlacklistCorporateForm.controls.taxIdentificationNumber
          .value,
    };
    return this.postJson(
      "/administration/internalblacklistCorporates",
      JSON.stringify(body)
    );
  }

  public addContractPolicyCategory(
    addContractPolicyCategoryForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addContractPolicyCategoryForm.controls.name.value,
      MappingReference:
        addContractPolicyCategoryForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/contractPolicyCategories",
      JSON.stringify(body)
    );
  }

  public addContractPolicyStatus(
    addContractPolicyStatusForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addContractPolicyStatusForm.controls.name.value,
      MappingReference:
        addContractPolicyStatusForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/contractPolicyStatus",
      JSON.stringify(body)
    );
  }

  public addOverallEvaluationGrade(
    addOverallEvaluationGradeForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addOverallEvaluationGradeForm.controls.name.value,
      riskPointFrom: addOverallEvaluationGradeForm.controls.riskPointFrom.value,
      riskPointTo: addOverallEvaluationGradeForm.controls.riskPointTo.value,
      score: addOverallEvaluationGradeForm.controls.score.value,
      color: addOverallEvaluationGradeForm.controls.color.value,
      nextEvaluationDays:
        addOverallEvaluationGradeForm.controls.nextEvaluationDays.value,
      defaultBringScoreHigh:
        addOverallEvaluationGradeForm.controls.bringScoringGradeHigh.value,
      isIneligibleGrade: null,
      evaluationGradeType: 1,
      regulationGroupId:
        addOverallEvaluationGradeForm.controls.regulationGroupId.value,
      mappingReference:
        addOverallEvaluationGradeForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/evaluationGrades", JSON.stringify(body));
  }

  public addAnswerEvaluationGrade(
    addAnswerEvaluationGradeForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addAnswerEvaluationGradeForm.controls.name.value,
      riskPointFrom: addAnswerEvaluationGradeForm.controls.riskPointFrom.value,
      riskPointTo: addAnswerEvaluationGradeForm.controls.riskPointTo.value,
      score: addAnswerEvaluationGradeForm.controls.score.value,
      color: addAnswerEvaluationGradeForm.controls.color.value,
      nextEvaluationDays: null,
      defaultBringScoreHigh:
        addAnswerEvaluationGradeForm.controls.bringScoringGradeHigh.value,
      isIneligibleGrade:
        addAnswerEvaluationGradeForm.controls.isIneligibleGrade.value,
      evaluationGradeType: 2,
      regulationGroupId:
        addAnswerEvaluationGradeForm.controls.regulationGroupId.value,
      mappingReference:
        addAnswerEvaluationGradeForm.controls.mappingReference.value,
    };
    return this.postJson("/settings/evaluationGrades", JSON.stringify(body));
  }

  public addContractPolicyBeneficiaryRelation(
    form: FormGroup
  ): Observable<any> {
    let body = {
      name: form.controls.name.value,
      mappingReference: form.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/contractPolicyBeneficiaryRelation",
      JSON.stringify(body)
    );
  }

  public addContractPolicyProduct(
    addContractPolicyProductForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addContractPolicyProductForm.controls.name.value,
      ContractPolicyCategoryId:
        addContractPolicyProductForm.controls.contractPolicyCategoryId.value,
      MappingReference:
        addContractPolicyProductForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/contractPolicyProducts",
      JSON.stringify(body)
    );
  }

  public addContractTransactionPaymentMethod(
    addContractTransactionPaymentMethodForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addContractTransactionPaymentMethodForm.controls.name.value,
      MappingReference:
        addContractTransactionPaymentMethodForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/contractTransactionPaymentMethod",
      JSON.stringify(body)
    );
  }

  public addRule(addRuleForm: FormGroup): Observable<any> {
    let body = {
      name: addRuleForm.controls.name.value,
      description: addRuleForm.controls.description.value,
      type: addRuleForm.controls.type.value,
      status: addRuleForm.controls.status.value,
      casesCreationFlagPolicy:
        addRuleForm.controls.casesCreationFlagPolicy.value,
      tuningQuery: "",
      tuningSelectQuery: addRuleForm.controls.tuningSelectQuery.value,
      regulationGroupId: addRuleForm.controls.regulationGroupId.value,
      autoCloseCases: addRuleForm.controls.autoCloseCases.value,
      ruleCategoryId: addRuleForm.controls.ruleCategoryId.value,
      manualCreateCases: addRuleForm.controls.manualCreateCases.value,
      referenceCode: addRuleForm.controls.referenceCode.value,
      ruleLogic: addRuleForm.controls.ruleLogic.value,
    };
    return this.postJson("/administration/rules", JSON.stringify(body));
  }

  public addRuleFromHash(
    addRuleForm: FormGroup,
    regulationGroups: any
  ): Observable<any> {
    let body = {
      securityHash: addRuleForm.controls.securityHash.value,
      regulationGroupIds: regulationGroups,
    };
    return this.postJson("/administration/ruleFromHash", JSON.stringify(body));
  }

  public addSystemDynamicReport(
    addDynamicReportForm: FormGroup
  ): Observable<any> {
    let body = {
      name: addDynamicReportForm.controls.name.value,
      description: addDynamicReportForm.controls.description.value,
      status: addDynamicReportForm.controls.status.value,
      query: addDynamicReportForm.controls.query.value,
    };
    return this.postJson(
      "/administration/systemDynamicReports",
      JSON.stringify(body)
    );
  }

  public addSystemDynamicReportFromHash(
    addDynamicReportForm: FormGroup
  ): Observable<any> {
    let body = {
      securityHash: addDynamicReportForm.controls.securityHash.value,
    };
    return this.postJson(
      "/administration/dynamicReportFromHash",
      JSON.stringify(body)
    );
  }

  public addSystemNotificationTemplate(addSystemNotificationTemplateForm: FormGroup): Observable<any> {
    let body = {
      notificationTemplateType: addSystemNotificationTemplateForm.controls['notificationTemplateType'].value,
      reference: addSystemNotificationTemplateForm.controls['reference'].value,
      title: addSystemNotificationTemplateForm.controls['title'].value,
      description: addSystemNotificationTemplateForm.controls['description'].value,
      externalCredentialsSetupId: addSystemNotificationTemplateForm.controls['externalCredentialsSetupId'].value,
      endpointUrl: addSystemNotificationTemplateForm.controls['endpointUrl'].value,
      endpointHeader: addSystemNotificationTemplateForm.controls['endpointHeader'].value,
      endpointBody: addSystemNotificationTemplateForm.controls['endpointBody'].value,
      method: addSystemNotificationTemplateForm.controls['method'].value.toUpperCase(),
      externalEndpointBodyTemplate: addSystemNotificationTemplateForm.controls['externalEndpointBodyTemplate'].value,
      unescapeBody: addSystemNotificationTemplateForm.controls['unescapeBody'].value,
      mappingReference: addSystemNotificationTemplateForm.controls['mappingReference'].value,
    };
    return this.postJson("/settings/notificationTemplate", JSON.stringify(body));
  }

  public addNotificationTemplate(
    addNotificationTemplateForm: FormGroup
  ): Observable<any> {
    let body = {
      reference: addNotificationTemplateForm.controls.reference.value,
      title: addNotificationTemplateForm.controls.title.value,
      description: addNotificationTemplateForm.controls.description.value,
      MappingReference:
        addNotificationTemplateForm.controls.mappingReference.value,
    };
    return this.postJson(
      "/settings/notificationTemplate",
      JSON.stringify(body)
    );
  }

  public testRuleExecution(
    ruleId: string,
    testTuningRuleParameterExecutionForm: FormGroup,
    createCases: boolean,
    json: string,
    ruleExecutionParams: any
  ): Observable<any> {
    const controls = testTuningRuleParameterExecutionForm.controls;
    let body = {
      ruleId: ruleId,
      executionName: controls.executionName.value,
      resultLimit: controls.resultLimit.value,
      jsonRecord: json,
      createCases: createCases,
      casesCreationFlagPolicy: controls.casesCreationFlagPolicy.value,
      statusFilter: controls.statusFilter.value,
      includeDeletedClients: controls.includeDeletedClients.value,
      includeArchivedClients: controls.includeArchivedClients.value,
      includeNonClients: controls.includeNonClients.value,
      ruleExecutionParamValues: ruleExecutionParams,
    };
    return this.postJson(
      "/administration/rules/" + ruleId + "/testExecution",
      JSON.stringify(body)
    );
  }

  public runRules(
    ruleIds: number[],
    runRuleForm: FormGroup,
    json: string
  ): Observable<any> {
    let body = {
      ruleIds: ruleIds,
      jsonRecord: json,
      casesCreationFlagPolicy:
        runRuleForm.controls.casesCreationFlagPolicy.value,
      statusFilter: runRuleForm.controls.statusFilter.value,
      includeDeletedClients: runRuleForm.controls.includeDeletedClients.value,
      includeArchivedClients: runRuleForm.controls.includeArchivedClients.value,
      includeNonClients: runRuleForm.controls.includeNonClients.value,
    };
    return this.postJson("/processes/runRules", JSON.stringify(body));
  }

  public getFirstClientByRegulationGroupId(
    regulationGroupId: number
  ): Observable<any> {
    return this.fetch(
      `/processes/runRules/regulation-group/${regulationGroupId}`
    );
  }

  public getClientsPortalUsers(): Observable<any> {
    return this.fetch('/processes/clientsPortalUsers');
  }

  public addIdVerificationDataSupport(
    idVerificationDataSupport: FormGroup
  ): Observable<any> {
    let body = {
      countryId: idVerificationDataSupport.controls.countryId.value,
      nameLevel: idVerificationDataSupport.controls.nameLevel.value,
      addressLevel: idVerificationDataSupport.controls.addressLevel.value,
      localityLevel: idVerificationDataSupport.controls.localityLevel.value,
      dateOfBirthLevel:
        idVerificationDataSupport.controls.dateOfBirthLevel.value,
      nationalIdLevel: idVerificationDataSupport.controls.nationalIdLevel.value,
      phoneLevel: idVerificationDataSupport.controls.phoneLevel.value,
      emailLevel: idVerificationDataSupport.controls.emailLevel.value,
      // "notes": idVerificationDataSupport.controls.notes.value
    };

    return this.postJson(
      "/systemSettings/idVerificationDataSupports",
      JSON.stringify(body)
    );
  }

  public addTuningRuleParameter(
    addTuningRuleParameterForm: FormGroup,
    ruleId: any
  ): Observable<any> {
    let body = {
      ruleId: ruleId,
      paramName: addTuningRuleParameterForm.controls.paramName.value,
      name: addTuningRuleParameterForm.controls.name.value,
      type: addTuningRuleParameterForm.controls.type.value,
      referenceTable: addTuningRuleParameterForm.controls.referenceTable.value,
    };
    return this.postJson(
      "/administration/rules/" + ruleId + "/tuning/parameters",
      JSON.stringify(body)
    );
  }

  public addTuningDynamicReportParameter(
    addTuningDynamicReportParameterForm: FormGroup,
    dynamicReportId: any
  ): Observable<any> {
    let body = {
      dynamicReportId: dynamicReportId,
      paramName: addTuningDynamicReportParameterForm.controls.paramName.value,
      name: addTuningDynamicReportParameterForm.controls.name.value,
      type: addTuningDynamicReportParameterForm.controls.type.value,
      referenceTable:
        addTuningDynamicReportParameterForm.controls.referenceTable.value,
      parameterQueryId:
        addTuningDynamicReportParameterForm.controls.parameterQueryId.value,
    };
    return this.postJson(
      "/administration/systemDynamicReports/" +
      dynamicReportId +
      "/tuning/parameters",
      JSON.stringify(body)
    );
  }

  public addTuningLinkedRule(
    addTuningLinkedRuleForm: FormGroup,
    ruleId: any
  ): Observable<any> {
    let body = {
      ruleId: ruleId,
      executionName: addTuningLinkedRuleForm.controls.executionName.value,
      linkedRuleId: addTuningLinkedRuleForm.controls.linkedRuleId.value,
      linkedExecutionName:
        addTuningLinkedRuleForm.controls.linkedExecutionName.value,
    };
    return this.postJson(
      "/administration/rules/" + ruleId + "/tuning/ruleExecutions",
      JSON.stringify(body)
    );
  }

  public addManyLinkedTuningRuleExecutions(
    selectedRuleIds: any,
    selectedExecutionNames: any,
    ruleId: any
  ): Observable<any> {
    let body = {
      ruleId: ruleId,
      selectedRuleExecutionIds: selectedRuleIds,
      ruleTuningExecutionNames: selectedExecutionNames,
    };
    return this.postJson(
      "/administration/rules/" + ruleId + "/tuning/ruleExecutions/addMany",
      JSON.stringify(body)
    );
  }

  public addTuningLinkedRuleBlockCase(
    addTuningLinkedRuleBlockCaseForm: FormGroup,
    ruleId: any
  ): Observable<any> {
    let body = {
      ruleId: ruleId,
      executionName:
        addTuningLinkedRuleBlockCaseForm.controls.executionName.value,
      linkedRuleId:
        addTuningLinkedRuleBlockCaseForm.controls.linkedRuleId.value,
      linkedExecutionName:
        addTuningLinkedRuleBlockCaseForm.controls.linkedExecutionName.value,
    };
    return this.postJson(
      "/administration/rules/" + ruleId + "/tuning/ruleExecutionsBlockCases",
      JSON.stringify(body)
    );
  }

  public addTuningRuleParameterExecution(
    addTuningRuleParameterForm: FormGroup,
    ruleId: any
  ): Observable<any> {
    let body = {
      ruleId: ruleId,
      executionName: addTuningRuleParameterForm.controls.executionName.value,
      liveTransactionStatus:
        addTuningRuleParameterForm.controls.liveTransactionStatus.value,
      weight: addTuningRuleParameterForm.controls.weight.value,
    };
    return this.postJson(
      "/administration/rules/" + ruleId + "/tuning/executions",
      JSON.stringify(body)
    );
  }

  public addQueryBasedSetupParameterExecution(
    addTuningRuleParameterForm: FormGroup,
    queryBasedSetupId: any
  ): Observable<any> {
    let body = {
      queryBasedSetupId: queryBasedSetupId,
      executionName: addTuningRuleParameterForm.controls.executionName.value,
    };
    return this.postJson(
      "/administration/queryBasedSetups/" +
      queryBasedSetupId +
      "/tuning/executions",
      JSON.stringify(body)
    );
  }

  public editTuningRuleParameterExecutionMainDetails(
    editTuningRuleParameterForm: FormGroup,
    ruleId: any
  ): Observable<any> {
    let body = {
      ruleId: ruleId,
      executionName: editTuningRuleParameterForm.controls.executionName.value,
      newExecutionName:
        editTuningRuleParameterForm.controls.newExecutionName.value,
      liveTransactionStatus:
        editTuningRuleParameterForm.controls.liveTransactionStatus.value,
      weight: editTuningRuleParameterForm.controls.weight.value,
      clientSegments: editTuningRuleParameterForm.controls.clientSegments.value,
    };
    return this.putJson(
      "/settings/rules/" + ruleId + "/tuning/executions/modify",
      JSON.stringify(body)
    );
  }

  public editTuningRuleParameterExecutionSegments(
    editTuningRuleParameterForm: FormGroup
  ): Observable<any> {
    let body = {
      ruleId: editTuningRuleParameterForm.controls.ruleId.value,
      executionName: editTuningRuleParameterForm.controls.executionName.value,
      clientSegments: editTuningRuleParameterForm.controls.clientSegments.value,
    };
    return this.putJson(
      "/settings/rules/" +
      editTuningRuleParameterForm.controls.ruleId.value +
      "/tuning/executions/modify/segments",
      JSON.stringify(body)
    );
  }

  public editQueryBasedSetupParameterExecutionMainDetails(
    editTuningRuleParameterForm: FormGroup,
    queryBasedSetupId: any
  ): Observable<any> {
    let body = {
      queryBasedSetupId: queryBasedSetupId,
      executionName: editTuningRuleParameterForm.controls.executionName.value,
      newExecutionName:
        editTuningRuleParameterForm.controls.newExecutionName.value,
    };
    return this.putJson(
      "/settings/queryBasedSetups/" +
      queryBasedSetupId +
      "/tuning/executions/modify",
      JSON.stringify(body)
    );
  }

  public addTuningDynamicReportParameterExecution(
    addTuningDynamicReportParameterForm: FormGroup,
    dynamicReportId: any
  ): Observable<any> {
    let body = {
      dynamicReportId: dynamicReportId,
      executionName:
        addTuningDynamicReportParameterForm.controls.executionName.value,
    };
    return this.postJson(
      "/administration/systemDynamicReports/" +
      dynamicReportId +
      "/tuning/executions",
      JSON.stringify(body)
    );
  }

  public deleteSelectedClientPersonSearchResults(
    clientId: any,
    personSearchResultIds: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      personSearchResultIds: personSearchResultIds,
    };
    return this.postJson(
      "/clientIndividuals/" + clientId + "/selectedPersonSearchResults",
      JSON.stringify(body)
    );
  }

  public deleteSelectedClientBusinessSearchResults(
    clientId: any,
    businessSearchResultIds: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      BusinessSearchResultIds: businessSearchResultIds,
    };

    return this.postJson(
      `/clientCorporates/${clientId}/selectedBusinessSearchResults`,
      JSON.stringify(body)
    );
  }

  //* Artemis Alpha Bank
  public performPersonSearchArtemisAlphaBank(
    frm: FormGroup,
    clientId: any
  ): Observable<any> {
    const body = {
      username: frm.controls.username.value,
      applicantCif: frm.controls.applicantCif.value,
      applicantName: frm.controls.applicantName.value,
      country: frm.controls.country.value,
      identificationNumber: frm.controls.identificationNumber.value,
      identificationType: frm.controls.identificationType.value,
    };
    return this.postJson(
      `/clientCommon/${clientId}/artemisKyc`,
      JSON.stringify(body)
    );
  }

  //* Internal Black Lists
  public getInternalBlackListsSearch(
    clientId: any,
    performSearchInternalBlackListsForm: FormGroup,
    clientType: number
  ): Observable<any> {
    const body = {
      registeredName: performSearchInternalBlackListsForm.controls
        .registeredName
        ? performSearchInternalBlackListsForm.controls.registeredName.value
        : null,
      identificationNumber: performSearchInternalBlackListsForm.controls
        .identificationNumber
        ? performSearchInternalBlackListsForm.controls.identificationNumber
          .value
        : null,
      taxIdentificationNumber: performSearchInternalBlackListsForm.controls
        .taxIdentificationNumber
        ? performSearchInternalBlackListsForm.controls.taxIdentificationNumber
          .value
        : null,
      firstName: performSearchInternalBlackListsForm.controls.firstName
        ? performSearchInternalBlackListsForm.controls.firstName.value
        : null,
      lastName: performSearchInternalBlackListsForm.controls.lastName
        ? performSearchInternalBlackListsForm.controls.lastName.value
        : null,
      dateOfBirth: performSearchInternalBlackListsForm.controls.dateOfBirth
        ? performSearchInternalBlackListsForm.controls.dateOfBirth.value
        : null,
      idNumber: performSearchInternalBlackListsForm.controls.idNumber
        ? performSearchInternalBlackListsForm.controls.idNumber.value
        : null,
      clientType: clientType,
    };
    return this.postJson(
      `/clientCommon/${clientId}/internalBlackListsSearch`,
      JSON.stringify(body)
    );
  }

  //* Add to ongoing monitoring of Internal Black Lists
  public addToInternalBlackListsOngoingMonitoring(
    clientId: any
  ): Observable<any> {
    const body = {};

    return this.postJson(
      `/clientCommon/${clientId}/internalBlackListsOngoingMonitoring`,
      JSON.stringify(body)
    );
  }

  //* Remove from ongoing monitoring of Internal Black Lists
  public removeFromInternalBlackListsOngoingMonitoring(
    clientId: any
  ): Observable<any> {
    return this.delete(
      `/clientCommon/${clientId}/internalBlackListsOngoingMonitoring`,
      ""
    );
  }

  //* Check if in an ongoing monitoring internal blacklist list
  public getClientInternalBlackListsOngoingMonitoringStatus(
    clientId: any
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${clientId}/internalBlackListsOngoingMonitoring`
    );
  }

  public refreshMonitoringResults(clientId: any) {
    let body = {};
    return this.putJson(
      `/clientCommon/${clientId}/refreshMonitoringResults`,
      JSON.stringify(body)
    );
  }

  public performPersonSearch(
    performPersonSearchForm: FormGroup,
    clientId: any,
    serviceName: string,
    monitoring: boolean = false
  ): Observable<any> {
    var body;
    if (serviceName == "Acuris" || serviceName == "AcurisV3") {
      let acurisBody = {
        fullName: performPersonSearchForm.controls.fullName.value,
        dateOfBirth: performPersonSearchForm.controls.dateOfBirth.value,
        address: performPersonSearchForm.controls.address.value,
        city: performPersonSearchForm.controls.city.value,
        postcode: performPersonSearchForm.controls.postCode.value,
        country: performPersonSearchForm.controls.country.value,
        threshold: performPersonSearchForm.controls.threshold.value,
      };

      body = {
        acuris: acurisBody,
      };
    } else if (serviceName == "Bridger") {
      let bridgerBody = {
        firstName: performPersonSearchForm.controls.firstName.value,
        middlename: performPersonSearchForm.controls.middleName.value,
        lastName: performPersonSearchForm.controls.lastName.value,
        dateOfBirth: performPersonSearchForm.controls.dateOfBirth.value,
        addressLine1: performPersonSearchForm.controls.addressLine1.value,
        addressLine2: performPersonSearchForm.controls.addressLine2.value,
        city: performPersonSearchForm.controls.city.value,
        postalCode: performPersonSearchForm.controls.postalCode.value,
        country: performPersonSearchForm.controls.country.value,
        iDNumber: performPersonSearchForm.controls.iDNumber.value,
        gender:
          performPersonSearchForm.controls.gender.value != ""
            ? performPersonSearchForm.controls.gender.value
            : null,
        threshold: performPersonSearchForm.controls.threshold.value,
      };
      body = {
        bridger: bridgerBody,
      };
    } else if (serviceName == "DowJones") {
      let dowJonesBody = {
        firstName: performPersonSearchForm.controls.firstName.value,
        middlename: performPersonSearchForm.controls.middleName.value,
        lastName: performPersonSearchForm.controls.lastName.value,
        threshold: performPersonSearchForm.controls.threshold.value,
      };
      body = {
        dowJones: dowJonesBody,
      };
    } else if (serviceName == "WorldCheck") {
      let worldCheckBody = {
        name: performPersonSearchForm.controls.name.value,
        DateOfBirth: performPersonSearchForm.controls.dateOfBirth.value,
        countryLocation: performPersonSearchForm.controls.countryLocation.value,
        countryOfBirth: performPersonSearchForm.controls.countryOfBirth.value,
        countryNationality:
          performPersonSearchForm.controls.countryNationality.value,
        gender:
          performPersonSearchForm.controls.gender.value != ""
            ? performPersonSearchForm.controls.gender.value
            : null,
        threshold: performPersonSearchForm.controls.threshold.value,
      };
      body = {
        worldCheck: worldCheckBody,
      };
    } else if (serviceName == "Rdc") {
      let rdcBody = {
        name: performPersonSearchForm.controls.name.value,
        dateOfBirth: performPersonSearchForm.controls.dateOfBirth.value,
        address: performPersonSearchForm.controls.address.value,
        city: performPersonSearchForm.controls.city.value,
        postcode: performPersonSearchForm.controls.postCode.value,
        stateProv: performPersonSearchForm.controls.stateProv.value,
        country: performPersonSearchForm.controls.country.value,
        threshold: performPersonSearchForm.controls.threshold.value,
        monitoring: monitoring,
      };
      body = {
        rdc: rdcBody,
      };
    }

    return this.postJson(
      `/clientIndividuals/${clientId}/performPersonSearch`,
      JSON.stringify(body)
    );
  }

  // Kompany.com
  public performCompanySearch(
    form: FormGroup,
    countryOfIncorporationId,
    clientId,
    cost
  ) {
    let body = {
      searchBy: form.controls.searchBy.value,
      searchByValue: form.controls.searchByValue.value,
      isoAlpha2Code: form.controls.isoAlpha2Code.value,
      countryState: form.controls.countryState.value,
      cost: cost,
      clientId: clientId,
    };
    return this.postJson(
      `/clientCorporates/${clientId}/performCompanySearch`,
      JSON.stringify(body)
    );
  }

  // Kompany.com
  public getCompanyDetails(
    clientId,
    clientCompanySearchId,
    dataset,
    documentsState,
    cost
  ) {
    let body = {
      clientCompanySearchId: clientCompanySearchId,
      dataset: dataset,
      documentsState: documentsState,
      cost: cost,
    };
    return this.postJson(
      `/clientCorporates/${clientId}/getCompanyDetails`,
      JSON.stringify(body)
    );
  }

  public performBusinessSearch(
    performBusinessSearchForm: FormGroup,
    clientId: any,
    serviceName: string,
    monitoring: boolean = false
  ): Observable<any> {
    var body;

    if (serviceName == "Acuris" || serviceName == "AcurisV3") {
      let acurisBody = {
        businessName: performBusinessSearchForm.controls.businessName.value,
        country: performBusinessSearchForm.controls.country.value,
        threshold: performBusinessSearchForm.controls.threshold.value,
      };
      body = {
        acuris: acurisBody,
      };
    } else if (serviceName == "Bridger") {
      let bridgerBody = {
        businessName: performBusinessSearchForm.controls.businessName.value,
        addressLine1: performBusinessSearchForm.controls.addressLine1.value,
        addressLine2: performBusinessSearchForm.controls.addressLine2.value,
        city: performBusinessSearchForm.controls.city.value,
        postalCode: performBusinessSearchForm.controls.postalCode.value,
        country: performBusinessSearchForm.controls.country.value,
        iDNumber: performBusinessSearchForm.controls.iDNumber.value,
        threshold: performBusinessSearchForm.controls.threshold.value,
      };
      body = {
        bridger: bridgerBody,
      };
    } else if (serviceName == "DowJones") {
      let dowJonesBody = {
        businessName: performBusinessSearchForm.controls.businessName.value,
        threshold: performBusinessSearchForm.controls.threshold.value,
      };
      body = {
        dowJones: dowJonesBody,
      };
    } else if (serviceName == "WorldCheck") {
      let worldCheckBody = {
        BusinessName: performBusinessSearchForm.controls.businessName.value,
        RegisteredCountry:
          performBusinessSearchForm.controls.registeredCountry.value,
        threshold: performBusinessSearchForm.controls.threshold.value,
      };
      body = {
        worldCheck: worldCheckBody,
      };
    } else if (serviceName == "Rdc") {
      let rdcCheckBody = {
        BusinessName: performBusinessSearchForm.controls.businessName.value,
        Address: performBusinessSearchForm.controls.address.value,
        City: performBusinessSearchForm.controls.city.value,
        Postcode: performBusinessSearchForm.controls.postCode.value,
        StateProv: performBusinessSearchForm.controls.stateProv.value,
        Country: performBusinessSearchForm.controls.country.value,
        Threshold: performBusinessSearchForm.controls.threshold.value,
        monitoring: monitoring,
      };
      body = {
        rdc: rdcCheckBody,
      };
    }

    return this.postJson(
      `/clientCorporates/${clientId}/performBusinessSearch`,
      JSON.stringify(body)
    );
  }

  public performPersonMonitoringAcuris(
    performPersonMonitoringForm: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      acurisPersonMonitoringRequest: {
        forename: performPersonMonitoringForm.controls.firstName.value,
        surname: performPersonMonitoringForm.controls.lastName.value,
        address1: performPersonMonitoringForm.controls.address1.value,
        address2: performPersonMonitoringForm.controls.address2.value,
        address3: performPersonMonitoringForm.controls.address3.value,
        postcode: performPersonMonitoringForm.controls.postcode.value,
        uniqueId: performPersonMonitoringForm.controls.uniqueId.value,
        country: performPersonMonitoringForm.controls.country.value,
        nationality: performPersonMonitoringForm.controls.nationality.value,
        sourceName: performPersonMonitoringForm.controls.sourceName.value,
        dob: performPersonMonitoringForm.controls.dob.value,
        yob: performPersonMonitoringForm.controls.yob.value,
        insertDate: performPersonMonitoringForm.controls.insertDate.value,
        updateDate: performPersonMonitoringForm.controls.updateDate.value,
        countryName: performPersonMonitoringForm.controls.countryName.value,
        nationalityName:
          performPersonMonitoringForm.controls.nationalityName.value,
      },
    };
    return this.postJson(
      `/clientIndividuals/${clientId}/performPersonMonitoring`,
      JSON.stringify(body)
    );
  }

  public editMonitoringPersonAcuris(
    performPersonMonitoringForm: FormGroup,
    clientId: any,
    uniqueId: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      forename: performPersonMonitoringForm.controls.firstName.value,
      surname: performPersonMonitoringForm.controls.lastName.value,
      businessName: null,
      uniqueId: uniqueId,
    };
    return this.postJson(
      `/administration/editOngoingMonitoringClients`,
      JSON.stringify(body)
    );
  }

  public editMonitoringBusinessAcuris(
    performPersonMonitoringForm: FormGroup,
    clientId: any,
    uniqueId: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      forename: null,
      surname: null,
      businessName: performPersonMonitoringForm.controls.businessName.value,
      uniqueId: uniqueId,
    };
    return this.postJson(
      `/administration/editOngoingMonitoringClients`,
      JSON.stringify(body)
    );
  }

  public performPersonMonitoringBridger(
    performPersonMonitoringForm: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      bridgerPersonMonitoringRequest: {
        firstName: performPersonMonitoringForm.controls.firstName.value,
        lastName: performPersonMonitoringForm.controls.lastName.value,
        middleName: performPersonMonitoringForm.controls.middleName.value,
        dateOfBirth: performPersonMonitoringForm.controls.dateOfBirth.value,
        addressLine1: performPersonMonitoringForm.controls.addressLine1.value,
        addressLine2: performPersonMonitoringForm.controls.addressLine2.value,
        city: performPersonMonitoringForm.controls.city.value,
        postalCode: performPersonMonitoringForm.controls.postalCode.value,
        country: performPersonMonitoringForm.controls.country.value,
        iDNumber: performPersonMonitoringForm.controls.iDNumber.value,
        gender: performPersonMonitoringForm.controls.gender.value,
      },
    };
    return this.postJson(
      `/clientIndividuals/${clientId}/performPersonMonitoring`,
      JSON.stringify(body)
    );
  }

  public performPersonMonitoringDowJones(
    performPersonMonitoringForm: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      dowJonesPersonMonitoringRequest: {
        firstName: performPersonMonitoringForm.controls.firstName.value,
        lastName: performPersonMonitoringForm.controls.lastName.value,
        middleName: performPersonMonitoringForm.controls.middleName.value,
      },
    };
    return this.postJson(
      `/clientIndividuals/${clientId}/performPersonMonitoring`,
      JSON.stringify(body)
    );
  }

  public performPersonMonitoringWorldCheck(
    performPersonMonitoringForm: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      worldCheckPersonMonitoringRequest: {
        caseId: performPersonMonitoringForm.controls.caseId.value,
        caseSystemId: performPersonMonitoringForm.controls.caseSystemId.value,
        worldCheckAction:
          performPersonMonitoringForm.controls.worldCheckAction.value,
      },
    };
    return this.postJson(
      `/clientIndividuals/${clientId}/performPersonMonitoring`,
      JSON.stringify(body)
    );
  }

  public performPersonMonitoringRdc(
    performPersonMonitoringForm: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      rdcPersonMonitoringRequest: {
        inquiryId: performPersonMonitoringForm.controls.inquiryId.value,
      },
    };
    return this.postJson(
      `/clientIndividuals/${clientId}/performPersonMonitoring`,
      JSON.stringify(body)
    );
  }

  public performBusinessMonitoringAcuris(
    performBusinessMonitoringForm: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      acurisBusinessMonitoringRequest: {
        businessName: performBusinessMonitoringForm.controls.businessName.value,
        address1: performBusinessMonitoringForm.controls.address1.value,
        address2: performBusinessMonitoringForm.controls.address2.value,
        address3: performBusinessMonitoringForm.controls.address3.value,
        address6: performBusinessMonitoringForm.controls.address6.value,
        postcode: performBusinessMonitoringForm.controls.postcode.value,
        insertDate: performBusinessMonitoringForm.controls.insertDate.value,
        uniqueId: performBusinessMonitoringForm.controls.uniqueId.value,
        updateDate: performBusinessMonitoringForm.controls.updateDate.value,
        country: performBusinessMonitoringForm.controls.country.value,
        countryName: performBusinessMonitoringForm.controls.countryName.value,
        sourceName: performBusinessMonitoringForm.controls.sourceName.value,
      },
    };
    return this.postJson(
      `/clientCorporates/${clientId}/performBusinessMonitoring`,
      JSON.stringify(body)
    );
  }

  public performBusinessMonitoringBridger(
    performBusinessMonitoringForm: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      bridgerBusinessMonitoringRequest: {
        businessName: performBusinessMonitoringForm.controls.businessName.value,
        // "address": performBusinessMonitoringForm.controls.address.value,
        // "city": performBusinessMonitoringForm.controls.city.value,
        // "country": performBusinessMonitoringForm.controls.country.value,
        // "postcode": performBusinessMonitoringForm.controls.postcode.value,
        // "iDNumber": performBusinessMonitoringForm.controls.iDNumber.value,
        // "addressLine2": performBusinessMonitoringForm.controls.addressLine2.value,
        // "registeredCountry": performBusinessMonitoringForm.controls.registeredCountry.value,
        // "threshold": performBusinessMonitoringForm.controls.threshold.value,
      },
    };
    return this.postJson(
      `/clientCorporates/${clientId}/performBusinessMonitoring`,
      JSON.stringify(body)
    );
  }

  public performBusinessMonitoringDowJones(
    performBusinessMonitoringForm: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      dowJonesBusinessMonitoringRequest: {
        businessName: performBusinessMonitoringForm.controls.businessName.value,
        // "address": performBusinessMonitoringForm.controls.address.value,
        // "city": performBusinessMonitoringForm.controls.city.value,
        // "country": performBusinessMonitoringForm.controls.country.value,
        // "postcode": performBusinessMonitoringForm.controls.postcode.value,
        // "iDNumber": performBusinessMonitoringForm.controls.iDNumber.value,
        // "addressLine2": performBusinessMonitoringForm.controls.addressLine2.value,
        // "registeredCountry": performBusinessMonitoringForm.controls.registeredCountry.value,
        // "threshold": performBusinessMonitoringForm.controls.threshold.value,
      },
    };
    return this.postJson(
      `/clientCorporates/${clientId}/performBusinessMonitoring`,
      JSON.stringify(body)
    );
  }

  public performBusinessMonitoringWorldCheck(
    performBusinessMonitoringForm: FormGroup,
    clientId: any
  ): Observable<any> {
    let body = {
      worldCheckBusinessMonitoringRequest: {
        caseId: performBusinessMonitoringForm.controls.caseId.value,
        caseSystemId: performBusinessMonitoringForm.controls.caseSystemId.value,
        worldCheckAction:
          performBusinessMonitoringForm.controls.worldCheckAction.value,
      },
    };
    return this.postJson(
      `/clientCorporates/${clientId}/performBusinessMonitoring`,
      JSON.stringify(body)
    );
  }

  public editRoleClaims(role: any): Observable<any> {
    let body = {
      Id: role.id,
      Claims: role.claims,
    };

    return this.putJson(
      "/administration/roles/" + role.id + "/claims",
      JSON.stringify(body)
    );
  }

  public addRole(addRoleForm: FormGroup): Observable<any> {
    let body = {
      Name: addRoleForm.controls.name.value,
      Description: addRoleForm.controls.description.value,
    };
    return this.postJson("/administration/roles", JSON.stringify(body));
  }

  public duplicateRole(duplicateRoleForm: FormGroup): Observable<any> {
    let body = {
      ExistingRoleId: duplicateRoleForm.controls.existingRoleId.value,
      NewName: duplicateRoleForm.controls.name.value,
      NewDescription: duplicateRoleForm.controls.description.value,
    };
    return this.postJson("/administration/duplicateRole", JSON.stringify(body));
  }

  public duplicatePortalSectionField(id): Observable<any> {
    return this.postJson(`/portalSetup/fields/${id}/duplicate`, "");
  }

  public duplicateDataAccessGroup(
    duplicateGroupForm: FormGroup
  ): Observable<any> {
    let body = {
      ExistingDataAccessGroupId:
        duplicateGroupForm.controls.existingGroupId.value,
      NewName: duplicateGroupForm.controls.name.value,
      NewDescription: duplicateGroupForm.controls.description.value,
    };
    return this.postJson(
      "/administration/duplicateDataAccessGroup",
      JSON.stringify(body)
    );
  }

  public duplicateRegulationGroup(
    duplicateRegulationGroupForm: FormGroup
  ): Observable<any> {
    let body = {
      ExistingRegulationGroupId:
        duplicateRegulationGroupForm.controls.existingRegulationGroupId.value,
      NewName: duplicateRegulationGroupForm.controls.newName.value,
      Hash: duplicateRegulationGroupForm.controls.hash.value,
    };
    return this.postJson(
      "/settings/duplicateregulationgroups",
      JSON.stringify(body)
    );
  }

  public changePassword(settingsForm: FormGroup): Observable<any> {
    let body = new URLSearchParams();
    body.set("Username", settingsForm.controls.username.value);
    body.set("CurrentPassword", settingsForm.controls.currentPassword.value);
    body.set("NewPassword", settingsForm.controls.newPassword.value);
    body.set("ConfirmPassword", settingsForm.controls.confirmPassword.value);

    return this.post("/user/changePassword", body);
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    // let errMsg = (error.error) ? error.error.message :
    //   error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(error); // log to console instead
    //Remove errors for no authentication
    if (error && error.error != null) {
      return throwError(error);
      //return Observable.throw(errMsg);
    }
  }

  public editClientAccount(
    editClientAccountForm: FormGroup,
    clientId: number,
    accountId: number,
    paymentMethods: any,
    customFields: any
  ): Observable<any> {
    let body = {
      id: accountId,
      clientId: clientId,
      bankId: editClientAccountForm.controls.bank.value,
      swiftCode: editClientAccountForm.controls.swiftCode.value,
      countryId: editClientAccountForm.controls.country.value,
      iban: editClientAccountForm.controls.iban.value,
      accountNumber: editClientAccountForm.controls.accountNumber.value,
      status: editClientAccountForm.controls.status.value,
      currencyInformationId:
        editClientAccountForm.controls.currencyInformationId.value,
      dateCreated: editClientAccountForm.controls.dateCreated.value,
      dateClosed: editClientAccountForm.controls.dateClosed.value,
      dateDeleted: editClientAccountForm.controls.dateDeleted.value,
      paymentMethodIds: paymentMethods,
      comment: editClientAccountForm.controls.comment.value,
      customFields: customFields,
    };

    return this.putJson(
      "/clientCommon/" + clientId + "/accounts",
      JSON.stringify(body)
    );
  }

  public editClientGamingAccount(
    editClientGamingAccountForm: FormGroup,
    clientId: number,
    accountId: number,
    customFields: any
  ): Observable<any> {
    let formControls = editClientGamingAccountForm.controls;

    let body = {
      id: accountId,
      clientId: clientId,
      GamingAccountStatusId:
        editClientGamingAccountForm.controls.gamingAccountStatusId.value,
      ExternalReference:
        editClientGamingAccountForm.controls.externalReference.value,
      SignUpDate: editClientGamingAccountForm.controls.signUpDate.value,
      SignUpIP: editClientGamingAccountForm.controls.signUpIP.value,
      signUpDevice: editClientGamingAccountForm.controls.signUpDevice.value,
      Comment: editClientGamingAccountForm.controls.comment.value,
      CustomFields: customFields,
      Balance: formControls.balance.value,
    };

    return this.putJson(
      "/clientCommon/" + clientId + "/gamingaccounts",
      JSON.stringify(body)
    );
  }

  public editClientWallet(
    addClientWalletForm: FormGroup,
    id: any,
    walletId: number,
    selectedCurrencies
  ): Observable<any> {
    let body = {
      clientId: id,
      id: walletId,
      address: addClientWalletForm.controls.address.value,
      name: addClientWalletForm.controls.name.value,
      currencies: selectedCurrencies,
      RecordInfo: {
        Created: addClientWalletForm.controls.dateCreated.value,
      },
    };

    return this.putJson(`/clientCommon/${id}/wallets`, JSON.stringify(body));
  }

  public editClientContract(
    editClientContractForm: FormGroup,
    clientId: number,
    contractId: number,
    customFields: any
  ): Observable<any> {
    let body = {
      id: contractId,
      ClientId: clientId,
      PolicyNumber: editClientContractForm.controls.policyNumber.value,
      PolicyParticulars:
        editClientContractForm.controls.policyParticulars.value,
      InitialAmount: editClientContractForm.controls.initialAmount.value,
      YearlyAmount: editClientContractForm.controls.yearlyAmount.value,
      ContractPolicyStatusId:
        editClientContractForm.controls.contractPolicyStatusId.value,
      ContractPolicyProductId:
        editClientContractForm.controls.contractPolicyProductId.value,
      PolicyInceptionDate:
        editClientContractForm.controls.policyInceptionDate.value,
      PolicyExpirationDate:
        editClientContractForm.controls.policyExpirationDate.value,
      policyPlan: editClientContractForm.controls.policyPlan.value,
      assignedTo: editClientContractForm.controls.assignedTo.value,
      applicationApprovalReference:
        editClientContractForm.controls.applicationApprovalReference.value,
      bankName: editClientContractForm.controls.bankName.value,
      customFields: customFields,
    };
    return this.putJson(
      "/clientCommon/" + clientId + "/contracts",
      JSON.stringify(body)
    );
  }

  public editClientContractTransaction(
    editClientContractTransactionForm: FormGroup,
    clientId: number,
    contractTransactionId: number,
    customFields: any
  ): Observable<any> {
    let controls = editClientContractTransactionForm.controls;
    let body = {
      id: contractTransactionId,
      clientId: clientId,
      clientContractId: controls.clientContractId.value,
      paymentAmount: controls.paymentAmount.value,
      paymentDate: controls.paymentDate.value,
      paymentDescription: controls.paymentDescription.value,
      reasonForTransaction: controls.reasonForTransaction.value,
      customFields: customFields,
    };

    return this.putJson(
      "/clientCommon/" + clientId + "/contractTransactions",
      JSON.stringify(body)
    );
  }

  public editClientContractCollection(
    editClientContractCollectionForm: FormGroup,
    clientId: number,
    contractCollectionId: number,
    customFields: any
  ): Observable<any> {
    let controls = editClientContractCollectionForm.controls;
    let body = {
      id: contractCollectionId,
      clientId: clientId,
      clientContractId: controls.clientContractId.value,
      paymentMethodId: controls.paymentMethodId.value,
      paymentAmount: controls.paymentAmount.value,
      paymentDate: controls.paymentDate.value,
      paymentDescription: controls.paymentDescription.value,
      reasonForTransaction: controls.reasonForTransaction.value,
      customFields: customFields,
    };

    return this.putJson(
      "/clientCommon/" + clientId + "/contractCollections",
      JSON.stringify(body)
    );
  }

  public getRemainingClientEvaluationCriteria(
    clientId: number
  ): Observable<any> {
    return this.fetch(
      `/clientCommon/${clientId}/evaluations/remainingcriteria`
    );
  }

  public addManuallyClientEvaluationCriterion(
    clientEvaluationId: number,
    criterionIds: number
  ): Observable<any> {
    const body = {
      clientEvaluationId: clientEvaluationId,
      criterionIds: criterionIds,
    };

    return this.postJson(
      `/clientCommon/evaluations/criteria/manually`,
      JSON.stringify(body)
    );
  }

  public deleteManuallyAddedClientEvaluationCriterion(
    clientEvaluationId: number,
    criterionId: number
  ): Observable<any> {
    return this.delete(
      `/clientCommon/evaluations/${clientEvaluationId}/criteria/manually/${criterionId}`,
      null
    );
  }

  public editClientEvaluation(
    clientId: any,
    clientEvaluation: any
  ): Observable<any> {
    let body = {
      id: clientEvaluation.id,
      clientId: clientId,
      evaluationDate: clientEvaluation.evaluationDate,
      reasonForEvaluation: clientEvaluation.reasonForEvaluation,
      evaluationScore: clientEvaluation.evaluationScore,
      evaluationGradeId: clientEvaluation.evaluationGradeId,
      highestGradeId: clientEvaluation.highestGradeId,
      complianceOfficerGradeId: clientEvaluation.complianceOfficerGradeId,
      complianceOfficerComment: clientEvaluation.complianceOfficerComment,
      managerGradeId: clientEvaluation.managerGradeId,
      managerComment: clientEvaluation.managerComment,
      nextEvaluationDate: clientEvaluation.nextEvaluationDate,
      comments: clientEvaluation.comments,
      clientEvaluationCriteria: clientEvaluation.clientEvaluationCriteria,
    };
    return this.putJson(
      "/clientCommon/" + clientId + "/evaluations/" + clientEvaluation.id,
      JSON.stringify(body)
    );
  }

  public deleteClientEvaluation(
    clientId: number,
    clientEvaluationId: number
  ): Observable<any> {
    return this.delete(
      "/clientCommon/" + clientId + "/evaluations/" + clientEvaluationId,
      {}
    );
  }

  public editClientQuestionnaire(
    clientId: any,
    clientQuestionnaire: any,
    isFinalized: boolean
  ): Observable<any> {
    let body = {
      id: clientQuestionnaire.id,
      clientId: clientId,
      questionnaireDate: clientQuestionnaire.questionnaireDate,
      reasonForQuestionnaire: clientQuestionnaire.reasonForQuestionnaire,
      nextQuestionnaireDate: clientQuestionnaire.nextQuestionnaireDate,
      comments: clientQuestionnaire.comments,
      clientQuestionnaireQuestions:
        clientQuestionnaire.clientQuestionnaireQuestions,
      isFinalized: isFinalized,
    };
    return this.putJson(
      "/clientCommon/" + clientId + "/questionnaires/" + clientQuestionnaire.id,
      JSON.stringify(body)
    );
  }

  public finalizeClientQuestionnaire(
    clientId: any,
    clientQuestionnaire: any,
    isFinalized: boolean
  ): Observable<any> {
    let body = {
      id: clientQuestionnaire.id,
      clientId: clientId,
      questionnaireDate: clientQuestionnaire.questionnaireDate,
      reasonForQuestionnaire: clientQuestionnaire.reasonForQuestionnaire,
      nextQuestionnaireDate: clientQuestionnaire.nextQuestionnaireDate,
      comments: clientQuestionnaire.comments,
      clientQuestionnaireQuestions:
        clientQuestionnaire.clientQuestionnaireQuestions,
      isFinalized: isFinalized,
    };
    return this.putJson(
      "/clientCommon/" +
      clientId +
      "/questionnaires/" +
      clientQuestionnaire.id +
      "/finalize",
      JSON.stringify(body)
    );
  }

  public editClientQuestionnaireResult(
    clientId: any,
    clientQuestionnaireId: any,
    form: FormGroup,
    customFields: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      clientQuestionnaireId: clientQuestionnaireId,
      clientCategorizationId:
        form.controls.clientQuestionnaireResultClientCategorizationEditableId
          .value || -1,
      comments: form.controls.comments.value,
      customFields: customFields,
    };
    return this.putJson(
      "/clientCommon/" +
      clientId +
      "/questionnaires/" +
      clientQuestionnaireId +
      "/result",
      JSON.stringify(body)
    );
  }

  public editClientCorporateEvaluation(
    clientId: any,
    clientEvaluations: any
  ): Observable<any> {
    var clientEvaluationsRequest = [];

    for (var clientEvaluation of clientEvaluations) {
      clientEvaluationsRequest.push(clientEvaluation);
    }

    var clientEvaluationId = clientEvaluations.filter(
      (x) => x.clientId == clientId
    )[0].id;

    let body = {
      clientEvaluations: clientEvaluationsRequest,
    };

    return this.putJson(
      "/clientCorporates/" +
      clientId +
      "/corporateEvaluations/" +
      clientEvaluationId,
      JSON.stringify(body)
    );
  }

  public editClientJointEvaluation(
    clientId: any,
    clientEvaluations: any
  ): Observable<any> {
    var clientEvaluationsRequest = [];

    for (var clientEvaluation of clientEvaluations) {
      clientEvaluationsRequest.push(clientEvaluation);
    }

    var clientEvaluationId = clientEvaluations.filter(
      (x) => x.clientId == clientId
    )[0].id;

    let body = {
      clientEvaluations: clientEvaluationsRequest,
    };

    return this.putJson(
      "/clientCorporates/" +
      clientId +
      "/corporateEvaluations/" +
      clientEvaluationId,
      JSON.stringify(body)
    );
  }

  public performClientEvaluation(
    clientId: any,
    clientEvaluationId: any
  ): Observable<any> {
    let body = {};

    return this.postJson(
      `/clientCommon/${clientId}/evaluations/${clientEvaluationId}/perform`,
      JSON.stringify(body)
    );
  }

  public performClientCorporateEvaluation(
    clientId: any,
    clientEvaluationId: any
  ): Observable<any> {
    let body = {};

    return this.postJson(
      `/clientCorporates/${clientId}/corporateevaluations/${clientEvaluationId}/perform`,
      JSON.stringify(body)
    );
  }

  public performClientJointEvaluation(
    clientId: any,
    clientEvaluationId: any
  ): Observable<any> {
    let body = {};

    return this.postJson(
      `/clientCorporates/${clientId}/corporateevaluations/${clientEvaluationId}/perform`,
      JSON.stringify(body)
    );
  }

  public getFieldLogUpdates(fieldLogSetups: FieldLogSetup[]): Observable<any> {
    let body = {
      FieldLogSetups: fieldLogSetups,
    };
    return this.postJson(
      "/clientCommon/fieldLogsUpdates",
      JSON.stringify(body)
    );
  }

  public editCompletedClientEvaluation(clientId: any, clientEvaluationId: any, clientEvaluationForm: any, customFields: any): Observable<any> {
    let body = {
      id: clientEvaluationId,
      clientId: clientId,
      complianceOfficerGradeId: clientEvaluationForm.controls['complianceOfficerGradeId'].value,
      complianceOfficerComment: clientEvaluationForm.controls['complianceOfficerComment'].value,
      complianceOfficerReviewStatus: clientEvaluationForm.controls['complianceOfficerReviewStatus'].value,
      managerGradeId: clientEvaluationForm.controls['managerGradeId'].value,
      managerComment: clientEvaluationForm.controls['managerComment'].value,
      managerReviewStatus: clientEvaluationForm.controls['managerReviewStatus'].value,
      complianceOfficerScore: clientEvaluationForm.controls['complianceOfficerScore'].value,
      managerScore: clientEvaluationForm.controls['managerScore'].value,
      customFields: customFields,
    };

    return this.putJson(`/clientCommon/${clientId}/evaluations/${clientEvaluationId}/completed`, JSON.stringify(body));
  }

  public editClientTransaction(
    editClientTransactionForm: FormGroup,
    clientId: number,
    transactionId: number,
    customFields: any,
    cardDetailsFilled: boolean
  ): Observable<any> {
    let controls = editClientTransactionForm.controls;
    let body = {
      Id: transactionId,
      ClientId: clientId,
      ClientAccountId: controls.clientAccount.value,
      BaseAmount: controls.baseAmount.value,
      ForeignAmount: controls.foreignAmount.value,
      ForeignCurrencyInformationId: controls.foreignCurrency.value,
      TransactionDate: controls.transactionDate.value,
      ApprovalDate: controls.approvalDate.value,
      Description: controls.description.value,
      IncomingCountryId: controls.incomingCountry.value,
      OutgoingCountryId: controls.outgoingCountry.value,
      CounterpartyAccount: controls.counterpartyAccount.value,
      CounterpartyName: controls.counterpartyName.value,
      TransactionMethodId: controls.transactionMethod.value,
      TransactionTypeId: controls.transactionType.value,
      ExternalReference:
        controls.externalReference.value == ""
          ? null
          : controls.externalReference.value,
      ClientWalletId: controls.clientWallet.value,
      CounterpartyWalletAddress: controls.counterpartyWalletAddress.value,
      TransferReference: controls.transferReference.value,
      ClientWalletCurrencyInformationId:
        controls.clientWalletCurrencyInformation.value,
      ClientCardTransaction: cardDetailsFilled
        ? {
          ClientCardId: controls.clientCardId.value,
          Chargeback: controls.chargeback.value,
          MCC: controls.mcc.value,
          Merchant: controls.merchant.value,
          MerchantExternalReference: controls.merchantExternalReference.value,
          PanNumber: controls.panNumber.value,
          CardPresentedStatus: controls.cardPresentedStatus.value,
          MagneticStripe: controls.magneticStripe.value,
          CardAuthorizationStatus: controls.cardAuthorizationStatus.value,
          Moto: controls.moto.value,
          KeyEntry: controls.keyEntry.value,
          CardTransactionStatus: controls.cardTransactionStatus.value,
        }
        : null,
      customFields: customFields,
    };

    return this.putJson(
      "/clientCommon/" + clientId + "/transactions",
      JSON.stringify(body)
    );
  }

  public editClientGamingTransaction(
    editClientGamingTransactionForm: FormGroup,
    clientId: number,
    transactionId: number,
    customFields: any
  ): Observable<any> {
    let formControls = editClientGamingTransactionForm.controls;
    let body = {
      Id: transactionId,
      ClientId: clientId,
      ClientGamingAccountId: formControls.clientGamingAccountId.value,
      TransactionDate: formControls.transactionDate.value,
      IPAddress: formControls.ipAddress.value,
      Comments: formControls.comments.value,
      DeviceUsed: formControls.deviceUsed.value,
      GamingTransactionTypeId: formControls.gamingTransactionTypeId.value,
      GameTypeId: formControls.gameTypeId.value,
      CountryId: formControls.countryId.value,
      Volume: formControls.volume.value,
      PlayTime: formControls.playTime.value,
      Location: formControls.location.value,
      TotalPlayTimeSinceLast: formControls.totalPlayTimeSinceLast.value,
      PromotionAmount: formControls.promotionAmount.value,
      WithdrawalLimit: formControls.withdrawalLimit.value,
      AmountOnHold: formControls.amountOnHold.value,
      ExternalReference:
        formControls.externalReference.value == ""
          ? null
          : formControls.externalReference.value,
      customFields: customFields,
    };

    return this.putJson(
      "/clientCommon/" + clientId + "/gamingtransactions",
      JSON.stringify(body)
    );
  }

  public editClientPaymentMethodVolumes(
    volumes: any,
    clientId: number,
    paymentMethodId: number
  ): Observable<any> {
    let body = {
      clientId: clientId,
      clientPaymentMethodId: paymentMethodId,
      volumes: volumes,
    };

    return this.putJson(
      "/clientCommon/" +
      clientId +
      "/paymentMethods/" +
      paymentMethodId +
      "/volumes",
      JSON.stringify(body)
    );
  }

  public editClientPaymentMethodCustomFields(
    customFields: any,
    clientId: number,
    clientPaymentMethodId: number
  ): Observable<any> {
    let body = {
      clientId: clientId,
      clientPaymentMethodId: clientPaymentMethodId,
      customFieldsDetails: customFields,
    };

    return this.putJson(
      "/clientCommon/" +
      clientId +
      "/paymentMethods/" +
      clientPaymentMethodId +
      "/customFields",
      JSON.stringify(body)
    );
  }

  public editClientCaseAnalysis(
    newData: any,
    oldData: any,
    clientId: any,
    clientCaseId: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      clientCaseId: clientCaseId,
      name: newData.name != undefined ? newData.name : oldData.name,
      value: newData.value != undefined ? newData.value : oldData.value,
      source: newData.source != undefined ? newData.source : oldData.source,
    };
    return this.putJson(
      "/clientCommon/" + clientId + "/cases/" + clientCaseId + "/analysis",
      JSON.stringify(body)
    );
  }

  public editClientGamingAccountHistory(newData: any, oldData: any) {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      historyDate:
        newData.historyDate != undefined
          ? newData.historyDate
          : oldData.historyDate,
      loginIpAddress:
        newData.loginIpAddress != undefined
          ? newData.loginIpAddress
          : oldData.loginIpAddress,
      loginCountryId:
        newData.loginCountryId != undefined
          ? newData.loginCountryId
          : oldData.loginCountryId,
      loginStatus:
        newData.loginStatus != undefined
          ? newData.loginStatus
          : oldData.loginStatus,
      loginType:
        newData.loginType != undefined ? newData.loginType : oldData.loginType,
      deviceUsed:
        newData.deviceUsed != undefined
          ? newData.deviceUsed
          : oldData.deviceUsed,
    };
    return this.putJson(
      "/clientCommon/gamingAccountHistory",
      JSON.stringify(body)
    );
  }

  public editClientContractAgent(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      agentName:
        newData.agentName != undefined ? newData.agentName : oldData.agentName,
      agentCode:
        newData.agentCode != undefined ? newData.agentCode : oldData.agentCode,
      split: newData.split != undefined ? newData.split : oldData.split,
      customFields:
        newData.customFields != undefined
          ? newData.customFields
          : oldData.customFields,
    };
    return this.putJson("/clientCommon/contracts/agents", JSON.stringify(body));
  }

  public editClientContractSurrender(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      surrender:
        newData.surrender != undefined ? newData.surrender : oldData.surrender,
      amount: newData.amount != undefined ? newData.amount : oldData.amount,
      date: newData.date != undefined ? newData.date : oldData.date,
      surrenderType:
        newData.surrenderType != undefined
          ? newData.surrenderType
          : oldData.surrenderType,
    };
    return this.putJson(
      "/clientCommon/contracts/surrenders",
      JSON.stringify(body)
    );
  }

  public editClientContractBooster(
    newData: any,
    oldData: any
  ): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      amount: newData.amount != undefined ? newData.amount : oldData.amount,
      date: newData.date != undefined ? newData.date : oldData.date,
    };
    return this.putJson(
      "/clientCommon/contracts/boosters",
      JSON.stringify(body)
    );
  }

  public editAction(actionBody: any): Observable<any> {
    return this.putJson("/clientCommon/actions", JSON.stringify(actionBody));
  }

  public editClientTrade(
    editClientTradeForm: FormGroup,
    clientId: number,
    tradeId: number,
    customFields: any
  ): Observable<any> {
    let controls = editClientTradeForm.controls;

    let body = {
      id: tradeId,
      clientId: clientId,
      createdOn: controls.createdOn.value,
      investmentAccountId: controls.investmentAccount.value,
      tradeTypeId: controls.tradeTypeId.value,
      baseOpenPrice: controls.baseOpenPrice.value,
      baseClosePrice: controls.baseClosePrice.value,
      foreignOpenPrice: controls.foreignOpenPrice.value,
      foreignClosePrice: controls.foreignClosePrice.value,
      openTime: controls.openTime.value,
      closeTime: controls.closeTime.value,
      orderReference: controls.orderReference.value,
      volume: controls.volume.value,
      symbol: controls.symbol.value,
      symbolGroup: controls.symbolGroup.value,
      foreignCurrencyInformationId: controls.foreignCurrency.value,
      baseProfit: controls.baseProfit.value,
      foreignProfit: controls.foreignProfit.value,
      frequency: controls.frequency.value,
      baseTradeAmount: controls.baseTradeAmount.value,
      foreignTradeAmount: controls.foreignTradeAmount.value,
      externalReference:
        controls.externalReference.value == ""
          ? null
          : controls.externalReference.value,
      customFields: customFields,
    };

    return this.putJson(
      "/clientCommon/" + clientId + "/trades",
      JSON.stringify(body)
    );
  }

  public editClientCase(
    editClientTradeForm: FormGroup,
    clientId: number,
    caseId: number,
    attachments: any[],
    customFields: any
  ): Observable<any> {
    let controls = editClientTradeForm.controls;

    let body = {
      Id: caseId,
      ClientId: clientId,
      Name: controls.name.value,
      Description: controls.description.value,
      Status: controls.status.value,
      SuspicionLevel: controls.suspicionLevel.value,
      SuspicionLevelComment: controls.suspicionLevelComment.value,
      LiveTransactionStatus: controls.liveTransactionStatus.value,
      liveClientTransactionId: controls.liveClientTransactionId.value,
      Priority: controls.priority.value,
      OwnerUsername: controls.ownerUsername.value,
      CaseWorkflowStatusId: controls.caseWorkflowStatusId.value,
      Attachments: attachments,
      DirectoryPathOrUrl: controls.directoryPathOrUrl.value,
      customFields: customFields,
    };

    return this.putJson(
      "/clientCommon/" + clientId + "/cases",
      JSON.stringify(body)
    );
  }

  public editClientTransactionScreeningCase(
    form: FormGroup,
    transactionScreeningCaseId: number,
    customFields: any
  ): Observable<any> {
    let controls = form.controls;

    let body = {
      id: transactionScreeningCaseId,
      caseStatus: controls.caseStatus.value,
      autoStatus: controls.autoStatus.value,
      manualStatus: controls.manualStatus.value,
      comments: controls.comments.value,
      ownerUsername: controls.ownerUsername.value,
      customFields: customFields,
    };

    return this.putJson(
      `/clientCommon/transactionScreeningCases/${transactionScreeningCaseId}`,
      JSON.stringify(body)
    );
  }

  public performIdentityCheck(
    id: any,
    identityCheckRequestForm: FormGroup
  ): Observable<any> {
    let body = {
      addressLine1: identityCheckRequestForm.controls.addressLine1.value,
      countryCode: identityCheckRequestForm.controls.countryCode.value,
      completeName: identityCheckRequestForm.controls.completeName.value,
      dob: identityCheckRequestForm.controls.dob.value,
      addressLine2: identityCheckRequestForm.controls.addressLine2.value,
      addressLine3: identityCheckRequestForm.controls.addressLine3.value,
      district: identityCheckRequestForm.controls.district.value,
      houseNumber: identityCheckRequestForm.controls.houseNumber.value,
      locality: identityCheckRequestForm.controls.locality.value,
      postalCode: identityCheckRequestForm.controls.postalCode.value,
      province: identityCheckRequestForm.controls.province.value,
      thoroughfare: identityCheckRequestForm.controls.thoroughfare.value,
      fullEmailAddress:
        identityCheckRequestForm.controls.fullEmailAddress.value,
      gender: identityCheckRequestForm.controls.gender.value,
      nationalID: identityCheckRequestForm.controls.nationalID.value,
      organizationName:
        identityCheckRequestForm.controls.organizationName.value,
      phoneNumber: identityCheckRequestForm.controls.phoneNumber.value,
    };
    return this.postJson(
      `/clientCommon/${id}/performIdentityCheck`,
      JSON.stringify(body)
    );
  }

  public performDocumentCheck(
    id: any,
    clientDocumentId: any,
    clientDocument: any,
    clientProfile: any
  ): Observable<any> {
    let file = "";
    let fileSupport = "";
    let fileSelfie = "";
console.log(clientDocument)
    if (clientDocument.document.documentType == 2) {
      // ID Card
      file =
        clientDocument.attachments
          .filter((x) => x.idCardType == 1)
          .sort((a, b) => {
            return b.id - a.id;
          })[0] != null
          ? clientDocument.attachments
            .filter((x) => x.idCardType == 1)
            .sort((a, b) => {
              return b.id - a.id;
            })[0].data
          : "";

      fileSupport =
        clientDocument.attachments
          .filter((x) => x.idCardType == 2)
          .sort((a, b) => {
            return b.id - a.id;
          })[0] != null
          ? clientDocument.attachments
            .filter((x) => x.idCardType == 2)
            .sort((a, b) => {
              return b.id - a.id;
            })[0].data
          : "";

      fileSelfie =
        clientDocument.attachments
          .filter((x) => x.idCardType == 3)
          .sort((a, b) => {
            return b.id - a.id;
          })[0] != null
          ? clientDocument.attachments
            .filter((x) => x.idCardType == 3)
            .sort((a, b) => {
              return b.id - a.id;
            })[0].data
          : "";
    }
    // Driving License
    else if (clientDocument.document.documentType == 4) {
      // ID Card
      file =
        clientDocument.attachments
          .filter((x) => x.drivingLicenseType == 1)
          .sort((a, b) => {
            return b.id - a.id;
          })[0] != null
          ? clientDocument.attachments
            .filter((x) => x.drivingLicenseType == 1)
            .sort((a, b) => {
              return b.id - a.id;
            })[0].data
          : "";

      fileSupport =
        clientDocument.attachments
          .filter((x) => x.drivingLicenseType == 2)
          .sort((a, b) => {
            return b.id - a.id;
          })[0] != null
          ? clientDocument.attachments
            .filter((x) => x.drivingLicenseType == 2)
            .sort((a, b) => {
              return b.id - a.id;
            })[0].data
          : "";
    } else {
      file =
        clientDocument.attachments.sort((a, b) => {
          return b.id - a.id;
        })[0] != null
          ? clientDocument.attachments.sort((a, b) => {
            return b.id - a.id;
          })[0].data
          : "";
    }

    let body = {
      title: "-",
      firstName: clientProfile.firstName,
      middleName: clientProfile.middleName,
      lastName: clientProfile.lastName,
      email: clientProfile.email,
      dateOfBirth: clientProfile.dateOfBirth,
      expiryDate: clientDocument.expirationDate,
      issueDate: clientDocument.dateOfIssue,
      telephone: "-",
      mobile: "-",
      nationality: "-",
      country: clientDocument.countryOfIssue.isoAlpha2Code,
      fileFace: fileSelfie,
      file: file,
      fileSupport: fileSupport,
      documentType: "-",
      side: "-",
      documentNumber: "00001",
    };
console.log(body);
    return this.postJson(
      `/clientCommon/${id}/documents/${clientDocumentId}/performDocumentCheck`,
      JSON.stringify(body)
    );
  }

  public jobSchedulerAddRuleJob(ruleId: string): Observable<any> {
    return this.post("/scheduler/" + ruleId, "");
  }

  public jobSchedulerDeleteRuleJob(ruleId: string): Observable<any> {
    return this.delete("/scheduler/" + ruleId, "");
  }

  public getJsonDifferences(lang1: string, lang2: string): Observable<any> {
    let body = {
      LanguageJsonString1: lang1,
      LanguageJsonString2: lang2,
    };

    return this.postJson(
      "/systemSettings/language/missingKeys",
      JSON.stringify(body)
    );
  }

  public getJsonFromFile(filePath: string): Observable<any> {
    return this.httpClient.get(filePath);
  }

  public getAddressTypeById(id: any): Observable<any> {
    return this.fetch("/settings/addressType/" + id);
  }

  public getFirmCriteriaCategories(): Observable<any> {
    return this.fetch("/settings/firms/criteriaCategories");
  }

  public getFirmSanctionCriteriaCategories(): Observable<any> {
    return this.fetch("/settings/firms/sanctionCriteriaCategories");
  }

  public getFirmImpactScores(): Observable<any> {
    return this.fetch("/settings/firms/impactScores");
  }

  public getFirmSanctionInherentRiskScores(): Observable<any> {
    return this.fetch("/settings/firms/sanctionInherentRiskScores");
  }

  public getFirmResidualRisks(): Observable<any> {
    return this.fetch("/settings/firms/residualRisks");
  }

  public getFirmSanctionResidualRisks(): Observable<any> {
    return this.fetch("/settings/firms/sanctionResidualRisks");
  }

  public getFirmOverallRiskScores(): Observable<any> {
    return this.fetch("/settings/firms/overallRiskScores");
  }

  public getFirmSanctionOverallRiskScores(): Observable<any> {
    return this.fetch("/settings/firms/sanctionOverallRiskScores");
  }

  public getFirmCriteria(): Observable<any> {
    return this.fetch("/settings/firms/criteria");
  }

  public getFirmSanctionCriteria(): Observable<any> {
    return this.fetch("/settings/firms/sanctionCriteria");
  }

  public getFirmCriteriaBasicInfo(): Observable<any> {
    return this.fetch("/settings/firms/criteria/basic");
  }

  public getFirmSanctionCriteriaBasicInfo(): Observable<any> {
    return this.fetch("/settings/firms/sanctionCriteria/basic");
  }

  public addFirmCriteriaCategory(model): Observable<any> {
    return this.postJson(
      "/settings/firms/criteriaCategories",
      JSON.stringify(model)
    );
  }

  public addSanctionFirmCriteriaCategory(model): Observable<any> {
    return this.postJson(
      "/settings/firms/sanctionCriteriaCategories",
      JSON.stringify(model)
    );
  }

  public getFirmMitigationActions(): Observable<any> {
    return this.fetch("/settings/firms/mitigationActions");
  }

  public getFirmSanctionMitigationActions(): Observable<any> {
    return this.fetch("/settings/firms/sanctionMitigationActions");
  }

  public addFirmImpactScore(model): Observable<any> {
    return this.postJson("/settings/firms/impactScores", JSON.stringify(model));
  }

  public addFirmInherentScore(model): Observable<any> {
    return this.postJson(
      "/settings/firms/sanctionInherentRiskScores",
      JSON.stringify(model)
    );
  }

  public addFirmSanctionImpactScore(model): Observable<any> {
    return this.postJson(
      "/settings/firms/sanctionImpactScores",
      JSON.stringify(model)
    );
  }

  public addFirmResidualRisk(model): Observable<any> {
    return this.postJson(
      "/settings/firms/residualRisks",
      JSON.stringify(model)
    );
  }

  public addFirmSanctionResidualRisk(model): Observable<any> {
    return this.postJson(
      "/settings/firms/sanctionResidualRisks",
      JSON.stringify(model)
    );
  }

  public addFirmOverallRiskScore(model): Observable<any> {
    return this.postJson(
      "/settings/firms/overallRiskScores",
      JSON.stringify(model)
    );
  }

  public addFirmSanctionOverallRiskScore(model): Observable<any> {
    return this.postJson(
      "/settings/firms/sanctionOverallRiskScores",
      JSON.stringify(model)
    );
  }

  public addFirmCriterion(model): Observable<any> {
    return this.postJson("/settings/firms/criteria", JSON.stringify(model));
  }

  public addFirmMitigationActions(model): Observable<any> {
    return this.postJson(
      "/settings/firms/mitigationActions",
      JSON.stringify(model)
    );
  }

  public addFirmSanctionMitigationActions(model): Observable<any> {
    return this.postJson(
      "/settings/firms/sanctionMitigationActions",
      JSON.stringify(model)
    );
  }

  public editFirmCriteriaCategory(model): Observable<any> {
    return this.putJson(
      "/settings/firms/criteriaCategories",
      JSON.stringify(model)
    );
  }

  public editSanctionFirmCriteriaCategory(model): Observable<any> {
    return this.putJson(
      "/settings/firms/sanctionCriteriaCategories",
      JSON.stringify(model)
    );
  }

  public editFirmImpactScore(model): Observable<any> {
    return this.putJson("/settings/firms/impactScores", JSON.stringify(model));
  }

  public editFirmInherentRiskScore(model): Observable<any> {
    return this.putJson(
      "/settings/firms/sanctionInherentRiskScores",
      JSON.stringify(model)
    );
  }

  public editFirmSanctionImpactScore(model): Observable<any> {
    return this.putJson(
      "/settings/firms/sanctionImpactScores",
      JSON.stringify(model)
    );
  }

  public editFirmResidualRisk(model): Observable<any> {
    return this.putJson("/settings/firms/residualRisks", JSON.stringify(model));
  }

  public editFirmSanctionResidualRisk(model): Observable<any> {
    return this.putJson(
      "/settings/firms/sanctionResidualRisks",
      JSON.stringify(model)
    );
  }

  public editFirmOverallRiskScore(model): Observable<any> {
    return this.putJson(
      "/settings/firms/overallRiskScores",
      JSON.stringify(model)
    );
  }

  public editFirmSanctionOverallRiskScore(model): Observable<any> {
    return this.putJson(
      "/settings/firms/sanctionOverallRiskScores",
      JSON.stringify(model)
    );
  }

  public editFirmCriterion(model): Observable<any> {
    return this.putJson("/settings/firms/criteria", JSON.stringify(model));
  }

  public editFirmMitigationActions(model): Observable<any> {
    return this.putJson(
      "/settings/firms/mitigationActions",
      JSON.stringify(model)
    );
  }

  public editFirmSanctionMitigationActions(model): Observable<any> {
    return this.putJson(
      "/settings/firms/sanctionMitigationActions",
      JSON.stringify(model)
    );
  }

  public deleteFirmCriteriaCategory(id): Observable<any> {
    return this.delete(`/settings/firms/criteriaCategories/${id}`, "");
  }

  public deleteSanctionFirmCriteriaCategory(id): Observable<any> {
    return this.delete(`/settings/firms/sanctionCriteriaCategories/${id}`, "");
  }

  public deleteFirmImpactScore(id): Observable<any> {
    return this.delete(`/settings/firms/impactScores/${id}`, "");
  }

  public deleteFirmInherentRiskScore(id): Observable<any> {
    return this.delete(`/settings/firms/sanctionInherentRiskScores/${id}`, "");
  }

  public deleteFirmSanctionImpactScore(id): Observable<any> {
    return this.delete(`/settings/firms/sanctionImpactScores/${id}`, "");
  }

  public deleteFirmResidualRisk(id): Observable<any> {
    return this.delete(`/settings/firms/residualRisks/${id}`, "");
  }

  public deleteFirmSanctionResidualRisk(id): Observable<any> {
    return this.delete(`/settings/firms/sanctionResidualRisks/${id}`, "");
  }

  public deleteFirmOverallRiskScore(id): Observable<any> {
    return this.delete(`/settings/firms/overallRiskScores/${id}`, "");
  }

  public deleteFirmSanctionOverallRiskScore(id): Observable<any> {
    return this.delete(`/settings/firms/sanctionOverallRiskScores/${id}`, "");
  }

  public deleteFirmCriterion(id): Observable<any> {
    return this.delete(`/settings/firms/criteria/${id}`, "");
  }

  public deleteFirmSanctionCriterion(id): Observable<any> {
    return this.delete(`/settings/firms/firmSanctionCriteria/${id}`, "");
  }

  public deleteFirmMitigationAction(id): Observable<any> {
    return this.delete(`/settings/firms/mitigationActions/${id}`, "");
  }

  public deleteFirmSanctionMitigationAction(id): Observable<any> {
    return this.delete(`/settings/firms/sanctionMitigationActions/${id}`, "");
  }

  public getMyTasks(): Observable<any> {
    return this.fetch("/WorkflowMyTasks/mytasks");
  }

  public updateMyTasksAssignee(
    taskId: number,
    isWorkflowTask: string
  ): Observable<any> {
    let body = {
      taskId: taskId,
      isWorkflowTask: isWorkflowTask,
    };
    return this.postJson(
      "/WorkflowMyTasks/UpdateAssigneeId",
      JSON.stringify(body)
    );
  }

  public addManualTask(taskBody: FormGroup): Observable<any> {
    let body = {
      description: taskBody.controls.description.value,
      clientId: taskBody.controls.clientId.value,
      assigneeId: taskBody.controls.assigneeId.value,
    };

    return this.postJson("/management/task", JSON.stringify(body));
  }

  public editManualTaskStatus(taskBody: FormGroup): Observable<any> {
    let body = {
      status: taskBody.controls.status.value,
      taskId: taskBody.controls.taskId.value,
      comment: taskBody.controls.comment.value,
    };

    return this.putJson("/management/manualTaskStatus", JSON.stringify(body));
  }

  public updateWorkflowTaskOwnerUser(
    id: number,
    formGroup: any
  ): Observable<any> {
    let body = {
      userId: formGroup.controls.taskOwnerId.value,
      roleId: null,
    };

    return this.putJson(`/WorkflowMyTasks/task/${id}`, JSON.stringify(body));
  }

  public updateWorkflowProgressStatus(
    task: any,
    formGroup: any
  ): Observable<any> {
    let body = {
      WFTaskId: task.wfTaskId,
      Comments: formGroup.controls.comments.value,
      TaskProgressStatus: formGroup.controls.taskProgressStatus.value,
    };

    return this.putJson(
      `/WorkflowMyTasks/updateWorkflowProgressStatus/${task.id}`,
      JSON.stringify(body)
    );
  }

  public updateWorkflowProgressStatusAuto(
    taskId: any,
    statusId: any
  ): Observable<any> {
    let body = {
      WFTaskId: taskId,
      TaskProgressStatus: statusId,
    };

    return this.putJson(
      `/WorkflowMyTasks/updateWorkflowProgressStatus/${taskId}`,
      JSON.stringify(body)
    );
  }

  public updateWorkflowTaskOwnerRole(
    id: number,
    formGroup: any
  ): Observable<any> {
    let body = {
      userId: null,
      roleId: formGroup.controls.taskRoleId.value,
    };

    return this.putJson(`/WorkflowMyTasks/task/${id}`, JSON.stringify(body));
  }

  public skipWorkflowTask(id: number): Observable<any> {
    return this.putJson(`/WorkflowMyTasks/skipTask/${id}`, "{}");
  }

  public endWorkflowProcess(processId: string): Observable<any> {
    return this.putJson(
      `/WorkflowMyTasks/endWorkflowProcess/${processId}`,
      "{}"
    );
  }

  public getPortalUrl(clientId: number): Observable<any> {
    let body = {
      clientId: clientId,
    };
    return this.postJson("/WorkflowMyTasks/GetPortalUrl", JSON.stringify(body));
  }

  public getPossibleTaskOwners(taskId: number): Observable<any> {
    return this.fetch(`/WorkflowMyTasks/possibleWorkflowTaskUsers/${taskId}`);
  }

  public getWorkflowTaskById(taskId: number): Observable<any> {
    return this.fetch(`/WorkflowMyTasks/task/${taskId}`);
  }

  public getUserTaskById(taskId: number): Observable<any> {
    return this.fetch(`/management/userTask/${taskId}`);
  }

  public initiateJobSchedulerUpdateClientEvaluationStatus(
    obj: any
  ): Observable<any> {
    let body = {
      criterion: obj.criterion,
      clientId: obj.clientId != null ? parseInt(obj.clientId) : null,
      userAction: obj.userAction,
      reason: obj.reason,
      triggerNow: obj.triggerNow == null ? false : true,
      explanation: obj.explanation,
    };

    return this.postJson(
      "/scheduler/postJobSchedulerUpdateClientEvaluationStatus",
      JSON.stringify(body)
    );
  }

  public getXmlPredefinedWorkflows(): Observable<any> {
    return this.fetch("/Workflows/PredefinedWorkflows");
  }

  public addPredefinedWorkflowFromXml(selectedWorkflows: any): Observable<any> {
    let body = {
      Workflows: selectedWorkflows,
    };

    return this.postJson("/Workflows/AddWorkflowsActive", JSON.stringify(body));
  }

  public editWorkflowXml(schemeCode: string): Observable<any> {
    return this.putJson(`/Workflows/EditWorkflowXmlScheme/${schemeCode}`, "{}");
  }

  public getXmlData(file: any): Observable<any> {
    let body = {
      Filename: file,
    };

    return this.postJson("/Workflows/XmlData", JSON.stringify(body));
  }

  public getWorkflowTasks(): Observable<any> {
    return this.fetch("/WorkflowMyTasks/mytasks");
  }

  public getWorkflowTasksHistory(): Observable<any> {
    return this.fetch("/WorkflowMyTasks/myTasksHistory");
  }

  public getCurrentEvaluationTaskAssigneeStatus(id: any) {
    return this.fetch(
      `/WorkflowMyTasks/IsCurrentEvaluationTaskAssigned/ ${id}`
    );
  }

  deletePendingClientIndividualEvaluation(id: number | any) {
    return this.delete(
      `/clientIndividuals/delete-pending-evaluation/${id}`,
      null
    );
  }

  deletePendingClientCorporateEvaluation(id: number | any) {
    return this.delete(
      `/clientCorporates/delete-pending-evaluation/${id}`,
      null
    );
  }

  public getCurrentPhaseTaskAssigneeStatus(clientRegistrationPhaseIds: any[]) {
    let body = {
      ClientRegistrationPhaseIds: clientRegistrationPhaseIds,
    };
    return this.postJson("/portalOnboarding/clients", JSON.stringify(body));
  }

  public closeCurrentUnlockPhaseTask(clientIds: any[]): Observable<any> {
    let body = {
      ClientIds: clientIds,
    };
    return this.putJson(
      "/WorkflowMyTasks/closeCurrentUnlockPhaseTask",
      JSON.stringify(body)
    );
  }

  public checkIfScreeningHistoryIsActive(): Observable<any> {
    return this.fetch("/administration/checkIfScreeningHistoryIsActive");
  }

  public performFraudRiskAnalysisOnPendingRecords(
    clientId: any,
    fraudDetectionRecordIds: any
  ): Observable<any> {
    let body = {
      fraudDetectionRecordIds: fraudDetectionRecordIds,
    };

    return this.postJson(
      `/clientCommon/${clientId}/performFraudRiskAnalysisOnPendingRecords`,
      JSON.stringify(body)
    );
  }

  public deleteSelectedPendingFraudDetectionRecords(
    clientId: any,
    pendingFraudDetectionRecordIds: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      pendingFraudDetectionRecordIds: pendingFraudDetectionRecordIds,
    };

    return this.postJson(
      `/clientCommon/${clientId}/deletePendingFraudDetectionRecordIds`,
      JSON.stringify(body)
    );
  }

  public deleteSelectedFraudDetectionRecords(
    clientId: any,
    fraudDetectionRecordIds: any
  ): Observable<any> {
    let body = {
      clientId: clientId,
      fraudDetectionRecordIds: fraudDetectionRecordIds,
    };

    return this.postJson(
      `/clientCommon/${clientId}/deleteFraudDetectionRecordIds`,
      JSON.stringify(body)
    );
  }

  public resumeWorkflowProcesses(processes: any): Observable<any> {
    let body = {
      workflowProcessesList: processes,
    };
    return this.postJson(
      "/processes/resumeWorkflowProcesses",
      JSON.stringify(body)
    );
  }

  public moveWorkflowProcessToNextState(process: any): Observable<any> {
    let body = {
      Process: process[0],
    };
    return this.putJson(
      "/processes/moveWorkflowProcessesToState",
      JSON.stringify(body)
    );
  }

  public finalizeWorkflowProcess(process: any): Observable<any> {
    let body = {
      Process: process[0],
    };
    return this.putJson(
      "/processes/finalizeWorkflowProcess",
      JSON.stringify(body)
    );
  }

  public getWorkflowErrorTask(): Observable<any> {
    return this.fetch(`/workflowMyTasks/workflowErrorTask`);
  }

  public updateUsersHasFirstLoginFlag(): Observable<any> {
    return this.putJson("/user/currentuser/hasFirstLogin", "{}");
  }

  public synchronizeSumSub(clientId: any): Observable<any> {
    return this.postJson(`/clientCommon/${clientId}/synchronizeSumSub`, "");
  }

  public requestApplicationFromSumSub(
    clientId: any,
    requestSumSubApplicationForm: FormGroup
  ): Observable<any> {
    return this.postJson(
      `/clientCommon/${clientId}/requestApplicationFromSumSub/${requestSumSubApplicationForm.controls.levelName.value}`,
      ""
    );
  }

  public getSumSubLevels(): Observable<any> {
    return this.fetch(`/clientCommon/sumSubLevelNames`);
  }

  public getClientExtendedDetails(clientId: number): Observable<any> {
    return this.fetch(`/clientCommon/${clientId}/extendedDetails`);
  }

  public synchronizeSumSubDemoWithApplicantId(
    clientId: any,
    applicantId: any
  ): Observable<any> {
    let body = {
      ClientId: clientId,
      ApplicantId: applicantId,
    };
    return this.postJson(
      `/clientIndividuals/synchronizeSumSubDemo`,
      JSON.stringify(body)
    );
  }

  public getRegTekDemoButtonsDefaultValuesEnum(): Observable<any> {
    return this.getCachedData("/enumerations/regtekDemoButtonsDefaultValues");
  }

  public getCertificateTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/certificateTypeEnum");
  }

  public addClientTransactionSyncWithChainAnalysisDemo(
    addClientTransactionForm: FormGroup,
    clientId: number
  ): Observable<any> {
    let formControls = addClientTransactionForm.controls;
    let body = {
      ClientId: clientId,
      BaseAmount: formControls.baseAmount.value,
      ExternalReference: formControls.externalReference.value,
    };
    return this.postJson(
      "/clientCommon/" + clientId + "/synchWithChainAnalysisDemo",
      JSON.stringify(body)
    );
  }

  public resetClientPossibleMatchStatuses(clientId: number): Observable<any> {
    return this.putJson(
      `/clientIndividuals/${clientId}/resetClientScreeningResults`,
      "{}"
    );
  }

  public getShowRegTekDemoFlag(): Observable<any> {
    return this.fetch(`/clientCommon/showRegTekDemoButtonsFlag`);
  }

  public getClientTasks(clientId: number): Observable<any> {
    return this.fetch(`/clientCommon/${clientId}/tasks`);
  }

  public editClientTaskAssignee(task: any, clientId: number): Observable<any> {
    let body = {
      taskId: task.taskId,
      isWorkflowTask: task.isWorkflowTask,
      description: task.description,
    };
    return this.putJson(`/clientCommon/${clientId}/task`, JSON.stringify(body));
  }

  public getClientDashboardInformation(): Observable<any> {
    return this.fetch(`/dashboard/clientDashboardInformation`);
  }

  public getLatestLiveAlerts(): Observable<any> {
    return this.fetch(`/dashboard/latestLiveAlerts`);
  }

  public getTotalCasesPerRiskLevel(
    evaluationGradeNames: any[]
  ): Observable<any> {
    let body = {
      Names: evaluationGradeNames,
    };
    return this.postJson(
      `/dashboard/assignedCasesForReviewPerRiskLevel`,
      JSON.stringify(body)
    );
  }

  public getDashboardTransactionVolume(): Observable<any> {
    return this.fetch("/dashboard/transactionVolume");
  }

  public triggerExternalNotifications(body: any): Observable<any> {
    return this.postJson(
      "/processes/trigger-external-notifications",
      JSON.stringify(body)
    );
  }

  public updateClientEvaluationCriteria(id: any): Observable<any> {
    let body = {};
    return this.putJson(`/settings/criteria/evaluation-criterion/${id}`, body);
  }

  public getClientEvaluationUISetups(): Observable<any> {
    return this.fetch("/clientCommon/evaluationFormUiSetups");
  }

  public getClientPortalMonitoringVisits(id: any): Observable<any> {
    return this.fetch(`/clientCorporates/${id}/monitoringVisits`);
  }

  public getMonitoringVisitStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/monitoringVisitStatus");
  }

  public addMonitoringVisit(form: FormGroup, clientId: any, score: any): Observable<any> {
    let body = {
      clientId: clientId,
      monitorDate: form.controls['monitoringVisitDate'].value,
      monitorBy: form.controls['monitoredBy'].value,
      monitoringVisitScore: score,
      monitoringVisitTypeId: form.controls['type'].value,
      status: form.controls['status'].value,
      aCCAReferenceNo: form.controls['accaReferenceNo'].value,
      portalQuestionnaireTypeId: form.controls['portalQuestionnaireTypeId'].value
    };

    return this.postJson(`/clientCorporates/${clientId}/monitoringVisit`, JSON.stringify(body));
  }

  public editClientMonitoringVisit(newData: any, oldData: any, clientId: any): Observable<any> {
    let body = {
      id: newData.id ? newData.id : oldData.id,
      monitorDate: newData.monitorDate ? newData.monitorDate : oldData.monitorDate,
      monitorBy: newData.monitorBy ? newData.monitorBy : oldData.monitorBy,
      monitoringVisitScore: newData.monitoringVisitScore ? newData.monitoringVisitScore : oldData.monitoringVisitScore,
      monitoringVisitTypeId: newData.monitoringVisitTypeId ? newData.monitoringVisitTypeId : oldData.monitoringVisitTypeId,
      status: newData.status ? newData.status : oldData.status,
      aCCAReferenceNo: newData.accaReferenceNo ? newData.accaReferenceNo : oldData.accaReferenceNo,
      visitNo: newData.visitNo ? newData.visitNo : oldData.visitNo,
      portalQuestionnaireTypeId: newData.portalQuestionnaireTypeId ? newData.portalQuestionnaireTypeId : oldData.portalQuestionnaireTypeId,
    };

    return this.putJson(`/clientCorporates/${clientId}/monitoringVisit`, JSON.stringify(body));
  }

  public editClientTag(newData: any, oldData: any): Observable<any> {
    let body = {
      id: newData.id != undefined ? newData.id : oldData.id,
      dateFrom: newData.tagValidFrom != undefined ? newData.tagValidFrom : oldData.tagValidFrom,
      dateTo: newData.tagValidTo === undefined ? oldData.tagValidTo : newData.tagValidTo,
      // creationDate: oldData.creationDate,
      // modifyDate: oldData.modifyDate,
      // clientName: oldData.clientName,
      // tagValidFrom: oldData.tagValidFrom,
    };
    return this.putJson("/processes/clientTag", JSON.stringify(body));
  }

  public performTransactionScreening(
    performTransactionScreeningForm: FormGroup,
    extendedInfo: any
  ): Observable<any> {
    var body = {
      SwiftMt: performTransactionScreeningForm.controls.swiftMt.value,
      Sepa: performTransactionScreeningForm.controls.sepa.value,
      Iso2022: performTransactionScreeningForm.controls.iso20022.value,
      PaymentDescriptionDetails:
        performTransactionScreeningForm.controls.paymentDescriptionDetails
          .value,
      CitiesMatchPercentage:
        performTransactionScreeningForm.controls.citiesMatchPercentage.value,
      InternalBlacklistsMatchPercentage:
        performTransactionScreeningForm.controls
          .internalBlacklistsListsMatchPercentage.value,
      CheckExistingScreening:
        performTransactionScreeningForm.controls.checkExistingScreening.value,
      ClientRegulationGroupId:
        performTransactionScreeningForm.controls.regulationGroupId.value,
      ClientId: performTransactionScreeningForm.controls.clientId.value,
      TransactionReference:
        performTransactionScreeningForm.controls.transactionReference.value,
      RetrieveFullScreeningDetails:
        performTransactionScreeningForm.controls.retrieveFullScreeningDetails
          .value,
      FastPayment: performTransactionScreeningForm.controls.fastPayment.value,
      PersonEntities: extendedInfo.personEntities,
      CorporateEntities: extendedInfo.businessEntities,
      UnspecifiedEntities: extendedInfo.unspecifiedEntities,
      CountriesCheck: extendedInfo.countries,
      CitiesCheck: extendedInfo.cities,
      RestrictedCountries: extendedInfo.restrictedCountries,
      RestrictedCities: extendedInfo.restrictedCities,
    };

    return this.postJson(
      `/ikyccalls/transactionScreening`,
      JSON.stringify(body)
    );
  }

  public getSyncedProfilesAnalysis(): Observable<any> {
    return this.fetch(`/clientCommon/syncedProfilesAnalysis`);
  }

  public getSyncStatusEnum(): Observable<any> {
    return this.getCachedData("/enumerations/cloneAndSyncProfileStatusEnum");
  }

  public getCloneAndSyncProfileDetailLinesEnum(): Observable<any> {
    return this.getCachedData(
      "/enumerations/cloneAndSyncProfileDetailLinesEnum"
    );
  }

  public getFirmSanctionImpactScores(): Observable<any> {
    return this.fetch("/settings/firms/sanctionImpactScores");
  }

  public deleteHandleClientTag(id: any): Observable<any> {
    return this.delete("/processes/handleClientTag/" + id, "");
  }

  public getQuestionCriteria(questionId: any): Observable<any> {
    return this.fetch(`/dropdown/criteria/question/${questionId}`);
  }

  public checkIfIkycIsActive(): Observable<any> {
    return this.fetch(`/administration/activeIkyc`);
  }

  public getTranslations(): Observable<any> {
    return this.fetch("/translations");
  }

  public getTranslationsAspNetDataStore(referenceTable: referenceTablesEnum, referenceId: number, referenceColumn: string, helperFunctionsService: HelperFunctionsService): any {

    let body = {
      "referenceTable": referenceTable,
      "referenceId": referenceId,
      "referenceColumn": referenceColumn
    }

    return this.getAspNetDataStoreComplete('id', 'post', 'translations/paging', body, helperFunctionsService);
  }

  public addOrUpdateTranslation(body: any): any {
    return this.postJson("/translations/addOrUpdate", JSON.stringify(body));
  }


  public getDocumentTemplatesTranslations(): Observable<any> {
    return this.fetch("/documentTemplatesTranslations");
  }

  public getDocumentTemplateTranslationsAspNetDataStore(referenceTable: referenceTablesEnum, referenceId: number, helperFunctionsService: HelperFunctionsService): any {

    let body = {
      "referenceTable": referenceTable,
      "referenceId": referenceId
    }

    return this.getAspNetDataStoreComplete('id', 'post', 'documentTemplatesTranslations/paging', body, helperFunctionsService);
  }

  public addOrUpdateDocumentTemplateTranslation(body: any): any {
    return this.postJson("/documentTemplatesTranslations/addOrUpdate", JSON.stringify(body));
  }

  public getLanguages(): Observable<any> {
    return this.fetch("/enumerations/languages");
  }

  getLanguageCode() {
    // let lan = this.i18nService.getLanguage();
    // debugger;
    // if (lan === "gr") {
    //   return "el-GR";
    // } else if (lan === "en") {
    //   return "en-GB";
    // } else {
    //   return "en-GB";
    // }
    return "en-GB";
  }

  public getClientDisciplinaryCases(id: any): Observable<any> {
    return this.fetch(`/clientCorporates/${id}/disciplinaryCase`);
  }

  public addClientDisciplinaryCase(form: FormGroup, clientId: any): Observable<any> {
    let body = {
      clientId: clientId,
      type: form.controls['type'].value,
      descriptionOfFailure: form.controls['descriptionOfFailure'].value,
      action: form.controls['action'].value,
      triggeringEvent: form.controls['triggeringEvent'].value,
      dateOfDecision: form.controls['dateOfDecision'].value,
      financiallyPenaltyAmount: form.controls['financiallyPenaltyAmount'].value
    };

    return this.postJson(`/clientCorporates/${clientId}/disciplinaryCase`, JSON.stringify(body));
  }

  public editClientDisciplinaryCase(newData: any, oldData: any, clientId: any): Observable<any> {
    let body = {
      id: newData.id ? newData.id : oldData.id,
      type: newData.type ? newData.type : oldData.type,
      descriptionOfFailure: newData.descriptionOfFailure ? newData.descriptionOfFailure : oldData.descriptionOfFailure,
      action: newData.action ? newData.action : oldData.action,
      triggeringEvent: newData.triggeringEvent ? newData.triggeringEvent : oldData.triggeringEvent,
      dateOfDecision: newData.dateOfDecision ? newData.dateOfDecision : oldData.dateOfDecision,
      financiallyPenaltyAmount: newData.financiallyPenaltyAmount ? newData.financiallyPenaltyAmount : oldData.financiallyPenaltyAmount
    };

    return this.putJson(`/clientCorporates/${clientId}/disciplinaryCase`, JSON.stringify(body));
  }

  public deleteClientDisciplinaryCase(clientId: any, id: any): Observable<any> {
    return this.delete(`/clientCorporates/${clientId}/disciplinaryCase/${id}`, {});
  }

  public getDisciplinaryCaseTypeEnum(): Observable<any> {
    return this.getCachedData("/enumerations/disciplinaryCaseTypesEnum");
  }

  public getDisciplinaryCaseTriggeringEventEnum(): Observable<any> {
    return this.getCachedData("/enumerations/disciplinaryCaseTriggeringEventEnum");
  }

  public getRegulatoryOrAdministrativeActionsEnum(): Observable<any> {
    return this.getCachedData("/enumerations/regulatoryOrAdministrativeActionsEnum");
  }

  public getDisciplinaryCaseDescriptionOfFailureEnum(): Observable<any> {
    return this.getCachedData("/enumerations/disciplinaryCaseDescriptionOfFailureEnum");
  }
}